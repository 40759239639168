import React from "react";
import { Navigate } from "react-router-dom";
import { useIsAuthenticatedForApp } from "shared/auth";
import { paths } from "../paths";

export const NoAuthRouteWrapper: React.FC = ({ children }) => {
  const isAuthenticated = useIsAuthenticatedForApp();

  if (isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: paths.app.conversations,
          search: window.location.search,
        }}
      />
    );
  }

  return <>{children}</>;
};
