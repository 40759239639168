import React from "react";
import { Box, styled } from "@mui/material";
import {
  AddEmailIcon,
  AttachmentIcon,
  IconButton,
  Menu,
} from "shared/components";
import { useToggle, validateFileSize } from "shared/utils";
import { useShowError } from "shared/notifications";

import { useChatContext } from "../context";
import { AddMailsToConversationDialog } from "./AddMailsToConversationDialog";
import {
  MAX_FILE_SIZE_IN_MB,
  MAX_IMAGE_SIZE_IN_MB,
  MAX_VIDEO_SIZE_IN_MB,
} from "shared/constants";

const errorInfo = `Selected files are too large. The maximum size of each file ${MAX_IMAGE_SIZE_IN_MB}MB for images, ${MAX_VIDEO_SIZE_IN_MB} for videos and ${MAX_FILE_SIZE_IN_MB}MB for other files.`;

export type ChatMediaMenuProps = {
  setAttachments: (attachments: File[]) => void;
  disabled?: boolean;
  attachments: File[] | null;
};

export const ChatMediaMenu: React.FC<ChatMediaMenuProps> = ({
  setAttachments,
  disabled,
  attachments,
}) => {
  const { conversation } = useChatContext();
  const [openMailsDialog, toggleMailsDialog] = useToggle();
  const showError = useShowError();

  const ref = useClearFileInput(attachments);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.currentTarget.files ?? []);

    if (!files.length) {
      return;
    }

    if (!files.every((f) => validateFileSize(f))) {
      return showError("File(s) to large", errorInfo);
    }

    setAttachments(files);
  };

  return (
    <Box data-testid="ChatMediaMenu">
      <Menu
        MenuButton={MenuButton}
        disabled={disabled}
        id="attach-files"
        label="attach files"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Menu.Item icon={<AddEmailIcon />} onClick={toggleMailsDialog}>
          Add email
        </Menu.Item>
        <Menu.Item
          icon={<AttachmentIcon />}
          onClick={() => ref.current?.click()}
        >
          Add file
        </Menu.Item>
      </Menu>
      <label htmlFor="attachment-button-input">
        <Input
          id="attachment-button-input"
          type="file"
          onChange={handleChange}
          ref={ref}
          multiple
        />
      </label>
      <AddMailsToConversationDialog
        onClose={toggleMailsDialog}
        dialogProps={{ open: openMailsDialog }}
        conversation={conversation}
      />
    </Box>
  );
};

const MenuButton = ({ ...props }) => (
  <IconButton
    icon={AttachmentIcon}
    color="transparent"
    label="attach files"
    sx={{ pr: 0 }}
    {...props}
  />
);

const useClearFileInput = (attachments: File[] | null) => {
  const ref = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (!attachments && ref.current) {
      ref.current.value = "";
    }
  }, [attachments]);
  return ref;
};

const Input = styled("input")({
  display: "none",
});
