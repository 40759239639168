import * as React from "react";
import { MoreVert } from "@mui/icons-material";
import {
  MenuItemProps,
  MenuProps as MuiMenuProps,
  Box,
  Link,
} from "@mui/material";
import { IconButton } from "./IconButton";
import { StyledLinkItem, StyledMenu, StyledMenuItem } from "./Menu.styles";

export type MenuButtonProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  id?: string | undefined;
  "aria-label"?: string | undefined;
  "aria-controls"?: string | undefined;
  "aria-haspopup"?: "true" | undefined;
  "aria-expanded"?: "true" | undefined;
};
export type MenuProps = {
  id: string;
  MenuButton?: (props: MenuButtonProps) => JSX.Element;
  disabled?: boolean;
  label?: string;
} & Omit<MuiMenuProps, "open">;

export const Menu = ({
  MenuButton = MenuButtonDefault,
  disabled,
  id,
  ...props
}: MenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <MenuButton
        onClick={handleClick}
        disabled={disabled}
        id={id + buttonIdSuffix}
        aria-label={props["aria-label"] || props.label}
        aria-controls={open ? id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      />
      <MenuComponent
        onClick={handleClose}
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": id + buttonIdSuffix,
        }}
        {...props}
      />
    </>
  );
};

const buttonIdSuffix = "-menu-button";

const MenuButtonDefault = ({ ...props }) => (
  <IconButton icon={MoreVert} label={"more"} {...props} />
);

const MenuComponent = (props: MuiMenuProps) => <StyledMenu {...props} />;

const MenuItem = ({
  danger,
  children,
  icon,
  href,
  ...props
}: MenuItemProps & { danger?: boolean; icon?: JSX.Element; href?: string }) => {
  const content = (
    <>
      {icon ? (
        <>
          <Box sx={{ display: "flex", alignItems: "center", marginRight: 2 }}>
            {icon}
          </Box>
          <span>{children}</span>
        </>
      ) : (
        children
      )}
    </>
  );

  return href ? (
    <StyledLinkItem
      {...props}
      href={href}
      className={(props.className ?? "") + (danger ? " danger" : "")}
    >
      {content}
    </StyledLinkItem>
  ) : (
    <StyledMenuItem
      {...props}
      className={(props.className ?? "") + (danger ? " danger" : "")}
    >
      {content}
    </StyledMenuItem>
  );
};

Menu.Item = MenuItem;
Menu.Menu = MenuComponent;
