import { Message } from "@twilio/conversations";
import { MessageType, getMessageType } from "shared/twilio";
import { isImage } from "shared/utils";
import { MessagePermissions } from "./model";

export function getMessagePermissions(
  message: Message,
  isOwner: boolean,
  isMine: boolean
): MessagePermissions {
  const type = getMessageType(message);

  const qoutable = isMessageQuotable(message);

  const permissions: MessagePermissions = {};

  if (qoutable) {
    permissions.quote = true;
  }

  if (type === MessageType.Email && isOwner) {
    permissions.delete = true;
  }

  if (type === MessageType.Text) {
    permissions.edit = isMine;
    permissions.delete = isOwner || isMine;
  }
  
  if (type === MessageType.Media) {
    permissions.delete = isOwner || isMine;
  }

  if (!Object.keys(permissions).length) {
    permissions.none = true;
  }

  return permissions;
}

export function isMessageQuotable(message: Message) {
  const type = getMessageType(message);

  if (type === MessageType.Text) {
    return true;
  }

  if (
    type === MessageType.Media &&
    message.attachedMedia?.length === 1 &&
    isImage(message.attachedMedia[0].contentType)
  ) {
    return true;
  }

  return false;
}
