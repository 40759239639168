import { useQuery, useQueryClient } from "react-query";

import { useIsAuthenticated } from "shared/auth";
import { useUser } from "shared/user";
import { useApi, QueryKey } from "shared/api";
import { useShowError } from "shared/notifications";
import { config } from "shared/config";
import { useInvalidateQueryOnPathChange } from "shared/router";
import { OutgoingInvitation } from "../model";

export function useOutgoingInvitations() {
  const queryClient = useQueryClient();
  const { get } = useApi();

  useInvalidateQueryOnPathChange(QueryKey.OutgoingInvitations);

  const isAuthenticated = useIsAuthenticated();
  const { isSupervisor } = useUser();
  const showError = useShowError();

  const { data, ...rest } = useQuery<OutgoingInvitation[]>(
    QueryKey.OutgoingInvitations,
    get(config.endpoints.invitations.outgoing),
    {
      enabled: isAuthenticated && isSupervisor,
      onError: () =>
        showError("Failed to fetch invitations", "Please try again later"),
    }
  );

  const invalidateOutgoingInvitations = () =>
    queryClient.invalidateQueries(QueryKey.OutgoingInvitations);

  return {
    outgoingInvitations: data || [],
    invalidateOutgoingInvitations,
    ...rest,
  };
}
