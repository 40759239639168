import { Skeleton } from "@mui/material";

import { QueryDataWrapper } from "shared/api";
import { useUser } from "shared/user";
import { DeletedIcon } from "shared/components";
import {
  MessageQuoteAuthor,
  MessageQuoteBody,
  MessageQuoteWrapper,
} from "./layout";
import { QuotedMediaMessage } from "./QuotedMediaMessage";
import { QuotedTextMessage } from "./QuotedTextMessage";
import { useQuotedMessage } from "./useQuotedMessage";

type Props = { sid: string; index: number; isMine: boolean };

export const MessageQuote = ({ index, isMine }: Props) => {
  const result = useQuotedMessage(index);

  const { user } = useUser();

  const isMyQuote = result.data
    ? result.data.message.author === user.id
    : false;

  return (
    <QueryDataWrapper
      result={result}
      errorView={<ErrorView isMine={isMine} />}
      loadingView={<LoadingView isMine={isMine} />}
    >
      {({ data: { author, message } }) =>
        message.type === "media" ? (
          <QuotedMediaMessage
            message={message}
            author={author}
            isMyQuote={isMyQuote}
            isMine={isMine}
          />
        ) : (
          <QuotedTextMessage
            message={message}
            author={author}
            isMyQuote={isMyQuote}
            isMine={isMine}
          />
        )
      }
    </QueryDataWrapper>
  );
};

const ErrorView = ({ isMine }: { isMine: boolean }) => {
  return (
    <MessageQuoteWrapper isMine={isMine}>
      <MessageQuoteAuthor>Deleted</MessageQuoteAuthor>
      <MessageQuoteBody deleted>
        <DeletedIcon sx={{ paddingRight: 1, color: "grey.600" }} />
        Original message was deleted.
      </MessageQuoteBody>
    </MessageQuoteWrapper>
  );
};

const LoadingView = ({ isMine }: { isMine: boolean }) => {
  return (
    <MessageQuoteWrapper isMine={isMine}>
      <Skeleton
        component="div"
        variant="text"
        sx={{
          height: 10,
          width: 100,
          transform: "scale(1)",
        }}
      />
      <Skeleton
        component="div"
        variant="text"
        sx={{
          height: 25,
          width: 100,
          transform: "scale(1)",
        }}
      />
    </MessageQuoteWrapper>
  );
};
