import { AppHeader, MainButton, PageHeader } from "shared/components";

export const ConversationsHeader = ({ onClick }: { onClick: () => void }) => {
  return (
    <>
      <AppHeader
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <PageHeader title="Discussions" />
        <MainButton onClick={onClick}>New discussion</MainButton>
      </AppHeader>
    </>
  );
};
