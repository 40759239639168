export const colors = Object.freeze({
  markusBlue: {
    main: "#4453DC",
    dark: "#1428BF",
    light: "#3C4EED",
    disabled: "#9CA4EC",
  },
  grey: {
    main: "#ECEFF1",
    light: "#F9FAFC",
    dark: "#9AA8B5",
  },
  white: "#FFFFFF",
  diamondWhite: "#DEEEF2",
  superLightBlue: "#F6FBFF",
  blueAccent: "#1E66E7",
  redAlert: "#D6355B",
  haleNavy: {
    dark: "#455869",
    main: "#576E83",
    light: "#788b9b",
  },
  textDark: "#233443",
  info: {
    dark: "#2A8DFF",
    main: "#92CDFF",
    light: "#EDF8FF",
  },
});
