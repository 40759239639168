import React from "react";
import { styled } from "@mui/material";
import { Link, LinkProps } from "react-router-dom";
import { IconButton, IconButtonProps } from "./IconButton";
import { NavigateBackIcon } from "./icons";

export const PrevButtonLink: React.FC<
  LinkProps & { buttonProps?: Omit<IconButtonProps, "icon" | "label"> }
> = ({ buttonProps,...props }) => {
  return (
    <ButtonLink {...props}>
      <IconButton
        label="navigate back"
        icon={NavigateBackIcon}
        {...buttonProps}
        sx={{ background: "transparent", ...buttonProps?.sx }}
      ></IconButton>
    </ButtonLink>
  );
};

const ButtonLink = styled(Link)(() => ({
  textDecoration: "none",
}));
