import React from "react";
import {
  Avatar,
  Box,
  Stack,
  StackProps,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import { PublicIcon } from "shared/components";

type PublicStatusSectionProps = Omit<StackProps, "onChange"> & {
  isPublic: boolean;
  onChange: (value: boolean) => void;
};

export const PublicStatusSection: React.FC<PublicStatusSectionProps> = ({
  isPublic,
  onChange,
  ...props
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    return onChange(event.target.checked);
  };

  return (
    <Wrapper {...props}>
      <Avatar sx={{ height: 36, width: 36, backgroundColor: "primary.light" }}>
        <PublicIcon sx={{ fontSize: 16, color: "secondary.main" }} />
      </Avatar>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        width={1}
        maxWidth={360}
        overflow={"hidden"}
        textOverflow={"ellipsis"}
      >
        Set discussion as public
        <Typography variant={"caption"}>
          Public discussion allows users to join the discussion via a web
          browser
        </Typography>
      </Box>
      <Switch checked={isPublic} onChange={handleChange} />
    </Wrapper>
  );
};

const Wrapper = styled((props: StackProps) => (
  <Stack spacing={3} direction={"row"} {...props} />
))(({ theme: { palette, spacing, typography } }) => ({
  borderRadius: 14,
  color: palette.secondary.light,
  fontSize: typography.subtitle2.fontSize,
  fontWeight: 600,
  border: `1px solid ${palette.grey[200]}`,
  height: 90,
  padding: spacing(3),
  width: "100%",
}));
