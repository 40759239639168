import { Box, styled } from "@mui/material";
import React from "react";

import { ParticipantStatus } from "../model";

export type StatusBadgeProps = {
  title: string;
  upperCase?: boolean;
  onClick?: () => void;
};

export const StatusBadge: React.VFC<StatusBadgeProps> = ({
  title,
  upperCase = false,
  onClick,
}) => {
  if (onClick) {
    return (
      <StyledButtonBadge upperCase={upperCase} onClick={onClick}>
        {title}
      </StyledButtonBadge>
    );
  }
  return <StyledBadge upperCase={upperCase}>{title}</StyledBadge>;
};

type MappedBadgeProps = {
  onAccept: () => void;
  onInvite: () => void;
};

export const mapStatusToBadge =
  (status: ParticipantStatus) =>
  ({ onAccept, onInvite }: MappedBadgeProps) => {
    const connectStatus =
      status === ParticipantStatus.None ||
      status === ParticipantStatus.InvitedBy;

    const title = connectStatus ? "+ Connect" : status;

    const onClick = !connectStatus
      ? undefined
      : status === ParticipantStatus.None
      ? onInvite
      : onAccept;

    if (status === ParticipantStatus.Restricted) return null;
    return <StatusBadge title={title} onClick={onClick} />;
  };

const StyledBadge = styled(Box, {
  shouldForwardProp: (prop) => prop !== "upperCase",
})<Pick<StatusBadgeProps, "upperCase">>(
  ({ theme: { palette, spacing }, upperCase }) => ({
    backgroundColor: palette.grey[200],
    fontSize: 12,
    lineHeight: "24px",
    borderRadius: 8,
    padding: spacing(0, 3),
    textTransform: upperCase ? "uppercase" : "capitalize",
  })
);

const StyledButtonBadge = styled("button", {
  shouldForwardProp: (prop) => prop !== "upperCase",
})<Pick<StatusBadgeProps, "upperCase">>(
  ({ theme: { palette, spacing }, upperCase }) => ({
    cursor: "pointer",
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    fontSize: 12,
    lineHeight: "24px",
    borderRadius: 8,
    padding: spacing(0, 3),
    border: "none",
    outline: "none",
    fontWeight: 600,
    textTransform: upperCase ? "uppercase" : "capitalize",
  })
);
