import React from "react";
import { Button, ButtonProps, darken, lighten, styled } from "@mui/material";
import { colors } from "shared/theme";

export type MainButtonProps = ButtonProps & {
  color?: "primary" | "secondary" | "error";
  onClick?: () => void;
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  startIcon?: React.ReactNode;
};

export const MainButton: React.FC<MainButtonProps> = ({
  children,
  color = "primary",
  size = "medium",
  onClick,
  disabled,
  fullWidth,
  ...props
}) => {
  return (
    <StyledButton
      size={size}
      color={color}
      aria-disabled={disabled}
      disabled={disabled}
      onClick={onClick}
      {...props}
      sx={{ width: fullWidth ? "100%" : "auto", ...props.sx }}
    >
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(Button)(
  ({ theme: { spacing }, color, size, startIcon }) => ({
    justifyContent: "center",
    alignItems: "center",
    padding: spacing(
      ...(calculatePaddingSize(size, !!startIcon) as [number, number])
    ),
    color: color === "secondary" ? colors.haleNavy.main : colors.white,
    backgroundColor:
      color === "primary"
        ? colors.markusBlue.main
        : color === "error"
        ? colors.redAlert
        : colors.white,
    borderColor:
      color === "primary"
        ? colors.markusBlue.main
        : color === "error"
        ? colors.redAlert
        : colors.haleNavy.main,
    borderRadius: 14,
    borderWidth: 1,
    borderStyle: "solid",
    fontSize: size === "large" ? 16 : 14,
    textTransform: "none",
    lineHeight: "24px",
    "&:hover": {
      color: colors.white,
      backgroundColor:
        color === "primary"
          ? colors.markusBlue.light
          : color === "error"
          ? darken(colors.redAlert, 0.1)
          : colors.haleNavy.main,
      borderColor:
        color === "primary"
          ? colors.markusBlue.light
          : color === "error"
          ? darken(colors.redAlert, 0.1)
          : colors.haleNavy.main,
    },
    "&[aria-disabled=true]": {
      color: color === "primary" ? colors.white : colors.haleNavy.main,
      backgroundColor:
        color === "primary" ? colors.markusBlue.disabled : colors.white,
      borderColor:
        color === "primary"
          ? colors.markusBlue.disabled
          : color === "error"
          ? lighten(colors.redAlert, 0.1)
          : colors.haleNavy.main,
      opacity: color === "primary" ? 1 : 0.5,
    },
    "&:active": {
      backgroundColor:
        color === "primary"
          ? colors.markusBlue.dark
          : color === "error"
          ? colors.redAlert
          : colors.haleNavy.dark,
      borderColor:
        color === "primary"
          ? colors.markusBlue.dark
          : color === "error"
          ? colors.redAlert
          : colors.haleNavy.dark,
    },
    "&& svg": {
      fontSize: size === "large" ? 28 : 24,
    },
    "& .MuiButton-startIcon": {
      marginLeft: 0,
    },
  })
);

function calculatePaddingSize(
  size?: "large" | "medium" | "small",
  hasIcon = false
) {
  switch (size) {
    case "large":
      return hasIcon ? [4, 8, 4, 6] : [4, 8];
    case "medium":
      return hasIcon ? [3, 6, 3, 4] : [3, 6];
    default:
      return hasIcon ? [2, 5, 2, 3] : [2, 5];
  }
}
