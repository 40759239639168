import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const PublicIcon: React.FC<SvgIconProps> = (props) => {
  const fillProp = props.fill ?? "transparent";
  return (
    <SvgIcon
      width="25"
      height="24"
      viewBox="0 0 25 24"
      {...props}
      sx={{ fill: fillProp, ...props.sx }}
      aria-label="public icon"
    >
        <path
          d="M18.3877 10.8967C19.7827 10.7007 20.8567 9.50473 20.8597 8.05573C20.8597 6.62773 19.8187 5.44373 18.4537 5.21973"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.2285 14.2502C21.5795 14.4522 22.5225 14.9252 22.5225 15.9002C22.5225 16.5712 22.0785 17.0072 21.3605 17.2812"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3867 14.6638C9.17273 14.6638 6.42773 15.1508 6.42773 17.0958C6.42773 19.0398 9.15573 19.5408 12.3867 19.5408C15.6007 19.5408 18.3447 19.0588 18.3447 17.1128C18.3447 15.1668 15.6177 14.6638 12.3867 14.6638Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3867 11.8879C14.4957 11.8879 16.2057 10.1789 16.2057 8.06888C16.2057 5.95988 14.4957 4.24988 12.3867 4.24988C10.2777 4.24988 8.56766 5.95988 8.56766 8.06888C8.55966 10.1709 10.2567 11.8809 12.3587 11.8879H12.3867Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.38472 10.8967C4.98872 10.7007 3.91572 9.50473 3.91272 8.05573C3.91272 6.62773 4.95372 5.44373 6.31872 5.21973"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.54388 14.2502C3.19288 14.4522 2.24988 14.9252 2.24988 15.9002C2.24988 16.5712 2.69388 17.0072 3.41188 17.2812"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
    </SvgIcon>
  );
};
