import { formHookFactory, defaultInputState, InputState } from "shared/forms";

type RegisterFormState = {
  name: InputState;
  email: InputState;
  password: InputState;
};
type LoginFormState = {
  email: InputState;
  password: InputState;
};

const validatePassword = (val: string) =>
  val
    ? val.length >= 6 &&
      val.length < 50 &&
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(val)
    : true;
const validateEmail = (val: string) =>
  val ? val.length >= 3 && /@/.test(val) : true;

export const useRegisterForm = formHookFactory<RegisterFormState>({
  name: { ...defaultInputState("name") },
  email: { ...defaultInputState("email", validateEmail) },
  password: { ...defaultInputState("password", validatePassword) },
});

export const useLoginForm = formHookFactory<LoginFormState>({
  email: { ...defaultInputState("email") },
  password: { ...defaultInputState("password") },
});
