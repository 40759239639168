import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const AttachmentIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} width="19" height="20" viewBox="0 0 19 20" fill="none">
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="attachment icon"
    >
      <path
        d="M12.1716 5L5.58579 11.5858C4.80474 12.3668 4.80474 13.6332 5.58579 14.4142C6.36684 15.1953 7.63317 15.1953 8.41421 14.4142L14.8284 7.82843C16.3905 6.26633 16.3905 3.73367 14.8284 2.17157C13.2663 0.609476 10.7337 0.609476 9.17157 2.17157L2.75736 8.75736C0.414214 11.1005 0.414214 14.8995 2.75736 17.2426C5.1005 19.5858 8.89949 19.5858 11.2426 17.2426L17.5 11"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
