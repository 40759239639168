import React from "react";
import { Button, ButtonProps, lighten, styled } from "@mui/material";
import { Apple } from "@mui/icons-material";

export const AppleButton: React.FC<ButtonProps> = ({ children, ...props }) => (
  <StyledButton
    size={"large"}
    startIcon={<Apple color={"inherit"} />}
    {...props}
  >
    {children}
  </StyledButton>
);

const StyledButton = styled(Button)(({ theme: { palette, typography } }) => ({
  backgroundColor: palette.common.black,
  color: palette.common.white,
  borderRadius: 12,
  minHeight: 45,
  fontSize: typography.subtitle2.fontSize,
  "&:hover": {
    backgroundColor: lighten(palette.common.black, 0.2),
  },
}));
