import React from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogProps as MuiDialogProps,
  Stack,
} from "@mui/material";
import { useIsBreakpoint } from "shared/theme";
import { QueryDataWrapper } from "shared/api";
import { useCollaboratorsInvitations } from "shared/invitations";
import { Collaborator } from "shared/collaborators";
import { AppDialog, AppDialogTitle, LoadingButton, Input } from "shared/components";
import { UserConvertGuard } from "shared/user";
import { arraysDiff } from "shared/utils";
import { AnalyticsEvent, useTrackEvents } from "shared/analytics";
import { appendParticipant, removeParticipant } from "./utils";
import { ConversationEditState } from "./types";
import { Conversation, CreateConversationPayload, Participant } from "../types";
import { GeneralSettings } from "./GeneralSettings";
import { MediaSettings } from "./MediaSettings";
import { MembersSettings } from "./MembersSettings";

export type ChatInformationDialogProps = {
  onClose: () => void;
  onSubmit: (data: CreateConversationPayload) => void;
  initialData?: ConversationEditState;
  isLoading: boolean;
  isMineConversation?: boolean;
  dialogProps: MuiDialogProps;
  title: string;
  enableInvitations?: boolean;
  conversation?: Conversation;
  iosStyle?: boolean;
};

const defaultData: ConversationEditState = {
  subject: "",
  participantsByUserId: {},
  isPublic: false,
};

export const ConversationEditDialog: React.FC<ChatInformationDialogProps> = ({
  onClose,
  onSubmit,
  isLoading,
  isMineConversation = true,
  initialData = defaultData,
  dialogProps,
  title,
  conversation,
  iosStyle = false,
}) => {
  const isSmBreakpoint = useIsBreakpoint("sm");

  const trackEvent = useTrackEvents();

  const { invitationsData, isLoading: invitationsLoading } =
    useCollaboratorsInvitations();

  const [data, setData] = React.useState<ConversationEditState>(initialData);

  const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData((prev) => ({
      ...prev,
      subject: event.target.value,
    }));
  };

  const handleIsPublicChange = (value: boolean) => {
    setData((prev) => ({
      ...prev,
      isPublic: value,
    }));
  };

  const handleCheck = (checked: boolean, collaborator: Collaborator) => {
    setData(({ participantsByUserId, ...prev }) => ({
      ...prev,
      participantsByUserId: checked
        ? appendParticipant(collaborator, participantsByUserId)
        : removeParticipant(collaborator, participantsByUserId),
    }));
  };

  const handleTrackSubject = () => {
    if (
      conversation &&
      isMineConversation &&
      conversation.subject !== data.subject
    ) {
      trackEvent(AnalyticsEvent.discuss_edited_subject);
    }
  };
  const handleTrackParticipants = () => {
    if (!isMineConversation) {
      return;
    }

    const diff = arraysDiff(
      conversation?.participants ?? [],
      Object.values(data.participantsByUserId ?? {}),
      (a: Participant, b: Participant) => a.userId === b.userId
    );

    diff.added.forEach(() =>
      conversation
        ? trackEvent(AnalyticsEvent.collaborator_added_existing_discussion)
        : trackEvent(AnalyticsEvent.collaborator_added_new_discussion)
    );

    diff.removed.forEach(() =>
      conversation
        ? trackEvent(AnalyticsEvent.collaborator_removed_existing_discussion)
        : trackEvent(AnalyticsEvent.collaborator_added_existing_discussion)
    );
  };

  const handleSubmit = () => {
    handleTrackSubject();
    handleTrackParticipants();
    return onSubmit({
      subject: data.subject,
      isPublic: data.isPublic,
      participants: Object.values(data.participantsByUserId).map(
        ({ userId }) => ({ userId })
      ),
    });
  };

  React.useEffect(() => {
    if (!dialogProps.open) {
      setData(defaultData);
    } else {
      setData(initialData);
    }
  }, [dialogProps.open, initialData]);

  const handleDialogClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };
  
  return (
    <AppDialog
      {...dialogProps}
      fullScreen={isSmBreakpoint}
      onClick={handleDialogClick}
      onClose={onClose}
      iosStyle={iosStyle}
    >
      <AppDialogTitle title={title} onClose={onClose} iosStyle={iosStyle} />
      <UserConvertGuard isLogoVisible={false}>
        <>
          <DialogContent>
            <QueryDataWrapper
              result={{
                data: invitationsData,
                isLoading: invitationsLoading,
                error: null,
              }}
            >
              {({
                data: {
                  collaborators: { collaborators },
                  incomingInvitations,
                  outgoingInvitations,
                },
              }) => (
                <Stack alignItems="center" spacing={3} height={1}>
                  <Stack
                    maxWidth={436}
                    pt={{ xs: 4, md: 8 }}
                    spacing={3}
                    width={1}
                  >
                    <Input
                      disabled={!isMineConversation}
                      label={"Subject of discussion"}
                      placeholder={"No subject"}
                      value={data.subject}
                      onChange={handleSubjectChange}
                    />
                    <MembersSettings
                      collaborators={collaborators}
                      incomingInvitations={incomingInvitations}
                      outgoingInvitations={outgoingInvitations}
                      onChecked={handleCheck}
                      participantsByUserId={data.participantsByUserId}
                      conversation={conversation}
                      isMineConversation={isMineConversation}
                    />
                    <Box width={1}>
                      <GeneralSettings
                        isMineConversation={isMineConversation}
                        conversation={conversation}
                        publicStatus={data.isPublic}
                        onPublicStatusChange={handleIsPublicChange}
                      />
                    </Box>
                    {conversation && <MediaSettings onClose={onClose} />}
                  </Stack>
                </Stack>
              )}
            </QueryDataWrapper>
          </DialogContent>
          {isMineConversation && (
            <DialogActions>
              <Box width={360}>
                <LoadingButton
                  variant={"contained"}
                  fullWidth
                  onClick={handleSubmit}
                  loading={isLoading}
                  size={"large"}
                >
                  Done
                </LoadingButton>
              </Box>
            </DialogActions>
          )}
        </>
      </UserConvertGuard>
    </AppDialog>
  );
};
