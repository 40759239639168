import React from "react";
import { Box, BoxProps, Stack, styled, Typography } from "@mui/material";
import { colors } from "shared/theme";
import { ConversationTitle } from "./ConversationTitle";

type MessagesListItemProps = {
  title: string | JSX.Element;
  subtitle: string | JSX.Element;
  text?: string | JSX.Element;
  timestamp?: string;
  AvatarEl?: JSX.Element;
  prependTitle?: JSX.Element;
  Actions?: JSX.Element;
  compact?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

export const MessagesListItem: React.FC<MessagesListItemProps> = ({
  AvatarEl,
  prependTitle,
  Actions,
  title,
  subtitle,
  timestamp,
  text,
  compact = false,
  ...props
}) => {
  return (
    <ListItemWrapper
      {...props}
      compact={compact}
      height={compact ? 120 : 155}
      role={props.onClick ? "button" : undefined}
    >
      {AvatarEl}
      <ContentWrapper>
        <Stack
          direction={"row"}
          spacing={2}
          justifyContent={"space-between"}
          alignItems={"start"}
        >
          <ConversationTitle>
            {prependTitle} {title}
          </ConversationTitle>
          <Stack direction={"row"} spacing={1} alignItems={"center"} pt={1}>
            <Typography
              sx={{
                fontSize: 14,
                lineHeight: "24px",
                color: colors.haleNavy.main,
              }}
            >
              {timestamp}
            </Typography>
            {Actions}
          </Stack>
        </Stack>
        <Box
          display={"flex"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <SecondaryText component={"div"}>{subtitle}</SecondaryText>
        </Box>
        {!compact && (
          <SecondaryText
            component={"div"}
            mt={1}
            sx={{
              color: colors.haleNavy.main,
            }}
          >
            {text}
          </SecondaryText>
        )}
      </ContentWrapper>
    </ListItemWrapper>
  );
};

const ListItemWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "compact",
})<BoxProps & { compact: boolean }>(
  ({ theme: { spacing, breakpoints }, onClick, compact }) => ({
    display: "flex",
    cursor: onClick ? "pointer" : "inherit",
    backgroundColor: "inherit",
    padding: compact ? spacing(6, 6, 0) : spacing(8, 8, 0),
    borderBottom: `1px solid ${colors.diamondWhite}`,
    [breakpoints.down("sm")]: {
      padding: compact ? spacing(6, 2, 0) : spacing(8, 4, 0),
    },
  })
);

const SecondaryText = styled(Box)(({ theme: { breakpoints } }) => ({
  fontSize: 14,
  lineHeight: "24px",
  fontWeight: 400,
  color: colors.haleNavy.dark,
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  [breakpoints.down("lg")]: {
    maxWidth: "50%",
  },
  [breakpoints.down("md")]: {
    maxWidth: "70%",
  },
  [breakpoints.down("sm")]: {
    maxWidth: "100%",
  },
}));

const ContentWrapper = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  margin: spacing(0, 0, 0, 3),
  flex: 1,
  width: 0,
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
}));
