import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

import { useHandleConversationsMetadata } from "./useHandleConversationsMetadata";
import { useHandleTwilioClient } from "./useHandleTwilioClient";
import { useInvalidateConversations } from "./useInvalidateConversations";
import { TwilioContextState, ConversationMetaData } from "../model";

const initialState: TwilioContextState = {
  twilioClient: undefined,
  conversationsMetaData: {},
  resetUnreadMessagesCount: () => ({}),
  logout: () => Promise.resolve(),
  setConversationsMetaData: () => ({}),
};

const TwilioContext = createContext(initialState);

export const TwilioProvider: React.FC = ({ children }) => {
  const twilioClient = useHandleTwilioClient();

  const { conversationsMetaData, resetUnreadMessagesCount, setMetadata } =
    useHandleConversationsMetadata(twilioClient);

  useInvalidateConversations(twilioClient);

  const logout = React.useCallback(async () => {
    if (!twilioClient) return;

    await twilioClient.shutdown();
  }, [twilioClient]);

  return (
    <TwilioContext.Provider
      value={{
        twilioClient,
        conversationsMetaData,
        resetUnreadMessagesCount,
        logout,
        setConversationsMetaData: setMetadata
      }}
    >
      {children}
    </TwilioContext.Provider>
  );
};

export const useTwilioClient = () =>
  useContextSelector(TwilioContext, ({ twilioClient }) => twilioClient);

export const useTwilioLogout = () =>
  useContextSelector(TwilioContext, ({ logout }) => logout);

export const useResetUnreadMessagesCount = () =>
  useContextSelector(
    TwilioContext,
    ({ resetUnreadMessagesCount }) => resetUnreadMessagesCount
  );

export function useConversationsMetaData(): Record<
  string,
  ConversationMetaData
>;
export function useConversationsMetaData(
  conversationId: string
): ConversationMetaData;
export function useConversationsMetaData(conversationId?: string) {
  return useContextSelector(TwilioContext, ({ conversationsMetaData }) =>
    conversationId
      ? conversationsMetaData[conversationId]
      : conversationsMetaData
  );
}

export function useSetConversationsMetaData() {
  return useContextSelector(
    TwilioContext,
    ({ setConversationsMetaData }) => setConversationsMetaData
  );
}
