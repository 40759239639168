import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const NavigateBackIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
    sx={{ ...props.sx, fill: "transparent" }}
  >
    <path
      d="M4.25 12.2744L19.25 12.2744"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2998 18.2988L4.2498 12.2748L10.2998 6.24976"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
