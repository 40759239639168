import React from "react";
import { palette, useIsBreakpoint } from "shared/theme";
import { RemoveIcon, EditCircleIcon } from "shared/components";
import { UserAvatar } from "shared/user";
import {
  StyledAvatarWrapper,
  EditIconWrapper,
  CancelIconWrapper,
} from "./styles";
import { useBase64avatar } from "../context";

type ProfilePictureProps = {
  id: string;
  name: string;
  editPhoto: () => void;
  resetPhoto: () => void;
};

export const ProfilePicture: React.VFC<ProfilePictureProps> = ({
  id,
  name,
  editPhoto,
  resetPhoto,
}) => {
  const isBelowSmBreakpoint = useIsBreakpoint("sm");

  const avatarSize = isBelowSmBreakpoint ? 68 : 110;

  const avatarData = useBase64avatar();

  const handleResetPhoto = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    resetPhoto();
  };

  return (
    <StyledAvatarWrapper role="button" onClick={editPhoto}>
      <UserAvatar
        userId={id}
        userName={name}
        sx={{ width: avatarSize, height: avatarSize, fontSize: avatarSize / 2 }}
        size={avatarSize}
        avatarData={avatarData}
      />
      {isBelowSmBreakpoint && (
        <EditIconWrapper>
          <EditCircleIcon sx={{ color: "white", fill: palette.primary.main }} />
        </EditIconWrapper>
      )}
      {avatarData && (
        <CancelIconWrapper role="button" onClick={handleResetPhoto}>
          <RemoveIcon
            sx={{ fontSize: 48, color: "error.main", fill: "error.main" }}
          />
        </CancelIconWrapper>
      )}
    </StyledAvatarWrapper>
  );
};
