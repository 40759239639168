import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Document, Page } from "react-pdf";
import { Box } from "@mui/material";
import { Media } from "@twilio/conversations";
import { LoadingIndicator } from "shared/components";
import { PdfProps, usePreviewPdf } from "../hooks/usePreviewPdf";
import { ErrorView } from "./ErrorView";
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const PdfPreview = ({ media }: { media: Media }) => {
  const { containerProps, pdfProps, error } = usePreviewPdf(media);

  if (error) {
    return <ErrorView />;
  }

  return (
    <Box
      width={1}
      px={{ xs: 0, sm: 4 }}
      display="flex"
      alignItems="center"
      flexDirection={"column"}
      height={1}
      {...containerProps}
    >
      {pdfProps.file ? (
        <Box
          sx={{
            flex: "1 1 auto",
            overflowY: "auto",
            height: 0,
            bgcolor: "#D9D9D9",
          }}
        >
          <RenderPdf {...pdfProps} />
        </Box>
      ) : (
        <LoadingIndicator />
      )}
    </Box>
  );
};

const RenderPdf = ({ numPages, width, ...documentProps }: PdfProps) => {
  return (
    <Document {...documentProps} loading={<></>}>
      {Array.from(new Array(numPages), (_, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          width={width}
          loading={<></>}
        />
      ))}
    </Document>
  );
};
