import { Message } from "@twilio/conversations";
import React from "react";
import { getMessageType } from "shared/twilio";
import { getMessagePermissions } from "./utils";

export function useCheckMessage(
  isMine: boolean,
  isOwner: boolean,
  message: Message
) {
  return React.useMemo(
    () => ({
      permissions: getMessagePermissions(message, isOwner, isMine),
      type: getMessageType(message),
    }),
    [message, isOwner, isMine]
  );
}
