import { Stack, Typography, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { DirectoryBlueDottedIcon as DirIcon } from "shared/components";
import { MediaViewType } from "../model";
import { getMediaBinHash } from "../utils";

export const NoMediaFallback = () => {
  const { hash } = useLocation();
  const mediaHash = getMediaBinHash(hash) ?? MediaViewType.images;

  return (
    <Stack alignItems="center" flex={1} justifyContent="center" pb={8}>
      <Box mb={6}>
        <DirIcon sx={{ height: 71, width: 71 }} />
      </Box>
      <Typography
        variant="h2"
        textAlign={"center"}
        sx={{ fontSize: 20, lineHeight: 1.5 }}
      >{`There are no ${mediaHash} attached in this discussion`}</Typography>
    </Stack>
  );
};
