import { ChevronRight } from "@mui/icons-material";
import { Avatar, Box, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { DirectoryIcon } from "shared/components";
import {
  SectionButtonHeader,
  SectionButtonWrapper,
  SectionHeader,
  SectionWrapper,
} from "./layout";

export const MediaSettings = ({ onClose }: { onClose: () => void }) => {
  return (
    <SectionWrapper>
      <SectionHeader>Media</SectionHeader>
      <SectionButtonWrapper>
        <StyledLink to="media">
          <SectionButtonHeader onClick={onClose}>
            <Box display={"flex"} alignItems={"center"}>
              <Avatar
                sx={{
                  height: 36,
                  width: 36,
                  backgroundColor: "primary.light",
                  marginRight: 2,
                }}
              >
                <DirectoryIcon sx={{ color: "grey.500" }} />
              </Avatar>
              Emails, photos, files, and links
            </Box>
            <ChevronRight />
          </SectionButtonHeader>
        </StyledLink>
      </SectionButtonWrapper>
    </SectionWrapper>
  );
};
const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
  color: "inherit",
  display: "block",
}));
