import React from "react";
import GradingIcon from "@mui/icons-material/Grading";
import {
  SummarizeEmailDialog,
  useIsAIAuthorized,
  useSummarizeEmail,
} from "shared/ai";
import { ProButton } from "shared/components";
import { extractTextFromHtml } from "shared/gmail";
import { EmailMessage } from "shared/chat/shared";
import { getAvatarColor } from "shared/utils";

type SummarizeProps = { message: EmailMessage };

export const SummarizeEmail = ({ message }: SummarizeProps) => {
  const isAiPermitted = useIsAIAuthorized();

  if (!isAiPermitted) {
    return null;
  }

  return <EmailSummary message={message} />;
};

const EmailSummary = ({
  message: { externalProviderMsgId, htmlText, fromEmail, subject },
}: SummarizeProps) => {
  const { text, avatarColor } = React.useMemo(() => {
    return {
      text: extractTextFromHtml(htmlText),
      avatarColor: getAvatarColor(externalProviderMsgId),
    };
  }, [externalProviderMsgId, htmlText]);

  const { isLoading, summarizeEmail, emailSummary, setEmailSummary } =
    useSummarizeEmail(text, externalProviderMsgId);

  return (
    <>
      <ProButton
        icon={GradingIcon}
        onClick={() => summarizeEmail()}
        size={42}
        label="review the email"
      />
      <SummarizeEmailDialog
        isLoading={isLoading}
        onClose={() => setEmailSummary(undefined)}
        open={isLoading || !!emailSummary}
        from={fromEmail}
        subject={subject}
        avatarColor={avatarColor}
        summary={emailSummary}
      />
    </>
  );
};
