type MergeFiltersOptions = {
  emails: string[];
  domains: string[];
  emailsToAdd: string[];
  domainsToAdd: string[];
};

export function mergeFilters({
  emails,
  domains,
  domainsToAdd,
  emailsToAdd,
}: MergeFiltersOptions) {
  return [
    ...[...emails, ...emailsToAdd.filter((value) => Boolean(value))],
    ...[
      ...domains,
      ...domainsToAdd
        .filter((value) => Boolean(value))
        .map((val) => `*@${val}`),
    ],
  ];
}
