import { Box, Stack, styled, Typography } from "@mui/material";
import React from "react";
import { colors } from "shared/theme";

type MssageTileProps = {
  isMine: boolean;
  caption?: JSX.Element;
  avatar?: JSX.Element;
  isFullWidth?: boolean;
  timestamp?: string;
  children?: React.ReactNode;
};

export const MessageTile = ({
  timestamp,
  isMine,
  isFullWidth,
  caption,
  avatar,
  children,
}: MssageTileProps) => {
  const showAvatar = !isMine && !isFullWidth;
  return (
    <Stack width={1} data-testid="message-tile">
      {timestamp && <Timestamp>{timestamp}</Timestamp>}
      <Container
        leftAlign={Boolean(!isMine || isFullWidth)}
        mt={timestamp ? 2 : 6}
      >
        {!isFullWidth && <Box px={showAvatar ? 11 : 0}>{caption}</Box>}
        <Wrapper isMine={isMine} isFullWidth={isFullWidth}>
          {showAvatar && <Box mr={3}>{avatar}</Box>}
          <MessageWrapper isFullWidth={isFullWidth}>{children}</MessageWrapper>
        </Wrapper>
      </Container>
    </Stack>
  );
};

const Timestamp = styled(Typography)(({ theme: { spacing } }) => ({
  display: "flex",
  justifyContent: "center",
  color: colors.haleNavy.main,
  fontWeight: 400,
  fontSize: 14,
  lineHeight: "18px",
  margin: spacing(3, 0),
}));

const Container = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "leftAlign",
})<{ leftAlign: boolean }>(
  ({ theme: { spacing, breakpoints }, leftAlign }) => ({
    alignItems: leftAlign ? "flex-start" : "flex-end",
    [breakpoints.up("sm")]: {
      paddingRight: leftAlign ? 0 : spacing(4),
    },
  })
);

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMine" && prop !== "isFullWidth",
})<{ isMine: boolean; isFullWidth?: boolean }>(
  ({ theme: { breakpoints, spacing }, isMine, isFullWidth }) => ({
    paddingTop: spacing(2),
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: isMine && !isFullWidth ? "row-reverse" : "row",
    width: isFullWidth ? "100%" : "auto",
    maxWidth: "calc(100% - 48px)",
    [breakpoints.up("sm")]: {
      maxWidth: isFullWidth ? "calc(100% - 40px)" : "60%",
    },
  })
);

const MessageWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isFullWidth",
})<{ isFullWidth?: boolean }>(({ theme: { spacing }, isFullWidth }) => ({
  position: "relative",
  width: isFullWidth ? "100%" : "auto",
}));
