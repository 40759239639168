import { useUpdateChatInfo } from "shared/conversations";
import { useTrackEvents, AnalyticsEvent } from "shared/analytics";
import { useConversationsContext } from "../context";
import { UpdateConversationPayload } from "../types";

export const useTogglePublicStatus = (chatSid: string) => {
  const { updateConversation, conversationsById } = useConversationsContext();
  const conversation = conversationsById[chatSid];
  const trackEvent = useTrackEvents();

  const { updateChatInfo, isLoading } = useUpdateChatInfo(chatSid, {
    onSuccess: ({ publicChatUrl }) =>
      updateConversation({
        chatSid,
        publicChatUrl,
      }),
  });

  const togglePublicStatus = (isPublic: boolean) => {
    isPublic
      ? trackEvent(AnalyticsEvent.public_chat_enable_exisitng)
      : trackEvent(AnalyticsEvent.public_chat_disable_existing);

    const data: UpdateConversationPayload = {
      isPublic,
      participants: conversation.participants.map(
        ({ authorizationCode, userId }) => ({ authorizationCode, userId })
      ),
    };
    updateChatInfo(data);
  };

  return {
    togglePublicStatus,
    isLoading,
  };
};
