import React from "react";
import { Box, Switch } from "@mui/material";
import { LoadingIndicator } from "shared/components";
import { AddressCopy } from "./AddressCopy";

type Props = {
  address: string | null;
  title: string;
  text: string;
  icon: React.ReactElement;
  onChange: (checked: boolean) => void;
  isLoading: boolean;
  copyText?: string;
  onCopy?: () => void
};

export const AddressSwitch: React.FC<Props> = ({
  title,
  text,
  address,
  icon,
  onChange,
  isLoading,
  copyText = `${title} address copied to clipboard`,
  onCopy
}) => {
  const isEnabled = !!address;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <AddressCopy
      address={address}
      icon={icon}
      text={text}
      title={title}
      copyText={copyText}
      isLoading={isLoading}
      onCopy={onCopy}
      fullWidth
    >
      {isLoading ? (
        <Box width={114}>
          <LoadingIndicator />
        </Box>
      ) : (
        <Switch aria-label={title} data-testid={title} checked={isEnabled} onChange={handleChange} />
      )}
    </AddressCopy>
  );
};