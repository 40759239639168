import React from "react";
import { IconButton } from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreVert";

import {
  Collaborator,
  useReinviteCollaborator,
  useRemoveCollaborator,
  InvitationStatus,
} from "shared/collaborators";
import { LoadingIndicator } from "shared/components";
import { useIsBreakpoint } from "shared/theme";
import { QueryDataWrapper } from "shared/api";
import { copyToClipboard } from "shared/utils";
import { useShowNotification } from "shared/notifications";
import { UserAvatar } from "shared/user";
import {
  ContactActions,
  ContactData,
  ContactItem,
  ContactName,
  StatusBadge,
} from "./styles";
import { CollaboratorItemMenu } from "./CollaboratorItemMenu";
import { getMenuButtonId } from ".";

export type CollaboratorItemProps = {
  collaborator: Collaborator;
};

export const CollaboratorItem: React.VFC<CollaboratorItemProps> = ({
  collaborator,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const showNotification = useShowNotification();
  const badgeRef = React.useRef<HTMLDivElement>(null);

  const { reinviteCollaborator, isLoading: reinviteLoading } =
    useReinviteCollaborator();

  const { removeCollaborator, isLoading: removeLoading } =
    useRemoveCollaborator(collaborator.authorizationCode);

  const isLoading = reinviteLoading || removeLoading;

  const desktop = !useIsBreakpoint("md");

  const { name, invitationStatus, authorizationCode, userId } = collaborator;

  const id = userId || authorizationCode;

  const handleClick = () => {
    if (badgeRef.current) {
      setAnchorEl(badgeRef.current);
    }
  };

  const onReinvite = async () => {
    setAnchorEl(null);

    const { link } = await reinviteCollaborator(name, authorizationCode);

    const copied = await copyToClipboard(link);

    if (copied) {
      showNotification("Invitation link copied to clipboard");
    }
  };

  const onRemove = async () => {
    setAnchorEl(null);
    removeCollaborator();
  };

  return (
    <>
      <ContactItem
        desktop={desktop}
        onClick={handleClick}
        id={getMenuButtonId(id)}
        disabled={isLoading}
      >
        <ContactData>
          <UserAvatar
            userName={collaborator.name}
            userId={collaborator.userId}
            avatarColor={collaborator._avatarColor}
            size={40}
          />
          <ContactName noWrap>{name}</ContactName>
        </ContactData>

        <ContactActions>
          <QueryDataWrapper
            result={{
              data: null,
              error: null,
              isLoading,
            }}
            loadingView={<LoadingIndicator height={40} />}
          >
            {() => (
              <>
                <StatusBadge ref={badgeRef}>{invitationStatus}</StatusBadge>
                {desktop && (
                  <IconButton
                    disableRipple
                    onClick={handleClick}
                    id={getMenuButtonId(id)}
                  >
                    <MoreIcon />
                  </IconButton>
                )}
              </>
            )}
          </QueryDataWrapper>
        </ContactActions>
      </ContactItem>

      <CollaboratorItemMenu
        anchor={anchorEl}
        onClose={() => setAnchorEl(null)}
        id={id}
        onRemove={onRemove}
        onReinvite={
          invitationStatus === InvitationStatus.Pending ? onReinvite : undefined
        }
      />
    </>
  );
};
