import React from "react";
import { Box } from "@mui/material";
import { format } from "date-fns";
import { GmailBaseMessage, GmailMessage, useGmailMessage } from "shared/gmail";
import { HasOnCheckedCb } from "shared/types";
import { QueryDataWrapper } from "shared/api";
import {
  Checkbox,
  MessagesListItem,
  MessagesListItemSkeleton,
} from "shared/components";

type Props = HasOnCheckedCb<GmailMessage> & {
  message: GmailBaseMessage;
  isSelected: boolean;
};

export const ChecklistItem: React.FC<Props> = React.memo(
  ({ message, onChecked, isSelected, ...props }) => {
    const { id } = message;

    const messageResult = useGmailMessage(id);

    return (
      <QueryDataWrapper
        result={messageResult}
        loadingView={<MessagesListItemSkeleton />}
      >
        {({ data: message }) => (
          <MessagesListItem
            compact
            key={message.id}
            onClick={() => onChecked(!isSelected, message)}
            AvatarEl={
              <Box
                paddingRight={{ xs: 2, sm: 4 }}
                display={"flex"}
                alignItems={"start"}
                justifyContent={"center"}
              >
                <Checkbox
                  checked={isSelected}
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 18, color: "info.dark" },
                  }}
                />
              </Box>
            }
            title={message._subject}
            subtitle={message._from}
            timestamp={format(
              new Date(Number(message.internalDate)),
              "MM/dd/yyyy"
            )}
            text={message.snippet}
            {...props}
          />
        )}
      </QueryDataWrapper>
    );
  },
  (prev, next) => {
    return (
      prev.message.id === next.message.id && prev.isSelected === next.isSelected
    );
  }
);
