import React from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogProps,
  Stack,
  Typography,
  Divider,
} from "@mui/material";
import { useIsBreakpoint } from "shared/theme";
import {
  AppDialog,
  AppDialogTitle,
  LoadingButton,
  ConversationList,
  BlueButton,
} from "shared/components";
import {
  Conversation,
  useAttachEmailToConversation,
} from "shared/conversations";
import { useChecklist } from "shared/utils";
import { GmailMessage } from "shared/gmail";
import { useSortedConversations } from "shared/conversations";
import { CreateConversationWithEmail } from "./CreateConversationWithEmail";

export type Props = {
  onClose: () => void;
  dialogProps: DialogProps;
  mail: GmailMessage | null;
};

export const AddMailToConversationsDialog: React.FC<Props> = ({
  onClose,
  mail,
  dialogProps,
}) => {
  const isSmBreakpoint = useIsBreakpoint("sm");
  const conversations = useSortedConversations();

  const { attachEmailToConversation, isLoading } = useAttachEmailToConversation(
    {
      onSuccess: () => {
        onClose();
      },
    }
  );

  const [selected, handleCheck, reset] = useChecklist<Conversation>([], "id");

  const handleSubmit = () => {
    if (!mail) return;

    return Promise.all(
      Object.values(selected).map(({ chatSid }) =>
        attachEmailToConversation(chatSid, mail)
      )
    );
  };

  React.useEffect(() => {
    if (dialogProps.open) {
      reset();
    }
  }, [dialogProps.open]);

  const handleDialogClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  return (
    <AppDialog
      {...dialogProps}
      fullScreen={isSmBreakpoint}
      onClick={handleDialogClick}
      iosStyle={true}
      onClose={onClose}
    >
      <AppDialogTitle
        title={"Choose discussion"}
        onClose={onClose}
        iosStyle={true}
      />
      <DialogContent>
        <Stack
          height={1}
          width={1}
          spacing={4}
          alignItems={"center"}
          mt={1}
          px={{ sx: 0, md: 2 }}
        >
          {mail && (
            <CreateConversationWithEmail
              message={mail}
              ButtonEl={({ toggle }) => (
                <BlueButton
                  onClick={() => {
                    toggle();
                  }}
                  sx={{ maxWidth: 340 }}
                  variant="outlined"
                >
                  Add to new discussion
                </BlueButton>
              )}
            />
          )}
          <Box width={1}>
            <Divider sx={{ width: "100%" }}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: "grey.500",
                  padding: 2,
                }}
              >
                OR
              </Typography>
            </Divider>
          </Box>
          <Typography
            variant="h4"
            sx={{ fontSize: 16, fontWeight: 600, color: "grey.600" }}
          >
            Choose existing discussion
          </Typography>
          <ConversationList
            conversations={conversations}
            isHeaderVisible={false}
            isSelectable={true}
            isLinkable={false}
            flex={1}
            overflow={"hidden"}
            onChecked={handleCheck}
            selected={selected}
            width={1}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box
          width={360}
          margin="0 auto"
          display={"flex"}
          justifyContent={"center"}
        >
          <LoadingButton
            variant={"outlined"}
            fullWidth
            onClick={handleSubmit}
            disabled={!Object.values(selected).length}
            loading={isLoading}
            size={"large"}
          >
            Add to existing discussion
          </LoadingButton>
        </Box>
      </DialogActions>
    </AppDialog>
  );
};
