import { useQuery } from "react-query";
import { QueryKey } from "../api";
import { gmailConfig } from "./model";
import { getGmailApiConfig } from "./utils";
import { GmailLabelsResponse } from "./types";
import { indexBy } from "../utils";
import { useGoogleApi } from "../google-auth";

export const useGmailLabels = () => {
  const { get } = useGoogleApi();

  return useQuery<GmailLabelsResponse>(
    [QueryKey.GmailLabels],
    get(gmailConfig.endpoints.labels.replace(/:userId/, "me"), {
      ...getGmailApiConfig(),
      transformResponse: (response) => ({
        ...response,
        _labelsByName: indexBy(response.labels, "name"),
      }),
    }),
    {}
  );
};
