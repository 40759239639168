import { Media } from "@twilio/conversations";
import React from "react";
import { useDataReducer, DataActionType } from "shared/utils";


export function useLoadMediaUrl(media: Media) {
  const [state, dispatch] = useDataReducer<string>();

  React.useEffect(() => {
    if (!media) {
      return;
    }

    dispatch({ type: DataActionType.LOADING });

    media
      .getContentTemporaryUrl()
      .then((url) => dispatch({ type: DataActionType.DATA, payload: url }))
      .catch(() => dispatch({ type: DataActionType.ERROR }));
  }, [media, dispatch]);

  return state;
}
