import { Box, Skeleton, styled } from "@mui/material";
import React from "react";

export const Thumbnail = ({
  thumbnail,
  filename,
}: {
  thumbnail: string;
  filename: string;
}) => {
  return (
    <ImagePreview
      sx={{ backgroundImage: `url("${thumbnail}")` }}
      role="img"
      aria-label={`${filename} preview`}
    />
  );
};

const ImagePreview = styled(Box)(({ theme: { palette } }) => ({
  width: 45,
  height: 45,
  borderRadius: 4,
  backgroundColor: palette.grey[100],
  overflow: "hidden",
  backgroundSize: "cover",
  backgroundPosition: "center",
}));
