import { Box, Skeleton, Stack } from "@mui/material";
import { UseQueryResult } from "react-query";
import { AnalyticsEvent, useTrackEvents } from "shared/analytics";
import { QueryDataWrapper } from "shared/api";
import {
  PublicIcon,
  DirectoryIcon,
  RelayIcon,
  IconButton,
  MainButton,
} from "shared/components";
import { useShowNotification } from "shared/notifications";
import { PublicInvitation } from "shared/public-invitation";
import { useIsBreakpoint } from "shared/theme";
import { copyToClipboard } from "shared/utils";

type ChatHeaderControlPanelProps = {
  publicInvitationData: UseQueryResult<PublicInvitation, unknown>;
  isPublicChatView: boolean;
  publicChatUrl: string;
  emailRelayAddress: string;
  onMediaBinClick: () => void;
  menu: JSX.Element;
};
export const ChatHeaderControlPanel = ({
  isPublicChatView,
  publicInvitationData,
  publicChatUrl,
  emailRelayAddress,
  onMediaBinClick,
  menu,
}: ChatHeaderControlPanelProps) => {
  const isSmBreakpoint = useIsBreakpoint("sm");
  const showNotification = useShowNotification();

  const trackEvent = useTrackEvents();

  const showRelay = !!emailRelayAddress && !isSmBreakpoint;
  const showMedia = !isSmBreakpoint && !isPublicChatView;
  const showPublicChat =
    !isSmBreakpoint && !!publicChatUrl && !isPublicChatView;

  const handleCopyUrl = async (type: "relay" | "public chat") => {
    const copy = type === "public chat" ? publicChatUrl : emailRelayAddress;

    try {
      const successText =
        type === "public chat"
          ? "Copied public chat link"
          : "Copied email relay link";

      const event =
        type === "public chat"
          ? AnalyticsEvent.public_chat_copy
          : AnalyticsEvent.email_relay_copy;

      const copied = await copyToClipboard(copy);

      if (copied) {
        trackEvent(event);
        showNotification(successText);
      }
    } catch (_) {
      showNotification("Failed to copy", {
        disableAutoHide: true,
        extraInfo: copy,
      });
    }
  };

  return (
    <Stack direction={"row"} spacing={1}>
      {isPublicChatView && (
        <QueryDataWrapper
          result={publicInvitationData}
          loadingView={<Skeleton width={80} height={40} />}
        >
          {({ data }) =>
            data.enabled && (
              <a
                href={data.dynamicLinkUrl}
                target={"_blank"}
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Box mr={2}>
                  <MainButton size="small">
                    {isSmBreakpoint ? "Join" : "Join Markus"}
                  </MainButton>
                </Box>
              </a>
            )
          }
        </QueryDataWrapper>
      )}

      {showRelay && (
        <IconButton
          label="Copy email relay link"
          onClick={() => handleCopyUrl("relay")}
          icon={RelayIcon}
        />
      )}

      {showPublicChat && (
        <IconButton
          label="Copy public chat link"
          onClick={() => handleCopyUrl("public chat")}
          icon={PublicIcon}
        />
      )}

      {showMedia && (
        <IconButton
          onClick={onMediaBinClick}
          label="Toggle media bin"
          icon={DirectoryIcon}
        />
      )}
      {menu}
    </Stack>
  );
};
