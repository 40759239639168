/**
 * Splits file name on name and extension. Optionally trims name part
 */
export function splitFileName(fileName: string, maxLength?: number) {
  let name: string, extension: string;
  let trimmed = false;
  const parts = fileName.split(".");

  if (parts.length < 2) {
    // no extension
    name = parts[0].slice(0, maxLength);
    extension = "";
  } else {
    name = parts.slice(0, -1).join(".").slice(0, maxLength);
    extension = "." + parts.slice(-1)[0];
  }

  if (!name) {
    return { name: "attachment", extension, trimmed };
  }
  if (maxLength && fileName.length - extension.length > maxLength) {
    trimmed = true;
    name = name + "...";
  }

  return { name, extension, trimmed };
}
