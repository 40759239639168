export const config = {
  apiUrl: getApiUrl(),
  endpoints: {
    twilio: {
      token: "/v1/twilio/token",
    },
    collaborator: {
      v3: {
        authorization: "/v3/collaborator/authorization",
      },
      v2: {
        list: "/v2/collaborator/list",
        invite: "/v2/collaborator/invite",
      },
      v1: {
        conversation: "/v1/collaborator/conversation/:conversationId",
        main: "/v1/collaborator",
        reinvite: "/v1/collaborator/reinvite",
      },
    },
    boards: {
      collaborator: "/v1/boards/collaborator/:codeId",
    },

    webCollaborator: {
      web: "/v4/collaborator/web",
    },
    user: {
      v1: {
        main: "/v1/user",
        avatar: "/v1/user/:userId/avatar",
        setup: "/v1/user/setup",
        settings: "/v1/user/settings",
        rename: "/v1/user/rename",
        sendAvatar: "/v1/user/avatar",
        subscription: "/v1/user/:userId/subscription",
      },
      v2: {
        convert: "/v2/user/convert",
      },
    },
    auth: {
      main: "/v2/auth",
      token: "/v1/auth/token",
      register: "/v1/auth/register",
      login: "/v1/auth/login",
      activate: "/v1/auth/activate/:code",
      resend: "/v1/auth/resend/:code",
      resetPassword: "/v1/auth/password/reset",
      changePassword: "/v1/auth/password/change",
      logout: "/v1/auth/logout",
    },
    google: {
      auth: "/auth/google",
      refreshToken: "/auth/google/refresh-token",
    },
    invitations: {
      main: "/v1/invitations",
      incoming: "/v1/invitations/incoming",
      outgoing: "/v1/invitations/outgoing",
      invitation: "/v1/invitations/:invitationId",
    },
    linkPreview: {
      main: "/v1/link/preview",
    },
    messages: {
      v1: {
        main: "/v1/messages/:messageId",
      },
    },
    publicInvitations: {
      v1: {
        main: "/v1/public-invitations",
        mainWithId: "/v1/public-invitations/:publicInvitationId",
        accept: "/v1/public-invitations/:publicInvitationId/accept",
        check: "/v1/public-invitations/check",
      },
    },
    conversations: {
      main: "/v1/conversations",
      conversation: "/v1/conversations/:chatSid",
      emails: "/v1/conversations/:chatSid/emails",
      email: "/v1/conversations/:chatSid/emails/:emailId",
      message: "/v1/conversations/:chatSid/messages/:messageSid",
      media: "/v1/conversations/:chatSid/media",
    },
    notifications: {
      callUsers: "/v1/notifications/call-users",
    },
    ai: {
      emailSummary: "/v1/ai/text/summarize",
      checkSpelling: "/v1/ai/text/spelling",
      transform: "/v1/ai/text/transform",
      prompt: "/v1/ai/text/prompt",
    },
  },
};

function getApiUrl() {
  const fallbackUrl = "https://api.dev.aws.gomarkus.com";
  if (process.env.NODE_ENV === "test") {
    return fallbackUrl;
  }
  return process.env.REACT_APP_API_URL ?? fallbackUrl;
}
