import React from "react";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";

import { QueryKey } from "../api";

export function useInvalidateQueryOnPathChange(query: QueryKey | QueryKey[]) {
  const { pathname } = useLocation();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.invalidateQueries(query);
  }, [pathname, queryClient, query]);
}
