export enum LinkType {
  YouTube = "YouTube",
  Text = "Text",
}

type YouToubeLinkInfo = {
  type: LinkType.YouTube;
  link: string;
  videoId: string;
};
type TextLinkInfo = {
  type: LinkType.Text;
  link: string;
};

export type LinkInfo = YouToubeLinkInfo | TextLinkInfo;
