import { SvgIcon, SvgIconProps } from "@mui/material";

export const DirectoryBlueDottedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="71"
      height="70"
      viewBox="0 0 71 70"
      fill="none"
      {...props}
      sx={{ ...props.sx, fill: "transparent" }}
      aria-label="directory blue dotted icon"
    >
      <g filter="url(#filter0_i_9976_16009)">
        <circle cx="35.4987" cy="34.9987" r="29.569" fill="#F6FAFF" />
      </g>
      <circle
        cx="35.4995"
        cy="34.9995"
        r="21.8276"
        fill="url(#paint0_radial_9976_16009)"
        stroke="#D3F1FF"
      />
      <circle
        cx="35.5012"
        cy="35.0012"
        r="23.6379"
        fill="url(#paint1_radial_9976_16009)"
        stroke="#D3F1FF"
      />
      <path
        d="M27 30V40C27 41.1046 27.8954 42 29 42H43C44.1046 42 45 41.1046 45 40V32C45 30.8954 44.1046 30 43 30H37L35 28H29C27.8954 28 27 28.8954 27 30Z"
        stroke="#2A8DFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="35.5"
        cy="35"
        r="34.5"
        stroke="#DCE7FF"
        strokeLinejoin="round"
        strokeDasharray="3 3"
      />
      <defs>
        <filter
          id="filter0_i_9976_16009"
          x="5.92969"
          y="5.42969"
          width="59.1367"
          height="59.1367"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_9976_16009"
          />
        </filter>
        <radialGradient
          id="paint0_radial_9976_16009"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.4995 34.9995) rotate(90) scale(27.4569 32.7711)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#DDE9FF" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_9976_16009"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.5012 35.0012) rotate(90) scale(46.6449 55.673)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#DDE9FF" />
        </radialGradient>
      </defs>
    </SvgIcon>
  );
};
