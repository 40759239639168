import { useLocation } from "react-router-dom";
import { SettingType } from "../model";

export function useCurrentSetting() {
  const { pathname } = useLocation();

  return mapPathToSettingType(pathname);
}

function mapPathToSettingType(path: string): SettingType | null {
  const sections = path.replace(/\/$/, "").split("/");

  const lastSection = sections[sections.length - 1];

  if (lastSection in SettingType) {
    return lastSection as SettingType;
  }

  return null;
}
