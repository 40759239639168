import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ArrowRightIcon: React.FC<SvgIconProps> = (props) => {
  const fillProp = props.fill ?? "transparent";

  return (
    <SvgIcon
      width="12"
      height="21"
      viewBox="0 0 12 21"
      {...props}
      sx={{ fill: fillProp, ...props.sx }}
      aria-label="arrow right icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.46287 0.417333L11.7078 9.5542C12.0974 9.93881 12.0974 10.5602 11.7078 10.9458L2.46286 20.0827C1.90053 20.6391 0.985625 20.6391 0.422297 20.0827C-0.140034 19.5262 -0.140034 18.6231 0.422297 18.0667L8.33087 10.2495L0.422298 2.43432C-0.140032 1.87687 -0.140032 0.973777 0.422298 0.417333C0.985627 -0.139112 1.90054 -0.139111 2.46287 0.417333Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
