import React from "react";
import { Box, BoxProps, CircularProgress } from "@mui/material";
import {colors} from "shared/theme";

export type LoadingIndicatorProps = BoxProps & {
  size?: number;
  color?: string;
};

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  size = 30,
  color = colors.markusBlue.main,
  ...props
}) => {
  return (
    <Box
      width={1}
      height={1}
      minHeight={size + 5}
      minWidth={size + 10}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      {...props}
    >
      <CircularProgress
        size={size}
        sx={{ color }}
        aria-label="loading-indicator"
        aria-busy="true"
        aria-live="polite"
      />
    </Box>
  );
};
