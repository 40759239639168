import React from "react";
import {
  createContext,
  useContext,
  useContextSelector,
} from "use-context-selector";
import { indexBy } from "shared/utils";
import { QueryDataWrapper } from "shared/api";

import { useConversations } from "../hooks";
import { ConversationsContextState, UpdateConversationData } from "./types";
import { Conversation } from "../types";
import { AppLoader } from "shared/components";

const initialContext: ConversationsContextState = {
  conversationsById: {},
  isLoading: false,
  error: null,
  updateConversation: () => ({}),
  removeConversation: () => ({}),
  addConversation: () => ({}),
};

const ConversationsContext = createContext(initialContext);

export const ConversationsProvider: React.FC = ({ children }) => {
  const [conversationsById, setConversationsById] = React.useState<
    Record<string, Conversation>
  >({});

  const conversationsResult = useConversations({
    onSuccess: (response) => {
      setConversationsById(indexBy(response, "chatSid"));
    },
  });


  const updateConversation = React.useCallback(
    (conversation: UpdateConversationData) => {
      setConversationsById((prev) => ({
        ...prev,
        [conversation.chatSid]: {
          ...prev[conversation.chatSid],
          ...conversation,
        },
      }));
    },
    []
  );

  const removeConversation = React.useCallback((conversationId: string) => {
    setConversationsById((prev) => {
      const { [conversationId]: removedSid, ...next } = prev;

      return next;
    });
  }, []);

  const addConversation = React.useCallback((conversation: Conversation) => {
    setConversationsById((prev) => ({
      [conversation.chatSid]: {
        ...conversation,
        metadata: {
          lastMessage: null,
          unreadMessagesCount: 0,
          updateDate: new Date(),
        },
      },
      ...prev,
    }));
  }, []);

  return (
    <QueryDataWrapper
      result={conversationsResult}
      loadingView={<AppLoader />}
    >
      {() => (
        <ConversationsContext.Provider
          value={{
            conversationsById,
            isLoading: conversationsResult.isLoading,
            error: conversationsResult.error,
            updateConversation,
            removeConversation,
            addConversation,
          }}
        >
          {children}
        </ConversationsContext.Provider>
      )}
    </QueryDataWrapper>
  );
};

export const useConversationsContext = () => useContext(ConversationsContext);

export const useConversationsById = () =>
  useContextSelector(
    ConversationsContext,
    ({ conversationsById }) => conversationsById
  );

export const useAddConversation = () =>
  useContextSelector(
    ConversationsContext,
    ({ addConversation }) => addConversation
  );

export const useIsConversationsLoading = () =>
  useContextSelector(ConversationsContext, ({ isLoading }) => isLoading);
