import { paths } from "shared/router";
import { ErrorPageMessages, ErrorPagesEnum } from "./model";

export function getErrorPage(error: keyof typeof ErrorPagesEnum) {
  return paths.error.replace(":error", ErrorPagesEnum[error]);
}

export function isNotFoundMessage(error: string) {
  return error === ErrorPageMessages[ErrorPagesEnum.NotFound].title;
}
