import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const MessageIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    width="25"
    height="24"
    viewBox="0 0 25 24"
    {...props}
    aria-label="message icon"
    sx={{ ...props.sx, fill: "transparent" }}
  >
    <path
      d="M18.4026 8.8512L13.9593 12.4642C13.1198 13.1302 11.9387 13.1302 11.0992 12.4642L6.61841 8.8512"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4089 21C20.4502 21.0084 22.5 18.5095 22.5 15.4384V8.57001C22.5 5.49883 20.4502 3 17.4089 3H7.59114C4.54979 3 2.5 5.49883 2.5 8.57001V15.4384C2.5 18.5095 4.54979 21.0084 7.59114 21H17.4089Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
