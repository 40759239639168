export enum RouteWrapperType {
  Auth = 'Auth',
  NoAuth = 'NoAuth',
  AuthPublic = 'AuthPublic',
  NoAuthPublic = 'NoAuthPublic',
  None = 'None',
}

export type AppRoute = {
  path: string;
  element: any;
  wrapper: RouteWrapperType;
  children?: AppRoute[]
};
