import React from "react";
import jwt_decode from "jwt-decode";
import { useGoogleAuthContext } from "../google-auth";
import { useUser } from "../user";

export function useGoogleProfilePicture(userId?: string) {
  const { user } = useUser();
  const { authData } = useGoogleAuthContext();

  const [pictureSrc, setPictureSrc] = React.useState<string>();

  React.useEffect(() => {
    if (userId && userId !== user.id) {
      return;
    }

    if (!authData.id_token) {
      return;
    }

    const data: { picture?: string } = jwt_decode(authData.id_token);
    if (isObjType(data) && data.picture) {
      setPictureSrc(data.picture);
    }
  }, [authData.id_token, user.id, userId]);

  return pictureSrc;
}

const isObjType = (arg: unknown): arg is { [key: string]: unknown } => {
  return typeof arg === "object" && arg !== null;
};
