import { AddressCopy, RelayIcon } from "shared/components";

type Props = {
  emailRelayAddress: string | null;
};

export const EmailRelayCopy = ({
  emailRelayAddress
}: Props) => {

  return (
    <AddressCopy
      fullWidth
      address={emailRelayAddress}
      icon={<RelayIcon sx={{ fontSize: 16, color: "secondary.main" }} />}
      title={"Email Relay"}
      text={
        "Participants can forward emails directly to this thread"
      }
    />
  );
};
