import React from "react";
import { Box } from "@mui/material";
import { ArchiveIcon, DiscussIcon, HelpIcon, Menu } from "shared/components";
import {
  gmailLabels,
  GmailMessage,
  LabelId,
  useGmailLabels,
  useModifyGmailMessage,
} from "shared/gmail";
import { useTrackEmailAction } from "shared/analytics";

import { AddMailToConversationsDialog } from "./AddMailToConversationsDialog";
import { useToggle } from "shared/utils";

type GmailMailMenuProps = {
  anchor: HTMLElement | null;
  onClose: () => void;
  message: GmailMessage | null;
  onRefreshMessages?: () => void;
};

const helpUrl = "https://www.gomarkus.com/help.html";

export const GmailMailMenu: React.FC<GmailMailMenuProps> = ({
  message,
  onRefreshMessages,
  anchor,
  onClose,
}) => {
  const { data: labels } = useGmailLabels();
  const { modifyGmailMessage } = useModifyGmailMessage(message?.id);
  const trackEmailAction = useTrackEmailAction();

  const [dialogOpen, toggleDialog] = useToggle();

  const handleArchiveClick = () => {
    const archivedLabelId = labels?._labelsByName?.[gmailLabels.archived]?.id;

    if (!archivedLabelId || !message) {
      return;
    }

    trackEmailAction({ action: "archive" });

    modifyGmailMessage(
      {
        addLabelIds: [archivedLabelId],
        removeLabelIds: [LabelId.Unread],
      },
      {
        onSuccess: () => {
          onRefreshMessages?.();
        },
      }
    );
  };

  return (
    <Box>
      <Menu.Menu
        open={!!anchor}
        id={"gmail_menu"}
        anchorEl={anchor}
        onClose={onClose}
        onClick={onClose}
      >
        <Menu.Item
          onClick={() => toggleDialog()}
          icon={<DiscussIcon color={"primary"} />}
        >
          Discuss
        </Menu.Item>
        <Menu.Item
          onClick={handleArchiveClick}
          icon={<ArchiveIcon color={"primary"} />}
        >
          Archive
        </Menu.Item>
        <Menu.Item href={helpUrl} icon={<HelpIcon color={"primary"} />}>
          Help
        </Menu.Item>
      </Menu.Menu>
      <AddMailToConversationsDialog
        mail={message}
        onClose={toggleDialog}
        dialogProps={{ open: dialogOpen }}
      />
    </Box>
  );
};
