import { Message } from "@twilio/conversations";
import {
  MessageQuoteAuthor,
  MessageQuoteBody,
  MessageQuoteWrapper,
} from "./layout";

type Props = {
  message: Message;
  author: string;
  isMyQuote: boolean;
  isMine: boolean;
};

export const QuotedTextMessage = ({
  author,
  isMyQuote,
  message,
  isMine,
}: Props) => {
  
  const text = trimMessageText(message.body);

  return (
    <MessageQuoteWrapper isMine={isMine}>
      <MessageQuoteAuthor>{isMyQuote ? "You" : author}</MessageQuoteAuthor>
      <MessageQuoteBody>{text}</MessageQuoteBody>
    </MessageQuoteWrapper>
  );
};

const trimMessageText = (text: string | null) => {
  if (!text) {
    return "";
  }
  return text.length > 150 ? text?.slice(0, 150) + "..." : text;
};
