import React from "react";
import { stringify } from "query-string";
import { config } from "../config";
import { handleUnexpectedError, ApiError } from "../api";
import { useGoogleTokenManager } from "../google-auth";
import { useLogout } from "shared/auth";

type RequestConfig<T> = {
  headers?: Record<string, string>;
  transformResponse?: (response: T) => T;
  apiUrl?: string;
  query?: any;
};

const defaultApiUrl = config.apiUrl as string;

const getDefaultHeaders = (token: string) => ({
  "Content-Type": "application/json",
  ...(token && { Authorization: `Bearer ${token}` }),
});

export const useGoogleApi = () => {
  const { requestToken } = useGoogleTokenManager();
  const logout = useLogout();

  const handleError = React.useCallback(
    async (response: Response, body?: any) => {
      if (response.status <= 400) {
        return;
      }

      if (response.status === 401) {
        return logout();
      }

      if (
        response.status === 500 &&
        body?.message === "Error while refreshing access_token"
      ) {
        return logout();
      }

      throw new ApiError("Unexpected error", response.status);
    },
    [logout]
  );

  const handleJSONResponse = React.useCallback(
    async (response: Response) => {
      const body = await response.json().catch(() => undefined);
      await handleError(response, body);
      return body;
    },
    [handleError]
  );

  const get = React.useCallback(
    <TResponse>(url: string, config: RequestConfig<TResponse> = {}) =>
      async (): Promise<TResponse> => {
        const apiUrl = config.apiUrl || defaultApiUrl;
        const token = await requestToken();

        return fetch(
          `${apiUrl}${url}${config.query ? "?" : ""}${stringify(
            config.query || {}
          )}`,
          {
            headers: {
              ...getDefaultHeaders(token),
              ...config.headers,
            },
          }
        )
          .then(handleJSONResponse)
          .then(config.transformResponse)
          .catch(handleUnexpectedError);
      },
    [handleJSONResponse, requestToken]
  );

  const post = React.useCallback(
    <TBody, TResponse, TError = Error>(
        url: string,
        config: RequestConfig<TResponse> = {}
      ) =>
      async (body: TBody): Promise<TResponse> => {
        const apiUrl = config.apiUrl || defaultApiUrl;
        const token = await requestToken();

        return fetch(
          `${apiUrl}${url}${config.query ? "?" : ""}${stringify(
            config.query || {}
          )}`,
          {
            method: "POST",
            headers: {
              ...getDefaultHeaders(token),
              ...config.headers,
            },
            body: JSON.stringify(body),
          }
        )
          .then(handleJSONResponse)
          .then(config.transformResponse)
          .catch(handleUnexpectedError);
      },
    [handleJSONResponse, requestToken]
  );

  const patch = React.useCallback(
    <TBody, TResponse, TError = Error>(
        url: string,
        config: RequestConfig<TResponse> = {}
      ) =>
      async (body: TBody): Promise<TResponse> => {
        const apiUrl = config.apiUrl || defaultApiUrl;
        const token = await requestToken();

        return fetch(
          `${apiUrl}${url}${config.query ? "?" : ""}${stringify(
            config.query || {}
          )}`,
          {
            method: "PATCH",
            headers: {
              ...getDefaultHeaders(token),
              ...config.headers,
            },
            body: JSON.stringify(body),
          }
        )
          .then(handleJSONResponse)
          .then(config.transformResponse)
          .catch(handleUnexpectedError);
      },
    [handleJSONResponse, requestToken]
  );

  const deleteRequest = React.useCallback(
    <TResponse>(url: string, config: RequestConfig<TResponse> = {}) =>
      async (): Promise<TResponse> => {
        const apiUrl = config.apiUrl || defaultApiUrl;
        const token = await requestToken();

        return fetch(
          `${apiUrl}${url}${config.query ? "?" : ""}${stringify(
            config.query || {}
          )}`,
          {
            method: "DELETE",
            headers: {
              ...getDefaultHeaders(token),
              ...config.headers,
            },
          }
        )
          .then(handleJSONResponse)
          .then(config.transformResponse)
          .catch(handleUnexpectedError);
      },
    [handleJSONResponse, requestToken]
  );

  return React.useMemo(() => {
    return {
      get,
      deleteRequest,
      post,
      patch,
    };
  }, [get, deleteRequest, post, patch]);
};
