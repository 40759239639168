import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";
import { theme } from "shared/theme";

export const MessageCircleIcon: React.FC<SvgIconProps> = ({
  fill = theme.palette.info.light,
  ...props
}) => {
  return (
    <SvgIcon
      viewBox="0 0 29 29"
      {...props}
      aria-label="message circle icon"
      sx={{ fill, ...props.sx }}
    >
      <circle cx="14.5" cy="14.5" r="14.5" />
      <path
        d="M19.0427 12.6562L15.8769 15.2305C15.2787 15.7051 14.4372 15.7051 13.839 15.2305L10.6465 12.6562"
        stroke="currentColor"
        strokeWidth="1.1875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1965 8.85547H10.6465C9.33481 8.85547 8.27148 9.91879 8.27148 11.2305V18.3555C8.27148 19.6671 9.33481 20.7305 10.6465 20.7305H19.1965C20.5082 20.7305 21.5715 19.6671 21.5715 18.3555V11.2305C21.5715 9.91879 20.5082 8.85547 19.1965 8.85547ZM10.6465 7.66797C8.67897 7.66797 7.08398 9.26295 7.08398 11.2305V18.3555C7.08398 20.323 8.67897 21.918 10.6465 21.918H19.1965C21.164 21.918 22.759 20.323 22.759 18.3555V11.2305C22.759 9.26295 21.164 7.66797 19.1965 7.66797H10.6465Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
