import { useNavigate } from "react-router-dom";
import { UseBaseQueryOptions } from "react-query";
import { getConversationPath } from "shared/router";
import { GmailMessage } from "shared/gmail";
import { useCreateConversation } from "shared/conversations";
import { useShowError } from "shared/notifications";
import { AnalyticsEvent, useTrackEvents } from "shared/analytics";

import { AttachMessageResponse } from "./types";
import { CreateConversationPayload } from "../types";
import { useAttachEmailToConversation } from "./useAttachEmailToConversation";

export const useCreateConversationWithMail = (
  message: GmailMessage,
  options: Pick<
    UseBaseQueryOptions<AttachMessageResponse>,
    "onSuccess" | "onError"
  > = {}
) => {
  const showError = useShowError();

  const { isLoading: attachEmailIsLoading, attachEmailToConversation } =
    useAttachEmailToConversation();

  const { isLoading: createConversationLoading, createConversation } =
    useCreateConversation({ navigateOnSuccess: false });

  const isLoading = attachEmailIsLoading || createConversationLoading;

  const navigate = useNavigate();

  const trackEvent = useTrackEvents();

  const createConversationWithEmail = async (
    data: CreateConversationPayload
  ) => {
    try {
      const conversation = await createConversation(data);
      const attachedMessage = await attachEmailToConversation(
        conversation.chatSid,
        message
      );

      if (!conversation || !attachedMessage) {
        throw new Error("Creating conversation with mail failed");
      }

      const response = { ...conversation, message: attachedMessage };

      trackEvent(AnalyticsEvent.discuss_create_chat, {
        email: true,
      });

      if (response.publicChatUrl) {
        trackEvent(AnalyticsEvent.public_chat_enable_on_create);
      }

      options.onSuccess?.(response);

      navigate(getConversationPath(conversation.chatSid));

      return response;
    } catch (error) {
      showError("Failed to create conversation", "Please try again later");
      options.onError?.(error);
    }
  };

  return {
    createConversationWithEmail,
    isLoading,
  };
};
