import React from "react";
import { useConversationsById } from "shared/conversations";
import { useUser } from "shared/user";
import {
  useConversationsMetaData,
  useSetConversationsMetaData,
  useTwilioClient,
} from "../context";
import { ConversationMetaData } from "../model";

/*
 * For reason uknown there can be mismatch between conversations list taken from Twilio
 * and conversations list taken from backend. To mitigate this we .
 */
export function useReconileConversations() {
  const setMetadata = useSetConversationsMetaData();
  const metadata = useConversationsMetaData();
  const conversations = useConversationsById();

  React.useEffect(() => {
    if (!conversations || !metadata) {
      return;
    }

    const twilioMetadataLength = Object.values(metadata).length;
    const conversationsLength = Object.values(conversations).length;

    // We deal only with cases when there are more conversations in twilio than in the database
    if (twilioMetadataLength <= conversationsLength) {
      return;
    }

    const extraIds = Object.keys(metadata).filter((key) => !conversations[key]);

    setMetadata((prev) =>
      Object.keys(prev).reduce(
        (acc, key) =>
          extraIds.includes(key) ? { ...acc } : { ...acc, [key]: prev[key] },
        {}
      )
    );
  }, [metadata, conversations]);
}

// In case We decide to leave twilio conversations that are not in the database:
// client.getSubscribedConversations().then(({ items }) =>
// items.map(async (twilioConversation) => {
// const twilioUser = await client.getUser(user.id);
// await twilioConversation.removeParticipant(twilioUser.identity);
