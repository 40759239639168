import React from "react";
import { Box, BoxProps, styled } from "@mui/material";

import { WEBSITE_URL } from "shared/constants";
import backgroundLogo from "assets/images/authLayoutLogo.png";
import smallLogo from "assets/images/logoWithName.png";

export type AuthLayoutProps = BoxProps & {
  logo?: "large" | "small" | "none";
  bottomComponent?: React.ReactNode;
};

export const AuthLayout: React.FC<AuthLayoutProps> = ({
  logo = "large",
  children,
  bottomComponent,
  ...props
}) => {
  const logoComponent = getLogoComponent(logo);

  return (
    <Wrapper {...props}>
      {logoComponent}
      <Box
        flexGrow={1}
        mt={6}
        mb={6}
        width={1}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        textAlign={"center"}
      >
        {children}
      </Box>
      {bottomComponent}
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme: { spacing, breakpoints } }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: spacing(5, 0),
  overflowY: "auto",
  [breakpoints.up("md")]: {
    height: "100%",
  },
}));

const Logo = styled("img")(() => ({
  width: 300,
  height: 300,
}));

const getLogoComponent = (logo: "large" | "small" | "none") => {
  switch (logo) {
    case "large":
      return (
        <a href={WEBSITE_URL}>
          <Logo src={backgroundLogo} alt="Markus logo with centric circles" />
        </a>
      );
    case "small":
      return (
        <a href={process.env.REACT_APP_APP_URL}>
          <LogoSmall
            src={smallLogo}
            alt="Markus logo"
            sx={{ my: { xs: 0, lg: 4 } }}
          />
        </a>
      );
    default:
      return <></>;
  }
};

const LogoSmall = styled("img")(() => ({
  width: 160,
  height: 37,
}));
