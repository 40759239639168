import React from "react";
import { Box, Stack, styled, Typography, TypographyProps } from "@mui/material";

export type CountSectionHeaderProps = TypographyProps & {
  title: string;
  count?: number;
};

export const CountSectionHeader: React.VFC<CountSectionHeaderProps> = ({
  title,
  count,
  ...props
}) => {
  return (
    <Stack direction={"row"} spacing={2} alignItems={"center"} mb={2}>
      <StyledSectionHeader variant="h3" {...props}>
        {title}
      </StyledSectionHeader>
      {Boolean(count) && <CountBadge>{count}</CountBadge>}
    </Stack>
  );
};

const StyledSectionHeader = styled(Typography)(
  ({ theme: { palette } }) => ({
    fontWeight: 600,
    color: palette.grey[600],
    fontSize: 20,
  })
);

const CountBadge = styled(Box)({
  backgroundColor: "#FE3263",
  width: 22,
  height: 20,
  borderRadius: "50%",
  color: "#FFF",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 14,
  fontWeight: 500,
  marginLeft: 16,
});
