import { Tooltip, Typography, TypographyProps } from "@mui/material";
import { useIsBreakpoint } from "../theme";
import { splitFileName } from "../utils";

export const FileName = (
  props: TypographyProps & {
    fileName: string;
    length?: number;
    mobileLength?: number;
  }
) => {
  const {
    fileName,
    length = 25,
    mobileLength = 20,
    ...typographyProps
  } = props;
  const isMobile = useIsBreakpoint("sm", "down");

  const { name, extension, trimmed } = splitFileName(
    fileName,
    isMobile ? mobileLength : length
  );
  return (
    <Tooltip
      title={fileName}
      disableFocusListener={trimmed ? undefined : true}
      disableHoverListener={trimmed ? undefined : true}
      disableTouchListener={trimmed ? undefined : true}
    >
      <Typography {...typographyProps}>{name + extension}</Typography>
    </Tooltip>
  );
};
