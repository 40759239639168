import { 
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  styled,
  SvgIconProps,
  Tooltip,
} from "@mui/material";
import React from "react";
import { colors } from "shared/theme";

export type IconButtonProps = {
  icon: React.FC<SvgIconProps>;
  label?: string;
  size?: number;
  color?: "primary" | "secondary" | "transparent";
  loading?: boolean;
  showTooltip?: boolean;
  iconProps?: SvgIconProps;
} & Omit<MuiIconButtonProps, "size" | "color">;

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(({
  icon: Icon,
  label,
  size,
  color = "primary",
  loading,
  showTooltip = true,
  disabled,
  iconProps,
  ...props
}, ref) => {
  const withTooltip = label && showTooltip && !disabled;
  const sx: any = props.sx;
  const customColor = sx?.color;
  const customBackground = sx?.backgroundColor || sx?.background;

  if (loading) {
  }

  const button = (
    <StyledButtton
      ref={ref}
      color={color as any}
      customColor={customColor}
      customBackground={customBackground}
      {...props}
      aria-label={label ?? props["aria-label"]}
      disabled={disabled}
      sx={{ ...props.sx, width: size, height: size }}
    >
      <Icon sx={{ fontSize: size ? size / 2 : undefined }} {...iconProps} />
    </StyledButtton>
  );

  return withTooltip ? <Tooltip title={label}>{button}</Tooltip> : button;
});

const StyledButtton = styled(MuiIconButton, {
  shouldForwardProp: (prop) =>
    !["color", "customColor", "customBackground"].includes(prop as string),
})<
  Omit<MuiIconButtonProps, "color"> & {
    color: IconButtonProps["color"];
    customColor?: string;
    customBackground?: string;
  }
>(({ color, customBackground, customColor }) => ({
  ...getColors(color, customColor, customBackground).main,

  width: 42,
  height: 42,
  "&:hover": {
    ...getColors(color, customColor, customBackground).hover,
  },
  "&:active": {
    ...getColors(color, customColor, customBackground).active,
  },
  "&.Mui-disabled": {
    opacity: 0.6,
    ...getColors(color, customColor, customBackground).main,
  },
}));

const getColors = (
  color: IconButtonProps["color"],
  customColor?: string,
  customBackground?: string
) => {
  const hover = {
    backgroundColor: customBackground ?? colors.markusBlue.light,
    color: customColor ?? colors.diamondWhite,
  };
  const active = {
    backgroundColor: customBackground ?? colors.haleNavy.main,
    color: customColor ?? colors.diamondWhite,
  };
  const transparent = {
    backgroundColor: customBackground ?? "transparent",
    color: customColor ?? colors.haleNavy.main,
  };

  switch (color) {
    case "primary":
      return {
        main: {
          backgroundColor: customBackground ?? colors.superLightBlue,
          color: customColor ?? colors.haleNavy.main,
        },
        active,
        hover,
      };
    case "secondary":
      return {
        main: active,
        active,
        hover,
      };
    default:
      return {
        main: transparent,
        active: transparent,
        hover: {
          backgroundColor: customBackground ?? "rgba(0, 0, 0, 0.04)",
          color: customColor ?? colors.haleNavy.main,
        },
      };
  }
};
