import React from "react";
import { Box, DialogContent } from "@mui/material";
import { ExpandMore, ExpandLess, ChevronRight } from "@mui/icons-material";
import { useIsBreakpoint } from "shared/theme";
import { useToggle } from "shared/utils";
import {
  AppDialog,
  AppDialogTitle,
  AvatarsGroup,
} from "shared/components";
import { IncomingInvitation, OutgoingInvitation } from "shared/invitations";

import { Collaborator } from "../../collaborators";
import {
  SectionButtonHeader,
  SectionButtonWrapper,
  SectionHeader,
  SectionWrapper,
} from "./layout";
import { Conversation, Participant } from "../types";
import {
  CollaboratorParticipantsList,
  OwnerParticipantsList,
} from "../participants-list";
import { participantToAvatar } from "../utils";

export type MemberSettingsProps = {
  isMineConversation?: boolean;
  conversation?: Conversation;
  collaborators: Collaborator[];
  participantsByUserId: Record<string, Participant>;
  incomingInvitations: IncomingInvitation[];
  outgoingInvitations: OutgoingInvitation[];
  onChecked: (checked: boolean, collaborator: Collaborator) => void;
};

export const MembersSettings = ({
  conversation,
  isMineConversation,
  collaborators,
  participantsByUserId,
  incomingInvitations,
  outgoingInvitations,
  onChecked,
}: MemberSettingsProps) => {
  return (
    <SectionWrapper>
      <SectionHeader>Members</SectionHeader>
      <ParticipantsListWrapper
        participants={conversation ? conversation.participants : collaborators}
      >
        {isMineConversation ? (
          <OwnerParticipantsList
            selectedParticipants={participantsByUserId}
            collaborators={collaborators}
            onChecked={onChecked}
          />
        ) : (
          <CollaboratorParticipantsList
            selectedParticipants={participantsByUserId}
            collaborators={collaborators}
            incomingInvitations={incomingInvitations}
            outgoingInvitations={outgoingInvitations}
          />
        )}
      </ParticipantsListWrapper>
    </SectionWrapper>
  );
};

type Props = {
  participants: Collaborator[];
};

const ParticipantsListWrapper: React.FC<Props> = ({
  participants,
  children,
}) => {
  const [openExtendable, toggleExtendable] = useToggle(false);
  const [openParticipantsList, toggleParticipantsList] = useToggle(false);

  const isXsBreakpoint = useIsBreakpoint("sm");

  return isXsBreakpoint ? (
    <>
      <SectionButtonWrapper>
        <SectionButtonHeader onClick={toggleParticipantsList}>
          <Box display={"flex"} alignItems={"center"}>
            <AvatarsGroup
              avatars={participants.map(participantToAvatar)}
              sx={{ mr: 1 }}
            />
            Discussion Members
          </Box>
          <ChevronRight />
        </SectionButtonHeader>
      </SectionButtonWrapper>
      <AppDialog
        open={openParticipantsList}
        fullScreen={true}
        iosStyle
        onClose={toggleParticipantsList}
      >
        <AppDialogTitle title={"Members"} onClose={toggleParticipantsList} />
        <DialogContent>{children}</DialogContent>
      </AppDialog>
    </>
  ) : (
    <Box height={62} width={1} position="relative">
      <SectionButtonWrapper
        height={
          // this is a workaround, cant make absolute position work with autosizer
          openExtendable ? Math.min(participants.length * 55 + 68, 400) : "auto"
        }
        position="absolute"
        width={1}
      >
        <SectionButtonHeader onClick={toggleExtendable}>
          <Box display={"flex"} alignItems={"center"}>
            <AvatarsGroup
              avatars={participants.map(participantToAvatar)}
              sx={{ mr: 1 }}
            />
            Participants
          </Box>
          {openExtendable ? <ExpandLess /> : <ExpandMore />}
        </SectionButtonHeader>
        <Box flex={1}>{children}</Box>
      </SectionButtonWrapper>
    </Box>
  );
};
