import { Close } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useChatSid } from "shared/chat";
import { AppHeader } from "shared/components";
import { getConversationPath } from "shared/router";

export const MediaBinHeader = () => {
  const navigate = useNavigate();
  const chatSid = useChatSid();

  const onClose = () => {
    navigate(getConversationPath(chatSid));
  };

  return (
    <Box
      sx={{
        width: "100%",
        borderLeft: { md: "1px solid" },
        borderColor: { md: "grey.200" },
        position: "relative",
      }}
    >
      <AppHeader>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 8,
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton color={"secondary"} onClick={onClose} aria-label="close media bin">
            <Close />
          </IconButton>
        </Box>
        <Typography
          variant="h3"
          sx={{
            color: "grey.600",
            fontWeight: 500,
            fontSize: 20,
            textAlign: "center",
            width: "100%"
          }}
        >
          Media
        </Typography>
      </AppHeader>
    </Box>
  );
};
