import React from "react";
import { unrefTimeout } from "./unrefTimeout";

/**
 * @param options.shouldPreventDefault - careful as this will break React default's onClick working for touch events, for all the children
 */
export function useLongPress(
  onLongPress: (event: React.TouchEvent<Element>) => void,
  {
    shouldPreventDefault = false,
    delay = 300,
    onBeforeCleanUp,
  }: {
    shouldPreventDefault?: boolean;
    delay?: number;
    onBeforeCleanUp?: () => void;
  } = {}
) {
  const timeout = React.useRef<NodeJS.Timeout>();

  const [noSelect, setNoSelect] = React.useState(false);

  const start = React.useCallback(
    (event: React.TouchEvent<Element>) => {
      setNoSelect(true);
      onBeforeCleanUp?.();

      // fixes chrome/android bug, that long pressing on image tag opens context menu
      // and ontouchend event is never triggered
      event.target.addEventListener("contextmenu", preventDefault);

      timeout.current = setTimeout(() => {
        onLongPress(event);
      }, delay);
      
      unrefTimeout(timeout.current);
    },
    [onLongPress, delay, shouldPreventDefault]
  );

  const clear = React.useCallback(
    (event: React.TouchEvent<Element>) => {
      if (shouldPreventDefault) {
        event.preventDefault();
      }

      setNoSelect(false);
      timeout.current && clearTimeout(timeout.current);

      // fixes chrome/android bug, that long pressing on image tag opens context menu
      // and ontouchend event is never triggered
      event.target.removeEventListener("contextmenu", preventDefault);
    },
    [shouldPreventDefault]
  );

  return {
    onTouchStart: (e: React.TouchEvent<Element>) => start(e),
    onTouchEnd: (e: React.TouchEvent<Element>) => clear(e),
    style: noSelect ? ({ userSelect: "none" } as const) : undefined,
  };
}

const preventDefault = (event: Event) => {
  event.preventDefault();
};
