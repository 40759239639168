import { Box, BoxProps, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import {
  Conversation,
  useSearchConversations,
} from "shared/conversations";

import { HasOnCheckedCb } from "shared/types";

import { ConversationListItem } from "./ConversationListItem";
import { Input } from "./Input";
import { useConversationsMetaData } from "shared/twilio";
import { getConversationPath } from "shared/router";

type ConversationListProps = BoxProps &
  Partial<HasOnCheckedCb<Conversation>> & {
    conversations: Conversation[];
    onClick?: () => void;
    selected?: Record<string, Conversation>;
    isSelectable?: boolean;
    isLinkable?: boolean;
    isHeaderVisible?: boolean;
  };

export const ConversationList = ({
  conversations,
  onClick,
  onChecked,
  selected = {},
  isSelectable = false,
  isLinkable = true,
  isHeaderVisible = true,
  ...props
}: ConversationListProps) => {
  const { handleSearch, resetSearch, searchValue, filteredConversations } =
    useSearchConversations(conversations);

  return (
    <Box display={"flex"} flexDirection={"column"} {...props}>
      <Box px={{ xs: 3, sm: 6 }} py={{ xs: 4, sm: 8 }}>
        <Input
          showLabel={false}
          label="Search conversations"
          value={searchValue}
          onChange={handleSearch}
          onCancel={resetSearch}
          placeholder={"Search by subject or name"}
          autoComplete={"off"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box flex={1}>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              width={width}
              height={height}
              itemCount={filteredConversations.length}
              itemSize={152}
              itemData={{
                conversations: filteredConversations,
                onClick,
                onChecked,
                selected,
                isSelectable,
                isLinkable,
              }}
            >
              {Row}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
};

const Row = (
  props: ListChildComponentProps<
    ConversationListProps & {
      conversations: Conversation[];
    }
  >
) => {
  const {
    data: {
      conversations,
      onClick,
      onChecked = () => {},
      selected = {},
      isSelectable = false,
      isLinkable = true,
    },
    style,
    index,
  } = props;

  const conversation = conversations[index];
  const metadata = useConversationsMetaData(conversation.chatSid);
  const to = getConversationPath(conversation.chatSid);
  const isSelected = !!selected[conversation?.id];

  return (
    <ConversationListItem
      conversation={conversation}
      onClick={onClick}
      key={index}
      style={style}
      to={isLinkable ? to : undefined}
      updateDate={metadata?.updateDate ?? undefined}
      unreadMessagesCount={metadata?.unreadMessagesCount}
      lastMessage={metadata?.lastMessage}
      onChecked={onChecked}
      isSelectable={isSelectable}
      isSelected={isSelected}
    />
  );
};
