import Send from "@mui/icons-material/Send";

import { MessageInput, IconButton } from "shared/components";

import { ChatMediaMenu } from "../chat-media-menu";
import { ChatMessageBox } from "./model";

export const TypeMessage: ChatMessageBox = ({
  inputProps,
  isLoading,
  messageProps,
  attachmentsProps,
  quotedMessageProps,
}) => {
  return (
    <MessageInput
      label="Message"
      InputLabelProps={{
        shrink: true,
      }}
      onChange={messageProps.onChange}
      value={messageProps.message}
      onPaste={messageProps.onPaste}
      onEnter={messageProps.sendMessage}
      disabled={!!attachmentsProps.attachments}
      inputRef={inputProps.ref}
      actions={
        <>
          <ChatMediaMenu
            attachments={attachmentsProps.attachments}
            setAttachments={attachmentsProps.setAttachments}
            disabled={!!quotedMessageProps.quotedMessage || isLoading}
          />
          <IconButton
            icon={Send}
            color="transparent"
            label="send message"
            onClick={() => messageProps.sendMessage()}
            disabled={isLoading}
          />
        </>
      }
    />
  );
};
