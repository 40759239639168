import { AuthProviderType } from "shared/auth";
import { useShowError } from "shared/notifications";
import { CredentialJWTPayload, useGoogleAuth } from "shared/google-auth";
import { useGoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import { useConvertUser } from "../useConvertUser";

export const useConvertGoogleUser = () => {
  const showError = useShowError();
  const { authenticateGoogle, isLoading: googleAuthLoading } = useGoogleAuth();
  const { convertUser, isLoading: convertUserLoading } = useConvertUser();

  const googleLogin = useGoogleLogin({
    onSuccess: ({ code }) => {
      if (!code) {
        return showError("Failed to convert user", "Please try again later.");
      }

      authenticateGoogle(
        { code },
        {
          onSuccess: ({ id_token }) => {
            const { name } = jwtDecode<CredentialJWTPayload>(id_token);

            convertUser({
              token: id_token,
              authProvider: AuthProviderType.Google,
              name,
            });
          },
        }
      );
    },
    flow: "auth-code",
  });

  return {
    convertUser: googleLogin,
    isLoading: convertUserLoading || googleAuthLoading,
  };
};
