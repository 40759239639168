import React from "react";
import {
  Box,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import { ArrowBackIosNew, Close } from "@mui/icons-material";
import { useIsBreakpoint } from "shared/theme";
import { Conversation } from "shared/conversations";
import { PublicChatParticipantsList } from "./PublicChatParticipantsList";

export type PublicChatDetailsDialogProps = {
  onClose: () => void;
  dialogProps: MuiDialogProps;
  conversation: Conversation;
};

export const PublicChatDetailsDialog: React.FC<
  PublicChatDetailsDialogProps
> = ({ dialogProps, onClose, conversation }) => {
  const isSmBreakpoint = useIsBreakpoint("sm");

  return (
    <Dialog {...dialogProps} fullScreen={isSmBreakpoint}>
      <DialogTitle>
        <Box flex={{ xs: 0, sm: 1 }}>
          {isSmBreakpoint && (
            <IconButton onClick={onClose} color={"secondary"} sx={{ mr: 2 }}>
              <ArrowBackIosNew />
            </IconButton>
          )}
        </Box>
        <Box
          flex={1}
          whiteSpace={"nowrap"}
          textAlign={{ xs: "left", sm: "center" }}
        >
          Participants
        </Box>
        {!isSmBreakpoint && (
          <Box flex={1} textAlign={"right"}>
            <IconButton color={"secondary"} onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        )}
      </DialogTitle>
      <DialogContent>
        <Box display={"flex"} height={1} justifyContent={"center"}>
          <Box flex={1} width={{ xs: 360, lg: "100%" }} maxWidth={400}>
            <PublicChatParticipantsList
              participants={conversation.participants}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const Dialog = styled((props: MuiDialogProps) => (
  <MuiDialog maxWidth={"sm"} keepMounted scroll={"paper"} {...props} />
))(({ theme: { spacing, breakpoints } }) => ({
  "& .MuiPaper-root": {
    position: "fixed",
    margin: 0,
    right: 0,
    borderRadius: 0,
    maxWidth: "100%",
    width: "60%",
    height: "100%",
    maxHeight: "100%",
    [breakpoints.down("lg")]: {
      width: "50%",
    },
    [breakpoints.down("md")]: {
      width: "100%",
    },
  },
  "& .MuiDialogContent-root": {
    padding: spacing(4, 6),
  },
  "& .MuiDialogTitle-root": {
    display: "flex",
    alignItems: "center",
    padding: spacing(4, 4, 2),
    [breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
  },
}));
