import React from "react";
import { UseBaseQueryOptions, useMutation, useQueryClient } from "react-query";
import { QueryKey, useApi } from "shared/api";
import { config } from "shared/config";
import { useShowError } from "shared/notifications";
import {
  useTwilioClient,
  sendEmailMessage,
  updateMessageAttributes,
} from "shared/twilio";
import { GmailMessage, LabelId } from "shared/gmail";
import { useTrackEmailAction } from "shared/analytics";

import { AttachedMessage, AttachEmailToConversationPayload } from "./types";
import { useGmailMessageAttachments } from "./utils";

export const useAttachEmailToConversation = (
  options: UseBaseQueryOptions<AttachedMessage> = {}
) => {
  const queryClient = useQueryClient();
  const { post } = useApi();
  const showError = useShowError();
  const twilioClient = useTwilioClient();
  const getGmailAttachments = useGmailMessageAttachments();
  const trackEmailAction = useTrackEmailAction();

  const [isLoadingState, setIsLoadingState] = React.useState(false);

  const showAttachEmailError = () =>
    showError("Failed to attach email", "Please try again later");

  const { mutateAsync, isLoading: queryIsLoading } = useMutation<
    AttachedMessage,
    unknown,
    AttachEmailToConversationPayload & { chatSid: string }
  >(({ chatSid, ...payload }) => {
    trackEmailAction({
      action: "discuss",
    });

    return post<AttachEmailToConversationPayload, AttachedMessage>(
      config.endpoints.conversations.emails.replace(/:chatSid/, chatSid)
    )(payload);
  });

  const attachEmailToConversation = async (
    chatSid: string,
    message: GmailMessage
  ) => {
    if (!twilioClient) {
      return showAttachEmailError();
    }
    setIsLoadingState(true);
    const attachments = ((await getGmailAttachments(message)) as File[]).filter(
      Boolean
    );

    const conversation = await twilioClient.getConversationBySid(chatSid);

    const twilioMessage = await sendEmailMessage(
      conversation,
      message.id,
      attachments
    );

    if (!twilioMessage) {
      setIsLoadingState(false);
      return showAttachEmailError();
    }

    return mutateAsync(
      {
        chatSid,
        messageSid: twilioMessage.sid,
        // payload
        htmlText: message._htmlContent,
        lastFetchDate: Number(message.internalDate),
        raw: "",
        mailSubject: message._subject,
        fromName: message._from,
        snippet: message.snippet,
        fromEmail: message._from,
        date: Number(message.internalDate),
        internalStatus: message.labelIds[0] || LabelId.Inbox,
        externalProviderMsgId: message.id,
        externalCreationDate: Number(message.internalDate),
        externalProviderMsgHistoryId: message.historyId,
        externalProviderThreadId: message.threadId,
        title: message._subject,
        subject: message._subject,
        replied: false,
        forwarded: false,
        fromAvatar: "", // todo add avatar
      },
      {
        ...options,
        onError: async () => {
          showAttachEmailError();
          await twilioMessage.remove();
        },
        onSuccess: async (response) => {
          await queryClient.invalidateQueries(QueryKey.Conversations);
          options.onSuccess?.(response);
        },
        onSettled: () => setIsLoadingState(false),
      }
    ).then(async (response) => {
      // Why this code is there:
      // Often Twilio sends message before, email is stored on server.
      // This results in 403 error when trying to fetch that email message.
      // The workaround is to create message with `messageSent: false` attribute
      // and then update this attribute to `true`.
      // Unfortunately when calling `attachEmailToConversation` multiple times concurrently
      // only last one calls `onSuccess` - here the workaround is to place the code in `then` function
      await updateMessageAttributes(twilioMessage, { emailSent: true });
      return response;
    });
  };

  const isLoading = isLoadingState || queryIsLoading;

  return {
    attachEmailToConversation,
    isLoading,
  };
};
