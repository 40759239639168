import { useUpdateUserSettings, useUser } from "shared/user";

export function useEmailNotificationsEnabled() {
  const { user, isFetching } = useUser();
  const { isLoading, updateUser } = useUpdateUserSettings();

  return {
    enabled: user.emailNotifications,
    isLoading: isLoading || isFetching,
    onChange: () =>
      updateUser({ emailNotifications: !user.emailNotifications }),
  };
}
