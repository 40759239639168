import { UseBaseQueryOptions, useMutation } from "react-query";
import { useApi, ApiError } from "shared/api";
import { config } from "shared/config";
import { AnalyticsEvent, useTrackEvent } from "shared/analytics";
import { useSetPublicChatAuthData } from "../context/PublicChatAuth";
import { AuthenticateResponse } from "../types";
import {getErrorPage} from "shared/notifications";
import {useNavigate} from "react-router-dom";

export type AuthenticateWebRequest = {
  user: string;
  authorizationCode: string;
  sChatId: string;
};

export const useAuthenticateWeb = (
  options: UseBaseQueryOptions<AuthenticateResponse> = {}
) => {
  const { post } = useApi();

  const setAuthData = useSetPublicChatAuthData();

  const navigate = useNavigate();

  const trackLogin = useTrackEvent(AnalyticsEvent.public_chat_user_login);

  const { mutate, isLoading } = useMutation(
    post<AuthenticateWebRequest, AuthenticateResponse>(
      config.endpoints.webCollaborator.web
    )
  );

  const authenticateWeb = (data: AuthenticateWebRequest) => {
    mutate(data, {
      ...options,
      onSuccess: (res) => {
        trackLogin();
        setAuthData(res);
        options.onSuccess?.(res);
      },
      onError: (e: unknown) => {
        if (e instanceof ApiError && e.status === 404) {
          return navigate(getErrorPage("PublicChatNotFound"));
          ;
        }

        options.onError?.(e);
      },
    });
  };

  return {
    authenticateWeb,
    isLoading,
  };
};
