export function unrefTimeout(timeout: NodeJS.Timeout) {
  if (
    typeof timeout === "object" &&
    timeout !== null &&
    "unref" in timeout &&
    typeof timeout.unref === "function"
  ) {
    timeout.unref();
  }
}
