import { useMutation, useQueryClient } from "react-query";

import { useApi, QueryKey } from "shared/api";
import { config } from "shared/config";
import { useShowError } from "shared/notifications";
import { AnalyticsEvent, useTrackEvent } from "shared/analytics";
import { InviteUserRequest, InviteUserResponse } from "../model";

export function useInviteUser() {
  const queryClient = useQueryClient();
  const { post } = useApi();

  const showError = useShowError();

  const { isLoading, mutateAsync, error } = useMutation(
    post<InviteUserRequest, InviteUserResponse>(
      config.endpoints.invitations.main
    )
  );

  const trackInvite = useTrackEvent(AnalyticsEvent.user_invited);

  const inviteUser = (userId: string) => {
    mutateAsync(
      { userId },
      {
        onError: () =>
          showError("Failed to invite user", "Please try again later"),
        onSuccess: () => {
          trackInvite({ userId });
          queryClient.invalidateQueries(QueryKey.OutgoingInvitations);
          queryClient.invalidateQueries(QueryKey.CollaboratorList);
        },
      }
    );
  };

  return {
    inviteUser,
    isLoading,
    error,
  };
}
