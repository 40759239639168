import { UseBaseQueryOptions, useMutation } from "react-query";
import { useApi } from "shared/api";
import { config } from "shared/config";
import { useShowNotification } from "shared/notifications";
import { useChatSid } from "../context";
import { CalledUser, CallUsersRequest } from "./model";

export function useCallUsers(options: UseBaseQueryOptions<void> = {}) {
  const { post } = useApi();
  const { mutate, isLoading } = useMutation(
    post<CallUsersRequest, void>(config.endpoints.notifications.callUsers)
  );
  const showNotification = useShowNotification();
  const chatSid = useChatSid();

  const callUsers = (users: CalledUser[] = []) => {
    if (!users.length) {
      return;
    }
    
    return mutate(
      { users, chatSid },
      {
        onSuccess: () => {
          showNotification(
            users.length > 1
              ? "Email notifications sent to users"
              : "Email notification sent to user"
          );
          options?.onSuccess?.();
        },
      }
    );
  };

  return {
    callUsers,
    isLoading,
  };
}
