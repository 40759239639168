import React from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Dialog,
  styled,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { colors, useIsBreakpoint } from "shared/theme";
import { MainButton } from "./MainButton";
import { Logo } from "./Logo";
import { ExclamationIcon } from "./icons";

export type PopupProps = {
  open: boolean;
  title: string;
  id: string;
  content: React.ReactNode;
  danger?: boolean;
  icon?: JSX.Element;
  acceptText?: string;
  cancelText?: string;
  disabled?: boolean;
  onClick: () => void;
  onClose?: () => void;
};

export const Popup: React.VFC<PopupProps> = ({
  open,
  title,
  content,
  id,
  icon,
  disabled,
  danger,
  acceptText = "Yes please",
  cancelText = "No thanks",
  onClick,
  onClose,
}) => {
  const isMobile = useIsBreakpoint("sm");
  const onCloseFn = onClose ? onClose : onClick;
  const stringContent = typeof content === "string";
  const defaultIcon = danger ? (
    <ExclamationIcon sx={{ fontSize: 28 }} />
  ) : (
    <Logo />
  );

  const handleAccept = () => {
    if (disabled) {
      return;
    }
    onClick();
    onClose?.();
  };

  return (
    <StyledDialog
      scroll="body"
      open={open}
      onClose={onCloseFn}
      id={`${id}-popUp-title`}
      aria-labelledby={`${id}-popUp-title`}
      aria-describedby={stringContent ? `${id}-popUp-description` : undefined}
      TransitionComponent={isMobile ? Transition : undefined}
    >
      {!isMobile && (
        <StyledIconWrapper danger={danger} hasIcon={!!icon}>
          {icon ?? defaultIcon}
        </StyledIconWrapper>
      )}
      <DialogTitle id={`${id}-popUp-title`}>{title}</DialogTitle>
      <DialogContent>
        {stringContent ? (
          <DialogContentText id={`${id}-popUp-description`}>
            {content}
          </DialogContentText>
        ) : (
          content
        )}
      </DialogContent>
      <DialogActions>
        {onClose ? (
          <>
            <MainButton
              sx={{
                maxWidth: { xs: 180, sm: "unset" },
                width: { xs: "50%", sm: "unset" },
              }}
              size={"large"}
              color="secondary"
              onClick={onCloseFn}
            >
              <ButtonTextWrapper>{cancelText}</ButtonTextWrapper>
            </MainButton>
            <MainButton
              sx={{
                maxWidth: { xs: 180, sm: "unset" },
                width: { xs: "50%", sm: "unset" },
              }}
              color={danger ? "error" : "primary"}
              size={"large"}
              onClick={handleAccept}
              disabled={disabled}
            >
              <ButtonTextWrapper>{acceptText}</ButtonTextWrapper>
            </MainButton>
          </>
        ) : (
          <MainButton
            color={danger ? "error" : "primary"}
            size={"large"}
            onClick={handleAccept}
            disabled={disabled}
          >
            {acceptText}
          </MainButton>
        )}
      </DialogActions>
    </StyledDialog>
  );
};

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

const ButtonTextWrapper = styled(Box)({
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
});

const StyledIconWrapper = styled(Box, {
  shouldForwardProp: (prop) => !["danger", "hasIcon"].includes(prop as string),
})<{ danger?: boolean; hasIcon: boolean }>(({ danger, hasIcon }) => ({
  width: 48,
  height: 48,
  borderRadius: 6,
  marginBottom: 32,
  backgroundColor: danger
    ? colors.redAlert
    : !hasIcon
    ? "transparent"
    : colors.superLightBlue,
  color: danger ? colors.white : colors.haleNavy.dark,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledDialog = styled(Dialog)(({ theme: { breakpoints, spacing } }) => ({
  "& .MuiDialog-container": {
    [breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
    },
  },
  "& .MuiDialog-paper": {
    width: "100%",
    margin: 0,
    borderRadius: "16px 16px 0 0",
    padding: spacing(18, 6, 11),
    "&.MuiDialog-paper": {
      maxWidth: "unset",
    },
    [breakpoints.up("sm")]: {
      borderRadius: 16,
      padding: spacing(21, 16),
      "&.MuiDialog-paper": {
        maxWidth: "min(779px, 90%)",
      },
    },
  },
  "& .MuiDialogTitle-root": {
    fontSize: 24,
    lineHeight: "36px",
    padding: 0,
    fontWeight: 600,
    textAlign: "center",
    color: colors.haleNavy.dark,
    marginBottom: 24,
    [breakpoints.up("sm")]: {
      fontSize: 32,
      textAlign: "left",
    },
  },

  "& .MuiDialogContent-root": {
    padding: 0,
    fontSize: 18,
    lineHeight: "32px",
    textAlign: "center",
    color: colors.haleNavy.main,
    [breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },

  "& .MuiDialogContentText-root": {
    fontSize: 18,
    padding: 0,
    lineHeight: "32px",
    textAlign: "center",
    color: colors.haleNavy.main,
    [breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },
  "& .MuiDialogActions-root": {
    marginTop: 48,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    [breakpoints.up("sm")]: {
      justifyContent: "flex-start",
      "&>:not(:first-of-type)": {
        marginLeft: 16,
      },
    },
  },
}));
