import React from "react";
import { useHover, useLongPress } from "shared/utils";

export function useMessageMenu() {
  const [showIcon, setShowIcon] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onHoverProps = useHover(
    () => setShowIcon(true),
    () => setShowIcon(false)
  );

  const onLongPressProps = useLongPress(() => setShowIcon((prev) => !prev));

  const closeMenu = React.useCallback(() => {
    setAnchorEl(null);
    setShowIcon(false);
  }, []);

  const openMenu = React.useCallback(
    (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
      if (!showIcon) {
        return;
      }
      setShowIcon(false);
      setAnchorEl(event.currentTarget);
    },
    [showIcon]
  );

  return {
    elementProps: { ...onHoverProps, ...onLongPressProps },
    closeMenu,
    openMenu,
    showIcon,
    menuOpen: !!anchorEl,
    anchor: anchorEl,
  };
}
