import { MenuItem } from "@mui/material";
import React from "react";
import { StyledContactMenu } from "./styles";
import { getMenuButtonId } from "./getMenuButtonId";

export type CollaboratorItemMenuProps = {
  id: string;
  anchor: null | HTMLElement;
  onClose: () => void;
  onRemove: () => void;
  onReinvite?: () => void;
};

export const CollaboratorItemMenu: React.VFC<CollaboratorItemMenuProps> = ({
  id,
  onClose,
  onReinvite,
  onRemove,
  anchor,
}) => {
  return (
    <StyledContactMenu
      id={`collaborator_${id}_menu`}
      anchorEl={anchor}
      open={Boolean(anchor)}
      onClose={onClose}
      MenuListProps={{
        "aria-labelledby": getMenuButtonId(id),
      }}
    >
      {onReinvite && (
        <MenuItem onClick={onReinvite}>Resend invitation</MenuItem>
      )}
      <MenuItem onClick={onRemove} className="delete">
        Delete
      </MenuItem>
    </StyledContactMenu>
  );
};
