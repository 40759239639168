import React from "react";

type FormatFn = (value: string) => string;

export const useSearch = (formatFn: FormatFn = (value) => value) => {
  const [searchValue, setSearchValue] = React.useState("");
  const [search, setSearch] = React.useState("");

  const handleSearch = (event: React.ChangeEvent<{ value: string }>) => {
    setSearch(event.target.value);
  };

  const resetSearch = () => {
    setSearch("");
  };

  React.useEffect(() => {
    setSearchValue(formatFn(search));
  }, [search, formatFn]);

  return {
    handleSearch,
    resetSearch,
    searchValue,
  };
};
