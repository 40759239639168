import React from "react";
import { Box, Stack, StackProps, styled } from "@mui/material";
import {
  AssistantButton,
  useMessageAssistantState,
  TransformMessage,
  CheckSpelling,
  AssistantStateTabs,
  AssistantChat,
} from "shared/ai";
import { MessageAssistantState } from "shared/ai/assitant/model";
import { AttachmentsPane } from "shared/components";
import { colors } from "shared/theme";
import { useSizeObserver } from "shared/utils";

import { CallingUsersMenu } from "../calling-users";
import { TypeMessage } from "./TypeMessage";
import { ChatMessageBox, HandleMessageReturnType } from "./model";

type MesssageBoxProps = HandleMessageReturnType;

export const MessageBox = ({
  attachmentsProps,
  callingUsersProps,
  inputProps,
  isLoading,
  messageProps,
  quotedMessageProps,
}: MesssageBoxProps) => {
  const assistantState = useMessageAssistantState();
  const boxRef = React.useRef<HTMLDivElement>(null);

  const {
    size: { height },
  } = useSizeObserver(boxRef, "border", 0);

  const attachmentsOpen =
    !!(attachmentsProps.attachments || quotedMessageProps.quotedMessage) &&
    assistantState === MessageAssistantState.Closed;

  const assistantOpen = assistantState !== MessageAssistantState.Closed;

  const ChatMessageBox = mapComponentToState(assistantState);

  return (
    <>
      <AttachmentsPane
        open={attachmentsOpen}
        attachmentsProps={attachmentsProps}
        quotedMessageProps={quotedMessageProps}
      />

      <Wrapper>
        <CallingUsersMenu callingUsersProps={callingUsersProps} />
        <AssistantButton />
        <Container
          sx={{
            height,
            backgroundColor: assistantOpen
              ? colors.grey.light
              : colors.superLightBlue,
          }}
        >
          <AssistantStateTabs />
          <StyledMessageSection ref={boxRef} assistantOpen={assistantOpen}>
            <ChatMessageBox
              attachmentsProps={attachmentsProps}
              inputProps={inputProps}
              isLoading={isLoading}
              messageProps={messageProps}
              quotedMessageProps={quotedMessageProps}
            />
          </StyledMessageSection>
        </Container>
      </Wrapper>
    </>
  );
};

const mapComponentToState = (state: MessageAssistantState): ChatMessageBox => {
  switch (state) {
    case MessageAssistantState.Spelling:
      return CheckSpelling;
    case MessageAssistantState.Message:
      return TransformMessage;
    case MessageAssistantState.Chat:
      return AssistantChat;
    default:
      return TypeMessage;
  }
};

const StyledMessageSection = styled(
  React.forwardRef((props: StackProps, ref) => (
    <Stack spacing={1} direction={"row"} {...props} ref={ref} />
  )),
  { shouldForwardProp: (prop) => prop !== "assistantOpen" }
)<{ assistantOpen: boolean }>(
  ({ theme: { spacing, breakpoints }, assistantOpen }) => ({
    padding: spacing(assistantOpen ? 8 : 6, 2, 2),
    maxWidth: "100vw",
    position: "absolute",
    bottom: 0,
    width: "100%",
    alignItems: "flex-start",
    transition: "background-color 0.3s ease-in-out",
    [breakpoints.up("sm")]: {
      padding: spacing(9, 8, 1),
    },
  })
);

const Container = styled(Box)({
  borderTop: `1px solid ${colors.diamondWhite}`,
  position: "relative",
  transition: "height .4s ease",
  overflow: "hidden",
});

const Wrapper = styled(Box)({
  position: "relative",
});
