import React from "react";
import { Chip, Stack, styled, Box } from "@mui/material";
import { NavLinkProps, To } from "react-router-dom";
import { getFriendlyTimestamp } from "shared/utils";
import {
  Checkbox,
  MessagesListItem,
  StyledNavLink,
  AvatarsGroupDiagonal,
  MessageIcon,
  AttachmentIcon,
} from "shared/components";
import { HasOnCheckedCb } from "shared/types";

import {
  Conversation,
  Participant,
  getConversationUpdateDate,
  participantToAvatar,
} from "shared/conversations";
import { UnreadMessagesBadge } from "./UnreadMessagesBadge";
import { colors } from "shared/theme";
import { ConversationMetaData } from "shared/twilio";

type ConversationListItemProps = Partial<NavLinkProps> &
  HasOnCheckedCb<Conversation> & {
    conversation: Conversation;
    updateDate?: Date;
    unreadMessagesCount?: number;
    lastMessage: string | null;
    isSelectable: boolean;
    isSelected: boolean;
  };

const ParticipantsNames = ({
  participants,
}: {
  participants: Participant[];
}) => {
  const total = participants.length;
  const names = participants
    .slice(0, 3)
    .map(({ name }) => name.split(" ").slice(0, 1).join(""))
    .join(", ");
  const subtitle = <b>{total > 1 ? `${total} members:` : "1 member:"}</b>;
  return (
    <>
      {subtitle} {names}
      {total > 3 ? "..." : ""}
    </>
  );
};

const LastMessage = ({ text }: { text?: string }) => {
  if (!text) {
    return <></>;
  }

  if (text.includes("User sent a file")) {
    return (
      <>
        <AttachmentIcon sx={{ fontSize: 14, mr: 1 }} />
        User sent a file
      </>
    );
  }

  if (text.includes("User sent an email")) {
    return (
      <>
        <MessageIcon sx={{ fontSize: 14, mr: 1 }} /> User sent an email
      </>
    );
  }

  return <>{text}</>;
};

const arePropsEqual = (
  prevConversation: ConversationListItemProps,
  nextConversation: ConversationListItemProps
) => {
  return (
    prevConversation.conversation === nextConversation.conversation &&
    prevConversation.to === nextConversation.to &&
    prevConversation.unreadMessagesCount ===
      nextConversation.unreadMessagesCount &&
    prevConversation.lastMessage === nextConversation.lastMessage &&
    prevConversation.isSelected === nextConversation.isSelected &&
    prevConversation.updateDate === nextConversation.updateDate
  );
};

export const ConversationListItem: React.FC<ConversationListItemProps> =
  React.memo(
    ({
      conversation,
      updateDate,
      unreadMessagesCount,
      lastMessage,
      isSelectable,
      isSelected,
      onChecked,
      ...props
    }) => {
      const isActive = props.to
        ? window.location.pathname.includes(props.to.toString())
        : false;

      const { participants, subject, publicChatUrl } = conversation;
      const friendlyTimestamp = React.useMemo(
        () =>
          getFriendlyTimestamp(
            getConversationUpdateDate(conversation, updateDate)
          ),
        [conversation, updateDate]
      );

      const participantsNames = React.useMemo(
        () => <ParticipantsNames participants={participants} />,
        [participants]
      );

      return (
        <Wrapper to={props.to}>
          <MessagesListItem
            AvatarEl={
              isSelectable ? (
                <Box
                  display={"flex"}
                  alignItems={"start"}
                  justifyContent={"center"}
                >
                  <Checkbox
                    checked={isSelected}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                  />
                </Box>
              ) : (
                <AvatarsGroupDiagonal
                  avatars={participants.map(participantToAvatar)}
                />
              )
            }
            title={subject}
            subtitle={participantsNames}
            prependTitle={
              !!publicChatUrl ? (
                <PublicChip label={"PUBLIC"} sx={{ mr: 1 }} />
              ) : undefined
            }
            timestamp={friendlyTimestamp}
            text={
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <UnreadMessagesBadge count={unreadMessagesCount} />
                <Box
                  sx={{
                    color: isActive ? colors.blueAccent : "unset",
                    fontWeight: isActive ? 500 : "unset",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {
                    <LastMessage
                      text={lastMessage || conversation.lastMessage?.text}
                    />
                  }
                </Box>
              </Stack>
            }
            {...props}
            onClick={() => onChecked(!isSelected, conversation)}
          />
        </Wrapper>
      );
    },
    arePropsEqual
  );

const Wrapper: React.FC<{ to?: To }> = ({ to, children }) =>
  !!to ? <StyledNavLink to={to}>{children}</StyledNavLink> : <>{children}</>;

const PublicChip = styled(Chip)(({ theme: { palette } }) => ({
  backgroundColor: colors.diamondWhite,
  borderRadius: 4,
  color: colors.haleNavy.dark,
  fontWeight: 500,
  fontSize: 9,
  height: 21,
}));
