import React from "react";
import { Media } from "@twilio/conversations";
import { useSetStateAsync } from "shared/utils";

export const useVideo = (media: Media) => {
  const [url, setUrl] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const setStateAsync = useSetStateAsync();

  React.useEffect(() => {
    setIsLoading(true);
    media
      .getContentTemporaryUrl()
      .then((url) => {
        if (!url) {
          return setStateAsync(() => setIsError(true));
        }
        setStateAsync(() => setUrl(url));
      })
      .catch(() => setStateAsync(() => setIsError(true)))
      .finally(() => setStateAsync(() => setIsLoading(false)));

    return () => {
      setIsLoading(false);
      setIsError(false);
    };
  }, [media, setStateAsync]);

  return { url, isLoading, isError, setIsError };
};
