import React from "react";
import { Avatar, AvatarProps } from "@mui/material";

import { GmailMessage } from "../types";

type GmailMessageAvatarProps = AvatarProps & {
  color: string,
  from: string,
  size?: number;
};

export const GmailMessageAvatar: React.FC<GmailMessageAvatarProps> = ({
  color,
  from,
  size = 36,
  ...props
}) => {
  return (
    <Avatar
      {...props}
      sx={{
        backgroundColor: color,
        width: size,
        height: size,
        ...props.sx,
      }}
    >
      {from.slice(0, 1)}
    </Avatar>
  );
};
