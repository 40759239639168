import { useUser } from "../user";
import { useGoogleAuthContext } from "./GoogleAuth.context";

export function useRevokeGoogleConsent() {
  const { authData } = useGoogleAuthContext();
  const { user } = useUser();

  return () => revokePromise({ email: user.email, idToken: authData.id_token });
}

const revokePromise = ({
  email,
  idToken,
}: {
  email: string | null;
  idToken: string | undefined;
}) => {
  /**
   * @dependency react-oauth/google lib loads Google Identity Services for Web
   */
  if (!window.google?.accounts?.id || !idToken || !email) {
    return Promise.reject("Failed to revoke google consent");
  }

  return new Promise((resolve, reject) => {
    window.google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID || "",
      callback: (res) => console.log("initialize callback", res),
    });

    window.google.accounts.id.revoke(email, ({ successful, error }) => {
      if (successful) {
        return resolve(true);
      }
      return reject(error);
    });
  });
};
