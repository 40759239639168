import React from "react";
import { Box, Stack, Typography } from "@mui/material";

import { isInPublicChat } from "shared/public-chat";
import { AuthLayout } from "shared/layouts";
import {
  GoogleButton,
  LoadingIndicator,
  OutlookButton,
  YahooButton,
} from "shared/components";
import { useHasGmailScopes } from "shared/google-auth";
import { QueryDataWrapper } from "shared/api";

import { useLoginWithGmail } from "./useLoginWithGmail";

type Props = { children: React.ReactNode; enabledProvidersOnly?: boolean };
export const UserGmailGuard = ({
  children,
  enabledProvidersOnly = false,
}: Props) => {
  const hasGmailScopes = useHasGmailScopes();
  const { loginWithGmail, isLoading } = useLoginWithGmail();
  const isPublicChat = isInPublicChat();

  return (
    <QueryDataWrapper
      result={{ isLoading, error: undefined, data: null }}
      loadingView={<LoadingIndicator />}
    >
      {() => (
        <>
          {hasGmailScopes ? (
            <>{children}</>
          ) : (
            <AuthLayout>
              <Stack spacing={4} textAlign={"center"} width={300}>
                <Typography variant={"h6"} color={"secondary"}>
                  Connect your email
                </Typography>
                <Typography
                  component={Box}
                  width={310}
                  variant={"body1"}
                  color={"grey.400"}
                >
                  To chat about your emails with your family and friends, link
                  your email account
                </Typography>
                <GoogleButton
                  onClick={() => loginWithGmail()}
                  disabled={isPublicChat}
                >
                  Connect with Google
                </GoogleButton>
                {!enabledProvidersOnly && (
                  <>
                    <OutlookButton disabled>Connect with Outlook</OutlookButton>
                    <YahooButton disabled>Connect with Yahoo</YahooButton>
                  </>
                )}
              </Stack>
            </AuthLayout>
          )}
        </>
      )}
    </QueryDataWrapper>
  );
};
