import { AddressSwitch, RelayIcon } from "shared/components";
import { AnalyticsEvent, useTrackEvent } from "shared/analytics";
import { useToggleEmailRelay } from "../useToggleEmailRelay";
import React from "react";

type Props = {
  chatSid: string;
  emailRelayAddress: string | null;
};

export const EmailRelaySwitch = ({ emailRelayAddress, chatSid }: Props) => {
  const { toggleEmailRelay, isLoading } = useToggleEmailRelay(chatSid);
  const trackCopy = useTrackEvent(AnalyticsEvent.email_relay_copy);

  return (
    <AddressSwitch
      address={emailRelayAddress}
      onChange={toggleEmailRelay}
      isLoading={isLoading}
      icon={<RelayIcon sx={{ fontSize: 16, color: "secondary.main" }} />}
      title={"Email Relay"}
      text={
        "Participants can forward emails directly to this thread"
      }
      onCopy={trackCopy}
    />
  );
};
