import { Media, Message } from "@twilio/conversations";
import React from "react";
import {
  createContext,
  useContext,
  useContextSelector,
} from "use-context-selector";
import { Conversation } from "shared/conversations";
import { getMediaToPreview } from "shared/twilio";
import { useUser } from "shared/user";
import { ChatContextState } from "./model";

type ChatProviderProps = {
  conversation: Conversation;
  isPublicChatView: boolean;
};

const initialState: ChatContextState = {
  ownerId: "",
  openEmailPreview: false,
  toggleEmailPreview: () => ({}),
  conversation: null as unknown as Conversation,
  isPublicChatView: false,
  externalProviderMsgId: undefined,
  isConversationOwner: false,
  quotedMessage: null,
  mediaPreview: null,
  setQuotedMessage: () => undefined,
  toggleMediaPreview: () => undefined,
};

const ChatContext = createContext(initialState);

export const ChatProvider: React.FC<ChatProviderProps> = ({
  conversation,
  isPublicChatView,
  children,
}) => {
  const { user } = useUser();

  const [externalProviderMsgId, setExternalProviderMsgId] =
    React.useState<string>();

  const [quotedMessage, setQuotedMessage] = React.useState<Message | null>(
    null
  );

  const [mediaPreview, setMediaPreview] = React.useState<Media | null>(null);

  const toggleMediaPreview = React.useCallback(
    (media: Media[] | Media | null) => {
      const mediaToPreview = getMediaToPreview(media);

      if (mediaToPreview) {
        setExternalProviderMsgId(undefined);
      }
      setMediaPreview(mediaToPreview);
    },
    []
  );

  const toggleEmailPreview = React.useCallback(
    (newExternalProviderMsgId?: string) => {
      if (newExternalProviderMsgId) {
        setMediaPreview(null);
      }
      setExternalProviderMsgId(newExternalProviderMsgId);
    },
    []
  );

  const ownerId = conversation.ownerId;
  const isConversationOwner = user.id === conversation.ownerId;

  // clean up when unmounting
  React.useEffect(() => {
    return () => {
      setQuotedMessage(null);
      setExternalProviderMsgId("");
    };
  }, []);

  return (
    <ChatContext.Provider
      value={{
        ownerId,
        openEmailPreview: !!externalProviderMsgId,
        toggleEmailPreview,
        conversation,
        isPublicChatView,
        externalProviderMsgId,
        isConversationOwner,
        quotedMessage,
        setQuotedMessage,
        mediaPreview,
        toggleMediaPreview,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => useContext(ChatContext);

export const useConversationOwner = () =>
  useContextSelector(ChatContext, ({ ownerId }) => ownerId);

export const useIsConversationOwner = () =>
  useContextSelector(
    ChatContext,
    ({ isConversationOwner }) => isConversationOwner
  );

export const useChatSid = () =>
  useContextSelector(ChatContext, ({ conversation }) => conversation.chatSid);

export const useToggleEmailPreview = () =>
  useContextSelector(
    ChatContext,
    ({ toggleEmailPreview }) => toggleEmailPreview
  );

export const useEmailToPreview = () =>
  useContextSelector(
    ChatContext,
    ({ externalProviderMsgId }) => externalProviderMsgId
  );

export const useQuotedMessage = () =>
  useContextSelector(ChatContext, ({ quotedMessage }) => quotedMessage);

export const useSetQuotedMessage = () =>
  useContextSelector(ChatContext, ({ setQuotedMessage }) => setQuotedMessage);

export const useMediaToPreview = () =>
  useContextSelector(ChatContext, ({ mediaPreview }) => mediaPreview);

export const useToggleMediaPreview = () =>
  useContextSelector(
    ChatContext,
    ({ toggleMediaPreview }) => toggleMediaPreview
  );

export const useHasMediaPreview = () =>
  useContextSelector(ChatContext, ({ mediaPreview, externalProviderMsgId }) =>
    Boolean(mediaPreview || externalProviderMsgId)
  );

export const useChatParticipants = () =>
  useContextSelector(
    ChatContext,
    ({ conversation }) => conversation.participants
  );
