export function hasPublicChatParams(search = window.location.search) {
  return /chat=[a-zA-Z0-9-]+/.test(search) && /code=[a-zA-Z0-9-]+/.test(search);
}

export function isInPublicChat(
  pathname = window.location.pathname,
  search = window.location.search
) {
  return (
    hasPublicChatParams(search) &&
    (pathname.startsWith("/public-chat") || pathname === "/")
  );
}

export function validateName(name: string) {
  if (
    name.length < 101 &&
    name.length >= 3 &&
    name.match(
      "^[a-zA-Z0-9àáâäãåąčśćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØŚÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]*$"
    )
  ) {
    return true;
  }

  return false;
}
