import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const RelayIcon: React.FC<SvgIconProps> = (props) => {
  const fillProp = props.fill ?? "transparent";

  return (
    <SvgIcon
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      {...props}
      sx={{ fill: fillProp, ...props.sx }}
      aria-label="relay icon"
    >
      <path
        d="M1 4L8.8906 9.2604C9.5624 9.70827 10.4376 9.70827 11.1094 9.2604L19 4M3 15H17C18.1046 15 19 14.1046 19 13V3C19 1.89543 18.1046 1 17 1H3C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
