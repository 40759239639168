import { Box, styled, Typography } from "@mui/material";

export const LinkWrapper = styled(Box
  )(({ theme: { palette, spacing } }) => ({
  minHeight: 100,
  margin: spacing(3, 0),
  backgroundColor: palette.common.white,
  borderRadius: spacing(1.5),
  width: "100%",
  maxWidth: 400,
  padding: spacing(2, 4),
  "& .MuiSkeleton-root": {
    width: "100%",
    borderRadius: spacing(1),
    height: 20,
    backgroundColor: palette.grey[300],
  },
  "& .MuiSkeleton-root:last-of-type": {
    marginTop: spacing(2),
    height: 70,
    backgroundColor: palette.grey[300],
  },
  "& h4.MuiTypography-root": {
    color: palette.grey[600],
  },
  "& a": {
    color: palette.primary.dark,
  },
  "& p.MuiTypography-root": {
    color: palette.grey[400],
  },
}));

export const LinkTitle = styled(Typography)(({ theme: { palette } }) => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
  fontWeight: 700,
  fontSize: 16,
}));

export const FavIcon = styled(Box)({
  width: 16,
  "& img": { width: "100%", marignRight: 8 },
});

export const StyledLinkContainer = styled(Box)(({ theme: { palette } }) => ({
  display: "flex",
  "& a": {
    color: palette.grey[500],
    fontSize: 14,
    fontWeight: 500,
  },
}));

export const LinkDescription = styled(Typography)(({ theme: { palette } }) => ({
  textAlign: "left",
  fontSize: 12,
}));

export const ImageContainer = styled(Box)({
  display: "flex",
  width: 150,
  paddingTop: 8,
  "& img": {
    maxWidth: "100%",
    maxHeight: 100,
  },
});


export const IFrameWrapper = styled(Box)(({theme: {spacing, palette, breakpoints}}) => ({
  margin: spacing(5, 0, 2),
  backgroundColor: palette.common.white,
  borderRadius: spacing(1.5),
  width: "100%",
  maxWidth: 400,
  height: 0,
  position: "relative",
  paddingBottom: "56.25%",
  overflow: "hidden",
  "& iframe": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  }
}))
