import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const FeedbackCircleIcon: React.FC<SvgIconProps> = (props) => {
  const fillProp = props.fill ?? "transparent";
  return (
    <SvgIcon
      width="48"
      height="48"
      viewBox="0 0 48 48"
      {...props}
      aria-label="feedback circle icon"
      sx={{ fill: fillProp, ...props.sx }}
    >
      <rect width="48" height="48" rx="24" />
      <path
        d="M16.0222 15.2234H16.0226L31.9774 15.2234L31.9777 15.2234C32.7426 15.2243 33.4758 15.5285 34.0166 16.0693C34.5574 16.6101 34.8616 17.3434 34.8625 18.1082V18.1085L34.8625 27.6058L34.8625 27.6061C34.8616 28.371 34.5574 29.1042 34.0166 29.645C33.4758 30.1858 32.7426 30.49 31.9777 30.4909H31.9774H27.7329V34.0693C27.7329 34.0693 27.7329 34.0693 27.7329 34.0693C27.7329 34.209 27.6915 34.3455 27.6139 34.4617C27.5363 34.5778 27.426 34.6684 27.2969 34.7218C27.1679 34.7753 27.0258 34.7892 26.8888 34.762C26.7519 34.7347 26.626 34.6674 26.5273 34.5687C26.5273 34.5686 26.5272 34.5686 26.5272 34.5686L22.4499 30.4909H16.0226H16.0222C15.2574 30.49 14.5242 30.1858 13.9834 29.645C13.4426 29.1042 13.1384 28.371 13.1375 27.6061V27.6058L13.1375 18.1085L13.1375 18.1082C13.1384 17.3434 13.4426 16.6101 13.9834 16.0693C14.5242 15.5285 15.2574 15.2243 16.0222 15.2234ZM22.7424 29.0784H22.7424C22.9297 29.0784 23.1093 29.1528 23.2417 29.2853L26.3204 32.3643V29.7847C26.3204 29.7847 26.3204 29.7847 26.3204 29.7846C26.3204 29.6919 26.3386 29.6001 26.3741 29.5144C26.4096 29.4287 26.4616 29.3508 26.5272 29.2852C26.5928 29.2196 26.6707 29.1676 26.7564 29.1321L26.8712 29.4093L26.7564 29.1321C26.8421 29.0966 26.9339 29.0784 27.0266 29.0784C27.0267 29.0784 27.0267 29.0784 27.0267 29.0784H31.9771C32.3676 29.078 32.742 28.9226 33.0181 28.6465C33.2942 28.3704 33.4495 27.996 33.45 27.6056C33.45 27.6055 33.45 27.6055 33.45 27.6055V18.1089C33.45 18.1088 33.45 18.1088 33.45 18.1088C33.4495 17.7183 33.2942 17.3439 33.0181 17.0678C32.742 16.7917 32.3677 16.6364 31.9772 16.6359C31.9772 16.6359 31.9771 16.6359 31.9771 16.6359H16.0229C16.0229 16.6359 16.0228 16.6359 16.0228 16.6359C15.6323 16.6364 15.258 16.7917 14.9819 17.0678L14.7698 16.8557L14.9819 17.0678C14.7058 17.344 14.5504 17.7183 14.55 18.1089V27.6055C14.5504 27.996 14.7058 28.3704 14.9819 28.6465C15.258 28.9226 15.6324 29.078 16.0229 29.0784H22.7424Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 24C28.5523 24 29 23.5523 29 23C29 22.4477 28.5523 22 28 22C27.4477 22 27 22.4477 27 23C27 23.5523 27.4477 24 28 24ZM25 23C25 23.5523 24.5523 24 24 24C23.4477 24 23 23.5523 23 23C23 22.4477 23.4477 22 24 22C24.5523 22 25 22.4477 25 23ZM21 23C21 23.5523 20.5523 24 20 24C19.4477 24 19 23.5523 19 23C19 22.4477 19.4477 22 20 22C20.5523 22 21 22.4477 21 23Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
