import { useNavigate, useParams } from "react-router-dom";
import { paths } from "shared/router";

type ConversationParams = {
  conversationId: string;
};

export const useConversationParams = () => {
  const { conversationId } = useParams();
  const navigate = useNavigate();

  if (!conversationId) {
    navigate(paths.app.conversations);
  }

  return { conversationId } as ConversationParams;
};
