import React from "react";
import { Box, BoxProps } from "@mui/material";
import { colors } from "shared/theme";

export const TermsOfServiceInfo: React.FC<BoxProps> = (props) => {
  return (
    <Box
      fontSize={14}
      fontWeight={500}
      width={1}
      display={"flex"}
      justifyContent={"center"}
      color={colors.haleNavy.dark}
      py={2}
      sx={{
        "& a": {
          color: colors.markusBlue.light,
          textDecoration: "none",
        },
      }}
      {...props}
    >
      <Box width={260}>
        By continuing you agree to accept our{" "}
        <a
          href="https://www.gomarkus.com/terms-of-service.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          terms of service
        </a>{" "}
        and{" "}
        <a
          href="https://www.gomarkus.com/privacy.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          privacy policy
        </a>
      </Box>
    </Box>
  );
};
