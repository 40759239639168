import { Stack, styled } from "@mui/material";
import { MARKUS_LINKS } from "shared/constants";
import {colors} from "shared/theme";

export const MarkusLinks = ({
  exclude = [],
}: {
  exclude?: (keyof typeof MARKUS_LINKS)[];
}) => {
  const links = Object.keys(MARKUS_LINKS) as (keyof typeof MARKUS_LINKS)[];
  return (
    <Stack
      role="complementary"
      direction="row"
      spacing={{ xs: 3, sm: 5 }}
      padding={2}
      flexWrap="wrap"
      justifyContent={"center"}
    >
      {links.map((key) =>
        exclude.includes(key) ? undefined : (
          <Link
            key={key}
            href={MARKUS_LINKS[key].url}
            rel="noopener noreferrer"
            target="_blank"
            sx={{color: colors.markusBlue.light}}
          >
            {MARKUS_LINKS[key].label}
          </Link>
        )
      )}
    </Stack>
  );
};

const Link = styled("a")(({ theme: { breakpoints, palette } }) => ({
  color: palette.primary.main,
  textDecoration: "none",
  textTransform: "capitalize",
  fontSize: 14,
  lineHeight: 1.8,
  wordBreak: "keep-all",
  fontWeight: 500,
  [breakpoints.up("md")]: {
    fontSize: 16,
  },
}));
