import React from "react";
import { alpha, Button, ButtonProps, styled } from "@mui/material";

export type BlueButtonProps = ButtonProps;

export const BlueButton: React.FC<BlueButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <StyledButton size={"large"} {...props}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(Button)(({ theme: { palette }, variant }) => ({
  backgroundColor:
    variant === "outlined" ? palette.common.white : palette.primary.main,
  color: variant === "outlined" ? palette.primary.main : palette.common.white,
  borderRadius: 15,
  textTransform: "none",
  width: "100%",
  fontSize: 16,
  height: 44,
  "&:hover": {
    backgroundColor:
      variant === "outlined" ? palette.grey[100] : palette.primary.dark,
  },
  "&[disabled]": {
    color: alpha(palette.grey[300], 0.7),
  },
}));
