import React from "react";
import { Stack, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Message } from "@twilio/conversations";

import { formatParticipantName } from "shared/twilio";
import { TextQuotePreview } from "./TextQuotePreview";
import { ImageQuotePreview } from "./ImageQuotePreview";
import { colors } from "shared/theme";
import { IconButton } from "shared/components";

type Props = { message: Message; onClose: () => void };
export const QuotePreview = ({ message, onClose }: Props) => {
  const [username, setUsername] = React.useState<string>("");

  const mounted = React.useRef(true);

  const mediaMessage = message.type === "media";

  React.useEffect(() => {
    message
      .getParticipant()
      .then((participant) => {
        if (!mounted) return null;

        setUsername(formatParticipantName(participant));
      })
      .catch(() => {});
  }, [message]);

  return (
    <Stack direction="row" flex={1}>
      <Box flex={1} minWidth={0}>
        <Stack color={colors.haleNavy.dark} spacing={1} width={1} pr={2}>
          <Typography variant="caption">
            Replying to{" "}
            <Box display="inline" fontWeight="bold">
              {username}
            </Box>
          </Typography>
          {mediaMessage ? (
            <ImageQuotePreview image={(message.attachedMedia ?? [])[0]} />
          ) : (
            <TextQuotePreview text={message.body ?? ""} />
          )}
        </Stack>
      </Box>
      <Box flex={0} display="flex" alignItems="center">
        <IconButton
          color="secondary"
          onClick={onClose}
          size={24}
          label="cancel reply"
          showTooltip={false}
          icon={CloseIcon}
        />
      </Box>
    </Stack>
  );
};
