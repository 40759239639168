import React from "react";
import { Client, Conversation, Message } from "@twilio/conversations";

export type ConversationMetaData = {
  sid?: string;
  unreadMessagesCount: number;
  lastMessage: string | null;
  updateDate: Date | null;
};

export type TwilioContextState = {
  twilioClient: Client | undefined;
  conversationsMetaData: Record<string, ConversationMetaData>;
  resetUnreadMessagesCount: (conversationId: string) => void;
  logout: () => Promise<void>;
  setConversationsMetaData: React.Dispatch<
    React.SetStateAction<Record<string, ConversationMetaData>>
  >;
};

export type HasUsername = {
  user_name: string;
};

export type HasWebUser = {
  web_user: string;
};

export type HasQuotedMessage = {
  quotedMessage: {
    sid: string;
    index: number;
  };
};

export enum MessageType {
  Text = "Text",
  Media = "Media",
  EmailAttachment = "EmailAttachments",
  Email = "Email",
}

export type TwilioConversationContextState = {
  messages: Message[];
  twilioConversation: Conversation | null;
  fetchMore: () => void;
  hasMore: boolean;
  updateParticipantName: (id: string, name: string) => Promise<void>;
  isLoading: boolean;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
};
