import React from "react";
import { Route } from "react-router-dom";
import { AppRoute } from "./model";
import { RouteWrapper } from "./RouteWrapper";

export const renderAppRoutes = (routes: AppRoute[]): React.ReactElement[] => {
  return routes.map(({ path, element, wrapper, children }) => {
    return (
      <Route
        key={path}
        element={<RouteWrapper wrapper={wrapper}>{element}</RouteWrapper>}
        path={path}
      >
        {children && renderAppRoutes(children)}
      </Route>
    );
  });
};
