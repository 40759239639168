import { Query, QueryClient } from "react-query";
import { GmailMessage, GmailMessagePart } from "./types";
import { gmailConfig } from "./model";
import { QueryKey } from "../api";
import xss from "xss";

export const getGmailApiConfig = () => ({
  apiUrl: gmailConfig.apiUrl,
});

export const flattenParts = (message: GmailMessagePart): GmailMessagePart[] => {
  return [
    message,
    ...(message.parts?.map((v) => flattenParts(v)).flat() ?? []),
  ];
};

export const formatGmailBase64String = (value: string) => {
  return value.replace(/_/g, "/").replace(/-/g, "+");
};

export const getCachedEmails = (client: QueryClient) => {
  const cache = client.getQueryCache();
  return (
    cache.findAll(QueryKey.GmailMessage) as Query<
      GmailMessage,
      unknown,
      GmailMessage
    >[]
  )
    .filter((item) => Boolean(item.state))
    .map((item) => item.state.data as GmailMessage);
};

export const extractTextFromHtml = (html: string) => {
  const match = html.match(/<body[^>]*>([\s\S]*)<\/body>/);
  const body = match
    ? xss(match[1], {
        css: true,
        stripIgnoreTag: true,
        stripIgnoreTagBody: ["script", "style"],
        whiteList: {},
      })
    : "";

  return body
    .replace(/(?:&nbsp;)+/g, " ") // remove non-breaking spaces
    .replace(/(?:\s)+/g, " ") // remove extra spaces
    .replace(/&[\S]{2,8};/g, ""); // remove html entities
};
