import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const QuoteMessageIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="response icon"
  >
    <path
      d="M13.86 8.41923H9.19337L11.14 6.47256C11.2025 6.41058 11.2521 6.33685 11.286 6.25561C11.3198 6.17437 11.3372 6.08723 11.3372 5.99923C11.3372 5.91122 11.3198 5.82408 11.286 5.74284C11.2521 5.6616 11.2025 5.58787 11.14 5.52589C11.0151 5.40173 10.8462 5.33203 10.67 5.33203C10.4939 5.33203 10.3249 5.40173 10.2 5.52589L7.14004 8.61256C7.07695 8.67537 7.02708 8.75017 6.99337 8.83256C6.92669 8.99487 6.92669 9.17692 6.99337 9.33923C7.02961 9.42025 7.07918 9.49461 7.14004 9.55923L10.2267 12.6659C10.3498 12.7915 10.5175 12.8634 10.6934 12.8659C10.8258 12.8667 10.9554 12.828 11.0658 12.7548C11.1761 12.6816 11.2622 12.5772 11.3129 12.4549C11.3637 12.3326 11.3769 12.1979 11.3509 12.0681C11.3248 11.9383 11.2607 11.8191 11.1667 11.7259L9.22004 9.75256H13.8867C14.4171 9.75256 14.9258 9.96327 15.3009 10.3383C15.676 10.7134 15.8867 11.2221 15.8867 11.7526V17.9992C15.8867 18.176 15.9569 18.3456 16.082 18.4706C16.207 18.5957 16.3766 18.6659 16.5534 18.6659C16.7302 18.6659 16.8997 18.5957 17.0248 18.4706C17.1498 18.3456 17.22 18.176 17.22 17.9992V11.7526C17.22 11.3126 17.133 10.8769 16.9638 10.4708C16.7946 10.0646 16.5467 9.69594 16.2343 9.38606C15.922 9.07618 15.5513 8.83121 15.1438 8.66527C14.7363 8.49933 14.3 8.41571 13.86 8.41923Z"
      fill="currentColor"
    />
  </SvgIcon>
);
