import React from "react";
import { useCollaborators } from "../../collaborators";
import { useIncomingInvitations } from "./useIncomingInvitations";
import { useOutgoingInvitations } from "./useOutgoingInvitations";

export function useCollaboratorsInvitations() {
  const { data: collaborators, isLoading: collaboratorsLoading } =
    useCollaborators();

  const { outgoingInvitations, isLoading: outgoingLoading } =
    useOutgoingInvitations();

  const { incomingInvitations, isLoading: incomingLoading } =
    useIncomingInvitations();

  const invitationsData = React.useMemo(
    () =>
      collaborators && incomingInvitations && outgoingInvitations
        ? {
            collaborators,
            incomingInvitations,
            outgoingInvitations,
          }
        : undefined,
    [collaborators, incomingInvitations, outgoingInvitations]
  );
  const isLoading = collaboratorsLoading || incomingLoading || outgoingLoading;

  return {
    invitationsData, isLoading
  }
}
