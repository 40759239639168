import React from "react";
import { Box, List, Typography } from "@mui/material";
import { useIsBreakpoint } from "shared/theme";
import { CountSectionHeader } from "shared/components";
import { ContactsListItem } from "./ContactsListItem";
import { Contact } from "./model";

type ContactListProps = { contacts: Contact[] };

export const ContactsList: React.VFC<ContactListProps> = ({ contacts }) => {
  const isMdBreakpoint = useIsBreakpoint("md");
  return (
    <>
      {isMdBreakpoint && <CountSectionHeader title="Contacts" />}

      <Box overflow={"auto"}>
        {!!contacts.length ? (
          <List>
            {contacts.map((contact, index) => (
              <ContactsListItem key={index} contact={contact} />
            ))}
          </List>
        ) : (
          <Box
            width={1}
            display={"flex"}
            alignItems={"center"}
            flexDirection={"column"}
            color={"grey.600"}
            textAlign={"center"}
            py={10}
            px={2}
            pt={{ xs: 2, md: 20 }}
          >
            <Typography mt={4} fontSize={{ xs: 14, md: 16 }} fontWeight={600}>
              Your contact list is empty
            </Typography>
            <Typography mt={2} fontSize={{ xs: 12, md: 14 }}>
              Use the invite link below to add people to your contacts
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
