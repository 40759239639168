import { EmailRelaySwitch } from "../email-relay";
import { Conversation } from "../types";
import { PublicStatusSection } from "./PublicStatusSection";
import { PublicStatusSwitch } from "./PublicStatusSwitch";
import { AddressCopy, PublicIcon, RelayIcon } from "shared/components";
import { SectionHeader, SectionWrapper } from "./layout";

export type GeneralSettingsProps = {
  onPublicStatusChange: (val: boolean) => void;
  publicStatus: boolean;
  isMineConversation?: boolean;
  conversation?: Conversation;
};

export const GeneralSettings = ({
  conversation,
  onPublicStatusChange,
  publicStatus,
  isMineConversation,
}: GeneralSettingsProps) => {
  if (!conversation) {
    return (
      <>
        <SectionHeader>General</SectionHeader>
        <PublicStatusSection
          isPublic={publicStatus}
          onChange={onPublicStatusChange}
        />
      </>
    );
  }

  return (
    <SectionWrapper>
      <SectionHeader>General</SectionHeader>
      {isMineConversation ? (
        <>
          <PublicStatusSwitch
            chatSid={conversation.chatSid}
            publicChatUrl={conversation.publicChatUrl}
          />
          <EmailRelaySwitch
            chatSid={conversation.chatSid}
            emailRelayAddress={conversation.emailRelayAddress}
          />
        </>
      ) : (
        <>
          <AddressCopy
            fullWidth
            address={conversation.publicChatUrl}
            icon={<PublicIcon sx={{ fontSize: 16, color: "secondary.main" }} />}
            title={"Copy Public Chat address"}
            text={
              "Public discussion allows users to join the discussion via a web browser"
            }
            disabledText={"Public Chat not enabled"}
            copyText="Public link copied to clipboard"
          />
          <AddressCopy
            fullWidth
            address={conversation.emailRelayAddress}
            icon={<RelayIcon sx={{ fontSize: 16, color: "secondary.main" }} />}
            title={"Copy Email Relay address"}
            text={
              "Participants can forward emails directly to this thread"
            }
            disabledText={"Email Relay not enabled"}
          />
        </>
      )}
    </SectionWrapper>
  );
};
