import React from "react";
import { Media } from "@twilio/conversations";
import { Box } from "@mui/material";

import { AttachmentMessage } from "../shared";

type Props = {
  media: Media[] | null;
};

export const ChatMessageEmailAttachments = ({ media }: Props) => {
  if (!media || !media.length) {
    return null;
  }

  return (
    <Box
      display={"flex"}
      p={2}
      sx={{
        backgroundColor: "grey.200",
      }}
      flexWrap="wrap"
    >
      {media.map((med) => (
        <Box p={1} key={med.sid}>
          
            <AttachmentMessage
              key={med.sid}
              media={med}
              type="email"
              compact
            />
        </Box>
      ))}
    </Box>
  );
};
