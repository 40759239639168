import React from "react";
import {
  Box,
  Dialog,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  Slide,
  styled,
} from "@mui/material";
import { ArrowBackIosNew, Close } from "@mui/icons-material";
import { TransitionProps } from "@mui/material/transitions";
import { colors, useIsBreakpoint } from "../theme";
import { IconButton } from "./IconButton";

type AppDialogTitleProps = {
  title: string;
  onClose: () => void;
  iosStyle?: boolean;
} & DialogTitleProps;

type AppDialogProps = DialogProps & { iosStyle?: boolean };

export const AppDialog = styled(
  ({ iosStyle = false, ...props }: AppDialogProps) => {
    const isSmBreakpoint = useIsBreakpoint("sm");
    return (
      <Dialog
        keepMounted
        scroll={"paper"}
        {...props}
        TransitionComponent={
          isSmBreakpoint && iosStyle ? Transition : undefined
        }
      />
    );
  }
)(({ theme: { spacing, breakpoints }, iosStyle }) => ({
  "& .MuiDialog-container": {
    [breakpoints.down("sm")]: {
      ...(iosStyle
        ? {
            alignItems: "flex-end",
          }
        : {}),
    },
  },
  "& .MuiPaper-root": {
    position: "fixed",
    margin: 0,
    right: 0,
    borderRadius: 0,
    maxWidth: "100%",
    width: 692,
    height: "100%",
    maxHeight: "100%",
    [breakpoints.down("md")]: {
      width: "100%",
    },
    [breakpoints.down("sm")]: {
      ...(iosStyle
        ? {
            width: "100%",
            margin: 0,
            borderRadius: "16px 16px 0 0",
            maxWidth: "unset",
            height: "calc(100vh - 48px)",
          }
        : {}),
    },
  },
  "& .MuiDialogContent-root": {
    padding: spacing(4, 4),
    [breakpoints.down("sm")]: {
      padding: spacing(2, 2),
    },
  },
  "& .MuiDialogActions-root": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: spacing(0, 4, 6),
    [breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  "& .MuiDialogTitle-root": {
    display: "flex",
    alignItems: "center",
    padding: spacing(4, 4, 2),
    [breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
}));

export const AppDialogTitle: React.FC<AppDialogTitleProps> = ({
  title,
  onClose,
  iosStyle = false,
  ...props
}) => {
  const isSmBreakpoint = useIsBreakpoint("sm");

  return (
    <DialogTitle {...props}>
      <Box flex={{ xs: iosStyle ? 1 : 0, sm: 1 }}>
        {isSmBreakpoint && !iosStyle && (
          <IconButton
            onClick={onClose}
            color={"transparent"}
            icon={ArrowBackIosNew}
            sx={{ mr: 2 }}
          />
        )}
      </Box>
      <Box
        m={0}
        fontSize={{ xs: 20, sm: 24 }}
        color={colors.haleNavy.dark}
        flex={1}
        whiteSpace={"nowrap"}
        textAlign={{ xs: "left", sm: "center" }}
      >
        {title}
      </Box>
      <Box flex={1} textAlign={"right"}>
        {(!isSmBreakpoint || iosStyle) && (
          <IconButton color={"transparent"} onClick={onClose} icon={Close} />
        )}
      </Box>
    </DialogTitle>
  );
};
const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

interface BusinessPartner {
  name: string;
  credit: number;
}

interface Identity {
  id: number;
  name: number;
}

interface Contact {
  email: string;
  phone: string;
}

type Customer = BusinessPartner & Contact & Identity;
type A = Customer["name"];
