import React from "react";
import { Box, Stack, StackProps, styled, Typography } from "@mui/material";
import { colors } from "shared/theme";

export const MessageQuoteAuthor = ({
  children,
}: {
  children?: React.ReactNode;
}) => (
  <Typography
    variant="h6"
    sx={{
      fontSize: 12,
      lineHeight: "16px",
      fontWeight: 500,
    }}
  >
    {children}
  </Typography>
);

export const MessageQuoteBody = ({
  children,
  deleted,
  fontSize = 14,
}: {
  children?: React.ReactNode;
  deleted?: boolean;
  fontSize?: number | string;
}) => (
  <Typography
    sx={{
      fontSize,
      lineHeight: "24px",
      fontWeight: 400,
      fontStyle: deleted ? "italic" : "normal",
      display: "flex",
      alignItems: "center",
    }}
  >
    {children}
  </Typography>
);

export const MessageQuoteWrapper = styled(
  (props: StackProps & { isMine: boolean }) => (
    <Box sx={{ display: "flex", paddingBottom: 2.5 }}>
      <Stack spacing={1} {...props} />
    </Box>
  ),
  {
    shouldForwardProp: (prop) => prop !== "isMine",
  }
)(({ theme: { palette, spacing }, isMine }) => ({
  borderLeft: `4px solid ${isMine ? colors.grey.dark : colors.blueAccent}`,
  borderRadius: spacing(1.5),
  padding: spacing(2, 2.5),
  minWidth: 100,
  backgroundColor: palette.common.white,
}));
