import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Routes } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";

import { routes, renderAppRoutes } from "shared/router";
import { AuthProvider, useIsAuthenticated } from "shared/auth";
import { TwilioProvider } from "shared/twilio";
import { theme } from "shared/theme";
import { SetupUser } from "shared/user";
import { NotificationsProvider } from "shared/notifications";
import { AnalyticsProvider } from "shared/analytics";
import { AppLoader } from "shared/components/AppLoader";
import { isInPublicChat } from "shared/public-chat";

const App = () => {
  React.useEffect(() => {
    const updateStatus = () => {
      if (navigator.onLine) {
        console.log("Your connection is back");
      } else {
        console.log("You have lost connection");
      }
    };

    window.addEventListener("offline", updateStatus);
    window.addEventListener("online", updateStatus);

    return () => {
      window.removeEventListener("offline", updateStatus);
      window.removeEventListener("online", updateStatus);
    };
  }, []);
  return (
    <>
      <Providers>
        <CssBaseline />
        <SetupUser />
        <React.Suspense fallback={<Fallback />}>
          <Routes>{renderAppRoutes(routes)}</Routes>
        </React.Suspense>
      </Providers>
    </>
  );
};

const Fallback = () => {
  const isAuthenticated = useIsAuthenticated();
  const isPublic = isInPublicChat();

  return <AppLoader inApp={isAuthenticated && !isPublic} />;
};

export default App;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: false,
    },
  },
});

const Providers: React.FC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <NotificationsProvider>
            <AuthProvider>
              <TwilioProvider>
                <AnalyticsProvider>{children}</AnalyticsProvider>
              </TwilioProvider>
            </AuthProvider>
          </NotificationsProvider>
        </ThemeProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};
