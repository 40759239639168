import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const EditCircleIcon: React.FC<SvgIconProps> = (props) => {
  const fillProp = props.fill ?? "transparent";
  return (
    <SvgIcon
      viewBox="0 0 27 27"
      {...props}
      aria-label="edit circle icon"
      sx={{ fill: fillProp, ...props.sx }}
    >
      <circle
        cx="13.5"
        cy="13.5"
        r="12.5"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M18.6451 7.26657L18.5391 7.37263L18.6451 7.26656C18.0897 6.71113 17.1864 6.71116 16.6309 7.26656L16.6309 7.26657L8.54059 15.3575C8.46771 15.4304 8.41961 15.5257 8.40209 15.6208C8.40209 15.6208 8.40209 15.6208 8.40209 15.6209C8.40207 15.6209 8.40206 15.621 8.40205 15.621L7.85839 18.5571L7.85839 18.5571C7.82833 18.7194 7.88008 18.8863 7.99689 19.0031C8.11381 19.12 8.28066 19.1717 8.44289 19.1416C8.44289 19.1416 8.4429 19.1416 8.4429 19.1416L11.3789 18.5978L11.3791 18.5978C11.4761 18.5797 11.5704 18.5312 11.6422 18.4593L19.7325 10.3684C20.2891 9.8118 20.2892 8.91071 19.7325 8.35407L18.6451 7.26657ZM19.0232 9.06341L19.1293 8.95735L19.0232 9.06342C19.1879 9.22808 19.1879 9.49437 19.0232 9.65906L18.7037 9.97863L17.0207 8.2955L17.3402 7.97591C17.5043 7.8118 17.7717 7.81177 17.9358 7.97592L19.0232 9.06341ZM8.97755 18.0223L9.2148 16.7411L10.2587 17.7851L8.97755 18.0223ZM9.60454 15.7122L16.3114 9.00482L17.9944 10.688L11.2876 17.3953L9.60454 15.7122Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.3"
      />
    </SvgIcon>
  );
};
