import { useUpdateUserSettings } from "shared/user";
import {
  GmailFilter,
  gmailLabels,
  useDeleteGmailFilter,
  useGmailFilters,
  useGmailLabels,
  useCreateGmailFilter,
} from "shared/gmail";

export const useUpdateFilters = () => {
  const { data: filters } = useGmailFilters();
  const { data: labels } = useGmailLabels();

  const labelId = labels?._labelsByName?.[gmailLabels.default]?.id;

  const filterId = filters?.filter && getFilterId(filters.filter, labelId);
  const { deleteGmailFilter, isLoading: deleteFilterLoading } =
    useDeleteGmailFilter(filterId);
  const { createGmailFilter, isLoading: createFilterLoading } =
    useCreateGmailFilter();

  const { updateUser, isLoading: updateSettingsLoading } =
    useUpdateUserSettings();

  const updateFilters = (filters: string[], autoArchive: boolean) => {
    if (!labelId) {
      return;
    }

    if (Boolean(filterId)) {
      deleteGmailFilter();
    }

    if (filters.length) {
      createGmailFilter({
        id: "",
        action: {
          addLabelIds: [labelId],
          removeLabelIds: autoArchive ? ["INBOX"] : undefined,
        },
        criteria: {
          from: filters.join(" OR "),
        },
      });
    }

    updateUser({
      userConsent: true,
      skipInbox: filters.length ? autoArchive : false,
      emailContacts: filters.map((value) => ({ email: value })),
    });
  };

  return {
    updateFilters,
    isLoading:
      updateSettingsLoading || deleteFilterLoading || createFilterLoading,
  };
};

const getFilterId = (filters: GmailFilter[], labelId: string | undefined) => {
  if (!labelId) {
    return undefined;
  }

  return filters.find(
    ({ action }) =>
      action.addLabelIds?.length === 1 && action.addLabelIds[0] === labelId
  )?.id;
};
