import React from "react";
import { AuthData, AuthDataType } from "../types";

export function useListenToLogoutEvent() {

  React.useEffect(() => {
    const handleLogoutEvent = (event: StorageEvent) => {
      if (event.storageArea !== localStorage) {
        return;
      }

      if (event.key !== AuthDataType.App || event.newValue === null) {
        return;
      }

      const newValue = JSON.parse(event.newValue) as AuthData;

      if (!newValue.accessToken) {
        window.location.reload();
      }
    };
    window.addEventListener("storage", handleLogoutEvent);

    return () => {
      window.removeEventListener("storage", handleLogoutEvent);
    };
  }, []);
}
