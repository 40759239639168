import React from "react";
import { Message } from "@twilio/conversations";
import { Box } from "@mui/material";

import { Popup, Input, EditMessageIcon } from "shared/components";
import { useShowError } from "shared/notifications";
import { hasEmail } from "shared/twilio";
import { useDeleteEmail } from "../../chat-email";
import { MessageDialogType } from "./model";
import { useDeleteMessage, useEditMessage } from "./EditMessage.context";

export type EditMessagePopupProps = {
  open: boolean;
  onClose: (idToDelete?: string) => void;
  message: Message | null;
  type?: MessageDialogType;
};

export const EditMessagePopUp: React.VFC<EditMessagePopupProps> = ({
  open,
  onClose,
  type,
  message,
}) => {
  const deleteMessage = useDeleteMessage();
  const deleteEmail = useDeleteEmail(message?.conversation?.sid);
  const editMessage = useEditMessage();

  const showError = useShowError();

  const [currentMessage, setCurrentMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const messageEdited = currentMessage !== message?.body;

  const handleEdit = async () => {
    if (!message || !messageEdited || loading) {
      return;
    }

    setLoading(true);

    try {
      await editMessage(currentMessage);
    } catch (_) {
      showError("Failed to edit message", "Please try again later");
    }

    setLoading(false);
  };

  const handleDelete = async () => {
    if (!message || loading) {
      return;
    }

    setLoading(true);

    try {
      if (hasEmail(message.attributes)) {
        await deleteEmail(
          message.attributes.externalProviderMsgId,
          message.sid
        );
      } else {
        await deleteMessage();
      }
    } catch (_) {
      showError("Failed to delete message", "Please try again later");
      setLoading(false);
      return;
    }

    setLoading(false);
    onClose(message.sid);
  };

  React.useEffect(() => {
    if (message && message.body) setCurrentMessage(message.body);
  }, [message]);

  if (type === MessageDialogType.Delete) {
    return (
      <Popup
        open={open}
        onClose={onClose}
        onClick={handleDelete}
        danger
        id="delete_message"
        title="Are you sure you want to delete message?"
        content="This action cannot be undone."
        acceptText="Delete"
        disabled={loading}
      />
    );
  }

  return (
    <Popup
      open={open}
      onClose={onClose}
      onClick={handleEdit}
      id="edit_message"
      title="Edit mesage text:"
      icon={<EditMessageIcon sx={{ fontSize: 40 }} />}
      content={
        <Box pt={1.5} maxWidth={400}>
          <Input
            maxRows={4}
            label="Message"
            labelFixed
            multiline
            value={currentMessage}
            onChange={(e) => setCurrentMessage(e.target.value)}
          />
        </Box>
      }
      acceptText="Change"
      cancelText="Cancel"
      disabled={!messageEdited || loading}
    />
  );
};
