import { Box, styled } from "@mui/material";
import React from "react";
import { IFrameWrapper } from "./styles";

export const YouTubeLinkPreview = ({ videoId }: { videoId: string }) => {
  return (
    <IFrameWrapper>
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </IFrameWrapper>
  );
};
