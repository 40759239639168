import { Stack, styled } from "@mui/material";
import React from "react";
import {
  IconSection,
  LinkMediaResponse,
  MediaItemContainer,
  TextPrimary,
  TextSecondary,
} from "../shared";
import { LinkMediaIcon } from "./LinkMediaIcon";

export const MediaBinLinkItem = ({ id, pageTitle, url }: LinkMediaResponse) => {
  return (
    <MediaItemContainer>
      <IconSection>
        <LinkMediaIcon />
      </IconSection>
      <StyledLink href={url} target="_blank" rel="noreferrer noopener">
        <LinkSection>
          <TextPrimary>{url}</TextPrimary>
          <TextSecondary>{pageTitle}</TextSecondary>
        </LinkSection>
      </StyledLink>
    </MediaItemContainer>
  );
};

const LinkSection = styled(Stack)(({ theme: { palette, spacing } }) => ({
  flex: 1,
  marginRight: spacing(2),
  padding: spacing(0, 3),
  width: 0,
}));

const StyledLink = styled("a")(({ theme: { palette, spacing } }) => ({
  textDecoration: "none",
  color: "unset",
  display: "flex",
  flex: 1,
}));
