import React from "react";
import { Box, lighten, styled, Typography } from "@mui/material";
import { Message } from "@twilio/conversations";

import { hasQuotedMessage } from "shared/twilio";
import { colors } from "shared/theme";

import { LinkPreview } from "../../link-preview";
import { useChatContext } from "../../context";
import { MessageQuote } from "../quote-message/MessageQuote";
import { useMessageBody } from "./useMessageBody";

type TextMessageProps = {
  message: Message;
  isMine: boolean;
};

export const TextMessage: React.FC<TextMessageProps> = ({
  message,
  isMine,
}) => {
  const { isPublicChatView } = useChatContext();

  const body = useMessageBody(message.body ?? "", message.attributes);

  const hasQuote = hasQuotedMessage(message.attributes);

  return (
    <TextMessageWrapper textAlign={"left"} isMine={isMine}>
      {hasQuote && (
        <MessageQuote
          sid={message.attributes.quotedMessage.sid}
          index={message.attributes.quotedMessage.index}
          isMine={isMine}
        />
      )}
      <Typography
        sx={{
          lineHeight: 1.25,
        }}
        component="div"
        dangerouslySetInnerHTML={{
          __html: body,
        }}
      />
      <LinkPreview isPublicChat={isPublicChatView} body={message.body ?? ""} />
    </TextMessageWrapper>
  );
};

export const TextMessageWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMine",
})<{ isMine: boolean }>(({ theme: { spacing, palette }, isMine }) => ({
  padding: spacing(3, 4),
  backgroundColor: isMine ? "#e6e2f9" : colors.grey.main,
  color: colors.haleNavy.dark,
  borderRadius: isMine ? spacing(3.5, 0, 3.5, 3.5) : spacing(3.5, 3.5, 3.5, 0),
  wordBreak: "break-word",
  "& a": {
    color: lighten(palette.primary.main, 0.2),
  },
}));
