import React from "react";
import {
  InputAdornment,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { colors } from "shared/theme";
import { ReturnIcon } from "shared/components";

type MessageInputProps = TextFieldProps & {
  onEnter?: () => void;
  helperText?: boolean;
  actions?: JSX.Element;
  inputRef?: React.MutableRefObject<
    HTMLInputElement | HTMLTextAreaElement | null
  >;
};

export const MessageInput: React.FC<MessageInputProps> = ({
  inputRef,
  actions,
  onPaste,
  onEnter,
  helperText = true,
  ...props
}) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (onEnter && event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      onEnter();
    }
  };

  useFocusAtTheEnd(inputRef);

  return (
    <StyledTextField
      id="message-text-field"
      placeholder={props.placeholder || "Type a message..."}
      multiline
      size={"small"}
      maxRows={!props.rows ? 4 : undefined}
      autoCorrect={"off"}
      autoCapitalize={"off"}
      spellCheck={false}
      helperText={helperText && <HelperText />}
      inputRef={inputRef}
      onPaste={onPaste}
      FormHelperTextProps={{
        sx: { textAlign: "right", lineHeight: 1, py: 0 },
      }}
      InputProps={{
        endAdornment: actions ? (
          <InputAdornment position="end">{actions}</InputAdornment>
        ) : undefined,
      }}
      inputProps={{
        "aria-label": "type a message",
      }}
      onKeyDown={handleKeyPress}
      {...props}
    />
  );
};

const useFocusAtTheEnd = (
  inputRef?: React.MutableRefObject<
    HTMLInputElement | HTMLTextAreaElement | null
  >
) => {
  React.useEffect(() => {
    if (!inputRef || !inputRef.current) {
      return;
    }

    inputRef.current.focus();
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.selectionStart = inputRef.current.selectionEnd = 10000;
        inputRef.current.scrollTop = inputRef.current.scrollHeight;
      }
    }, 0);
  }, [inputRef]);
};

const HelperText = () => (
  <Typography
    component={"span"}
    sx={{
      color: colors.haleNavy.light,
      fontSize: 12,
    }}
  >
    <ReturnIcon
      sx={{ verticalAlign: "middle", mr: 1 }}
      fontSize="inherit"
      color="inherit"
    />
    <span style={{ fontWeight: 600 }}>Shift + Enter</span> to add a new line
  </Typography>
);

const StyledTextField = styled(TextField)(
  ({ theme: { spacing, breakpoints } }) => ({
    "& .MuiOutlinedInput-root": {
      padding: spacing(4, 2, 4, 6),
      "& fieldset": {
        borderColor: colors.haleNavy.light,
        borderRadius: spacing(3.5),
      },
      "&:hover fieldset": {
        borderColor: colors.haleNavy.light,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 1,
        borderColor: colors.blueAccent,
      },
      "& textarea": {
        fontSize: 16,
        lineHeight: "18.75px",
        color: colors.haleNavy.main,
      },
      "& textarea::placeholder": {
        color: colors.haleNavy.dark,
      },
    },
    [breakpoints.down("sm")]: {
      "& .MuiFormHelperText-root": {
        display: "none",
      },
    },
  })
);
