import React from "react";
import { UseBaseQueryOptions, useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useApi } from "shared/api";
import { config } from "shared/config";
import { getErrorPage } from "shared/notifications";
import { paths } from "shared/router";

export function useResendLink() {
  const navigate = useNavigate();
  const { code } = useParams();
  const { post } = useApi();

  const { mutate, isLoading, error } = useMutation(
    post<undefined, {}>(
      config.endpoints.auth.resend.replace(":code", code || "")
    )
  );

  const resendLink = React.useCallback(
    (options: UseBaseQueryOptions<{}> = {}) => {
      mutate(undefined, {
        ...options,
        onSuccess: () => navigate(paths.auth.registered),
        onError: () => navigate(getErrorPage("Request")),
      });
    },
    [mutate, navigate]
  );

  React.useEffect(() => {
    if (!code) navigate(paths.notFound);
  }, [code, navigate]);

  return {
    resendLink,
    isLoading,
    error,
  };
}
