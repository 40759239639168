import { Media } from "@twilio/conversations";
import { Box, styled } from "@mui/material";
import { useGetMediaAsObjectUrl } from "shared/twilio";
import { LoadingIndicator } from "shared/components";
import {
  APP_HEADER_HEIGHT,
  MOBILE_APP_BAR_HEIGHT,
} from "shared/constants";

export const ImagePreview = ({ media }: { media: Media }) => {
  const src = useGetMediaAsObjectUrl(media);
  return (
    <Box
      flex={1}
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Wrapper>
        {!src && <LoadingIndicator />}
        <img
          src={src}
          style={{
            maxWidth: "100%",
            maxHeight: `calc(100vh - ${APP_HEADER_HEIGHT}px - 60px)`, // value 100% was causing Ipad won't show image until rotated
            display: src ? "block" : "none",
          }}
          alt={media.filename ?? ""}
        />
      </Wrapper>
    </Box>
  );
};
const Wrapper = styled(Box)(({ theme: { breakpoints } }) => ({
  maxWidth: "100%",
  overflow: "auto",
  maxHeight: `calc(100vh - ${MOBILE_APP_BAR_HEIGHT}px - 60px)`,
  [breakpoints.up("sm")]: {
    maxHeight: `calc(100vh - ${APP_HEADER_HEIGHT}px - 60px)`,
  },
}));