import React from "react";
import { omit } from "ramda";

import { indexBy } from "./indexBy";

export const useChecklist = <T>(initialValue: T[], accessor: keyof T) => {
  const [value, setValue] = React.useState(indexBy(initialValue, accessor));

  const handleCheck = React.useCallback(
    (checked: boolean, item: T) => {
      setValue((prev) => {
        const itemAccessor = [item[accessor]] as unknown as string;

        return checked
          ? { ...prev, ...{ [itemAccessor]: item } }
          : omit([itemAccessor], prev);
      });
    },
    [accessor]
  );

  const reset = React.useCallback(() => {
    setValue(indexBy(initialValue, accessor));
  }, [initialValue]);

  return [value, handleCheck, reset] as [
    Record<string, T>,
    (checked: boolean, item: T) => void,
    () => void
  ];
};
