import React from "react";
import { throttle } from "./throttle";

export function useSizeObserver<T extends HTMLElement>(
  ref: React.RefObject<T>,
  boxType: "border" | "content" = "content",
  throttleTime = 300
) {
  const [size, _setSize] = React.useState({ width: 0, height: 0 });
  const [isError, setError] = React.useState(false);

  const setSize = React.useCallback(
    throttleTime > 0 ? throttle(_setSize, throttleTime) : _setSize,
    [throttleTime]
  );
  const handleSize = React.useCallback(
    (entry: ResizeObserverEntry) => {
      const boxSize =
        boxType === "content"
          ? entry.contentBoxSize[0]
          : entry.borderBoxSize[0];

      setSize({ width: boxSize.inlineSize, height: boxSize.blockSize });
    },
    [setSize, boxType]
  );

  const observerRef = React.useRef(
    new ResizeObserver((entries) => {
      if (entries.length !== 1 || !entries[0]) {
        return setError(true);
      }
      handleSize(entries[0]);
    })
  );

  React.useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }
    const observer = observerRef.current;
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
      setError(false);
      setSize({ width: 0, height: 0 });
    };
  }, [setSize, ref]);

  return {
    isError,
    size,
  };
}
