import React from "react";
import {
  Box,
  IconButton,
  Skeleton,
  Stack,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import {
  usePublicInvitation,
  useUpdatePublicInvitation,
} from "shared/public-invitation";
import { InvitationType } from "shared/invitations";
import { QueryDataWrapper } from "shared/api";
import { copyToClipboard } from "shared/utils";
import { LoadingIndicator } from "shared/components";
import { useShowNotification } from "shared/notifications";
import { AnalyticsEvent, useTrackEvent } from "shared/analytics";

export const PublicInvitationLink: React.FC = () => {
  const publicInvitationResult = usePublicInvitation({
    type: InvitationType.Contact,
  });

  const { updatePublicInvitation, isLoading } = useUpdatePublicInvitation(
    publicInvitationResult.data?.id || ""
  );

  const trackInvitationCopy = useTrackEvent(
    AnalyticsEvent.universal_invitation_copy
  );

  const showNotification = useShowNotification();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updatePublicInvitation({
      enabled: event.target.checked,
    });
  };

  const handleCopy = async (value: string) => {
    const copied = await copyToClipboard(value);

    trackInvitationCopy();

    if (!copied) {
      return showNotification("Failed to copy", {
        extraInfo: `Text: ${value}`,
        disableAutoHide: true,
      });
    }

    showNotification("Invitation link copied to clipboard");
  };

  return (
    <Box display={"flex"} width={1} justifyContent={"center"}>
      <Wrapper>
        <QueryDataWrapper
          result={publicInvitationResult}
          loadingView={<PublicInvitationSkeleton />}
        >
          {({ data }) => (
            <>
              <Box
                display={"flex"}
                alignItems={"start"}
                justifyContent={"space-between"}
              >
                <Stack spacing={1}>
                  <Typography component={Box} variant={"subtitle2"}>
                    Universal Invitation Link
                  </Typography>
                  <Typography component={Box} fontSize={12}>
                    Send the link below to anyone to connect with you
                  </Typography>
                </Stack>
                <QueryDataWrapper
                  result={{ data: {}, isLoading, error: null }}
                  loadingView={
                    <LoadingIndicator size={25} width={60} height={40} />
                  }
                >
                  {() => (
                    <Switch
                      checked={data.enabled}
                      onChange={handleChange}
                      disabled={isLoading}
                    />
                  )}
                </QueryDataWrapper>
              </Box>
              {data.enabled && (
                <LinkSection>
                  <Link variant={"caption"} noWrap textOverflow={"ellipsis"}>
                    {data.dynamicLinkUrl}
                  </Link>
                  <IconButton
                    color={"inherit"}
                    onClick={() => handleCopy(data.dynamicLinkUrl)}
                  >
                    <ContentCopy />
                  </IconButton>
                </LinkSection>
              )}
            </>
          )}
        </QueryDataWrapper>
      </Wrapper>
    </Box>
  );
};

const Wrapper = styled(Box)(({ theme: { palette, spacing } }) => ({
  border: `1px solid ${palette.grey[200]}`,
  borderRadius: 14,
  width: 450,
  minHeight: 90,
  padding: spacing(4, 4, 1),
  color: palette.grey[500],
  overflow: "hidden",
}));

const LinkSection = styled(Box)(({ theme: { palette, spacing } }) => ({
  borderTop: `1px solid ${palette.grey[200]}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: spacing(2),
  paddingTop: spacing(1),
  overflow: "hidden",
}));

const Link = styled(Typography)(({ theme: { palette } }) => ({
  borderRadius: 14,
  color: palette.grey[300],
}));

const PublicInvitationSkeleton = () => (
  <Stack spacing={5} width={1}>
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"start"}>
      <Box>
        <Skeleton width={150} />
        <Skeleton width={200} />
      </Box>
      <Skeleton width={60} />
    </Box>
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Skeleton width={200} />
      <Skeleton variant={"circular"} height={30} width={30} />
    </Box>
  </Stack>
);
