import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { paths } from "shared/router";
import { usePublicChatParams } from "./usePublicChatParams";

type ConversationItemParams = {
  conversationId: string;
};

export const usePublicChatParamsGuard = () => {
  const { chat, code } = usePublicChatParams();
  const { search } = useLocation();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!chat || !code) {
      navigate({ pathname: paths.home, search });
    }
  }, [chat, code, search]);

  return { conversationId: chat } as ConversationItemParams;
};
