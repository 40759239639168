const allowedImageTypes = [
  "image/png",
  "image/jpeg",
  "image/bmp",
  "image/webp",
];
const maxImageSize = 1024 * 1024 * 10; // 10MB

export function validateProfilePicture(file: File) {
  if (!allowedImageTypes.includes(file.type)) {
    return false;
  }
  
  if (file.size > maxImageSize) {
    return false;
  }
  return true;
}
