import {
  Alert,
  Box,
  Snackbar as MuiSnackbar,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "shared/theme";
import { IconButton } from "./IconButton";
// import { InfoSquareIcon } from "./icons";

type SnackbarProps = {
  open: boolean;
  onClose: () => void;
  title?: string;
  text?: string;
  disableAutoHide?: boolean;
  type?: "info" | "error";
  onExit?: () => void;
};

export const Snackbar = ({
  title,
  text,
  disableAutoHide,
  onClose,
  open,
  onExit,
  type = "info",
}: SnackbarProps) => {
  return (
    <StyledSnackbar
      type={type}
      open={open}
      autoHideDuration={disableAutoHide ? null : 6000}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      TransitionProps={{
        onExited: onExit,
      }}
    >
      <Alert
        elevation={6}
        onClose={onClose}
        severity={type}
        icon={<></>}
        sx={{ width: "100%" }}
        variant="filled"
        action={
          <IconButton
            label="close snackbar"
            color="transparent"
            sx={{
              ml: 2,
              mr: -2,
              mt: text ? -2 : undefined,
              color: "#F9FAFB",
            }}
            icon={CloseIcon}
            iconProps={{ sx: { color: "inherit", fontSize: 22 } }}
            size={40}
            onClick={onClose}
          />
        }
      >
        <Stack>
          <Typography
            noWrap
            sx={{
              fontWeight: 600,
              fontSize: 16,
              my: text ? undefined : 2,
            }}
          >
            {title}
          </Typography>
          {text && (
            <Typography sx={{ fontWeight: 400, fontSize: 14, mt: 1 }}>{text}</Typography>
          )}
        </Stack>
      </Alert>
    </StyledSnackbar>
  );
};

const StyledSnackbar = styled(MuiSnackbar, {
  shouldForwardProp: (prop) => prop !== "type",
})<{ type: SnackbarProps["type"] }>(
  ({ theme: { breakpoints, spacing }, type }) => ({
    minWidth: 300,
    "& .MuiAlert-message": {
      wordBreak: "break-word",
      minWidth: 0,
      padding: 0,
      [breakpoints.up("sm")]: {
        maxWidth: 600,
      },
    },
    "& .MuiPaper-root": {
      padding: spacing(4, 6),
      backgroundColor: type === "info" ? colors.blueAccent : colors.redAlert,
      color: "#FFF3F3",
    },
    "& .MuiAlert-icon": {
      fontSize: 24,
      padding: 0,
      marginRight: 12,
    },
    "& .MuiAlert-action": {
      padding: 0,
    },
  })
);
