import { useMemo } from "react";
import { defaultTo, descend, groupBy, pipe } from "ramda";
import { ConversationMetaData, useConversationsMetaData } from "shared/twilio";
import { useConversationsContext } from "../context";
import { Conversation } from "../types";
import { getConversationUpdateDate } from "../utils";

export function useSortedConversations(): Conversation[] {
  const { conversationsById } = useConversationsContext();
  const metadata = useConversationsMetaData();

  return useMemo(() => {
    if (!metadata) {
      return Object.values(conversationsById);
    }
    const { read, unread } = groupByUnread(conversationsById, metadata);

    return [
      ...unread.sort(
        descend((c) =>
          getConversationUpdateDate(c, metadata[c.chatSid]?.updateDate)
        )
      ),
      ...read.sort(
        descend((c) =>
          getConversationUpdateDate(c, metadata[c.chatSid]?.updateDate)
        )
      ),
    ];
  }, [conversationsById, metadata]);
}

function groupByUnread(
  conversations: Record<string, Conversation>,
  metadata: Record<string, ConversationMetaData>
) {
  const groups = groupBy<Conversation>(
    pipe(
      ({ chatSid }) => metadata[chatSid]?.unreadMessagesCount,
      defaultTo(0),
      (unreadCount) => (unreadCount ? "unread" : "read")
    ),
    Object.values(conversations)
  ) as { unread: Conversation[]; read: Conversation[] };

  return { unread: groups.unread || [], read: groups.read || [] };
}
