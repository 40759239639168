import { Box, styled, Typography } from "@mui/material";

export const MediaItemContainer = styled(Box)(
  ({ theme: { palette, spacing } }) => ({
    width: "100%",
    padding: spacing(2, 2, 2, 4),
    borderRadius: spacing(2),
    border: `1px solid ${palette.grey[200]}`,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  })
);

export const IconSection = styled(Box)({
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const TextPrimary = styled(Typography)({
  fontSize: 16,
  minWidth: 0,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const TextSecondary = styled(Typography)(({ theme: { palette } }) => ({
  fontSize: 12,
  color: palette.grey[300],
  wordSpacing: 3,
  minWidth: 0,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));
