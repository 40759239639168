import { useGoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import { AuthProviderType, useAuthenticate } from "shared/auth";
import { useShowError } from "shared/notifications";
import { CredentialJWTPayload, useGoogleAuth } from "shared/google-auth";
import { AnalyticsEvent, useTrackEvent } from "shared/analytics";

export const useLoginWithGoogle = () => {
  const { authenticateGoogle, isLoading: googleAuthLoading } = useGoogleAuth();
  const { authenticate, isLoading: authenticateLoading } = useAuthenticate();
  const showError = useShowError();

  const trackGoogleLogin = useTrackEvent(
    AnalyticsEvent.apple_account_connected
  );

  const loginWithGoogle = useGoogleLogin({
    onSuccess: ({ code }) => {
      if (!code) {
        return showError("Authentication failed", "Please try again later");
      }

      authenticateGoogle(
        { code },
        {
          onSuccess: ({ id_token }) => {
            const { name } = jwtDecode<CredentialJWTPayload>(id_token);

            trackGoogleLogin();

            authenticate({
              authProvider: AuthProviderType.Google,
              token: id_token,
              name,
            });
          },
        }
      );
    },
    flow: "auth-code",
  });

  return {
    loginWithGoogle,
    isLoading: authenticateLoading || googleAuthLoading,
  };
};
