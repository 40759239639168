const gmailLabel = process.env.REACT_APP_GMAIL_LABEL || "MARKUS_DEV";

export const gmailConfig = {
  apiUrl: "https://gmail.googleapis.com/gmail/v1",
  endpoints: {
    messages: "/users/:userId/messages",
    message: "/users/:userId/messages/:messageId",
    modifyMessage: "/users/:userId/messages/:messageId/modify",
    filters: "/users/:userId/settings/filters",
    filter: "/users/:userId/settings/filters/:filterId",
    labels: "/users/:userId/labels",
    messageAttachment:
      "/users/:userId/messages/:messageId/attachments/:attachmentId",
  },
};

export const gmailScopes = [
  "https://www.googleapis.com/auth/gmail.settings.basic",
  "https://www.googleapis.com/auth/gmail.labels",
  "openid",
  "https://www.googleapis.com/auth/userinfo.email",
  "https://www.googleapis.com/auth/userinfo.profile",
  "https://www.googleapis.com/auth/gmail.modify",
];

export const gmailLabels = {
  default: gmailLabel,
  archived: `${gmailLabel}_ARCHIVED`,
};
