import { useQuery } from "react-query";

import { QueryKey, useApi } from "shared/api";
import { config } from "shared/config";

export type LinkPreviewData = {
  url: string;
  mediaType: string;
  contentType: string;
  favicons: string[];
  title?: string;
  siteName?: string;
  description?: string;
  images?: string[];
  videos?: string[];
};

export type LinkPreviewResponse = {
  status: "fulfilled" | "rejected";
  data: LinkPreviewData | null;
};

export function useLinkPreview(link: string) {
  const { get } = useApi();

  return useQuery<LinkPreviewResponse>(
    [QueryKey.User, link],
    get(config.endpoints.linkPreview.main, {
      query: {
        url: link,
      },
    }),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
}
