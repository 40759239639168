import React from "react";
import { useUser } from "shared/user";
import { useUpdateFilters } from "./useUpdateFilters";

export function useAutoArchive(filters: string[]) {
  const { user, isLoading: userLoading } = useUser();

  const { isLoading: updateLoading } = useUpdateFilters();

  const [autoArchive, setAutoarchive] = React.useState(user.skipInbox);
  const [autoarchiveEnabled, setAutoarchiveEnabled] = React.useState(false);

  const isLoading = userLoading || updateLoading;

  const toggleAutoarchive = () => setAutoarchive((prev) => !prev);

  React.useEffect(() => {
    setAutoarchive(user.skipInbox);
  }, [user.skipInbox]);

  React.useEffect(() => {
    if (!filters.length) {
      setAutoarchive(false);
    }

    setAutoarchiveEnabled(Boolean(filters.length && !isLoading));
  }, [filters, isLoading]);

  return { autoArchive, autoarchiveEnabled, toggleAutoarchive };
}
