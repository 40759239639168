import React from "react";
import { Box } from "@mui/material";
import { Menu } from "shared/components";
import { useToggle } from "shared/utils";
import { useChatContext } from "../context";
import { PublicChatDetailsDialog } from "./PublicChatDetailsDialog";

export const ChatHeaderMenuPublic: React.FC = () => {
  const { conversation } = useChatContext();

  const [openInformation, toggleInformation] = useToggle(false);

  return (
    <Box>
      <Menu id="public_chat_header_menu">
        <Menu.Item onClick={toggleInformation}>Information</Menu.Item>
      </Menu>
      <PublicChatDetailsDialog
        onClose={toggleInformation}
        dialogProps={{ open: openInformation }}
        conversation={conversation}
      />
    </Box>
  );
};
