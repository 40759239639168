import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const DownloadIcon: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    aria-label="download icon"
  >
    <path
      d="M4.5 14C4.63261 14 4.75979 14.0527 4.85355 14.1464C4.94732 14.2402 5 14.3674 5 14.5V17C5 17.2652 5.10536 17.5196 5.29289 17.7071C5.48043 17.8946 5.73478 18 6 18H18C18.2652 18 18.5196 17.8946 18.7071 17.7071C18.8946 17.5196 19 17.2652 19 17V14.5C19 14.3674 19.0527 14.2402 19.1464 14.1464C19.2402 14.0527 19.3674 14 19.5 14C19.6326 14 19.7598 14.0527 19.8536 14.1464C19.9473 14.2402 20 14.3674 20 14.5V17C20 17.5304 19.7893 18.0391 19.4142 18.4142C19.0391 18.7893 18.5304 19 18 19H6C5.46957 19 4.96086 18.7893 4.58579 18.4142C4.21071 18.0391 4 17.5304 4 17V14.5C4 14.3674 4.05268 14.2402 4.14645 14.1464C4.24021 14.0527 4.36739 14 4.5 14Z"
      fill="currentColor"
    />
    <path
      d="M11.6466 15.854C11.6931 15.9006 11.7483 15.9375 11.809 15.9627C11.8697 15.9879 11.9349 16.0009 12.0006 16.0009C12.0664 16.0009 12.1315 15.9879 12.1923 15.9627C12.253 15.9375 12.3082 15.9006 12.3546 15.854L15.3546 12.854C15.4485 12.7601 15.5013 12.6328 15.5013 12.5C15.5013 12.3672 15.4485 12.2399 15.3546 12.146C15.2607 12.0521 15.1334 11.9994 15.0006 11.9994C14.8679 11.9994 14.7405 12.0521 14.6466 12.146L12.5006 14.293V5.5C12.5006 5.36739 12.448 5.24021 12.3542 5.14645C12.2604 5.05268 12.1332 5 12.0006 5C11.868 5 11.7408 5.05268 11.6471 5.14645C11.5533 5.24021 11.5006 5.36739 11.5006 5.5V14.293L9.35463 12.146C9.26075 12.0521 9.13341 11.9994 9.00063 11.9994C8.86786 11.9994 8.74052 12.0521 8.64663 12.146C8.55275 12.2399 8.5 12.3672 8.5 12.5C8.5 12.6328 8.55275 12.7601 8.64663 12.854L11.6466 15.854Z"
      fill="currentColor"
    />
  </SvgIcon>
);
