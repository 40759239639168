import React from "react";
import { Button, ButtonProps, lighten, styled } from "@mui/material";
import { GoogleIcon } from "./icons";

export const GoogleButton: React.FC<ButtonProps> = ({ children, ...props }) => (
  <StyledButton size={"large"} startIcon={<GoogleIcon />} {...props}>
    {children}
  </StyledButton>
);

const StyledButton = styled(Button)(({ theme: { typography } }) => ({
  backgroundColor: lighten("#808080", 0.8),
  color: "black",
  borderRadius: 12,
  minHeight: 45,
  fontSize: typography.subtitle2.fontSize,
  "&:hover": {
    backgroundColor: lighten("#808080", 0.6),
  },
  "&:disabled": {
    opacity: 0.4,
  },
}));
