import React from "react";
import { useLocalStorage } from "../utils";
import {
  GoogleAuthContextState,
  GoogleAuthResponse,
  initialAuthData,
} from "./types";
import {
  createContext,
  useContext,
  useContextSelector,
} from "use-context-selector";
import { gmailScopes } from "../gmail";
import { isInPublicChat } from "shared/public-chat";

const initialContext: GoogleAuthContextState = {
  authData: initialAuthData,
  setAuthData: () => {},
  isAuthenticated: false,
};

const GoogleAuthContext = createContext(initialContext);

export const GoogleAuthProvider: React.FC = ({ children }) => {
  const [authData, setAuthData] = useLocalStorage<GoogleAuthResponse>(
    "google-auth",
    initialAuthData
  );

  return (
    <GoogleAuthContext.Provider
      value={{
        authData,
        setAuthData,
        isAuthenticated: !!authData.access_token,
      }}
    >
      {children}
    </GoogleAuthContext.Provider>
  );
};

export const useSetGoogleAuthData = () =>
  useContextSelector(GoogleAuthContext, ({ setAuthData }) => setAuthData);

export const useGoogleLogout = () => {
  const setAuthData = useSetGoogleAuthData();

  return () => setAuthData(initialAuthData);
};

export const useGoogleAuthContext = () => useContext(GoogleAuthContext);

export const useHasGmailScopes = () => {
  const inPublicChat = isInPublicChat();
  return useContextSelector(GoogleAuthContext, ({ authData }) =>
    inPublicChat
      ? false
      : !gmailScopes.some((scope) => !authData.scope?.includes(scope))
  );
};
