import React from "react";
import { useToggleMediaPreview } from "shared/chat";
import {
  MediaLoader,
  MediaBinViewProps,
  MediaViewType,
  useMediaList,
  MediaType,
  MediaBinFileItem,
} from "../shared";

export const FilesBin = ({ chatSid }: MediaBinViewProps) => {
  const { data, length, fetchNextPage, hasNextPage, isLoading } = useMediaList(
    chatSid,
    MediaType.file
  );

  const toggleMedia = useToggleMediaPreview();

  return (
    <MediaLoader
      hasMore={hasNextPage}
      type={MediaViewType.files}
      loadMore={fetchNextPage}
      dataLength={length}
      isLoading={isLoading}
    >
      {data.map((item) => (
        <MediaBinFileItem {...item} key={item.id} onClick={toggleMedia} />
      ))}
    </MediaLoader>
  );
};
