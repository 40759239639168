import React from "react";
import {
  createContext,
  useContext,
  useContextSelector,
} from "use-context-selector";
import { useShowError } from "shared/notifications";
import { useUser } from "shared/user";
import { fileToBase64, validateProfilePicture } from "../utils";

export type UserProfileContextState = {
  profileChanged: boolean;
  currentName: string;
  base64avatar: string;
  setAvatar: (file: File | null) => void;
  setProfileChanged: (val: boolean) => void;
  setCurrentName: (val: string) => void;
};

const initialState: UserProfileContextState = {
  profileChanged: false,
  currentName: "",
  base64avatar: "",
  setProfileChanged: () => {},
  setCurrentName: () => {},
  setAvatar: () => {},
};

const UserProfileContext = createContext(initialState);

export const UserProfileProvider: React.FC = ({ children }) => {
  const [profileChanged, setProfileChanged] = React.useState(false);
  const [currentName, setCurrentName] = React.useState("");
  const [base64avatar, _setBase64avatar] = React.useState("");
  const showError = useShowError();

  const {
    user: { name },
  } = useUser();

  React.useEffect(() => {
    if (name) {
      setCurrentName(name);
    }
  }, [name]);

  // profile changed when current name changed
  React.useEffect(() => {
    if (profileChanged) {
      return;
    }
    if (name !== currentName && name && currentName) {
      setProfileChanged(true);
    }
  }, [name, currentName, profileChanged]);

  // profile changed when current avatar changed
  React.useEffect(() => {
    if (profileChanged) {
      return;
    }
    if (base64avatar) {
      setProfileChanged(true);
    }
  }, [base64avatar, profileChanged]);

  // profile not changed when data is reset
  React.useEffect(() => {
    if (!profileChanged) {
      return;
    }
    if (!base64avatar && (currentName === name || !currentName)) {
      setProfileChanged(false);
    }
  }, [base64avatar, currentName, name, profileChanged]);

  const setAvatar = React.useCallback(
    async (file: File | null) => {
      if (!file) {
        _setBase64avatar("");
        return;
      }

      if (!validateProfilePicture(file)) {
        return showError(
          "Invalid picture.",
          "Please provide valid profile photo. Available formats: png, jpg, jpeg, webp, bmp. Max size 10MB."
        );
      }

      const base64 = await fileToBase64(file);

      _setBase64avatar(base64);
    },
    [_setBase64avatar, showError]
  );

  const context = React.useMemo(
    () => ({
      currentName,
      base64avatar,
      profileChanged,
      setCurrentName,
      setProfileChanged,
      setAvatar,
    }),
    [
      currentName,
      setCurrentName,
      base64avatar,
      profileChanged,
      setProfileChanged,
      setAvatar,
    ]
  );

  return (
    <UserProfileContext.Provider value={context}>
      {children}
    </UserProfileContext.Provider>
  );
};

export const useProfileChanged = () =>
  useContextSelector(
    UserProfileContext,
    ({ profileChanged }) => profileChanged
  );

export const useSetProfileChanged = () =>
  useContextSelector(
    UserProfileContext,
    ({ setProfileChanged }) => setProfileChanged
  );

export const useCurrentName = () =>
  useContextSelector(UserProfileContext, ({ currentName }) => currentName);

export const useSetCurrentName = () =>
  useContextSelector(
    UserProfileContext,
    ({ setCurrentName }) => setCurrentName
  );

export const useBase64avatar = () =>
  useContextSelector(UserProfileContext, ({ base64avatar }) => base64avatar);

export const useSetAvatar = () =>
  useContextSelector(UserProfileContext, ({ setAvatar }) => setAvatar);

export const useUserProfileContext = () => useContext(UserProfileContext);
