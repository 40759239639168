type Comparator<T> = (a: T, b: T) => boolean;

export function arraysDiff<T>(prev: T[], next: T[], fn: Comparator<T>) {
  const tempPrev = [...prev];
  const tempNext = [...next];
  return {
    added: next.filter((nextP) => {
      const i = tempPrev.findIndex((prevP) => fn(prevP, nextP));
      if (i > -1) {
        tempPrev.splice(i, 1);
        return false;
      }
      return true;
    }),
    removed: prev.filter((prevP) => {
      const i = tempNext.findIndex((nextP) => fn(prevP, nextP));
      if (i > -1) {
        tempNext.splice(i, 1);
        return false;
      }
      return true;
    }),
  };
}
