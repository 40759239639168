import { Box, Button, Switch, Typography } from "@mui/material";
import { RelayIcon } from "shared/components";
import { useEmailNotificationsEnabled } from "../hooks";
import { ProfileOption } from "./styles";

export const EmailNotificationsOption = () => {
  const { enabled, isLoading, onChange } = useEmailNotificationsEnabled();
  return (
    <ProfileOption>
      <Button
        variant="text"
        fullWidth={true}
        onClick={onChange}
        disabled={isLoading}
      >
        <Box
          sx={{
            width: 48,
            height: 48,
            borderRadius: "50%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            backgroundColor: "#F4F9FF",
          }}
        >
          <RelayIcon style={{ color: "#233443", width: 22.5, height: 18 }} />
        </Box>
        <Typography>Email notifications</Typography>
        <Switch
          sx={{ marginLeft: "auto" }}
          readOnly
          aria-label={"Email notifications status"}
          disabled={isLoading}
          checked={enabled}
        />
      </Button>
    </ProfileOption>
  );
};
