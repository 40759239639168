import { QueryClient } from "react-query";
import { QueryKey } from "shared/api";
import { unrefTimeout } from "shared/utils";
import { MediaViewType } from "../model";

export function invalidateMediaBin(client: QueryClient, chatSid: string) {
  // Media list is created by webhook, over which timing we have no control
  // We try to hack this by invalidating query some time later
  unrefTimeout(
    setTimeout(
      () => client.invalidateQueries([QueryKey.MediaList, chatSid]),
      3000
    )
  );
}

export function getMediaBinHash(hash: string) {
  hash = hash.replace("#", "");

  if (Object.values(MediaViewType).includes(hash as MediaViewType)) {
    return hash as MediaViewType;
  }
  return null;
}
