import { JSONValue } from "@twilio/conversations";
import { pipe } from "ramda";
import React from "react";
import urlRegexSafe from "url-regex-safe";
import xss from "xss";

import { palette } from "shared/theme";
import { hasCalledUsers } from "../../calling-users";

/**
 * Protect message body from XSS attacks and insert links to text
 */
export function useMessageBody(body: string, attributes: JSONValue) {
  return React.useMemo(() => {
    const textPipe = pipe(insertCalledUsersToText, insertLinksToText);

    return xss(textPipe(body, attributes), {
      whiteList: { a: ["rel", "target", "href"], br: [], span: ["style"] },
      css: false
    });
  }, [body, attributes]);
}

const insertLinksToText = (text: string) => {
  return text
    .split(" ")
    .map((str) => {
      const match = str.match(
        urlRegexSafe({ trailingPeriod: true, exact: true })
      );
      if (!match) return str;

      const link = match[0];
      const endsWithDot = link.endsWith(".");
      const startsWithProtocol = /^https?:\/\//i.test(link);
      const href = endsWithDot
        ? `${startsWithProtocol ? "" : "http://"}${link.slice(0, -1)}`
        : `${startsWithProtocol ? "" : "http://"}${link}`;

      return endsWithDot
        ? `<a href="${href}" rel="noreferrer" target="_blank">${link.slice(
            0,
            -1
          )}</a>.`
        : `<a href="${href}" rel="noreferrer" target="_blank">${link}</a>`;
    })
    .join(" ")
    .replaceAll(/(\r\n|\r|\n)/g, "<br>");
};

const insertCalledUsersToText = (text: string, attributes: JSONValue) => {
  if (!hasCalledUsers(attributes)) {
    return text;
  }

  for (const user of attributes.calledUsers) {
    text = text.replaceAll(
      user.name,
      `<span style="color: ${palette.primary.main};">${user.name}</span>`
    );
  }
  return text;
};
