import { Message } from "@twilio/conversations";
import React from "react";

/**
 * Focus MessageInput when:
 *  - mounted
 *  - message is qouted
 *  - called users are added
 * @returns ref object for MessageInput
 */
export function useMessageInput({
  quotedMessage,
}: {
  quotedMessage: Message | null;
}) {
  const ref = React.useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
  /* Message and attachments */
  const [message, setMessage] = React.useState("");
  const [position, setPosition] = React.useState(0);
  // focus on mount
  React.useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
    return () => {
      setMessage("");
      setPosition(0);
    };
  }, [ref]);

  // focus when message is qouted
  React.useEffect(() => {
    if (!ref.current) return;
    if (quotedMessage) {
      ref.current.focus();
    }
  }, [ref, quotedMessage]);

  return {
    ref,
    message,
    setMessage,
    position,
    setPosition,
    positionCursor: (newPosition?: number) => {
      const pos = "undefined" === typeof newPosition ? position : newPosition;
      /**
       * Without setTimeout setting cursor position doesn't work.
       * 400ms is for the on-screen keyboard (mobile) to disappear.
       * Otherwise focused input is hidden behind the keyboard.
       */
      const timeout = document.activeElement === ref.current ? 0 : 400;

      setTimeout(() => {
        if (ref.current === null) return;
        document.activeElement === ref.current || ref.current!.focus();
        ref.current.setSelectionRange(pos, pos);
      }, timeout);
    },
  };
}
