import React from "react";
import { AvatarGroup as MuiAvatarsGroup, AvatarGroupProps } from "@mui/material";
import { UserAvatar, UserAvatarProps } from "shared/user";

type Props = AvatarGroupProps & {
  avatars: UserAvatarProps[];
  size?: number
};

export const AvatarsGroup: React.FC<Props> = ({
  avatars,
  max = 2,
  size,
  ...props
}) => {
  return (
    <MuiAvatarsGroup max={max} {...props}>
      {avatars.map((a) => (
        <UserAvatar
          key={a.userId ?? a.userName}
          userName={a.userName}
          userId={a.userId}
          avatarColor={a.avatarColor}
          size={size}
        />
      ))}
    </MuiAvatarsGroup>
  );
};
