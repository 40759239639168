export async function copyToClipboard(text: string): Promise<boolean> {
  if (!navigator.clipboard) {
    return copyToClipboardFallback(text);
  }

  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (error) {
    console.error("Error", error);
    return false;
  }
}

function copyToClipboardFallback(text: string) {
  let success = false;
  const textArea = document.createElement("textarea");
  textArea.value = text;
  

  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  
  try {
    document.execCommand("copy");
    success = true;
  } catch (err) {
    console.error("Fallback: unable to copy", err);
  }

  document.body.removeChild(textArea);
  
  return success;
}
