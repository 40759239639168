import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ArchiveIcon: React.FC<SvgIconProps> = (props) => {
  const fillProp = props.fill ?? "transparent";
  return (
    <SvgIcon
      width="23"
      height="19"
      viewBox="0 0 23 19"
      fill="none"
      {...props}
      aria-label="archive icon"
      sx={{ fill: fillProp, ...props.sx }}
    >
      <path
        d="M20.091 5.77344V18.1825H2.90918V5.77344"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 1H1V5.77273H22V1Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.59082 9.58984H13.409"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
