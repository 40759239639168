import { Message } from "@twilio/conversations";
import { UseBaseQueryOptions, useMutation } from "react-query";

import { useApi } from "shared/api";
import { config } from "shared/config";
import { useChatContext } from "../../context";

type DeleteMessageResponse = {
  status: number;
  message: string;
  chatSid: string;
  messageSid: string;
};

export function useDeleteTwilioMessage(message: Message | null) {
  const { deleteRequest } = useApi();
  const {
    conversation: { chatSid },
  } = useChatContext();

  const { mutateAsync, isLoading, isError } = useMutation(
    deleteRequest<void, DeleteMessageResponse>(
      config.endpoints.conversations.message
        .replace(/:chatSid/, chatSid)
        .replace(/:messageSid/, message ? message.sid : "")
    )
  );

  const deleteMessage = (
    options: UseBaseQueryOptions<DeleteMessageResponse> = {}
  ) => {
    if (!message) {
      throw new Error("No message to remove");
    }
    return mutateAsync(undefined, options);
  };

  return {
    deleteMessage,
    isLoading,
    isError,
  };
}
