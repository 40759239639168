import { useGoogleLogin } from "@react-oauth/google";
import { useGoogleAuth } from "shared/google-auth";
import { gmailScopes } from "shared/gmail";
import { useShowError } from "shared/notifications";
import { AnalyticsEvent, useTrackEvent } from "shared/analytics";
import { useUser } from "../useUser";

export const useLoginWithGmail = () => {
  const { user } = useUser();
  const { authenticateGoogle, isLoading: googleAuthLoading } = useGoogleAuth();
  const showError = useShowError();

  const trackGmailConnected = useTrackEvent(
    AnalyticsEvent.gmail_account_connected
  );

  const loginWithGmail = useGoogleLogin({
    scope: gmailScopes.join(" "),
    onSuccess: ({ code }) => {
      if (!code) {
        return showError("Login failed", "Please try again later");
      }

      trackGmailConnected();
      authenticateGoogle({ code });
    },
    flow: "auth-code",
    hint: user.email,
  });

  return {
    loginWithGmail,
    isLoading: googleAuthLoading,
  };
};
