import { UseBaseQueryOptions, useMutation, useQueryClient } from "react-query";
import { gmailConfig } from "./model";
import { getGmailApiConfig } from "./utils";
import { GmailFilter } from "./types";
import { QueryKey } from "../api";
import { useGoogleApi } from "../google-auth";

export const useCreateGmailFilter = () => {
  const queryClient = useQueryClient();
  const { post } = useGoogleApi();

  const { mutate, isLoading } = useMutation(
    post<GmailFilter, GmailFilter>(
      gmailConfig.endpoints.filters.replace(/:userId/, "me"),
      {
        ...getGmailApiConfig(),
      }
    )
  );

  const createGmailFilter = (
    data: GmailFilter,
    options: UseBaseQueryOptions<GmailFilter> = {}
  ) => {
    mutate(data, {
      ...options,
      onSuccess: (response) => {
        queryClient.invalidateQueries([QueryKey.GmailMessages]);
        options.onSuccess?.(response);
      },
    });
  };

  return {
    createGmailFilter,
    isLoading,
  };
};
