import React from "react";
import { useParams } from "react-router-dom";
import { setPageTitle } from "shared/utils";
import { ConversationMetaData, useConversationsMetaData } from "shared/twilio";

export const usePageTitleWithUnreadCount = (title: string) => {
  const { conversationId: conversationIdParam } = useParams();

  const metadata = useConversationsMetaData();

  const unreadThreadsCount = React.useMemo(
    () => calculateUnreadThreadsCount(metadata),
    [metadata]
  );

  React.useEffect(() => {
    if (Boolean(conversationIdParam)) {
      return;
    }

    if (Boolean(unreadThreadsCount)) {
      return setPageTitle(`(${unreadThreadsCount}) ${title}`);
    }

    return setPageTitle(title);
  }, [title, unreadThreadsCount, conversationIdParam]);
};

const calculateUnreadThreadsCount = (
  conversationsMetaData: Record<string, ConversationMetaData>
) => {
  return Object.values(conversationsMetaData).filter((m) =>
    Boolean(m.unreadMessagesCount)
  ).length;
};
