import { useUpdateChatInfo } from "shared/conversations";
import { useConversationsContext } from "../context";
import { UpdateConversationPayload } from "../types";


export const useToggleEmailRelay = (chatSid: string) => {
  const { updateConversation, conversationsById } = useConversationsContext();
  const conversation = conversationsById[chatSid];

  const { updateChatInfo, isLoading } = useUpdateChatInfo(chatSid, {
    onSuccess: ({ emailRelayAddress }) => {
      updateConversation({
        chatSid,
        emailRelayAddress,
      });
    },
  });

  const toggleEmailRelay = (isEmailRelayEnabled: boolean) => {
    const data: UpdateConversationPayload = {
      isEmailRelayEnabled,
      participants: conversation.participants.map(
        ({ authorizationCode, userId }) => ({ authorizationCode, userId })
      ),
    };
    updateChatInfo(data);
  };

  return {
    toggleEmailRelay,
    isLoading,
  };
};
