import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";

import { useUser } from "shared/user";
import { useLogout } from "shared/auth";
import { paths } from "shared/router";
import { config } from "shared/config";
import { useApi } from "shared/api";
import { useShowError } from "shared/notifications";
import { useRevokeGoogleConsent } from "shared/google-auth";

export function useRemoveAccount() {
  const { isSupervisor } = useUser();
  const revokeGoogleConsent = useRevokeGoogleConsent();
  const logout = useLogout();

  const navigate = useNavigate();

  const { deleteRequest } = useApi();

  const showError = useShowError();

  const { mutateAsync: deleteUser, isLoading } = useMutation(
    deleteRequest<undefined, {}>(config.endpoints.user.v1.main)
  );

  const removeAccount = async () => {
    try {
      if (!isSupervisor) {
        return showError("Insufficient permissions", "Please try again later.");
      }

      await deleteUser(undefined);

      await revokeGoogleConsent();

      await logout();

      navigate(paths.auth.login);
    } catch (error) {
      showError("Failed to remove account", "Please try again later.");
    }
  };

  return { removeAccount, isLoading };
}
