import { Box, Stack } from "@mui/material";
import { Message } from "@twilio/conversations";
import { ImageQuotePreview } from "./ImageQuotePreview";
import {
  MessageQuoteAuthor,
  MessageQuoteBody,
  MessageQuoteWrapper,
} from "./layout";

type Props = {
  message: Message;
  author: string;
  isMyQuote: boolean;
  isMine: boolean;
};

export const QuotedMediaMessage = ({
  author,
  isMyQuote,
  message,
  isMine,
}: Props) => {
  
  return (
    <MessageQuoteWrapper isMine={isMine}>
      <MessageQuoteAuthor>{isMyQuote ? "You" : author}</MessageQuoteAuthor>
      <Stack direction={"row"} spacing={1}>
        <Box display="flex" alignItems="flex-start">
          <MessageQuoteBody fontSize={12}>Photo</MessageQuoteBody>
        </Box>
        <ImageQuotePreview image={message.attachedMedia![0]} />
      </Stack>
    </MessageQuoteWrapper>
  );
};
