import { useApi } from "shared/api";
import { UseBaseQueryOptions, useMutation } from "react-query";
import { config } from "shared/config";
import { AnalyticsEvent, useTrackEvents } from "shared/analytics";
import { Conversation, CreateConversationPayload } from "../types";
import { useNavigate } from "react-router-dom";
import { useAddConversation } from "../context";
import { getConversationPath } from "shared/router";

type Options = UseBaseQueryOptions<Conversation> & {
  navigateOnSuccess?: boolean;
};
export const useCreateConversation = ({
  navigateOnSuccess = true,
  ...options
}: Options = {}) => {
  const { post } = useApi();
  const { mutateAsync, isLoading } = useMutation(
    post<CreateConversationPayload, Conversation>(
      config.endpoints.conversations.main
    )
  );

  const navigate = useNavigate();

  const addConversation = useAddConversation();

  const trackEvent = useTrackEvents();

  const createConversation = (data: CreateConversationPayload) => {
    return mutateAsync(data, {
      ...options,
      onSuccess: (res) => {
        trackEvent(AnalyticsEvent.discuss_create_chat);

        if (res.publicChatUrl) {
          trackEvent(AnalyticsEvent.public_chat_enable_on_create);
        }

        addConversation(res);
        navigateOnSuccess && navigate(getConversationPath(res.chatSid));

        options.onSuccess?.(res);
      },
    });
  };

  return {
    createConversation,
    isLoading,
  };
};
