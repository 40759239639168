export enum MediaType {
  image = "image",
  email = "email",
  file = "file",
  link = "link",
  video = "video",
}

export enum MediaViewType {
  images = "images",
  emails = "emails",
  files = "files",
  links = "links",
  videos = "videos",
}

export type MediaQueryParams = {
  limit: number;
  page: number;
  type: MediaType;
};

export type MediaResponse = {
  pageTotal: number;
  total: number;
  next: boolean;
  previous: boolean;
  results: FileMediaResponse[] | EmailMediaResponse[] | LinkMediaResponse[];
};

export type FileMediaResponse = {
  id: string;
  messageSid: string;
  mediaSid: string;
  filename: string;
  fileSize: number;
  contentType: string;
  index: number;
  thumbnail: string | null;
};

export type EmailMediaResponse = {
  id: string;
  conversationId: string;
  externalProviderMsgId: string;
  messageSid: string;
  message: ShortMessage;
};

type ShortMessage = {
  externalProviderMsgId: string;
  snippet: string;
  fromEmail: string;
  fromName: string;
  subject: string;
  externalCreationDate: string;
  _avatarColor: string;
};

export type LinkMediaResponse = {
  id: string;
  messageSid: string;
  index: number;
  url: string;
  pageTitle: string;
};

export type MediaBinViewProps = {
  chatSid: string;
};
