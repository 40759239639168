import { Box } from "@mui/material";
import { useVisibleSettings } from "../hooks";
import { settings } from "../model";
import { SettingLink } from "./SettingLink";
import { StyledList } from "./styles";

export const SettingsList = () => {
  const { visibleSettings } = useVisibleSettings();

  return (
    <Box width={1}>
      <StyledList>
        {visibleSettings.map((setting) => (
          <SettingLink
            key={setting}
            setting={setting}
            external={!!settings[setting].url}
          />
        ))}
      </StyledList>
    </Box>
  );
};