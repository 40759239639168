import React from "react";
import { Box, Stack } from "@mui/material";
import { Letter } from "react-letter";
import { useGmailMessage } from "shared/gmail";
import { QueryDataWrapper } from "shared/api";
import { useToggle } from "shared/utils";
import {
  APP_HEADER_HEIGHT,
  MOBILE_APP_HEADER_HEIGHT,
  MOBILE_APP_BAR_HEIGHT,
} from "shared/constants";
import { BlueButton, AddEmailIcon, MainButton } from "shared/components";

import { GmailMessagePreviewHeader } from "./GmailMessagePreviewHeader";
import { AddMailToConversationsDialog } from "./AddMailToConversationsDialog";

type GmailMessagePreviewProps = {
  messageId: string;
};

export const GmailMessagePreview: React.FC<GmailMessagePreviewProps> = ({
  messageId,
}) => {
  const messageResult = useGmailMessage(messageId);

  const [addEmailToConvOpen, toggleAddEmailToConv] = useToggle();

  return (
    <Box
      flex={{
        md: 4,
        lg: 6,
      }}
      minWidth={0}
    >
      <Box
        height={{
          xs: `calc(100vh - ${MOBILE_APP_BAR_HEIGHT + MOBILE_APP_HEADER_HEIGHT}px)`,
          sm: `calc(100vh - ${APP_HEADER_HEIGHT}px)`,
        }}
        display={"flex"}
        flexDirection={"column"}
        overflow={"hidden"}
        borderLeft={({ palette }) => `1px solid ${palette.grey[200]}`}
      >
        <QueryDataWrapper result={messageResult}>
          {({ data: message }) => (
            <>
              <GmailMessagePreviewHeader message={message} />
              <Box flex={1} p={3} overflow={"auto"}>
                <Letter html={message._htmlContent} />
              </Box>
              <Stack
                direction={{ xs: "column", lg: "row" }}
                alignItems={"center"}
                justifyContent={"center"}
                bgcolor={"white"}
                flex={0}
                px={6}
                py={3}
                spacing={3}
              >
                <MainButton
                  startIcon={<AddEmailIcon />}
                  onClick={toggleAddEmailToConv}
                  sx={{ maxWidth: 300 }}
                >
                  Add email to discussion
                </MainButton>
                <AddMailToConversationsDialog
                  mail={message}
                  onClose={toggleAddEmailToConv}
                  dialogProps={{ open: addEmailToConvOpen }}
                />
              </Stack>
            </>
          )}
        </QueryDataWrapper>
      </Box>
    </Box>
  );
};
