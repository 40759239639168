import React from "react";
import { UseBaseQueryOptions, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useApi } from "shared/api";
import { config } from "shared/config";
import { useShowError } from "shared/notifications";
import { paths } from "shared/router";
import { RegisterResponse, RegisterRequest } from "../types";

export function useRegisterLocal() {
  const navigate = useNavigate();
  const { post } = useApi();
  const showError = useShowError();

  const { mutate, isLoading, error } = useMutation(
    post<RegisterRequest, RegisterResponse>(config.endpoints.auth.register)
  );

  const register = React.useCallback(
    (
      data: RegisterRequest,
      options: UseBaseQueryOptions<RegisterResponse> = {}
    ) => {
      mutate(data, {
        ...options,
        onSuccess: () => navigate(paths.auth.registered),
        onError: () => {
          showError("Registration failed", "Please try again later");
        },
      });
    },
    [showError, mutate, navigate]
  );

  return {
    register,
    isLoading,
    error,
  };
}
