import { Box, styled } from "@mui/material";

export const Content = styled(Box)(({ theme: { palette } }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
}));

export const Wrapper = styled(Box)(() => ({
  display: "flex",
  flex: "1 0",
  flexDirection: "column",
  overflow: "hidden",
}));
