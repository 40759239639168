import urlRegexSafe from "url-regex-safe";

import { LinkType } from "./types";

const youtubePrefixes = [
  /^(?:https?:\/\/)?(:?www\.)?youtube.com/,
  /^(?:https?:\/\/)?(:?www\.)?youtu\.be/,
];

export function getLinktype(link: string): LinkType {
  return youtubePrefixes.some((prefix) => prefix.test(link))
    ? LinkType.YouTube
    : LinkType.Text;
}

export function getYouTubeVideoId(link: string): string | null {
  try {
    if (!link.startsWith("http")) {
      link = `https://${link}`;
    }

    const url = new URL(link);

    if (["youtu.be", "www.youtu.be"].includes(url.hostname)) {
      return url.pathname.slice(1);
    }

    if (/youtube\.com\/embed/.test(url.toString())) {
      const match = url.pathname.match(/\w+$/);
      if (match) {
        return match[0];
      }
    }

    const params = new URLSearchParams(url.search);

    return params.get("v");
  } catch {
    return null;
  }
}

export const findLastLink = (text: string) => {
  const links = text
    .split(" ")
    .filter((str) => {
      return str.match(urlRegexSafe({ trailingPeriod: true, exact: true }));
    })
    .filter((link) => link.indexOf("@") === -1) // remove all email addresses
    .map((link) => link.replace(/\.$/, "")); // replace trailing period

  const length = links.length;

  return length ? links[length - 1] : null;
};
