import React from "react";
import { NavLink } from "react-router-dom";
import {
  AppBar as MuiAppBar,
  Box,
  styled,
  Toolbar as MuiToolbar,
} from "@mui/material";

import { AppMenuLink } from "../layouts/useAppMenuLinks";
import { MOBILE_APP_BAR_HEIGHT } from "shared/constants";
import { colors } from "shared/theme";

export type MobileAppBarProps = {
  links: AppMenuLink[];
  position?: "absolute" | "fixed";
  hide?: boolean;
};

export const MobileAppBar = ({
  links,
  hide = false,
  position = "fixed",
}: MobileAppBarProps) => {
  return !hide ? (
    <Wrapper>
      <AppBar position={position} role="navigation">
        <Toolbar>
          {links.map(({ url, label, icon: Icon, id }) => (
            <StyledNavLink to={url} key={id}>
              <Icon sx={{ fontSize: 24, mb: 1.5 }} />
              <Box>{label}</Box>
            </StyledNavLink>
          ))}
        </Toolbar>
      </AppBar>
    </Wrapper>
  ) : (
    <></>
  );
};

const Wrapper = styled(Box)(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1,
}));

const AppBar = styled(MuiAppBar)(({ position }) => ({
  backgroundColor: colors.white,
  borderTop: `1px solid ${colors.diamondWhite}`,
  fontSize: 14,
  fontWeight: 500,
  position: position ?? "fixed",
  top: "auto",
  bottom: 0,
  boxShadow: "none",
  height: MOBILE_APP_BAR_HEIGHT + 1,
}));

const Toolbar = styled(MuiToolbar)(({ theme: { spacing } }) => ({
  width: "100%",
  justifyContent: "space-between",
  height: "100%",
  padding: spacing(1, 4),
}));

const StyledNavLink = styled(NavLink)(({ theme: { palette } }) => ({
  textDecoration: "none",
  color: colors.haleNavy.main,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
  "&.active": {
    color: colors.markusBlue.main,
  },
}));
