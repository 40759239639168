export const indexBy = <T, K extends keyof T>(
  arr: T[],
  key: K
): Record<string, T> => {
  return arr.reduce(
    (acc, curr) => ({
      ...acc,
      [curr[key] as unknown as K]: curr,
    }),
    {}
  );
};
