import { SvgIconProps } from "@mui/material";
import { isImage, isPdf, isDoc, isSheet, isVideo } from "shared/utils";
import VideoIcon from "@mui/icons-material/PlayCircleFilled";
import {
  MessageCircleIcon,
  DocFileIcon,
  SheetFileIcon,
  PdfFileIcon,
  UnknownFileIcon,
  TextFileIcon,
} from "shared/components";
import { theme } from "shared/theme";
import { hasEmail, isEmailAttachmentMessage } from "shared/twilio";
import { FileType, IconType, AttachmentConfig } from "./model";
import { Message } from "@twilio/conversations";

const Icons: { [key in IconType]: React.FC<SvgIconProps<"svg", {}>> } = {
  doc: DocFileIcon,
  sheet: SheetFileIcon,
  pdf: PdfFileIcon,
  text: TextFileIcon,
  image: TextFileIcon,
  video: VideoIcon,
  other: UnknownFileIcon,
  email: MessageCircleIcon,
};

export const getAttachmentConfig = ({
  fileType,
  type = "message",
  isMine = true,
}: {
  fileType: FileType;
  type?: AttachmentConfig["type"];
  isMine?: boolean;
}): AttachmentConfig => {
  const iconName: IconType = type === "relay" ? "email" : fileType;
  const Icon = Icons[iconName];

  let colors = {
    border: theme.palette.info.main,
    main: "white",
    accent: theme.palette.info.light,
  };

  // alternative colors to use when needed

  // if (type === "message" && isMine) {
  //   colors = { border: "#F4F2FE", main: "#F4F2FE", accent: "white" };
  // } else if (type === "message" && !isMine) {
  //   colors = { border: "#F4F2FE", main: "white", accent: "#F4F2FE" };
  // }

  return { Icon, colors, isMine, type };
};

export function getFileType(mimeType = ""): FileType {
  if (isImage(mimeType)) {
    return "image";
  } else if (isPdf(mimeType)) {
    return "pdf";
  } else if (mimeType.includes("text/")) {
    return "text";
  } else if (isSheet(mimeType)) {
    return "sheet";
  } else if (isDoc(mimeType)) {
    return "doc";
  } else if (isVideo(mimeType)) {
    return "video";
  } else {
    return "other";
  }
}

export const isMessageEdited = (message: Message) => {
  if (message.type !== "text") {
    return false;
  } else if (hasEmail(message.attributes)) {
    return false;
  } else if (!message.dateCreated || !message.dateUpdated) {
    return false;
  } else {
    return message.dateCreated.getTime() !== message.dateUpdated.getTime();
  }
};

export const findAttachments = (messages: Message[], index: number) => {
  const attachmentsMessages: Message[] = [];

  // start from next message
  index += 1;
  while (index < messages.length) {
    const msg = messages[index];
    if (!isEmailAttachmentMessage(msg)) {
      break;
    }
    attachmentsMessages.push(msg);
    index++;
  }

  return attachmentsMessages.map((m) => m.attachedMedia ?? []).flat();
};
