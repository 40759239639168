import { Box, styled, Typography } from "@mui/material";
import raccoonImage from "assets/images/raccoon.png";

export const InvitationsListPlaceholder = () => {
  return (
    <Box
      width={1}
      display={"flex"}
      alignItems={"center"}
      flexDirection={"column"}
      color={"grey.600"}
      textAlign={"center"}
      py={10}
      px={2}
      pt={{ xs: 2, md: 15 }}
    >
      <PlaceholderImage src={raccoonImage} alt={"empty-list"} />
      <Typography mt={4} fontSize={{ xs: 14, md: 16 }} fontWeight={600}>
        You have no invitations yet
      </Typography>
      <Typography mt={2} fontSize={{ xs: 12, md: 14 }}>
        If someone adds you to their contact list, you will see an invitation
        here
      </Typography>
    </Box>
  );
};

const PlaceholderImage = styled("img")(({ theme: { breakpoints } }) => ({
  height: 100,
  [breakpoints.down("md")]: {
    height: 80,
  },
}));
