import { format, isSameDay, isSameMonth, isSameWeek, isSameYear } from "date-fns";

export const getFriendlyTimestamp = (date: Date) => {
  if (isSameDay(date, new Date())) {
    return format(date, "p");
  }

  if (isSameWeek(date, new Date())) {
    return format(date, "eee p");
  }
  
  if (isSameMonth(date, new Date())) {
    return format(date, "MMM dd p");
  }

  if (isSameYear(date, new Date())) {
    return format(date, "MMM dd");
  }

  return format(date, "MM/dd/yyyy");
};
