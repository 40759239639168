import { useMemo } from "react";
import { useUser } from "shared/user";
import { SettingType } from "../model";
import { useHasGmailScopes } from "shared/google-auth";

export function useVisibleSettings() {
  const { isSupervisor } = useUser();
  const hasInbox = useHasGmailScopes();
  const visibleSettings = useMemo(() => {
    return [
      SettingType.profile,
      ...(!hasInbox ? [SettingType.email] : []),
      SettingType.privacy,
      SettingType.termsOfService,
      SettingType.privacyPolicy,
      SettingType.googleApiDisclosure,
      SettingType.about,
      SettingType.help,
      SettingType.contactUs,
    ];
  }, [hasInbox]);

  return {
    visibleSettings,
    isSupervisor,
    hasInbox,
  };
}
