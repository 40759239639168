import React from "react";
import { Media, Message } from "@twilio/conversations";

import { getFriendlyTimestamp } from "shared/utils";
import {
  hasEmail,
  isEmailAttachmentMessage,
  hasWebUser,
  formatParticipantName,
  hasQuotedMessage,
} from "shared/twilio";
import { UserAvatar } from "shared/user";
import { MessageCaption, MessageTile } from "shared/components";

import { MediaWithPreviewMessage } from "./MediaWithPreviewMessage";
import { TextMessage } from "./TextMessage";
import { MessageMenu } from "../message-menu";
import { ChatMessageEmail } from "../email-message";
import { isMessageEdited } from "../shared";
import { Participant } from "shared/conversations";
import { AIResponseMessage, hasAIResponse } from "shared/ai";

type ChatMessageProps = {
  isMine: boolean;
  isTimestampVisible: boolean;
  isUsernameVisible: boolean;
  message: Message;
  participant: Participant | undefined;
  isOwner: boolean;
  emailAttachments?: Media[];
};

export const ChatMessage: React.FC<ChatMessageProps> = React.memo(
  ({
    message,
    isMine,
    isTimestampVisible,
    participant,
    isOwner,
    emailAttachments,

  }) => {
    const [username, setUsername] = React.useState<string>("");
    const [isWebUser, setIsWebUser] = React.useState(false);
    const mounted = React.useRef(true);
    const isEmailMessage = hasEmail(message.attributes);
    const hasQuote = hasQuotedMessage(message.attributes);
    const isEdited = isMessageEdited(message);
    const isToChatBot = hasAIResponse(message.attributes);

    React.useEffect(() => {
      message
        .getParticipant()
        .then((participant) => {
          if (!mounted) return null;

          setUsername(formatParticipantName(participant));

          if (
            hasWebUser(participant.attributes) &&
            participant.attributes.web_user
          ) {
            setIsWebUser(true);
          }
        })
        .catch(() => {});
    }, [message]);

    const friendlyTimestamp = React.useMemo(
      () =>
        message.dateCreated && isTimestampVisible
          ? getFriendlyTimestamp(new Date(message.dateCreated))
          : "",
      [message.dateCreated, isTimestampVisible]
    );

    return (
      <>
        <MessageTile
          isMine={isMine}
          avatar={
            <UserAvatar
              userId={participant?.userId}
              userName={participant?.name ?? username}
              avatarColor={participant?._avatarColor}
            />
          }
          isFullWidth={isEmailMessage || isEmailAttachmentMessage(message)}
          timestamp={friendlyTimestamp}
          caption={
            <MessageCaption
              isMine={isMine}
              userName={username}
              isQuote={hasQuote}
              isWebUser={isWebUser}
              isEdited={isEdited}
              toChatBot={isToChatBot}
            />
          }
        >
          <MessageMenu isMine={isMine} message={message} isOwner={isOwner}>
            {renderMessage(message, { isMine, emailAttachments })}
          </MessageMenu>
        </MessageTile>
        {isToChatBot && (
          <AIResponseMessage response={message.attributes.aiResponse} />
        )}
      </>
    );
  });

const renderMessage = (
  message: Message,
  context: { isMine: boolean; emailAttachments?: Media[] }
) => {
  if (hasEmail(message.attributes)) {
    return (
      <ChatMessageEmail
        externalProviderMsgId={message.attributes.externalProviderMsgId}
        message={message}
        attachments={context.emailAttachments}
      />
    );
  }

  if (message.type === "media") {
    return (
      <MediaWithPreviewMessage message={message} isMine={context.isMine} />
    );
  }

  return <TextMessage message={message} isMine={context.isMine} />;
};
