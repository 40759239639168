import { UseBaseQueryOptions, useMutation, useQueryClient } from "react-query";

import { useApi, QueryKey } from "shared/api";
import { config } from "shared/config";
import { useShowError } from "shared/notifications";
import { RemoveCollaboratorResponse } from "../model";

export function useRemoveCollaborator(code: string) {
  const queryClient = useQueryClient();

  const { deleteRequest } = useApi();
  const showError = useShowError();

  const { mutate, isLoading, error } = useMutation(
    deleteRequest<undefined, RemoveCollaboratorResponse>(
      config.endpoints.boards.collaborator.replace(/:codeId/, code)
    )
  );

  const removeCollaborator = (
    options: Omit<UseBaseQueryOptions<{}>, "onSuccess" | "onError"> = {}
  ) =>
    mutate(undefined, {
      onSuccess: () => queryClient.invalidateQueries(QueryKey.CollaboratorList),
      onError: () =>
        showError("Failed to remove collaborator", "Please try again later"),
      ...options,
    });

  return { removeCollaborator, isLoading, error };
}
