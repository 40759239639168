import React from "react";
import { styled, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { paths } from "shared/router";
import {colors} from "shared/theme";

type LoginOrRegisterProps = {
  goto?: "login" | "register";
};

export const LoginOrRegister: React.VFC<LoginOrRegisterProps> = ({
  goto = "login",
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const path = goto === "login" ? paths.auth.login : paths.auth.register;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigate({ pathname: path, search });
  };

  switch (goto) {
    case "login":
      return (
        <StyledOption>
          Already have an account?{" "}
          <a onClick={handleClick} href={window.location.origin + path}>
            Log in
          </a>
        </StyledOption>
      );

    default:
      return (
        <StyledOption>
          Don’t have an account?{" "}
          <a onClick={handleClick} href={window.location.origin + path}>
            Sign up
          </a>
        </StyledOption>
      );
  }
};

const StyledOption = styled(Typography)(({ theme: { palette, spacing } }) => ({
  paddingTop: spacing(2),
  fontWeight: 500,
  fontSize: 14,
  lineHeight: 1.5,
  color: colors.haleNavy.dark,
  "& a": {
    color: colors.markusBlue.light,
  },
}));
