import { Box } from "@mui/material";
import PlayIcon from '@mui/icons-material/PlayArrow';

export const VideoAttachmentIcon = () => {
  return (
    <Box
      sx={({ palette }) => ({
        bgcolor: palette.info.light,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 35,
        width: 35,
        borderRadius: "50%",
      })}
    >
      <PlayIcon sx={{ color: "info.dark", width: 18, height: 18 }} />
    </Box>
  );
};
