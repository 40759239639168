import {
  styled,
  Drawer as MuiDrawer,
  Toolbar as MuiToolbar,
} from "@mui/material";

import { Logo } from "shared/components";
import { DESKTOP_DRAWER_WIDTH, TABLET_DRAWER_WIDTH } from "shared/constants";

export const AppDrawer: React.FC = ({ children }) => {
  return (
    <Drawer variant="permanent">
      <Toolbar disableGutters>
        <Logo />
        {children}
      </Toolbar>
    </Drawer>
  );
};

const Drawer = styled(MuiDrawer)(({ theme: { spacing, breakpoints } }) => ({
  "& .MuiDrawer-paper": {
    whiteSpace: "nowrap",
    padding: spacing(4, 0),
    position: "fixed",
    left: 0,
    height: "100%",
    width: TABLET_DRAWER_WIDTH,
    [breakpoints.up("lg")]: {
      width: DESKTOP_DRAWER_WIDTH,
    },
  },
}));

const Toolbar = styled(MuiToolbar)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: 0,
  height: "100%",
}));
