import { Stack } from "@mui/material";
import { useHandleMessage } from "./useHandleMessage";
import { MessageAssistantProvider } from "shared/ai";
import { MessageBox } from "./MessageBox";
import { ChangeNameGuard } from "shared/public-chat";
import {HandleMessageReturnType} from "./model";

export const ChatBox = () => {
  const props = useHandleMessage();

  return (
    <Stack width={1} position="relative">
      <MessageAssistantProvider>
        <ChangeNameGuard>
          <MessageBox {...props} />
        </ChangeNameGuard>
      </MessageAssistantProvider>
    </Stack>
  );
};