export function validateProfileData(
  base64avatar: string,
  newName: string,
  currentName: string
) {
  if (!(base64avatar || newName)) {
    return false;
  }
  
  if (!base64avatar && (currentName === newName)) {
    return false;
  }
  
  return true;
}
