import React from "react";
import { setPageTitle } from "shared/utils";

export const useChatPageTitle = (title: string, unreadCount: number) => {
  React.useEffect(() => {
    if (Boolean(unreadCount)) {
      return setPageTitle(`(${unreadCount}) ${title || "No subject"}`);
    }

    return setPageTitle(title || "No subject");
  }, [title, unreadCount]);
};
