import { ConversationMetaData } from "shared/twilio";
import { Collaborator } from "../collaborators";
import { PublicInvitation } from "../public-invitation";

export enum ParticipantRole {
  Supervisor = "supervisor",
  Collaborator = "collaborator",
}

export type Message = {
  id: string;
  text: string;
};

export type ConversationEmailMessage = {
  externalProviderMsgId: string;
  fromEmail: string;
  fromName: string;
  snippet: string;
  subject: string;
};

export type Participant = Collaborator & {
  role: ParticipantRole;
  support?: boolean;
};

export type Conversation = {
  id: string;
  chatSid: string;
  ownerId: string;
  pinned: boolean;
  publicChatUrl: string;
  userRole: ParticipantRole;
  title: string | null;
  subject: string;
  participants: Participant[];
  lastUpdateDate: string;
  lastMessage: Message;
  message?: ConversationEmailMessage;
  hasMessages?: boolean;
  publicInvitation?: PublicInvitation | null;
  emailRelayAddress: string | null;
};

export type ParticipantPayload = Pick<Participant, "userId">;

export type CreateConversationPayload = {
  subject: string;
  isPublic: boolean;
  participants: ParticipantPayload[];
};

export type UpdateConversationPayload = {
  participants: ParticipantPayload[];
  subject?: string;
  isPublic?: boolean;
  isEmailRelayEnabled?: boolean;
};
