import { Button, CircularProgress } from "@mui/material";
import { DownloadIcon, FileName } from "shared/components";


export const DownloadFile = ({
  isLoading,
  onClick,
  fileName,
}: {
  isLoading: boolean;
  onClick: () => void;
  fileName: string;
}) => {
  return (
    <Button
      onClick={onClick}
      variant="text"
      color="secondary"
      sx={{
        fontWeight: 300,
        color: "grey.600",
        fontSize: "1rem",
        "&:hover": { background: "transparent" },
      }}
      endIcon={
        isLoading ? (
          <CircularProgress size={24} sx={{ color: "grey.400" }} />
        ) : (
          <DownloadIcon sx={{ width: 24, height: 24 }} />
        )
      }
      disabled={isLoading}
    >
      <FileName fileName={fileName} />
    </Button>
  );
};
