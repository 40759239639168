import { useMutation, useQueryClient } from "react-query";
import { useUser, User } from "shared/user";
import { QueryKey, useApi } from "shared/api";
import { config } from "shared/config";
import { useShowError } from "shared/notifications";
import { useUserProfileContext } from "../context";
import { validateProfileData } from "../utils";

type RenameRequest = {
  newName: string;
};

type AvatarRequest = {
  avatar: string;
};

export function useUserProfile() {
  const queryClient = useQueryClient();
  const { post, patch } = useApi();

  const showError = useShowError();

  const {
    currentName,
    profileChanged,
    setCurrentName,
    setProfileChanged,
    base64avatar,
    setAvatar,
  } = useUserProfileContext();

  const { isLoading: userLoading, user: userOrPlaceholder } = useUser();

  const { mutateAsync: mutateUserName, isLoading: renameLoading } = useMutation(
    patch<RenameRequest, User>(config.endpoints.user.v1.rename)
  );

  const { mutateAsync: mutateAvatar, isLoading: avatarLoading } = useMutation(
    post<AvatarRequest, User>(config.endpoints.user.v1.sendAvatar)
  );

  const renameUser = (data: RenameRequest) => mutateUserName(data);

  const sendAvatar = (data: AvatarRequest) => mutateAvatar(data);

  const updateProfile = async () => {
    try {
      let promises: Promise<User>[] = [];
      if (
        !validateProfileData(currentName, base64avatar, userOrPlaceholder.name)
      ) {
        showError(
          "Failed to update profile",
          "Please provide valid profile data before saving."
        );
        return;
      }

      if (base64avatar) {
        promises.push(sendAvatar({ avatar: base64avatar }));
      }

      if (currentName && currentName !== userOrPlaceholder.name) {
        promises.push(renameUser({ newName: currentName }));
      }

      await Promise.all(promises);

      setAvatar(null);

      queryClient.invalidateQueries(QueryKey.User);
      queryClient.invalidateQueries([QueryKey.UserAvatar, user?.id]);
    } catch (error) {
      showError(
        "Failed to update profile",
        "Please try again later"
      );
    }
  };

  const isLoading = userLoading || renameLoading || avatarLoading;

  const user = userOrPlaceholder.name ? userOrPlaceholder : null;

  return {
    isLoading,
    user,
    updateProfile,
    currentName,
    profileChanged,
    setCurrentName,
    setProfileChanged,
    setAvatar,
    renameUser,
  };
}
