import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const DirectoryIcon: React.FC<SvgIconProps> = (props) => {
  const fillProp = props.fill ?? "transparent";
  return (
    <SvgIcon
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      aria-label="directory icon"
      {...props}
      sx={{ fill: fillProp, ...props.sx }}
    >
      <path
        d="M1 3V13C1 14.1046 1.89543 15 3 15H17C18.1046 15 19 14.1046 19 13V5C19 3.89543 18.1046 3 17 3H11L9 1H3C1.89543 1 1 1.89543 1 3Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
