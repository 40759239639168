import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const PrivacyCircleIcon: React.FC<SvgIconProps> = (props) => {
  const fillProp = props.fill ?? "transparent";
  return (
    <SvgIcon
      width="48"
      height="48"
      viewBox="0 0 48 48"
      {...props}
      aria-label="privacy circle icon"
      sx={{ fill: fillProp, ...props.sx }}
    >
      <rect width="48" height="48" rx="24" />
      <path
        d="M20.6667 25.2454L22.8889 27.5081L27.3333 22.9826M33.5754 18.4394C33.3481 18.4511 33.1192 18.4571 32.8889 18.4571C29.4739 18.4571 26.3589 17.1498 23.9999 15C21.641 17.1498 18.526 18.4569 15.1111 18.4569C14.8809 18.4569 14.652 18.451 14.4246 18.4392C14.1474 19.5294 14 20.6728 14 21.8512C14 28.1773 18.2492 33.4929 24 35C29.7508 33.4929 34 28.1773 34 21.8512C34 20.6729 33.8526 19.5295 33.5754 18.4394Z"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
