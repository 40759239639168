import React from "react";
import { useUser } from "shared/user";

export const SupervisorGuard: React.FC = ({ children }) => {
  const { isSupervisor } = useUser();

  if (!isSupervisor) return null;

  return <>{children}</>;
};
