import { BoxProps, styled } from "@mui/material";
import { Box } from "@mui/system";
import {
  DESKTOP_DRAWER_WIDTH,
  MOBILE_APP_BAR_HEIGHT,
  TABLET_DRAWER_WIDTH,
} from "shared/constants";
import { AuthLayout } from "shared/layouts";
import { useAppMenuLinks } from "shared/layouts/useAppMenuLinks";
import { paths } from "shared/router";
import { useIsBreakpoint } from "shared/theme";
import { AppDrawer } from "./AppDrawer";
import { AppMenu } from "./AppMenu";
import { ConversationsLoader } from "./ConversationsLoader";
import { LoadingIndicator } from "./LoadingIndicator";
import { MobileAppBar } from "./MobileAppBar";

type AppLoaderProps = {
  inApp?: boolean;
  disablePadding?: boolean;
};
export const AppLoader = ({ disablePadding, inApp = true }: AppLoaderProps) => {
  const links = useAppMenuLinks({ contactsBadge: false, messagesBadge: false });
  const isMobile = useIsBreakpoint("sm");
  const isTablet = useIsBreakpoint("lg");

  const inConversations = isOnConversationsPage();
  const inConversation = isOnConversationPage();

  const loaderElement = inConversations ? (
    <ConversationsLoader inChat={inConversation} isMobile={isTablet} />
  ) : (
    <LoadingIndicator />
  );

  switch (inApp) {
    case true:
      return (
        <Wrapper disablePadding={disablePadding}>
          {isMobile ? (
            <MobileAppBar links={links} />
          ) : (
            <AppDrawer>
              <AppMenu links={links} />
            </AppDrawer>
          )}
          {loaderElement}
        </Wrapper>
      );
    case false:
      return (
        <AuthLayout logo={"small"}>
          <LoadingIndicator />
        </AuthLayout>
      );
  }
};

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "disablePadding",
})<BoxProps & { disablePadding?: boolean }>(
  ({ theme: { breakpoints }, disablePadding }) => ({
    height: "100%",
    width: "100%",
    overflow: "hidden",
    marginLeft: 0,
    paddingBottom: disablePadding ? 0 : `${MOBILE_APP_BAR_HEIGHT}px`,
    [breakpoints.up("sm")]: {
      paddingBottom: 0,
      marginLeft: disablePadding ? 0 : `${TABLET_DRAWER_WIDTH}px`,
    },
    [breakpoints.up("lg")]: {
      marginLeft: disablePadding ? 0 : `${DESKTOP_DRAWER_WIDTH}px`,
    },
  })
);

const isOnConversationPage = () =>
  new RegExp(paths.app.conversations + "/?\\w+$").test(
    window.location.pathname
  );

const isOnConversationsPage = () =>
  window.location.pathname.startsWith(paths.app.conversations);
