import { useQuery } from "react-query";
import { QueryKey } from "../api";
import { gmailConfig } from "./model";
import { getGmailApiConfig } from "./utils";
import { GmailFiltersResponse } from "./types";
import { indexBy } from "../utils";
import { useGoogleApi } from "../google-auth";

export const useGmailFilters = () => {
  const { get } = useGoogleApi();

  return useQuery<GmailFiltersResponse>(
    [QueryKey.GmailFilters],
    get(gmailConfig.endpoints.filters.replace(/:userId/, "me"), {
      ...getGmailApiConfig(),
      transformResponse: (response) => ({
        ...response,
        _filterById: response.filter ? indexBy(response.filter, "id") : {},
      }),
    }),
    {}
  );
};
