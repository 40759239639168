import { Box, Stack, styled } from "@mui/material";
import { NavLink } from "react-router-dom";
import {useLogout} from "shared/auth";
import { AppMenuLink } from "shared/layouts/useAppMenuLinks";
import { colors } from "shared/theme";
import { useToggle } from "shared/utils";
import { LogoutIcon } from "./icons";

export const AppMenu = ({ links }: { links: AppMenuLink[] }) => {
  const logout = useLogout();
  const [showDialog, toggleDialog] = useToggle();
  return (
    <Stack sx={{ height: "100%" }} data-cy={"app-menu"}>
      {links.map(({ url, label, icon: Icon, id }) => (
        <AppMenuItem to={url} key={id}>
          <Icon sx={{ fontSize: 24, mb: 2 }} />
          <Label>{label}</Label>
        </AppMenuItem>
      ))}
      <LogoutButton onClick={logout}>
        <LogoutIcon sx={{ fontSize: 24, mb: 2 }} />
        <Label>Logout</Label>
      </LogoutButton>
    </Stack>
  );
};

const AppMenuItem = styled(NavLink)(({ theme: { spacing, typography } }) => ({
  textDecoration: "none",
  color: colors.haleNavy.main,
  "&.active, &:hover": {
    color: colors.markusBlue.main,
  },
  display: "flex",
  alignItems: "center",
  padding: spacing(4),
  fontWeight: 500,
  lineHeight: 1,
  flexDirection: "column",
  justifyContent: "center",
  fontSize: typography.subtitle2.fontSize,
}));

const LogoutButton = styled("button")(({ theme: { spacing, typography } }) => ({
  cursor: "pointer",
  textDecoration: "none",
  border: "none",
  outline: "none",
  color: colors.redAlert,
  backgroundColor: "transparent",
  display: "flex",
  alignItems: "center",
  padding: spacing(4),
  fontWeight: 500,
  lineHeight: 1,
  flexDirection: "column",
  justifyContent: "center",
  fontSize: typography.subtitle2.fontSize,
}));

const Label = styled(Box)(({ theme: { spacing } }) => ({
  margin: spacing(1, 0, 0),
}));
