import {
  Box,
  Button,
  darken,
  lighten,
  ListItem,
  styled,
  Typography,
} from "@mui/material";


export const InvitationData = styled(Box)({
  display: "flex",
  alignItems: "center",
});

export const InvitationName = styled(Typography)(
  ({ theme: { palette, spacing, breakpoints } }) => ({
    marginLeft: spacing(3),
    fontWeight: 600,
    color: palette.grey[600],
    fontSize: 16,
    lineHeight: "24px",
    maxWidth: 160,
    [breakpoints.up("sm")]: {
      maxWidth: 200,
    },
  })
);

export const RejectButton = styled(Button)(
  ({ theme: { breakpoints, spacing, palette } }) => ({
    width: 62,
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FD2C5E",
    fontSize: 12,
    lineHeight: "24px",
    borderRadius: spacing(2),
    border: "1px solid #FD2C5E",
    background: "transparent",
    marginLeft: spacing(2),
    [breakpoints.up("lg")]: {
      marginLeft: spacing(7),
    },
    "&:hover": {
      backgroundColor: lighten(palette.error.main, 0.9),
    },
  })
);

export const AcceptButton = styled(Button)(
  ({ theme: { palette, spacing } }) => ({
    width: 62,
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: palette.common.white,
    fontSize: 12,
    lineHeight: "24px",
    borderRadius: spacing(2),
    background: palette.primary.main,
    "&:hover": {
      backgroundColor: darken(palette.primary.main, 0.4),
    },
    "& svg": {
      color: palette.common.white,
    },
  })
);

export const StyledListItem = styled(ListItem)(
  ({ theme: { spacing, breakpoints } }) => ({
    width: "100%",
    display: "flex",
    paddingLeft: 0,
    justifyContent: "space-between",
    marginBottom: spacing(2),
    [breakpoints.up("md")]: {
      paddingRight: 0,
    },
  })
);
