import React from "react";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useGoogleLogout } from "shared/google-auth";
import { useTwilioLogout } from "shared/twilio";
import {
  useResetAnalyticsUser,
  useTrackEvent,
  AnalyticsEvent,
} from "shared/analytics";
import { isInPublicChat } from "shared/public-chat";
import { paths } from "shared/router";
import { useAppLogout } from "../context/AppAuth";
import { usePublicChatLogout } from "../context/PublicChatAuth";

export function useLogout() {
  const queryClient = useQueryClient();
  const { pathname, search } = useLocation();
  const appLogout = useAppLogout();
  const publicChatLogout = usePublicChatLogout();
  const twilioLogout = useTwilioLogout();
  const googleLogout = useGoogleLogout();
  const navigate = useNavigate();
  const resetUser = useResetAnalyticsUser();
  const trackLogout = useTrackEvent(AnalyticsEvent.logout);

  const apiLogout = React.useCallback(async () => {
    if (isInPublicChat()) {
      publicChatLogout();

      return navigate(paths.publicChat.login);
    }
    appLogout();
    navigate(paths.auth.login);
  }, [search, pathname]);

  return React.useCallback(async () => {
    trackLogout();
    apiLogout();
    await twilioLogout();
    googleLogout();
    resetUser();

    queryClient.removeQueries();
  }, [apiLogout, twilioLogout, googleLogout]);
}
