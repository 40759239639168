import React from "react";
import { Mutex } from "shared/api/mutex";
import { config } from "shared/config";

import {
  AuthController,
  AuthenticateResponse,
  NotAuthorizedResponse,
} from "../types";
import { useLogout } from "./useLogout";

const refreshTokenMutex = Mutex();

export function useRequestToken(authController: AuthController) {
  const logout = useLogout();

  return React.useCallback(async () => {
    const { getAuthData, setAuthData } = authController;

    if (
      getAuthData().refreshToken &&
      isExpired(new Date(getAuthData().refreshTokenExpiresAt), new Date())
    ) {
      logout();
      throw new Error();
    }

    if (refreshTokenMutex.isLocked || !getAuthData().accessToken) {
      await refreshTokenMutex.waitForUnlock();

      return getAuthData().accessToken;
    }

    if (
      new Date().getTime() + 60_000 >
      new Date(getAuthData().accessTokenExpiresAt).getTime()
    ) {
      refreshTokenMutex.lock();

      const newAuthData = await refreshTokenFn(getAuthData().refreshToken);

      if (isNotAuthorizedResponse(newAuthData)) {
        await logout();
        refreshTokenMutex.unlock();
        throw new Error("Failed to refresh auth data");
      }

      setAuthData(newAuthData);

      refreshTokenMutex.unlock();

      return getAuthData().accessToken;
    }

    return getAuthData().accessToken;
  }, [authController, logout]);
}

const isExpired = (date: Date, refDate: Date) => {
  return date < refDate;
};

const refreshTokenFn = (
  refreshToken: string
): Promise<AuthenticateResponse | NotAuthorizedResponse> => {
  const params = new URLSearchParams();

  params.append("refresh_token", refreshToken);

  return fetch(`${config.apiUrl}${config.endpoints.auth.token}`, {
    method: "POST",
    body: params,
  })
    .then((res) => res.json())
    .catch(() => {
      return { status: 401, message: "Unauthorized" };
    });
};

const isNotAuthorizedResponse = (
  res: AuthenticateResponse | NotAuthorizedResponse
): res is NotAuthorizedResponse => {
  return "status" in res && res.status === 401;
};
