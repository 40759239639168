import React from "react";
import { useQueryClient } from "react-query";
import { Box, Slide, Stack, Typography, Paper, styled } from "@mui/material";

import { colors } from "shared/theme";
import { Input, MainButton } from "shared/components";
import { useShowError } from "shared/notifications";
import { QueryKey } from "shared/api";
import { unrefTimeout, useSessionStorage } from "shared/utils";
import { useTwilioConversationSelector } from "shared/twilio";
import { isInPublicChat } from "shared/public-chat";
import { useUserProfile } from "shared/settings";
import { validateName } from "./utils";

type GuardProps = {
  children: React.ReactNode;
};

const transitionTime = 400;

export const ChangeNameGuard = ({ children }: GuardProps) => {
  const isPublic = isInPublicChat();

  if (!isPublic) return <>{children}</>;

  return <Guard>{children}</Guard>;
};

const Guard = ({ children }: GuardProps) => {
  const queryClient = useQueryClient();

  const updateParticipantName = useTwilioConversationSelector(
    "updateParticipantName"
  );

  const { renameUser, isLoading, user } = useUserProfile();

  const showError = useShowError();

  const [nameChanged, setNameChanged] = useSessionStorage("nameChanged", false);

  const [name, setName] = React.useState("");

  const [nameError, setNameError] = React.useState("");

  const showGuard = !nameChanged;

  const [showPlaceholder, setShowPlaceholder] = React.useState(showGuard);

  React.useEffect(() => {
    if (nameChanged) {
      unrefTimeout(setTimeout(() => setShowPlaceholder(false), transitionTime));
    }
  }, [nameChanged]);

  const onChange = (e: { target: { value: string } }) => {
    if (nameError) {
      setNameError("");
    }

    setName(e.target.value);
  };

  const handleRename = async () => {
    if (!validateName(name)) {
      return setNameError(
        "Name should have at least 3 characters and contain alphanumeric characters."
      );
    }

    try {
      await renameUser({ newName: name });

      queryClient.invalidateQueries(QueryKey.User);
      queryClient.invalidateQueries(QueryKey.Conversation);

      await updateParticipantName(user!.id, name);

      setNameChanged(true);
    } catch (error) {
      showError();
    } finally {
      setName("");
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleRename();
    }
  };

  return (
    <>
      <Slide direction="up" in={showGuard} timeout={{ appear: 0, exit: 400 }}>
        <StyledPaper elevation={2}>
          <Stack spacing={2} alignItems="center">
            <Typography variant={"h6"} textAlign="center">
              Choose a name to participate:
            </Typography>
            <Box width={300}>
              <Input
                sx={{ width: "100%" }}
                labelFixed
                label="Name"
                value={name}
                onChange={onChange}
                onKeyDown={handleKeyPress}
                placeholder={"Enter your name"}
              />
            </Box>
            {nameError && (
              <Typography
                variant="caption"
                textAlign="center"
                color="error.main"
              >
                {nameError}
              </Typography>
            )}
            <MainButton
              disabled={Boolean(!name || nameError || isLoading)}
              onClick={handleRename}
            >
              Join a discussion
            </MainButton>
          </Stack>
        </StyledPaper>
      </Slide>

      {showPlaceholder ? (
        <Box height={placeholderHeight(showGuard, Boolean(nameError))} />
      ) : (
        children
      )}
    </>
  );
};

const placeholderHeight = (show: boolean, error: boolean) =>
  !show
    ? 114 // text section height
    : error
    ? 235 // placeholder with error
    : 186; // placeholder without error

const StyledPaper = styled(Paper)(({ theme: { spacing } }) => ({
  justifyContent: "center",
  display: "flex",
  maxWidth: "100vw",
  position: "absolute",
  width: "100%",
  minHeight: 215,
  padding: spacing(5),
  bottom: 0,
  left: 0,
  zIndex: 5000,
  backgroundColor: colors.superLightBlue,
  "& h6": {
    color: colors.haleNavy.main,
    marginBottom: spacing(5),
  },
  "& .MuiTypography-caption": {
    width: 300,
  },
  "& button": {
    justifyContent: "center",
    height: 52,
    width: 300,
    fontSize: 16,
  },
}));
