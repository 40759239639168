import React from "react";
import { UseBaseQueryOptions, useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useApi, isApiError } from "shared/api";
import { config } from "shared/config";
import { paths, useSearchParam } from "shared/router";
import { getErrorPage, useShowNotification } from "shared/notifications";

export function useActivateAccount() {
  const navigate = useNavigate();
  const { code } = useParams();
  const { post } = useApi();
  const userName = useSearchParam("invitedBy");

  const showNotification = useShowNotification();

  const { mutate, isLoading, error } = useMutation(
    post<undefined, {}>(
      config.endpoints.auth.activate.replace(":code", code || "")
    )
  );

  const activateAcount = React.useCallback(
    (options: UseBaseQueryOptions<{}> = {}) => {
      mutate(undefined, {
        ...options,
        onError: (error: unknown) => {
          if (isApiError(error) && error.status === 410) {
            navigate(paths.auth.resend.replace(":code", code || ""));
          } else {
            navigate(getErrorPage("Request"));
          }
        },
        onSuccess: (res) => {
          if (userName) {
            showNotification(
              `Invitation for ${userName} sent. Waiting for acceptance`
            );
          }
          options.onSuccess?.(res);
        },
      });
    },
    [mutate, navigate, code]
  );

  React.useEffect(() => {
    if (!code) navigate(paths.notFound);
  }, [code, navigate]);

  return {
    activateAcount,
    isLoading,
    error,
  };
}
