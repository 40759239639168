import { useQuery } from "react-query";

import { QueryKey, useApi } from "shared/api";
import { useIsAuthenticated } from "shared/auth";
import { config } from "shared/config";
import { useShowError } from "shared/notifications";
import { User } from "./types";

export const initialUser: User = {
  id: "",
  name: "",
  email: "",
  avatarId: "",
  contacts: [],
  _emailsContacts: [],
  _domainsContacts: [],
  isSetup: null,
  userConsent: false,
  registrationDate: "",
  skipInbox: false,
  loginEnabled: false,
  emailNotifications: false,
};

export const isUserValid = (user: User) => user === initialUser;

export const useUser = () => {
  const isAuthenticated = useIsAuthenticated();
  const { get } = useApi();
  const showError = useShowError();

  const { data, ...rest } = useQuery<User>(
    QueryKey.User,
    get(config.endpoints.user.v1.main, {
      transformResponse: transformUserResponse,
    }),
    {
      enabled: isAuthenticated,
      onError: () =>
        showError("Failed to fetch user data", "Please try again later."),
    }
  );

  const user = data || initialUser;
  const isSupervisor = !!user.email;

  return { user, isSupervisor, ...rest };
};

const transformUserResponse = (user: User): User => {
  const flattenedContacts = user.contacts.map(({ email }) => email);

  return {
    ...user,
    _emailsContacts: flattenedContacts.filter((val) => !val.includes("*")),
    _domainsContacts: flattenedContacts.filter((val) => val.includes("*")),
  };
};
