import React from "react";
import { Box } from "@mui/material";
import { differenceInMinutes } from "date-fns";
import InfiniteScroll from "react-infinite-scroll-component";
import AutoSizer from "react-virtualized-auto-sizer";
import { Message as TwilioMessage } from "@twilio/conversations";
import { User, useUser } from "shared/user";
import { Conversation, Participant } from "shared/conversations";
import { indexBy } from "shared/utils";
import { LoadingIndicator } from "shared/components";
import {
  hasEmail,
  isEmailAttachmentMessage,
  useTwilioConversationContext,
} from "shared/twilio";

import { useIsConversationOwner } from "../../context";
import { ChatMessage } from "./ChatMessage";
import { findAttachments } from "../shared";

export type MessageContext = {
  participantsById: Record<string, Participant>;
  user: User;
  isOwner: boolean;
};

type MessagesListProps = {
  conversation: Conversation;
};

const scrollableElementId = "chat-messages-list";

export const MessagesList: React.VFC<MessagesListProps> = ({
  conversation,
}) => {
  const { user } = useUser();
  const isOwner = useIsConversationOwner();
  const { fetchMore, hasMore, messages } = useTwilioConversationContext();

  const containerRef = React.useRef<HTMLDivElement>();

  const context = React.useMemo(
    () => ({
      participantsById: indexBy(conversation.participants, "userId"),
      isOwner,
      user,
    }),
    [conversation.participants, isOwner, user]
  );

  const handleNext = () => {
    if (!containerRef.current) {
      return;
    }

    containerRef.current.scrollTo(0, 0 - containerRef.current.scrollHeight);
    fetchMore();
  };

  return (
    <Box flex={1} height={1}>
      <AutoSizer disableWidth>
        {({ height }) => (
          <Box
            ref={containerRef}
            id={scrollableElementId}
            height={height}
            width={1}
            overflow={"auto"}
            display={"flex"}
            flexDirection={"column-reverse"}
            py={3}
            px={{ xs: 2, sm: 4 }}
          >
            <InfiniteScroll
              dataLength={messages.length}
              hasMore={hasMore}
              next={handleNext}
              inverse={true}
              loader={<LoadingIndicator />}
              scrollableTarget={scrollableElementId}
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden", // This is needed to prevent second scroll bar from glimpsing when new messages are loaded
              }}
            >
              {messages.map((message, index, array) =>
                renderMessage(message, index, array, context)
              )}
            </InfiniteScroll>
          </Box>
        )}
      </AutoSizer>
    </Box>
  );
};

const renderMessage = (
  current: TwilioMessage,
  index: number,
  messages: TwilioMessage[],
  { user, isOwner, participantsById }: MessageContext
) => {
  if (!current.author || isEmailAttachmentMessage(current)) {
    return;
  }
  const previous = messages[index - 1];
  const isMine = current.author === user.id;

  const isTimestampVisible =
    current.dateCreated && previous?.dateCreated
      ? differenceInMinutes(current.dateCreated, previous.dateCreated) > 10
      : current.dateCreated
      ? true
      : false;

  const isUsernameVisible = current.author !== previous?.author;
  const emailAttachments =
    hasEmail(current.attributes) && findAttachments(messages, index);

  return (
    <ChatMessage
      key={current.sid}
      isMine={isMine}
      isTimestampVisible={isTimestampVisible}
      isUsernameVisible={isUsernameVisible}
      message={current}
      isOwner={isOwner}
      participant={participantsById[current.author]}
      emailAttachments={emailAttachments || undefined}
    />
  );
};
