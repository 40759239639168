import { WEBSITE_URL } from "shared/constants";

export enum SettingType {
  profile = "profile",
  privacy = "privacy",
  email = "email",
  termsOfService = "termsOfService",
  privacyPolicy = "privacyPolicy",
  help = "help",
  about = "about",
  googleApiDisclosure = "googleApiDisclosure",
  contactUs = "contactUs",
}

export type Setting = {
  primary: string;
  secondary?: string;
  url?: string;
};

const supportMail = "support@gomarkus.com";

export const settings: Record<SettingType, Setting> = {
  profile: {
    primary: "Profile",
    secondary: "Edit your profile, logout and remove an account",
  },
  email: {
    primary: "Email settings",
    secondary:
      "Edit list of emails fetched by Markus and switch autoarchive option",
  },
  privacy: {
    primary: "Privacy and Security",
    secondary: "3rd parties legal notices",
  },
  termsOfService: {
    primary: "Terms & Service",
    url: `${WEBSITE_URL}/terms-of-service.html`,
  },
  privacyPolicy: {
    primary: "Privacy Policy",
    url: `${WEBSITE_URL}/privacy.html`,
  },
  googleApiDisclosure: {
    primary: "Google Api Disclosure",
    url: `${WEBSITE_URL}/google-api-disclosure.html`,
  },
  help: {
    primary: "Help",
    url: `${WEBSITE_URL}/help.html`,
  },
  about: {
    primary: "About",
    url: `${WEBSITE_URL}/about.html`,
  },
  contactUs: {
    primary: "Contact us",
    url: `mailto:${supportMail}`,
  },
};
