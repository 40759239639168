import {
  Conversation,
  JSONValue,
  Media,
  Message,
  Participant,
} from "@twilio/conversations";
import { max } from "date-fns";
import { getPreviewType, MediaPreviewType } from "shared/chat";
import {
  HasQuotedMessage,
  HasUsername,
  HasWebUser,
  MessageType,
} from "./model";

type HasEmail = {
  externalProviderMsgId: string;
};

type HasEmailSent = {
  emailSent: true;
};

export const createMediaMessage = (file: File | Blob) => {
  const formData = new FormData();

  formData.append("file", file);

  return formData;
};

export const hasEmail = (attributes: JSONValue): attributes is HasEmail => {
  return (attributes as HasEmail).externalProviderMsgId !== undefined;
};

export const hasEmailSent = (
  attributes: JSONValue
): attributes is HasEmailSent => {
  return (attributes as HasEmailSent).emailSent !== undefined;
};

const hasUsername = (participant: JSONValue): participant is HasUsername => {
  return !(participant as HasUsername).user_name !== undefined;
};

export const hasWebUser = (
  participant: JSONValue
): participant is HasWebUser => {
  return !(participant as HasWebUser).web_user !== undefined;
};

export const isEmailAttachmentMessage = (message: Message): boolean => {
  return Boolean((message.attributes as any).emailID);
};

export const formatParticipantName = ({ attributes }: Participant) => {
  if (!hasUsername(attributes)) {
    return "";
  }

  return attributes.user_name.length > 30
    ? `${attributes.user_name.slice(0, 29)}...`
    : attributes.user_name;
};

export const getEmailAttachmentMessages = (
  message: Message | null,
  messagesList: Message[]
) => {
  if (!message || !hasEmail(message.attributes)) {
    return [];
  }
  const externalId = message.attributes.externalProviderMsgId;
  return messagesList.filter((m) =>
    isEmailAttachmentMessage(m)
      ? (m.attributes as any).emailID === externalId
      : false
  );
};

export const getMessageByIndex = async (
  index: number | null,
  conversation?: Conversation | null
) => {
  if (!conversation || !index) {
    return null;
  }

  /**
   * !IMPORTANT: after deleting message with index n, calling this function will return n-1 message.
   * One must manually check on message.index to verfiy it is the right message
   */
  const paginator = await conversation.getMessages(1, index);

  if (!paginator.items.length) {
    return null;
  }

  const message = paginator.items[0];

  if (message.index !== index) {
    return null;
  }

  return message;
};

export const sendEmailMessage = async (
  conversation: Conversation,
  externalProviderMsgId: string,
  attachments: File[] = []
) => {
  const builder = conversation.prepareMessage();

  builder.setAttributes({ externalProviderMsgId, emailSent: false });
  builder.setBody(JSON.stringify({ externalProviderMsgId }));

  attachments.forEach((attachment) =>
    builder.addMedia(createMediaMessage(attachment))
  );

  const index = await builder.buildAndSend();

  return getMessageByIndex(index, conversation);
};

export const updateMessageAttributes = async (
  message: Message,
  newAttributes: Record<string, JSONValue>,
  keysToDelete: string[] = []
) => {
  const attributes = (message.attributes || {}) as Record<string, JSONValue>;

  for (const key of Object.keys(newAttributes)) {
    attributes[key] = newAttributes[key];
  }

  for (const key of keysToDelete) {
    delete attributes[key];
  }

  return message.updateAttributes(attributes);
};

export function getMessageType(message: Message) {
  if (isEmailAttachmentMessage(message)) {
    return MessageType.EmailAttachment;
  } else if (hasEmail(message.attributes)) {
    return MessageType.Email;
  } else if (message.type === "media") {
    return MessageType.Media;
  } else {
    return MessageType.Text;
  }
}

export async function getMediaAsObjectUrl(media: Media) {
  return media
    .getContentTemporaryUrl()
    .then((url) => {
      if (!url) {
        throw new Error("Couldn't get image url.");
      }
      return url;
    })
    .then(fetch)
    .then((response) => response.blob())
    .then((blob) => URL.createObjectURL(blob));
}

export const hasQuotedMessage = (
  attributes: JSONValue
): attributes is HasQuotedMessage => {
  if (!attributes) {
    return false;
  }

  if ((attributes as HasQuotedMessage).quotedMessage === undefined) {
    return false;
  }

  if (
    (attributes as HasQuotedMessage).quotedMessage.index === undefined ||
    (attributes as HasQuotedMessage).quotedMessage.sid === undefined
  ) {
    return false;
  }

  return true;
};

export function getMediaToPreview(media: Media[] | Media | null) {
  const mediaItem = Array.isArray(media) ? media[0] : media;

  if (!mediaItem) {
    return null;
  }

  if (getPreviewType(mediaItem.contentType) !== MediaPreviewType.unknown) {
    return mediaItem;
  }

  return null;
}

export const getFileHref = (media: Media) => {
  return media
    .getContentTemporaryUrl()
    .then((url) => {
      if (!url) {
        throw new Error("Couldn't get image url.");
      }
      return url;
    })
    .then(fetch)
    .then((response) => response.blob())
    .then((blob) => URL.createObjectURL(blob));
};

export const getTwilioUpdateDate = (conversation: Conversation) =>
  max(
    [
      conversation.lastMessage?.dateCreated,
      conversation.dateCreated,
      conversation.dateUpdated,
      new Date(0),
    ].filter(Boolean) as Date[]
  );

export const copyMessage = (message: Message) => {
  // This is bad for performance reasons see:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/setPrototypeOf
  // However, we must return new message object in order for components to be refreshed.
  const messageCopy = Object.assign({}, message);

  Object.setPrototypeOf(messageCopy, Message.prototype);
  return messageCopy;
};
