import { NavLink } from "react-router-dom";
import { styled } from "@mui/material";
import { colors } from "shared/theme";

export const StyledNavLink = styled(NavLink)(() => ({
  cursor: "pointer",
  textDecoration: "none",
  "&:hover": {
    backgroundColor: colors.superLightBlue,
  },
  "&.active": {
    backgroundColor: colors.superLightBlue,
  },
}));
