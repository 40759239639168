import React from "react";
import { AppRoute, RouteWrapperType } from "./model";
import { paths } from "./paths";

// app
const AppPage = React.lazy(() => import("pages/AppPage"));
const ConversationsPage = React.lazy(() => import("pages/ConversationsPage"));
const ConversationPage = React.lazy(() => import("pages/ConversationPage"));
const MediaPage = React.lazy(() => import("pages/MediaPage"));

const SettingsPage = React.lazy(() => import("pages/SettingsPage"));
const ProfilePage = React.lazy(() => import("pages/ProfilePage"));
const PrivacyPage = React.lazy(() => import("pages/PrivacyPage"));
const EmailPage = React.lazy(() => import("pages/EmailPage"));

const InboxPage = React.lazy(() => import("pages/InboxPage"));
const ContactsPage = React.lazy(() => import("pages/ContactsPage"));
const InboxMessagePreviewPage = React.lazy(
  () => import("pages/InboxMessagePreviewPage")
);

const WelcomePage = React.lazy(() => import("pages/WelcomePage"));

// auth
const AuthPage = React.lazy(() => import("pages/AuthPage"));
const LoginPage = React.lazy(() => import("pages/LoginPage"));
const RegisterPage = React.lazy(() => import("pages/RegisterPage"));
const RegisteredPage = React.lazy(() => import("pages/RegisteredPage"));
const ActivateAccountPage = React.lazy(
  () => import("pages/ActivateAccountPage")
);
const ResendLinkPage = React.lazy(() => import("pages/ResendLinkPage"));
const NewPasswordPage = React.lazy(() => import("pages/NewPasswordPage"));
const ResetPasswordPage = React.lazy(() => import("pages/ResetPasswordPage"));

// public chat
const PublicChatPage = React.lazy(() => import("pages/PublicChatPage"));
const PublicChatConversationPage = React.lazy(
  () => import("pages/PublicChatConversationPage")
);
const PublicChatLoginPage = React.lazy(
  () => import("pages/PublicChatLoginPage")
);

// error
const ErrorPage = React.lazy(() => import("pages/ErrorPage"));

// home
const HomePage = React.lazy(() => import("pages/HomePage"));

// not found
const NotFoundPage = React.lazy(() => import("pages/NotFoundPage"));

// invitation
const InvitationPage = React.lazy(() => import("pages/InvitationPage"));

// disable subscription
const DisableSubscriptionPage = React.lazy(
  () => import("pages/DisableSubscriptionPage")
);

export const routes: AppRoute[] = [
  {
    path: paths.auth.main,
    element: <AuthPage />,
    wrapper: RouteWrapperType.NoAuth,
    children: [
      {
        path: paths.auth.login,
        element: <LoginPage />,
        wrapper: RouteWrapperType.NoAuth,
      },
      {
        path: paths.auth.register,
        element: <RegisterPage />,
        wrapper: RouteWrapperType.NoAuth,
      },
      {
        path: paths.auth.registered,
        element: <RegisteredPage />,
        wrapper: RouteWrapperType.NoAuth,
      },
      {
        path: paths.auth.activate,
        element: <ActivateAccountPage />,
        wrapper: RouteWrapperType.NoAuth,
      },
      {
        path: paths.auth.resend,
        element: <ResendLinkPage />,
        wrapper: RouteWrapperType.None,
      },
      {
        path: paths.auth.newPassword,
        element: <NewPasswordPage />,
        wrapper: RouteWrapperType.None,
      },
      {
        path: paths.auth.resetPassword,
        element: <ResetPasswordPage />,
        wrapper: RouteWrapperType.None,
      },
    ],
  },
  {
    path: paths.app.main,
    element: <AppPage />,
    wrapper: RouteWrapperType.Auth,
    children: [
      {
        path: paths.app.conversations,
        element: <ConversationsPage />,
        wrapper: RouteWrapperType.Auth,
        children: [
          {
            path: paths.app.conversation,
            element: <ConversationPage />,
            wrapper: RouteWrapperType.Auth,
            children: [
              {
                path: paths.app.media,
                element: <MediaPage />,
                wrapper: RouteWrapperType.None,
              },
            ],
          },
        ],
      },
      {
        path: paths.app.settings.index,
        element: <SettingsPage />,
        wrapper: RouteWrapperType.Auth,
        children: [
          {
            path: paths.app.settings.profile,
            element: <ProfilePage />,
            wrapper: RouteWrapperType.Auth,
          },
          {
            path: paths.app.settings.privacy,
            element: <PrivacyPage />,
            wrapper: RouteWrapperType.Auth,
          },

          {
            path: paths.app.settings.email,
            element: <EmailPage />,
            wrapper: RouteWrapperType.Auth,
          },
        ],
      },
      {
        path: paths.app.inboxMessages,
        element: <InboxPage />,
        wrapper: RouteWrapperType.Auth,
        children: [
          {
            path: paths.app.inboxMessagePreview,
            element: <InboxMessagePreviewPage />,
            wrapper: RouteWrapperType.Auth,
          },
        ],
      },
      {
        path: paths.app.contacts,
        element: <ContactsPage />,
        wrapper: RouteWrapperType.Auth,
      },
      {
        path: paths.app.welcome,
        element: <WelcomePage />,
        wrapper: RouteWrapperType.Auth,
      },
    ],
  },
  {
    path: paths.home,
    element: <HomePage />,
    wrapper: RouteWrapperType.Auth,
  },
  {
    path: paths.error,
    element: <ErrorPage />,
    wrapper: RouteWrapperType.None,
  },
  {
    path: paths.invitation,
    element: <InvitationPage />,
    wrapper: RouteWrapperType.None,
  },
  {
    path: paths.publicChat.main,
    element: <PublicChatPage />,
    wrapper: RouteWrapperType.None,
    children: [
      {
        path: paths.publicChat.login,
        element: <PublicChatLoginPage />,
        wrapper: RouteWrapperType.NoAuthPublic,
      },
      {
        path: paths.publicChat.conversation,
        element: <PublicChatConversationPage />,
        wrapper: RouteWrapperType.AuthPublic,
      },
    ],
  },
  {
    path: paths.disableSubscription,
    element: <DisableSubscriptionPage />,
    wrapper: RouteWrapperType.None,
  },
  {
    path: paths.notFound,
    element: <NotFoundPage />,
    wrapper: RouteWrapperType.None,
  },
  {
    path: "*",
    element: <NotFoundPage />,
    wrapper: RouteWrapperType.None,
  },
];
