import React from "react";

import { MoreVert } from "@mui/icons-material";
import { Box, Fade, styled } from "@mui/material";

import { MessageType } from "shared/twilio";
import { IconButton } from "shared/components";
import { colors } from "shared/theme";

import { MessageMenuProps, OpenMenuButtonProps } from "./model";
import { useMessageMenu } from "./useMessageMenu.hook";
import { MessageMenuPopup } from "./MessageMenuPopup";
import { useCheckMessage } from "./useCheckMessage.hook";

export const MessageMenu: React.FC<MessageMenuProps> = ({
  isMine,
  message,
  children,
  isOwner,
}) => {
  const { elementProps, openMenu, closeMenu, menuOpen, showIcon, anchor } =
    useMessageMenu();
  const { permissions, type } = useCheckMessage(isMine, isOwner, message);

  if (permissions.none) return <>{children}</>;

  return (
    <StyledContainer {...elementProps}>
      <OpenMenuButton
        id={`message_${message.sid}_menu_button`}
        visible={showIcon}
        onClick={openMenu}
        left={isMine && !(type === MessageType.Email)}
      />
      <MessageMenuPopup
        permissions={permissions}
        anchor={anchor}
        onClose={closeMenu}
        open={menuOpen}
        message={message}
        type={type}
        isMine={isMine}
      />
      {children}
    </StyledContainer>
  );
};

const OpenMenuButton = ({
  id,
  onClick,
  visible,
  left = true,
}: OpenMenuButtonProps) => {
  return (
    <Fade in={visible} timeout={600}>
      <Box
        sx={{
          position: "absolute",
          left: left ? -40 : undefined,
          right: !left ? -40 : undefined,
          top: 0,
          width: 40,
          height: "100%",
          padding: 1,
          display: "flex",
          justifyContent: left ? "flex-end" : "flex-start",
          alignItems: "flex-start",
        }}
        onClick={onClick}
      >
        <IconButton
          id={id}
          label="Opens message edit menu"
          showTooltip={false}
          icon={MoreVert}
          size={34}
          sx={{
            color: colors.haleNavy.dark,
            backgroundColor: colors.superLightBlue,
          }}
          iconProps={{ sx: { fontsize: 22 } }}
        />
      </Box>
    </Fade>
  );
};

const StyledContainer = styled(Box)(({ theme: { breakpoints } }) => ({
  [breakpoints.down("sm")]: {
    cursor: "pointer",
    width: "100%",
  },
}));
