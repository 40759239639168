import React from "react";
import { useLocation } from "react-router-dom";
import { setPageTitle } from "./setPageTitle";

export function usePageTitle(title: string) {
  const { pathname } = useLocation();
  React.useEffect(() => {
    setPageTitle(title);
  }, [title, pathname]);
}
