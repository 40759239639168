import { QueryKey, useApi } from "shared/api";
import { UseBaseQueryOptions, useQuery } from "react-query";
import { config } from "shared/config";
import { appendAvatarColor } from "shared/utils";
import { useShowError } from "shared/notifications";
import { useIsAuthenticated } from "shared/auth";
import { Conversation } from "../types";

export const useConversations = (
  options: UseBaseQueryOptions<Conversation[]> = {}
) => {
  const { get } = useApi();
  const showError = useShowError();
  const isAuthenticated = useIsAuthenticated();

  return useQuery<Conversation[]>(
    [QueryKey.Conversations, isAuthenticated],
    get(config.endpoints.conversations.main, {
      transformResponse: (response) =>
        response.map(({ participants, ...conversation }) => ({
          ...conversation,
          participants: participants.map((p) =>
            appendAvatarColor(p, (p) => p.userId || p.authorizationCode)
          ),
        })),
    }),
    {
      onError: () =>
        showError("Failed to fetch conversation", "Please try again later"),
      enabled: isAuthenticated,
      ...options,
    }
  );
};
