import React from "react";

import { DisconnectIcon, Popup } from "shared/components";
import { useRevokeGoogleConsent } from "shared/google-auth";
import { useShowError } from "shared/notifications";
import { useLogout } from "shared/auth";

export type DisconnectGmailPopupProps = {
  open: boolean;
  onClose: () => void;
};

export const DisconnectGmailPopUp: React.VFC<DisconnectGmailPopupProps> = ({
  open,
  onClose,
}) => {
  const revokeConsent = useRevokeGoogleConsent();
  const showError = useShowError();
  const logout = useLogout();

  const onRemove = () => {
    revokeConsent()
      .then(() => logout())
      .catch((e) =>
        showError(
          "Failed to disconnect Gmail account",
          "Please try again later"
        )
      );
  };

  return (
    <Popup
      danger
      open={open}
      id="disconnect-gmail"
      acceptText="Disconnect"
      title="Disconnect your Gmail account from Markus?"
      content="Once you disconnect, you'll lose access to Gmail. Additionally, you'll be automatically logged out of your Markus account"
      onClick={onRemove}
      onClose={onClose}
      icon={<DisconnectIcon sx={{ fontSize: 28 }} />}
    />
  );
};
