import { Box, Stack, styled, Typography } from "@mui/material";

export const SectionButtonWrapper = styled(Box)(
  ({ theme: { palette, spacing } }) => ({
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${palette.grey[200]}`,
    borderRadius: 14,
    overflow: "hidden",
    padding: spacing(1, 2),
    backgroundColor: "white",
  })
);

export const SectionButtonHeader = styled(Box)(
  ({ theme: { spacing, palette } }) => ({
    display: "flex",
    fontWeight: 500,
    alignItems: "center",
    justifyContent: "space-between",
    padding: spacing(2),
    cursor: "pointer",
    color: palette.grey[500],
  })
);

export const SectionHeader = ({ children }: { children: React.ReactNode }) => (
  <Typography
    variant="h5"
    fontWeight={500}
    fontSize={16}
    lineHeight="30px"
    color={"grey.500"}
  >
    {children}
  </Typography>
);

export const SectionWrapper = ({ children }: { children: React.ReactNode }) => (
  <Stack spacing={2} pb={6}>
    {children}
  </Stack>
);
