import React from "react";
import { Box } from "@mui/material";
import { Media } from "@twilio/conversations";
import { LoadingIndicator } from "shared/components";
import { useSizeObserver } from "shared/utils";
import { useLoadMediaUrl } from "../hooks";
import { ErrorView } from "./ErrorView";

export const OfficePreview = ({ media }: { media: Media }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { data: url, isError, isLoading } = useLoadMediaUrl(media);
  const { size, isError: isSizeError } = useSizeObserver(ref);

  if (isError || isSizeError) {
    return <ErrorView />;
  }

  return (
    <Box sx={{ width: "100%", height: "100%", position: "relative" }} ref={ref}>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <iframe
          title={media.filename ?? "attachment"}
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
            url ?? ""
          )}`}
          width={size.width}
          height={size.height - 25}
          frameBorder="0"
        >
          <Box></Box>
        </iframe>
      )}
    </Box>
  );
};
