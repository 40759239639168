import React from "react";
import { useToggle } from "shared/utils";
import {
  ConversationEditDialog,
  useCreateConversationWithMail,
} from "shared/conversations";
import { GmailMessage } from "shared/gmail";

type CreateConversationWithEmailProps = {
  message: GmailMessage;
  ButtonEl: React.ElementType<{ toggle: () => void }>;
  onClose?: () => void;
};

// todo: add email to discussion dialog extra scroll bar on <ios></ios>

export const CreateConversationWithEmail: React.FC<
  CreateConversationWithEmailProps
> = ({ message, ButtonEl, onClose }) => {
  const [open, toggle] = useToggle(false);

  const { createConversationWithEmail, isLoading } =
    useCreateConversationWithMail(message, {
      onSuccess: () => {
        toggle();
        onClose?.();
      },
    });

  return (
    <>
      <ButtonEl toggle={toggle} />
      <ConversationEditDialog
        iosStyle={true}
        title={"New discussion"}
        onSubmit={createConversationWithEmail}
        isLoading={isLoading}
        initialData={{
          subject: message._subject,
          isPublic: false,
          participantsByUserId: {},
        }}
        dialogProps={{ open }}
        onClose={toggle}
      />
    </>
  );
};
