import { JwtPayload } from "jwt-decode";

export type CredentialJWTPayload = JwtPayload & {
  azp?: string;
  email: string;
  email_verified: boolean;
  family_name?: string;
  given_name?: string;
  name?: string;
  picture?: string;
};

export type GoogleAuthRequest = {
  code: string;
};

export type GoogleAuthResponse = {
  access_token: string;
  refresh_token: string;
  scope: string;
  token_type: string;
  id_token: string;
  expiry_date: number;
};

export const initialAuthData: GoogleAuthResponse = {
  access_token: "",
  expiry_date: 0,
  id_token: "",
  refresh_token: "",
  scope: "",
  token_type: "",
};

export type GoogleAuthContextState = {
  authData: GoogleAuthResponse;
  setAuthData: (val: GoogleAuthResponse) => void;
  isAuthenticated: boolean;
};

