import { UseBaseQueryOptions, useMutation, useQueryClient } from "react-query";

import { useApi, QueryKey } from "shared/api";
import { config } from "shared/config";
import { useShowError } from "shared/notifications";
import { AnalyticsEvent, useTrackEvent } from "shared/analytics";
import { AcceptInvitationResponse } from "../model";

export function useAcceptInvitation(id: string) {
  const queryClient = useQueryClient();

  const { post } = useApi();
  const showError = useShowError();
  const trackReject = useTrackEvent(AnalyticsEvent.accept_invitation);

  const { mutate, isLoading, error } = useMutation(
    post<undefined, AcceptInvitationResponse>(
      config.endpoints.invitations.invitation.replace(/:invitationId/, id)
    )
  );

  const acceptInvitation = (
    options: Omit<UseBaseQueryOptions<{}>, "onSuccess" | "onError"> = {}
  ) =>
    mutate(undefined, {
      onSuccess: () => {
        trackReject();
        queryClient.invalidateQueries(QueryKey.IncomingInvitations);
        queryClient.invalidateQueries(QueryKey.CollaboratorList);
      },
      onError: () =>
        showError("Failed to accept invitation", "Please try again later"),
      ...options,
    });

  return { acceptInvitation, isLoading, error };
}
