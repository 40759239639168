import { IconButton, IconButtonProps } from "./IconButton";
import { ProRibbon } from "./ProRibbon";

export const ProButton = ({
  size = 32,
  ...props
}: IconButtonProps) => {
  return (
    <ProRibbon size={size}>
      <IconButton size={size} {...props} />
    </ProRibbon>
  );
};
