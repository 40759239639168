import React from "react";
import { MoreVert } from "@mui/icons-material";
import { NavLinkProps } from "react-router-dom";
import { format } from "date-fns";
import { QueryDataWrapper } from "shared/api";
import {
  IconButton,
  MessagesListItem,
  MessagesListItemSkeleton,
  StyledNavLink,
} from "shared/components";
import {
  GmailBaseMessage,
  useGmailMessage,
  GmailMessageAvatar,
  GmailMessage,
} from "shared/gmail";

type GmailMessagesListItemProps = NavLinkProps & {
  message: GmailBaseMessage;
  toggleMenu: (
    message: GmailMessage | null,
    anchor: HTMLElement | null
  ) => void;
};

export const GmailMessagesListItem: React.FC<GmailMessagesListItemProps> =
  React.memo(
    ({ message, toggleMenu, ...props }) => {
      const { id } = message;

      const messageResult = useGmailMessage(id);

      return (
        <QueryDataWrapper
          result={messageResult}
          loadingView={<MessagesListItemSkeleton />}
        >
          {({ data: message }) => (
            <StyledNavLink to={props.to}>
              <MessagesListItem
                AvatarEl={
                  <GmailMessageAvatar
                    from={message._from}
                    color={message._avatarColor}
                  />
                }
                title={message._subject}
                subtitle={message._from}
                timestamp={format(
                  new Date(Number(message.internalDate)),
                  "MM/dd/yyyy, p"
                )}
                text={message.snippet}
                Actions={
                  <IconButton
                    label="more"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleMenu(message, e.currentTarget);
                    }}
                    icon={MoreVert}
                  />
                }
                {...props}
              />
            </StyledNavLink>
          )}
        </QueryDataWrapper>
      );
    },
    (prev, next) => {
      return prev.message.id === next.message.id && prev.to === next.to;
    }
  );
