import { CircularProgress, styled } from "@mui/material";
import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import { colors } from "shared/theme";
import { DownloadIcon } from "./icons";

type DownloadButtonProps = {
  handleDownload: () => any;
  isDownloading: boolean;
  size?: "small" | "medium" | "large";
  color?: string;
} & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const DownloadButton = ({
  handleDownload,
  isDownloading,
  color,
  size = "medium",
  ...props
}: DownloadButtonProps) => {
  const pxSize = size === "small" ? 30 : size === "medium" ? 38 : 46;
  return (
    <StyledButton
      size={pxSize}
      data-testid="download_button"
      aria-label="press to download"
      onClick={(e) => {
        e.stopPropagation();
        handleDownload();
      }}
      {...props}
    >
      {isDownloading ? (
        <CircularProgress
          size={pxSize - (size === "small" ? 12 : 15)}
          sx={{ color: color ?? colors.grey.dark }}
        />
      ) : (
        <DownloadIcon sx={{ width: pxSize - 8, height: pxSize - 8 }} />
      )}
    </StyledButton>
  );
};

const StyledButton = styled("button", {
  shouldForwardProp: (prop) => prop !== "size",
})<{ size: number }>(({ size }) => ({
  color: colors.textDark,
  backgroundColor: colors.superLightBlue,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 3,
  width: size,
  height: size,
  borderRadius: "50%",
  border: "none",
  outline: "none",
  cursor: "pointer",
}));
