import Resizer from "react-image-file-resizer";

const resizeFile = (file: File): Promise<string> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      128,
      128,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri as string);
      },
      "base64",
      128,
      128
    );
  });

export async function fileToBase64(file: File) {
  const base64 = await resizeFile(file);
  // return raw data only
  return base64.replace(/^data:image\/jpeg;base64,/, "");
}
