import React from "react";
import { Box } from "@mui/material";
import { Letter } from "react-letter";
import { QueryDataWrapper } from "shared/api";
import { useEmailMessage } from "../../shared";
import { EmailPreviewHeader } from "./EmailPreviewHeader";

type Props = {
  externalProviderMsgId: string;

};

export const EmailPreview: React.FC<Props> = ({ externalProviderMsgId }) => {
  const emailMessageResult = useEmailMessage(externalProviderMsgId);
  
  return (
    <QueryDataWrapper result={emailMessageResult}>
      {({ data }) => (
        <>
          <EmailPreviewHeader message={data} />
          <Box flex={1} p={3} overflow={"auto"}>
            <Letter html={data.htmlText} />
          </Box>
        </>
      )}
    </QueryDataWrapper>
  );
};
