import { UseBaseQueryOptions, useQuery } from "react-query";
import { QueryKey } from "../api";
import { gmailConfig } from "./model";
import { getGmailApiConfig } from "./utils";
import { GmailMessagesResponse } from "./types";
import { useUser } from "../user";
import { useGoogleApi } from "../google-auth";

type UseGmailMessages = {
  qLabel?: string;
  pageToken?: string;
  reactQueryOptions?: UseBaseQueryOptions<GmailMessagesResponse>;
};

const maxResults = 20;

export const useGmailMessages = ({
  qLabel,
  pageToken = "",
  reactQueryOptions = {},
}: UseGmailMessages = {}) => {
  const { user } = useUser();
  const { get } = useGoogleApi();

  return useQuery<GmailMessagesResponse>(
    [QueryKey.GmailMessages, pageToken, qLabel, user.contacts],
    get(gmailConfig.endpoints.messages.replace(/:userId/, "me"), {
      ...getGmailApiConfig(),
      query: {
        maxResults,
        pageToken,
        q: `in:inbox ${qLabel} from:(${user.contacts
          .map(({ email }) => email)
          .join(" OR ")})`,
      },
    }),
    {
      enabled: false,
      ...reactQueryOptions,
    }
  );
};
