import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const LinkIcon: React.FC<SvgIconProps> = (props) => {
  const fillProp = props.fill ?? "transparent";
  return (
    <SvgIcon
      {...props}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      aria-label="link icon"
      sx={{ fill: fillProp, ...props.sx }}
    >
      <path
        d="M10.5801 7.41982C9.23011 6.06986 7.04139 6.06986 5.69143 7.41982L2.23464 10.8766C0.884678 12.2266 0.884678 14.4153 2.23464 15.7653C3.5846 17.1152 5.77332 17.1152 7.12328 15.7653L8.07525 14.8133M7.41982 10.5801C8.76978 11.93 10.9585 11.93 12.3085 10.5801L15.7653 7.12328C17.1152 5.77332 17.1152 3.5846 15.7653 2.23464C14.4153 0.884678 12.2266 0.884678 10.8766 2.23464L9.92633 3.18492"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
