import { Box, styled } from "@mui/material";
import React from "react";
import { colors } from "shared/theme";

export const ProRibbon = ({
  size = 32,
  children,
  color = colors.haleNavy.light,
}: {
  size?: number;
  children?: React.ReactNode;
  color?: string; 
}) => {
  return <StyledBox size={size} data-testid="pro-ribbon">{children}</StyledBox>;
};

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "size",
})<{
  size: number;
}>(({ size }) => ({
  width: size,
  height: size,
  borderRadius: "50%",
  overflow: "hidden",
  position: "relative",
  cursor: "pointer",
  "&::after": {
    pointerEvents: "none",
    content: '"PRO"',
    lineHeight: `${size / 4}px`,
    position: "absolute",
    width: "100%",
    height: size / 4,
    fontWeight: 600,
    textAlign: "center",
    fontSize: size / 5,
    backgroundColor: colors.diamondWhite,
    color: colors.markusBlue.light,
    transform: "rotate(45deg)",
    top: size / 5.5,
    left: size / 4,
  },
}));
