import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { Box } from "@mui/material";

import { useChatContext } from "../context";
import { EmailPreview } from "./email-preview";

export const ChatEmailPreview: React.FC = () => {
  const {
    openEmailPreview,
    externalProviderMsgId,
  } = useChatContext();

  if (!openEmailPreview || !externalProviderMsgId) {
    return null;
  }

  return (
    <Box
      flex={"1"}
      borderLeft={({ palette }) => `1px solid ${palette.grey[200]}`}
      display={"flex"}
      flexDirection={"column"}
      overflow={"hidden"}
    >
      <AutoSizer disableWidth>
        {({ height }) => (
          <Box height={height} display={"flex"} flexDirection={"column"}>
            <EmailPreview
              externalProviderMsgId={externalProviderMsgId}
            />
          </Box>
        )}
      </AutoSizer>
    </Box>
  );
};
