import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ErrorDottedIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 70 70"
      aria-label="error icon"
      {...props}
      sx={{ ...props.sx, fill: "transparent" }}
    >
        <g filter="url(#filter0_i_6878_2760)">
          <circle cx="35.0001" cy="35.0006" r="29.569" fill="#FFF6F6" />
        </g>
        <circle
          cx="35.0004"
          cy="34.9995"
          r="21.8276"
          fill="url(#paint0_radial_6878_2760)"
          stroke="#D3F1FF"
        />
        <circle
          cx="35.0002"
          cy="35.0002"
          r="23.6379"
          fill="url(#paint1_radial_6878_2760)"
          stroke="#FD2C5E"
        />
        <path
          d="M26.9448 43L42.9122 27M26.9448 27L42.9122 43"
          stroke="#FD2C5E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="35"
          cy="35"
          r="34.5"
          stroke="#FFDCDC"
          strokeLinejoin="round"
          strokeDasharray="3 3"
        />
        <defs>
          <filter
            id="filter0_i_6878_2760"
            x="5.43115"
            y="5.43164"
            width="59.1377"
            height="59.1379"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_6878_2760"
            />
          </filter>
          <radialGradient
            id="paint0_radial_6878_2760"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(35.0004 34.9995) rotate(90) scale(27.4569 32.7711)"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#DDE9FF" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_6878_2760"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(35.0002 35.0002) rotate(90) scale(46.6449 55.673)"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#FFDDDD" />
          </radialGradient>
        </defs>
    </SvgIcon>
  );
};
