export type EmailSummary = {
  description: string;
  items: string[];
};

export type EmailSummaryResponse = EmailSummary;
export type EmailSummaryRequest = { text: string };

export enum EmailTextStatus {
  OK = "OK",
  TOO_LONG = "TOO_LONG",
  TOO_SHORT = "TOO_SHORT",
  EMPTY = "TOO_SHORT",
}

export const EmailErrorText: Record<
  Exclude<keyof typeof EmailTextStatus, "OK">,
  string
> = {
  TOO_LONG: "Provided text is too long for current AI model",
  TOO_SHORT: "Provided text is too short to be summarized",
  EMPTY: "Failed to load an email",
};

