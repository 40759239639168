import React from "react";

export const storageUtilFactory = (storage: Storage) => {
  const setItem = <T extends unknown>(key: string, data: T) => {
    storage.setItem(key, JSON.stringify(data));
  };

  const getItem = <TData extends unknown>(
    key: string,
    defaultValue: TData | null = null
  ): TData => {
    const value = storage.getItem(key);
    return value ? JSON.parse(value) : defaultValue;
  };

  const removeItem = (key: string) => storage.removeItem(key);
  const clear = () => storage.clear();

  return {
    setItem,
    getItem,
    removeItem,
    clear,
  };
};

export const localStorageService = storageUtilFactory(window.localStorage);
export const sessionStorageService = storageUtilFactory(window.sessionStorage);

const createUseStorageFactory =
  (storage: ReturnType<typeof storageUtilFactory>) =>
  <T>(key: string, initialValue?: T) => {
    const [value, setStoredValue] = React.useState(() =>
      storage.getItem(key, initialValue)
    );
    const setValue = React.useCallback(
      (newValue: T): void => {
        storage.setItem(key, newValue);
        setStoredValue(newValue);
      },
      [setStoredValue, key]
    );

    return [value, setValue] as [T, (value: T) => void];
  };

export const useLocalStorage = createUseStorageFactory(localStorageService);
export const useSessionStorage = createUseStorageFactory(sessionStorageService);
