import { UseBaseQueryOptions, useMutation, useQueryClient } from "react-query";
import { gmailConfig } from "./model";
import { getGmailApiConfig } from "./utils";
import { GmailMessageModify, GmailMessageModifyResponse } from "./types";
import { QueryKey } from "../api";
import { useGoogleApi } from "../google-auth";

export const useModifyGmailMessage = (messageId?: string) => {
  const queryClient = useQueryClient();
  const { post } = useGoogleApi();

  const { mutate, isLoading } = useMutation(
    post<GmailMessageModify, GmailMessageModifyResponse>(
      gmailConfig.endpoints.modifyMessage
        .replace(/:userId/, "me")
        .replace(/:messageId/, messageId ?? ""),
      {
        ...getGmailApiConfig(),
      }
    )
  );

  const modifyGmailMessage = (
    data: GmailMessageModify,
    options: UseBaseQueryOptions<GmailMessageModifyResponse> = {}
  ) => {
    mutate(data, {
      ...options,
      onSuccess: (response) => {
        queryClient.invalidateQueries([QueryKey.GmailMessages]);
        queryClient.refetchQueries([QueryKey.GmailMessages]);
        options.onSuccess?.(response);
      },
    });
  };

  return {
    modifyGmailMessage,
    isLoading,
  };
};
