import { Box, BoxProps, styled } from "@mui/material";

import { APP_HEADER_HEIGHT, MOBILE_APP_HEADER_HEIGHT } from "shared/constants";
import { colors } from "shared/theme";

export const AppHeader = styled((props: BoxProps) => (
  <Box role="banner" {...props} />
))(({ theme: { spacing, breakpoints } }) => ({
  overflow: "hidden",
  width: "100%",
  borderBottom: `1px solid ${colors.diamondWhite}`,
  height: MOBILE_APP_HEADER_HEIGHT,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: spacing(6, 2),
  [breakpoints.up("sm")]: {
    height: APP_HEADER_HEIGHT,
    padding: spacing(6, 8),
  },
}));
