import { Menu, MenuItem, MenuItemProps, styled } from "@mui/material";
import { colors } from "shared/theme";

export const StyledMenu = styled(Menu)(({ theme: { spacing } }) => ({
  "& .MuiPaper-root": {
    borderRadius: spacing(3.5),
    overflow: "hidden",
  },
  "& .MuiMenu-list": {
    padding: 0,
  },
  "& .MuiMenuItem-root": {
    borderBottom: `1px solid ${colors.diamondWhite}`,
    color: colors.haleNavy.main,
    "&:last-child": {
      borderBottom: "none",
    },
    "&.danger": {
      color: colors.redAlert,
    },
    "&:hover": {
      color: colors.diamondWhite,
      backgroundColor: colors.haleNavy.light,
      "&.danger": {
        backgroundColor: colors.redAlert,
      },
    },
  },
}));

export const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== "disableMinWidth",
})<{ disableMinWidth?: boolean }>(
  ({ theme: { spacing }, disableMinWidth }) => ({
    "&&": {
      padding: spacing(2, 4),
      minWidth: disableMinWidth ? "unset" : 150,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "22px",
      minHeight: 48,
    },
  })
);

export const StyledLinkItem = styled(
  ({ children, href, ...props }: MenuItemProps & { href: string }) => (
    <MenuItem {...props} >
      <a href={href} rel="noreferrer" target="_blank">{children}</a>
    </MenuItem>
  ),
  {
    shouldForwardProp: (prop) => prop !== "disableMinWidth",
  }
)<{ disableMinWidth?: boolean }>(({ theme: { spacing }, disableMinWidth }) => ({
  padding: 0,
  "&>a": {
    padding: spacing(2, 4),
    minWidth: disableMinWidth ? "unset" : 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "22px",
    minHeight: 48,
    textDecoration: "none",
    color: "inherit",
  },
}));
