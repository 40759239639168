import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const EditMessageIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="edit message icon"
  >
    <path
      d="M7.69141 15.7497H10.3414C10.4237 15.7502 10.5052 15.7344 10.5813 15.7033C10.6575 15.6722 10.7268 15.6264 10.7852 15.5685L15.1102 11.2372L16.8852 9.49972C16.9437 9.44162 16.9902 9.37249 17.022 9.29633C17.0537 9.22017 17.07 9.13848 17.07 9.05597C17.07 8.97346 17.0537 8.89177 17.022 8.81561C16.9902 8.73945 16.9437 8.67032 16.8852 8.61222L14.2352 5.93097C14.1771 5.87239 14.1079 5.82589 14.0318 5.79416C13.9556 5.76243 13.8739 5.74609 13.7914 5.74609C13.7089 5.74609 13.6272 5.76243 13.551 5.79416C13.4749 5.82589 13.4058 5.87239 13.3477 5.93097L11.5852 7.69972L7.24766 12.031C7.18973 12.0894 7.1439 12.1586 7.1128 12.2348C7.0817 12.3109 7.06593 12.3925 7.06641 12.4747V15.1247C7.06641 15.2905 7.13225 15.4494 7.24946 15.5667C7.36667 15.6839 7.52565 15.7497 7.69141 15.7497ZM13.7914 7.25597L15.5602 9.02472L14.6727 9.91222L12.9039 8.14347L13.7914 7.25597ZM8.31641 12.731L12.0227 9.02472L13.7914 10.7935L10.0852 14.4997H8.31641V12.731ZM17.6914 16.9997H6.44141C6.27565 16.9997 6.11667 17.0656 5.99946 17.1828C5.88225 17.3 5.81641 17.459 5.81641 17.6247C5.81641 17.7905 5.88225 17.9494 5.99946 18.0667C6.11667 18.1839 6.27565 18.2497 6.44141 18.2497H17.6914C17.8572 18.2497 18.0161 18.1839 18.1333 18.0667C18.2506 17.9494 18.3164 17.7905 18.3164 17.6247C18.3164 17.459 18.2506 17.3 18.1333 17.1828C18.0161 17.0656 17.8572 16.9997 17.6914 16.9997Z"
      fill="currentColor"
    />
  </SvgIcon>
);
