import React from "react";
import { AuthLayout } from "../../layouts";
import { Box, BoxProps, Stack, Typography } from "@mui/material";
import {
  AppleButton,
  GoogleButton,
  LoadingIndicator,
  TermsOfServiceInfo,
} from "../../components";
import { useConvertGoogleUser } from "./useConvertGoogleUser";
import { useConvertAppleUser } from "./useConvertAppleUser";
import { useUser } from "../useUser";
import { QueryDataWrapper } from "../../api";

type ConvertUserGuardProps = BoxProps & {
  isLogoVisible?: boolean;
};

export const UserConvertGuard: React.FC<ConvertUserGuardProps> = ({
  children,
  ...props
}) => {
  const { convertUser: convertGoogleUser, isLoading: convertGoogleLoading } =
    useConvertGoogleUser();
  const { convertAppleUser, isLoading: convertAppleLoading } =
    useConvertAppleUser();
  const {
    user: { email },
    isLoading: userLoading,
  } = useUser();

  const isCollaborator = !email;

  return (
    <QueryDataWrapper
      result={{
        isLoading: userLoading || convertAppleLoading || convertGoogleLoading,
        error: undefined,
        data: null,
      }}
      loadingView={<LoadingView />}
    >
      {() => (
        <>
          {isCollaborator ? (
            <AuthLayout {...props}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                textAlign={"center"}
                width={300}
                minHeight={400}
              >
                <Typography component={Box} variant={"h5"}>
                  Sign in and get all features
                </Typography>
                <Typography
                  component={Box}
                  width={280}
                  mt={4}
                  variant={"body1"}
                  color={"secondary.light"}
                >
                  Create discussions, invite friends, connect your mailbox
                </Typography>
                <Stack spacing={3} mt={10} width={1}>
                  <GoogleButton onClick={convertGoogleUser}>
                    Connect with Google
                  </GoogleButton>
                  <AppleButton onClick={convertAppleUser}>
                    Sign in with Apple
                  </AppleButton>
                </Stack>
                <TermsOfServiceInfo mt={7} />
              </Box>
            </AuthLayout>
          ) : (
            <>{children}</>
          )}
        </>
      )}
    </QueryDataWrapper>
  );
};

const LoadingView = () => (
  <Box
    minHeight={300}
    height={1}
    display={"flex"}
    alignItems={"center"}
    justifyContent={"center"}
  >
    <LoadingIndicator />
  </Box>
);
