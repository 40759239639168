import React from "react";
import { Badge, Box } from "@mui/material";

import { UserAvatar, UserAvatarProps } from "shared/user";

type AvatarsGroupDiagonalProps = {
  avatars: UserAvatarProps[];
};

export const AvatarsGroupDiagonal: React.FC<AvatarsGroupDiagonalProps> = ({
  avatars,
}) => {
  const hasMultiple = avatars.length > 1;

  return (
    <Box width={42} textAlign={"right"}>
      {hasMultiple ? (
        <MultipleAvatars avatars={avatars} />
      ) : (
        <UserAvatar
          userName={avatars[0].userName}
          userId={avatars[0].userId}
          avatarColor={avatars[0].avatarColor}
          size={36}
        />
      )}
    </Box>
  );
};

const MultipleAvatars: React.FC<AvatarsGroupDiagonalProps> = ({ avatars }) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      badgeContent={
        <UserAvatar
          userName={avatars[0].userName}
          userId={avatars[0].userId}
          avatarColor={avatars[0].avatarColor}
          size={26}
          sx={{
            border: `1px solid white`,
          }}
        />
      }
    >
      <UserAvatar
        userName={avatars[1].userName}
        userId={avatars[1].userId}
        avatarColor={avatars[1].avatarColor}
        size={26}
      />
    </Badge>
  );
};
