import { createTheme } from "@mui/material";
import createPalette, {
  PaletteOptions,
} from "@mui/material/styles/createPalette";
import createTypography, {
  TypographyOptions,
} from "@mui/material/styles/createTypography";

const grey = {
  100: "#F8F8F8",
  200: "#E4E9EE",
  300: "#93A7BA",
  400: "#576E83",
  500: "#455869",
  600: "#233443",
  700: "#19202E",
};

export const purple = {
  500: "#7B2CBF",
};

const paletteOptions: PaletteOptions = {
  primary: {
    main: "#3443BF",
    light: "#E9F4FF",
  },
  secondary: {
    main: grey[600],
    dark: grey[700],
    light: grey[500],
  },
  info: {
    dark: "#2A8DFF",
    main: "#92CDFF",
    light: "#EDF8FF",
  },
  success: {
    main: "#F4F9FF",
  },
  error: {
    main: "#FF4D77",
  },
  grey,
};

export const palette = createPalette(paletteOptions);

export const typographyOptions: TypographyOptions = {
  h5: {
    fontSize: 24,
    fontWeight: 700,
  },
  h6: {
    fontSize: 20,
    fontWeight: 600,
  },
};

export const typography = createTypography(palette, typographyOptions);

export const theme = createTheme({
  palette,
  typography,
  spacing: 4,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: "100%",
        },
        body: {
          height: "100%",
          color: palette.secondary.main,
          textRendering: "optimizeSpeed",
          scrollBehavior: "smooth",
        },
        "::-webkit-scrollbar": {
          width: ".4em",
          height: ".4em",
        },
        "::-webkit-scrollbar-track": {
          backgroundColor: palette.grey[200],
          borderRadius: 15,
          marginTop: 4,
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: palette.grey[300],
          borderRadius: 15,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 500,
          borderRadius: 15,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
          fontSize: 14,
          fontWeight: 500,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginRight: 8,
          marginLeft: 8,
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterDelay: 1000,
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 15,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: palette.secondary.main,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          "& fieldset": {
            borderRadius: 15,
          },
        },
      },
    },
  },
});
