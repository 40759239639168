import { UseBaseQueryOptions, useQuery } from "react-query";
import { QueryKey, useApi } from "shared/api";
import { config } from "shared/config";
import { ConversationEmailMessage } from "shared/conversations";
import { formatEmailMessageDate } from "shared/utils";

export type EmailMessage = ConversationEmailMessage & {
  lastFetchDate: number;
  externalCreationDate: string;
  htmlText: string;
  raw: string;
  fromAvatar: string;
  date: string;
  userId: string;
  user?: {
    id: string;
    name: string;
  };
};

export const useEmailMessage = (
  messageId: string,
  options: UseBaseQueryOptions<EmailMessage> = {}
) => {
  const { get } = useApi();

  return useQuery<EmailMessage>(
    [QueryKey.Conversation, messageId],
    get(config.endpoints.messages.v1.main.replace(/:messageId/, messageId), {
      transformResponse: (res) => ({
        ...res,
        externalCreationDate: formatEmailMessageDate(res.externalCreationDate),
        date: formatEmailMessageDate(res.date),
      }),
    }),
    {
      ...options,
      enabled: !!messageId,
    }
  );
};
