import React from "react";
import { Box, BoxProps, styled } from "@mui/material";

import { useIsBreakpoint } from "shared/theme";
import {
  DESKTOP_DRAWER_WIDTH,
  MOBILE_APP_BAR_HEIGHT,
  TABLET_DRAWER_WIDTH,
} from "shared/constants";
import { useIsOnConversationPage } from "shared/utils";
import { useIncomingInvitations } from "shared/invitations";
import { AppDrawer, MobileAppBar } from "shared/components";
import { useAppMenuLinks } from "./useAppMenuLinks";
import { AppMenu } from "shared/components/AppMenu";
import { useConversationsMetaData } from "shared/twilio";

export const AppLayout: React.FC = ({ children }) => {
  const isSmBreakpoint = useIsBreakpoint("sm");
  const hideMobileBar = useIsOnConversationPage();
  const incomingInvitationsQuery = useIncomingInvitations();
  const metadata = useConversationsMetaData();

  const hasUnreadMessages = React.useMemo(
    () => Object.values(metadata).some((m) => m.unreadMessagesCount),
    [metadata]
  );

  const links = useAppMenuLinks({
    contactsBadge: !!incomingInvitationsQuery.incomingInvitations.length,
    messagesBadge: hasUnreadMessages,
  });

  return (
    <Wrapper>
      <Main
        component={"main"}
        disablePadding={!isSmBreakpoint || hideMobileBar}
      >
        {children}
        {isSmBreakpoint ? (
          <MobileAppBar hide={hideMobileBar} links={links} />
        ) : (
          <AppDrawer>
            <AppMenu links={links} data-cy={"app-menu"} />
          </AppDrawer>
        )}
      </Main>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(() => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  minHeight: 0,
  minWidth: 0,
  position: "relative",
  alignItems: "stretch",
}));

const Main = styled(Box, {
  shouldForwardProp: (prop) => prop !== "disablePadding",
})<BoxProps & { disablePadding?: boolean }>(
  ({ theme: { breakpoints }, disablePadding }) => ({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginLeft: 0,
    paddingBottom: disablePadding ? "unset" : `${MOBILE_APP_BAR_HEIGHT}px`,
    [breakpoints.up("sm")]: {
      marginLeft: `${TABLET_DRAWER_WIDTH}px`,
    },
    [breakpoints.up("lg")]: {
      marginLeft: `${DESKTOP_DRAWER_WIDTH}px`,
    },
  })
);
