import { UseBaseQueryOptions, useMutation } from "react-query";
import { useApi } from "shared/api";
import { config } from "shared/config";
import { AnalyticsEvent, useTrackEvent } from "shared/analytics";

export const useDeleteConversation = (conversationId: string) => {
  const { deleteRequest } = useApi();

  const { mutate, isLoading } = useMutation(
    deleteRequest<undefined, {}>(
      config.endpoints.conversations.conversation.replace(
        /:chatSid/,
        conversationId
      )
    )
  );

  const trackDeleteConversation = useTrackEvent(
    AnalyticsEvent.discuss_remove_chat
  );

  const deleteConversation = (options: UseBaseQueryOptions<{}> = {}) => {
    mutate(undefined, {
      ...options,
      onSuccess: (res) => {
        trackDeleteConversation();
        options.onSuccess?.(res);
      },
    });
  };

  return {
    deleteConversation,
    isLoading,
  };
};
