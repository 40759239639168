import { Media } from "@twilio/conversations";
import { Box, BoxProps, styled, Typography } from "@mui/material";
import { getFileSizeInMB } from "shared/utils";
import { DownloadButton, FileName } from "shared/components";
import { useDownloadMedia } from "shared/twilio";
import {
  getPreviewType,
  MediaPreviewType,
  useToggleMediaPreview,
} from "shared/chat";
import { AttachmentConfig, MessageAttachmentType } from "./model";
import { getAttachmentConfig, getFileType } from "./utils";

type AttachmentMessageProps = BoxProps & {
  media: Media;
  isMine?: boolean;
  type?: MessageAttachmentType;
  compact?: boolean;
};

export const AttachmentMessage = ({
  media,
  isMine = false,
  type = "message",
  compact = false,
  ...props
}: AttachmentMessageProps) => {
  const fileType = getFileType(media.contentType);

  const togglePreview = useToggleMediaPreview();
  const canPreview =
    getPreviewType(media.contentType) !== MediaPreviewType.unknown;
  const { Icon, colors } = getAttachmentConfig({ fileType, type, isMine });

  const { handleDownload, isLoading } = useDownloadMedia();

  const handleClick = (e: { stopPropagation: () => void }) => {
    if (!canPreview) {
      return;
    }
    e.stopPropagation();
    togglePreview(media);
  };

  return (
    <Wrapper
      {...props}
      colors={colors}
      width={{
        xs: compact ? 210 : type === "relay" ? 260 : undefined,
        sm: compact ? 220 : type === "relay" ? 300 : undefined,
      }}
    >
      <Icon
        sx={{ fontSize: 35, fill: colors.accent, cursor: "default", color: "grey.600" }}
      />
      <Box
        onClick={handleClick}
        sx={{ cursor: canPreview ? "pointer" : "initial" }}
        flexGrow={1}
        mx={2}
        overflow={"hidden"}
        textAlign={"left"}
        textOverflow={"ellipsis"}
      >
        <FileName
          fileName={media.filename ?? "attachment"}
          length={compact ? 9 : 20}
          mobileLength={compact ? 9 : 16}
          sx={{ fontSize: 14, fontWeight: 500, mb: 1 }}
        />
        <Typography sx={{ fontSize: 12 }}>
          {getFileSizeInMB(media.size).toFixed(2)}MB
        </Typography>
      </Box>
      <DownloadButton
        handleDownload={() => handleDownload(media)}
        isDownloading={isLoading}
      />
    </Wrapper>
  );
};

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "config",
})<BoxProps & { colors: AttachmentConfig["colors"] }>(
  ({ theme: { spacing }, colors }) => ({
    display: "flex",
    alignItems: "center",
    padding: spacing(1, 2),
    backgroundColor: colors.main,
    borderRadius: 12,
    border: `2px solid ${colors.border}`,
    maxWidth: 300,
  })
);
