import { Box, Typography } from "@mui/material";
import { QuoteMessageIcon } from "shared/components";
import { colors } from "shared/theme";

type MessageCaptionProps = {
  isMine: boolean;
  userName?: string;
  isEdited?: boolean;
  isQuote?: boolean;
  isWebUser?: boolean;
  toChatBot?: boolean;
};

export const MessageCaption: React.VFC<MessageCaptionProps> = ({
  userName,
  isMine,
  isEdited,
  isQuote,
  isWebUser,
  toChatBot
}) => (
  <Typography
    fontSize={10}
    textAlign={isMine ? "right" : "left"}
  >
    {isQuote && (
      <QuoteMessageIcon
        data-testid="quote-message-icon"
        sx={{ fontSize: 16, verticalAlign: "bottom" }}
      />
    )}
    {isMine ? "You" : userName || "Deleted account"}
    {isWebUser && !isMine && (
      <>
        {" "}
        <Box
          component="span"
          sx={{
            color: colors.grey.dark,
          }}
        >
          from Public Chat
        </Box>
      </>
    )}
    {toChatBot && ( <>
        {" "}
        <Box
          component="span"
          sx={{
            color: colors.info.dark,
          }}
        >
          to Chatbot
        </Box>
      </>)}
    {isEdited && (
      <>
        {" "}
        <Box
          component="span"
          sx={{
            fontWeight: 700,
            color: colors.grey.dark,
          }}
        >
          edited
        </Box>
      </>
    )}
  </Typography>
);
