export type InviteCollaboratorResponse = {
  authorizationCode: string;
  boardId: string;
  link: string;
};

export type InviteCollaboratorRequest = {
  invitedCollaboratorName: string;
  generateLink: true;
};

export type ReinviteCollaboratorRequest = {
  authorizationCode: string;
  invitedCollaboratorName: string;
  generateLink: true;
};


export enum InvitationStatus {
  Accepted = "accepted",
  Pending = "pending",
}

export type Collaborator = {
  authorizationCode: string;
  invitationStatus: InvitationStatus;
  userId: string;
  name: string;
  avatarId: string;
  _avatarColor: string;
};

export type RemoveCollaboratorResponse = {
  status: 200;
  message: string;
};