import { QueryKey, useApi } from "../api";
import { UseBaseQueryOptions, useMutation, useQueryClient } from "react-query";
import { config } from "../config";
import { User, UserSettingsRequest } from "./types";

export const useUpdateUserSettings = (
  options: UseBaseQueryOptions<User> = {}
) => {
  const queryClient = useQueryClient();
  const { patch } = useApi();

  const { mutate, isLoading } = useMutation(
    patch<UserSettingsRequest, User>(config.endpoints.user.v1.settings)
  );

  const updateUser = (data: UserSettingsRequest) => {
    mutate(data, {
      ...options,
      onSuccess: (response) => {
        queryClient.invalidateQueries(QueryKey.User);
        options.onSuccess?.(response);
      },
    });
  };

  return {
    updateUser,
    isLoading,
  };
};
