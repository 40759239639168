import { IconButton, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { AppHeader } from "shared/components";
import { useDownloadMedia } from "shared/twilio";
import { useMediaToPreview, useToggleMediaPreview } from "../../context";
import { DownloadFile } from "./DownloadFile";

export const MediaPreviewHeader = () => {
  const media = useMediaToPreview();

  const togglePreview = useToggleMediaPreview();

  const { handleDownload, isLoading } = useDownloadMedia();

  return (
    <AppHeader>
      <Box flex={1} display="flex" justifyContent="center">
        <DownloadFile
          fileName={media?.filename || "attachment"}
          onClick={() => handleDownload(media)}
          isLoading={isLoading}
        />
      </Box>
      <IconButton color={"secondary"} onClick={() => togglePreview(null)}>
        <Close />
      </IconButton>
    </AppHeader>
  );
};
