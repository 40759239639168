import { useMutation } from "react-query";

import { useApi } from "shared/api";
import { config } from "shared/config";
import { useShowError } from "shared/notifications";
import { ReinviteCollaboratorRequest, InviteCollaboratorResponse } from "../";

export function useReinviteCollaborator() {
  const { patch } = useApi();

  const showError = useShowError();

  const { isLoading, mutateAsync, error, data } = useMutation(
    patch<ReinviteCollaboratorRequest, InviteCollaboratorResponse>(
      config.endpoints.collaborator.v1.reinvite
    )
  );

  const reinviteCollaborator = (name: string, authorizationCode: string) =>
    mutateAsync(
      { generateLink: true, invitedCollaboratorName: name, authorizationCode },
      {
        onError: () => showError("Invitation failed", "Please try again later"),
      }
    );

  return { reinviteCollaborator, isLoading, error, data };
}
