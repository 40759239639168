import { Message } from "@twilio/conversations";
import React from "react";

export enum MessageDialogType {
  Edit = "Edit",
  Delete = "Delete",
}

export type EditMessageContextState = {
  toggleDialog: (
    type: MessageDialogType,
    message: Message,
    isMine: boolean
  ) => void;
  editMessage: (body: string) => Promise<void>;
  deleteMessage: () => Promise<void>;
};

export type MessageMenuProps = {
  isMine: boolean;
  message: Message;
  isOwner: boolean;
  children?: React.ReactNode;
};

export type OpenMenuButtonProps = {
  id: string;
  visible: boolean;
  onClick: (e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => void;
  left?: boolean;
};

export type MessagePermissions = {
  none?: boolean;
  edit?: boolean;
  delete?: boolean;
  quote?: boolean;
};
