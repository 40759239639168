import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const RemoveIcon: React.FC<SvgIconProps> = (props) => {
  const fillProp = props.fill ?? "transparent";

  return (
    <SvgIcon
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      {...props}
      sx={{ fill: fillProp, ...props.sx }}
      aria-label="remove icon"
    >
      <rect width="48" height="48" rx="24" fillOpacity="0.05" />
      <path
        d="M16 32L32 16M16 16L32 32"
        stroke="currentColor"
        strokeOpacity="0.9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
