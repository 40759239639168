import React from "react";

type Dictionary = { [key: string]: any };

type FormState<T extends Dictionary> = {
  [key in keyof T]: InputState;
};

export type InputState = {
  name: string;
  value: string;
  error: boolean;
  validate: (val: string, state?: any) => boolean;
};

export type InputOption = Pick<InputState, "value" | "validate">;

export type FormOptions<T extends Dictionary> = {
  [key in keyof T]: InputOption;
};

export const defaultInputState = (
  name: string,
  validate?: (val: string, state?: any) => boolean
): InputState => ({
  name,
  value: "",
  error: false,
  validate: validate ? validate : () => true,
});

const validateForm = <T extends Dictionary>(state: FormState<T>) =>
  Object.values(state).every(
    ({ value, validate }: InputState) => value && validate(value, state)
  );

export function formHookFactory<T extends Dictionary>(options: FormOptions<T>) {
  const state = (Object.keys(options) as Array<keyof typeof options>).reduce(
    (acc, key) => ({
      ...acc,
      [key]: { ...options[key], error: false, name: key as string },
    }),
    {} as FormState<T>
  );

  return function useForm() {
    const [formState, setFormState] = React.useState<FormState<T>>(state);

    const [valid, setValid] = React.useState(false);

    React.useEffect(() => {
      setValid(validateForm(formState));
    }, [formState]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const key = e.target.name as keyof FormState<T>;
      setFormState((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          value: e.target.value,
          error: false,
        },
      }));
    };

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      const key = e.target.name as keyof FormState<T>;
      setFormState((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          error: !prev[key].validate(prev[key].value, prev),
        },
      }));
    };
    
    const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      const key = e.target.name as keyof FormState<T>;
      setFormState((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          error: false,
        },
      }));
    };

    return {
      ...formState,
      onBlur,
      onChange,
      onFocus,
      valid,
      resetForm: () => setFormState(state),
    };
  };
}
