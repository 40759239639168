import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { Box, Stack, Typography } from "@mui/material";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { Participant } from "shared/conversations";
import { Collaborator } from "shared/collaborators";
import {
  StatusBadge as Badge,
  Invitation,
  mapStatusToBadge,
  useAcceptInvitation,
  useInviteUser,
} from "shared/invitations";
import { useConversationOwner } from "shared/chat";
import {
  appendInvitationStatuses,
  ParticipantWithStatus,
} from "shared/invitations/utils";
import { LoadingIndicator } from "shared/components";
import { useUser, UserAvatar } from "shared/user";

type CollaboratorParticipantsListProps = {
  selectedParticipants: Record<string, Participant>;
  collaborators: Collaborator[];
  incomingInvitations: Invitation[];
  outgoingInvitations: Invitation[];
};

export const CollaboratorParticipantsList: React.FC<
  CollaboratorParticipantsListProps
> = ({
  selectedParticipants,
  collaborators,
  incomingInvitations,
  outgoingInvitations,
}) => {
  const {
    user: { id },
  } = useUser();

  const participantsWithStatuses = appendInvitationStatuses(
    Object.values(selectedParticipants),
    collaborators,
    incomingInvitations,
    outgoingInvitations,
    id
  );

  return (
    <AutoSizer disableWidth>
      {({ height }) => (
        <FixedSizeList
          width={"100%"}
          height={height}
          itemCount={participantsWithStatuses.length}
          itemSize={55}
          itemData={{
            participants: participantsWithStatuses,
            invitations: incomingInvitations,
          }}
        >
          {ListItem}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};

const ListItem = (
  props: ListChildComponentProps<{
    participants: ParticipantWithStatus[];
    invitations: Invitation[];
  }>
) => {
  const {
    data: { participants, invitations },
    index,
    style,
  } = props;
  const ownerId = useConversationOwner();

  const participant = participants[index];

  const isOwner = participant.userId === ownerId;

  const existingInvitation = invitations.find(
    (i) => i.userId === participant.userId
  );

  const { acceptInvitation, isLoading: acceptLoading } = useAcceptInvitation(
    existingInvitation?.invitationId || ""
  );

  const { inviteUser, isLoading: inviteLoading } = useInviteUser();

  const isLoading = acceptLoading || inviteLoading;

  const StatusBadge = mapStatusToBadge(participant.status);

  return (
    <Stack
      direction={"row"}
      spacing={3}
      overflow={"hidden"}
      alignItems={"center"}
      style={style}
      px={2}
    >
      <UserAvatar
        userName={participant.name}
        userId={participant.userId}
        avatarColor={participant._avatarColor}
        size={40}
      />
      <Typography sx={{ flex: 1 }} noWrap>
        {participant.name}
      </Typography>
      {isOwner && <Badge title="Owner" />}
      {isLoading ? (
        <Box width={61}>
          <LoadingIndicator />
        </Box>
      ) : (
        <StatusBadge
          onAccept={acceptInvitation}
          onInvite={() => inviteUser(participant.userId)}
        />
      )}
    </Stack>
  );
};
