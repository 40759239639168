import React from "react";
import { uniq } from "ramda";
import { useChatParticipants } from "shared/chat";
import { Participant } from "shared/conversations";
import { useUser } from "shared/user";
import { CalledUser } from "./model";
import { useCallUsers } from "./useCallUsers";
import { findCallableParticipants, isUserNameCalled } from "./utils";

export function useCalledUsers() {
  const { user } = useUser();
  const allPatrticipants = useChatParticipants();
  const { callUsers, isLoading } = useCallUsers();

  const [calledUsers, setCalledUsers] = React.useState<CalledUser[]>([]);
  const [lastCalledUserName, setLastCalledUserName] = React.useState("");
  const [searchedName, setSearchedName] = React.useState("");
  const [participantsToShow, setParticipantsToShow] = React.useState<
    Participant[]
  >([]);

  /**
   * Show participants that can be called based on search term
   */
  React.useEffect(() => {
    if (!searchedName) {
      return setParticipantsToShow([]);
    }

    setParticipantsToShow(
      findCallableParticipants({
        participants: allPatrticipants,
        searchTerm: searchedName,
        callingUser: user,
      })
    );
  }, [user, searchedName, allPatrticipants]);

  /**
   * Clean up
   */
  React.useEffect(() => {
    return () => {
      setSearchedName("");
      setLastCalledUserName("");
      setParticipantsToShow([]);
      setCalledUsers([]);
    };
  }, []);
  return {
    participantsToShow,

    setSearchedName,

    searchedName,

    addCalledUser: (user: CalledUser) => {
      setSearchedName("");
      setLastCalledUserName(user.name);
      setCalledUsers((prev) => uniq([...prev, user])); // avoid duplicates
    },

    clearCalledUsers: () => {
      setSearchedName("");
      setCalledUsers([]);
      setLastCalledUserName("");
    },

    /**
     * Sets searchedName if there is a posibility that user is trying to call someone
     * @param value slice of message from beginneing to the cursor position
     */
    onMessageChange(value: string) {
      const name = isUserNameCalled(value);

      /* Clear search value and last called user name if no name matches */
      if (!name) {
        searchedName && setSearchedName("");
        lastCalledUserName && setLastCalledUserName("");
        return;
      }

      /**
       * If we have two users 'John' and 'John Doe' and user just added 'John' to called users
       * and continues typing 'do' we don't want to show a propmpt with 'Jogn Doe' as a suggestion
       */
      if (!lastCalledUserName || !name.startsWith(lastCalledUserName)) {
        setSearchedName(name);
      }
    },

    /**
     * Deletes calledUser from calledUsers array if user name is found at the end of text
     * @param newMessage message after deletion
     * @param oldSliced message before deletion sliced to cursor position
     * @returns  Non negative integer indicating how many characters should be deleted extra from message when called user is found on the end
     */
    onMessageDelete(
      newMessage: string,
      oldSliced: string,
      deletedLength: number
    ) {
      let deleteExtra = 0;

      const userAtTheEnd = calledUsers.find((u) =>
        new RegExp(`@${u.name}$`).test(oldSliced)
      );

      if (userAtTheEnd && deletedLength < userAtTheEnd.name.length + 1) {
        deleteExtra = userAtTheEnd.name.length + 1 - deletedLength;
      }

      const filteredCalledUsers = calledUsers.filter((u) =>
        new RegExp(`@${u.name}`).test(newMessage)
      );

      if (lastCalledUserName) {
        filteredCalledUsers.find((u) => u.name === lastCalledUserName) ||
          setLastCalledUserName("");
      }

      if (filteredCalledUsers.length !== calledUsers.length) {
        setCalledUsers(filteredCalledUsers);
      }

      return deleteExtra;
    },

    /**
     * Re-checks message for called users, removes '@' signs, prepares attributes for Twilio message
     * @returns object with updated message and optional message attributes with called users
     */
    beforeMessageSend: (message: string) => {
      const foundUsers = calledUsers.filter((u) =>
        new RegExp(`(^|\\s)@${u.name}`).test(message)
      );

      const updatedMessage = foundUsers.reduce(
        (m, u) => m.replaceAll(`@${u.name}`, u.name),
        message
      );

      setSearchedName("");
      setCalledUsers([]);
      return {
        message: updatedMessage,
        attributes: foundUsers.length ? { calledUsers: foundUsers } : undefined,
      };
    },
    isLoading,
    callUsers,
    calledUsers,
  };
}
