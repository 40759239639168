import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { isImage } from "shared/utils";
import { getFileType, getAttachmentConfig } from "shared/chat";
import { IconButton, LoadingIndicator } from ".";
import { colors } from "shared/theme";

type AttachmentPreviewCardProps = {
  file: File;
  onClose: () => void;
  isLoading: boolean;
};

export const AttachmentPreviewCard: React.FC<AttachmentPreviewCardProps> = (
  props
) => {
  return (
    <Box
      borderRadius={2}
      sx={{
        backgroundColor: colors.grey.main,
        height: 65,
        width: 65,
        position: "relative",
        color: "black",
        marginRight: 1.5,
        flexShrink: 0,
      }}
    >
      {props.isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <IconButton
            onClick={props.onClose}
            label="remove attachment"
            icon={Close}
            color="secondary"
            size={16}
            showTooltip={false}
            sx={{
              position: "absolute",
              top: 4,
              right: 4,

            }}
          />
          {isImage(props.file.type) ? (
            <ImageAttachment {...props} />
          ) : (
            <BasicAttachment {...props} />
          )}
        </>
      )}
    </Box>
  );
};

const BasicAttachment: React.FC<AttachmentPreviewCardProps> = ({ file }) => {
  const fileType = getFileType(file.type);
  const { Icon } = getAttachmentConfig({ fileType });

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
      height={1}
    >
      <Icon
        sx={{
          fontSize: 45,
          cursor: "default",
          color: colors.markusBlue.light,
        }}
      />
      <Typography
        fontSize={10}
        variant={"caption"}
        fontWeight={500}
        noWrap
        width={53}
        color={colors.haleNavy.dark}
        lineHeight={1.4}
      >
        {file.name}
      </Typography>
    </Box>
  );
};

const ImageAttachment: React.FC<AttachmentPreviewCardProps> = ({ file }) => {
  const imageRef = React.useRef<HTMLImageElement>(null);

  React.useEffect(() => {
    if (!imageRef.current) {
      return;
    }

    imageRef.current.src = URL.createObjectURL(file);
  }, [file]);

  return <StyledImage ref={imageRef} alt={"image_preview"} />;
};

const StyledImage = styled("img")(() => ({
  height: "100%",
  width: "100%",
  objectFit: "cover",
  borderRadius: 8,
}));
