import { UseBaseQueryOptions, useQuery } from "react-query";
import { QueryKey } from "../api";
import { gmailConfig } from "./model";
import { useGoogleApi } from "../google-auth";
import {
  extractTextFromHtml,
  flattenParts,
  formatGmailBase64String,
  getGmailApiConfig,
} from "./utils";
import { GmailMessage } from "./types";
import { appendAvatarColor, decodeBase64ToUtf8, indexBy } from "../utils";

export const useGmailMessage = (
  messageId?: string,
  opts?: UseBaseQueryOptions<GmailMessage>
) => {
  const { get } = useGoogleApi();

  return useQuery<GmailMessage>(
    [QueryKey.GmailMessage, messageId],
    get(
      gmailConfig.endpoints.message
        .replace(/:userId/, "me")
        .replace(/:messageId/, messageId ?? ""),
      {
        ...getGmailApiConfig(),
        transformResponse,
      }
    ),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: Boolean((opts?.enabled ? opts.enabled : true) && messageId),
      ...opts,
    }
  );
};

const transformResponse = (res: GmailMessage) => {
  const parts = flattenParts(res.payload);
  const contentPart = parts.find(({ mimeType }) => mimeType === "text/html");
  const textPart = parts.find(({ mimeType }) => mimeType === "text/plain");
  const headersByName = indexBy(res.payload.headers, "name");
  const htmlContent = decodeBase64ToUtf8(
    formatGmailBase64String(contentPart?.body?.data || "")
  );
  const textContent =
    decodeBase64ToUtf8(formatGmailBase64String(textPart?.body?.data || "")) ||
    extractTextFromHtml(htmlContent);

  return {
    ...res,
    ...appendAvatarColor(res, (message) => message.id),
    _parts: parts,
    _attachments: parts.filter(({ body }) => Boolean(body.attachmentId)),
    _textContent: textContent,
    _htmlContent: htmlContent,
    _from: headersByName["From"].value,
    _subject: headersByName["Subject"].value,
  };
};
