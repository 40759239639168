import React from "react";

/**
 * Prevents from "Called SetState() on an Unmounted Component" React Errors.
 * Provides a wrapper for calling setState in async functions/promises. 
 * Makes sure state won't change after component unmounted
 * @example
 * // inside component
 * const [state, setState] = React.useState(false);
 * const useAsync = useSetStateAsync()
 * 
 * React.useEffect(() => {
    somePromise()
      .then(() => {
        // this will take effects only if component is mounted at this point
        useAsync(() => setState(true))
      })
  }, []);
 */
export function useSetStateAsync() {
  const mounted = React.useRef(true);
  
  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return React.useCallback((setState: () => void) => {
    if(mounted.current) setState();
  },[])
}