import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getConversationPath } from "shared/router";
import { useConversationsMetaData } from "shared/twilio";
import { useSortedConversations } from "./useSortedConversations";

export function useNavigateToDefaultConversation() {
  const { conversationId } = useParams();
  const conversations = useSortedConversations();
  const metadata = useConversationsMetaData();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (conversationId) {
      return;
    }

    // wait until metadata is loaded
    if (Object.keys(metadata).length === 0 || conversations.length === 0) {
      return;
    }

    navigate({
      pathname: getConversationPath(conversations[0].chatSid),
      search: window.location.search,
    });
  }, [conversationId, metadata, conversations]);
}
