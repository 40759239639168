import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const PdfFileIcon: React.FC<SvgIconProps> = (props) => {
  const fillProp = props.fill ?? "transparent";
  return (
    <SvgIcon
      width="29"
      height="29"
      viewBox="0 0 29 29"
      {...props}
      sx={{ fill: fillProp, ...props.sx }}
      aria-label="pdf file icon"
    >
      <circle cx="14.5" cy="14.5" r="14.5" />
      <path
        d="M12.355 14.6541C12.355 14.313 12.1181 14.1094 11.7001 14.1094C11.5296 14.1094 11.4141 14.1258 11.3535 14.1423V15.2376C11.4251 15.2539 11.5132 15.2594 11.6339 15.2594C12.0799 15.2594 12.355 15.0339 12.355 14.6541Z"
        fill="currentColor"
      />
      <path
        d="M14.9404 14.1172C14.7531 14.1172 14.6323 14.1337 14.5605 14.1502V16.577C14.6322 16.5937 14.7478 16.5937 14.8524 16.5937C15.6117 16.5991 16.107 16.181 16.107 15.295C16.1126 14.5242 15.6612 14.1172 14.9404 14.1172Z"
        fill="currentColor"
      />
      <path
        d="M19.8988 12.695H19.5946V11.2274C19.5946 11.2183 19.5932 11.209 19.5919 11.1998C19.5915 11.1415 19.5729 11.0843 19.533 11.0389L17.0907 8.24902C17.09 8.2483 17.0893 8.24805 17.0888 8.24727C17.0742 8.23101 17.0573 8.21749 17.0393 8.20578C17.034 8.20222 17.0286 8.19923 17.0231 8.19614C17.0075 8.18766 16.991 8.18062 16.9741 8.17554C16.9695 8.17425 16.9653 8.17236 16.9607 8.17117C16.9423 8.1668 16.9231 8.16406 16.9037 8.16406H10.9015C10.6274 8.16406 10.4047 8.38698 10.4047 8.66084V12.6949H10.1006C9.70855 12.6949 9.39062 13.0127 9.39062 13.4049V17.0971C9.39062 17.4891 9.70855 17.807 10.1006 17.807H10.4048V20.3344C10.4048 20.6083 10.6274 20.8312 10.9015 20.8312H19.0979C19.3717 20.8312 19.5946 20.6083 19.5946 20.3344V17.807H19.8988C20.2908 17.807 20.6088 17.4891 20.6088 17.0971V13.4051C20.6087 13.0129 20.2908 12.695 19.8988 12.695ZM10.9015 8.66093H16.6553V11.2025C16.6553 11.3397 16.7667 11.4508 16.9037 11.4508H19.0979V12.6952H10.9015V8.66093ZM16.9995 15.2709C16.9995 15.981 16.741 16.4706 16.3827 16.7734C15.9924 17.098 15.398 17.2521 14.6718 17.2521C14.2371 17.2521 13.929 17.2245 13.7197 17.1971V13.5539C14.0278 13.5045 14.4298 13.477 14.8535 13.477C15.5575 13.477 16.0144 13.6036 16.3723 13.8731C16.7573 14.1593 16.9995 14.616 16.9995 15.2709ZM10.522 17.2136V13.5539C10.7805 13.5101 11.1439 13.477 11.6557 13.477C12.173 13.477 12.5417 13.576 12.7893 13.7743C13.026 13.9613 13.1852 14.2695 13.1852 14.6327C13.1852 14.9961 13.0645 15.3042 12.8442 15.5132C12.558 15.7829 12.1343 15.9041 11.6389 15.9041C11.529 15.9041 11.4296 15.8985 11.3529 15.8874V17.2137H10.522V17.2136ZM19.0979 20.2H10.9015V17.807H19.0979V20.2H19.0979ZM19.8176 14.1924H18.392V15.0399H19.7239V15.7223H18.392V17.2137H17.5505V13.5045H19.8176V14.1924Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
