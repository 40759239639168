import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useChatContext } from "shared/chat";

export function useToggleMediaBin() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    toggleMediaPreview,
    toggleEmailPreview,
    mediaPreview,
    externalProviderMsgId,
  } = useChatContext();

  return React.useCallback(() => {
    const mediaBinOpen = pathname.endsWith("/media");
    const mediaBinHidden =
      Boolean(externalProviderMsgId || mediaPreview) && mediaBinOpen;

    // users can't see media bin, so expect it to open  
    if (mediaBinHidden) {
      // Close previews as they obscures the media bin
      toggleMediaPreview(null);
      toggleEmailPreview(undefined);
      return;
    }

    // users can see media bin, so expect it to close
    if (pathname.endsWith("/media")) {
      return navigate(pathname.replace(/\/media.*$/, ""));
    }

    // Close previews as they obscures the media bin
    toggleMediaPreview(null);
    toggleEmailPreview(undefined);

    return navigate("media");
  }, [
    pathname,
    navigate,
    toggleMediaPreview,
    toggleEmailPreview,
    mediaPreview,
    externalProviderMsgId,
  ]);
}
