import React from "react";
import { Box, styled } from "@mui/material";

export const TextSeparator: React.FC = ({ children }) => {
  return (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Line />
      <Box sx={{ mx: 4 }}>{children}</Box>
      <Line />
    </Box>
  );
};

const Line = styled(Box)(({ theme: { palette } }) => ({
  height: 1,
  flex: 1,
  backgroundColor: palette.common.black,
  opacity: 0.5,
}));
