import React from "react";
import { Button, ButtonProps, lighten, styled } from "@mui/material";
import { OutlookIcon } from "./icons";

export const OutlookButton: React.FC<ButtonProps> = ({
  children,
  ...props
}) => (
  <StyledButton size={"large"} startIcon={<OutlookIcon />} {...props}>
    {children}
  </StyledButton>
);

const StyledButton = styled(Button)(({ theme: { typography } }) => ({
  backgroundColor: lighten("#88BEEB", 0.4),
  color: "#5797C0",
  borderRadius: 12,
  minHeight: 45,
  fontSize: typography.subtitle2.fontSize,
  "&:hover": {
    backgroundColor: lighten("#88BEEB", 0.2),
  },
  "&:disabled": {
    opacity: 0.4,
  },
}));
