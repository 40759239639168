import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const DocFileIcon: React.FC<SvgIconProps> = (props) => {
  const fillProp = props.fill ?? "transparent";
  return (
    <SvgIcon
      width="29"
      height="29"
      viewBox="0 0 29 29"
      {...props}
      aria-label="doc file icon"
      sx={{ fill: fillProp, ...props.sx }}
    >
      <circle cx="14.5" cy="14.5" r="14.5" />
      <path
        d="M19.8989 12.1952H19.5948V10.7273C19.5948 10.7182 19.5933 10.7089 19.5921 10.6997C19.5916 10.6414 19.573 10.5842 19.5331 10.5388L17.0908 7.74901C17.0901 7.7483 17.0894 7.74804 17.0889 7.74728C17.0743 7.73102 17.0574 7.71748 17.0394 7.70579C17.0341 7.70223 17.0287 7.69925 17.0231 7.69613C17.0076 7.68767 16.9911 7.68063 16.9741 7.67552C16.9695 7.67425 16.9654 7.67243 16.9608 7.67118C16.9423 7.66679 16.9232 7.66406 16.9038 7.66406H10.9015C10.6274 7.66406 10.4048 7.88699 10.4048 8.16083V12.195H10.1007C9.70855 12.195 9.39062 12.5128 9.39062 12.905V16.5971C9.39062 16.989 9.70855 17.3071 10.1007 17.3071H10.4048V19.8346C10.4048 20.1085 10.6274 20.3313 10.9015 20.3313H19.098C19.3718 20.3313 19.5948 20.1085 19.5948 19.8346V17.3072H19.8989C20.2909 17.3072 20.6088 16.9892 20.6088 16.5972V12.9051C20.6089 12.5128 20.2909 12.1952 19.8989 12.1952ZM10.9015 8.16083H16.6554V10.7023C16.6554 10.8396 16.7667 10.9507 16.9038 10.9507H19.098V12.1951H10.9015V8.16083ZM16.8617 14.5493C16.8617 15.6785 16.1771 16.363 15.1704 16.363C14.1485 16.363 13.5507 15.5915 13.5507 14.6106C13.5507 13.5785 14.2098 12.807 15.2265 12.807C16.2841 12.807 16.8617 13.5988 16.8617 14.5493ZM10.1527 16.2915V12.9093C10.4388 12.8633 10.8117 12.8377 11.2053 12.8377C11.8592 12.8377 12.2832 12.9553 12.6154 13.2055C12.973 13.4713 13.1979 13.8954 13.1979 14.5033C13.1979 15.1624 12.9579 15.6171 12.6258 15.8981C12.263 16.1996 11.7111 16.3426 11.0366 16.3426C10.6328 16.3426 10.3468 16.317 10.1527 16.2915ZM19.098 19.7H10.9015V17.3072H19.098V19.7ZM19.1356 15.7143C19.3706 15.7143 19.6312 15.6629 19.7844 15.6019L19.9021 16.2098C19.7589 16.2816 19.4371 16.3579 19.0182 16.3579C17.8277 16.3579 17.2145 15.6171 17.2145 14.6361C17.2145 13.4612 18.0524 12.807 19.0946 12.807C19.4982 12.807 19.805 12.8887 19.9431 12.9604L19.7845 13.5785C19.6263 13.5121 19.4065 13.4507 19.1305 13.4507C18.5123 13.4507 18.032 13.8237 18.032 14.59C18.0318 15.2799 18.4407 15.7143 19.1356 15.7143Z"
        fill="currentColor"
      />
      <path
        d="M12.3693 14.531C12.3744 13.8155 11.9554 13.4375 11.2863 13.4375C11.1125 13.4375 10.9999 13.4528 10.9336 13.4682V15.7215C10.9999 15.7369 11.1073 15.7369 11.2045 15.7369C11.9095 15.7419 12.3693 15.3535 12.3693 14.531Z"
        fill="currentColor"
      />
      <path
        d="M14.373 14.5918C14.373 15.2665 14.6899 15.7415 15.211 15.7415C15.7373 15.7415 16.0387 15.2407 16.0387 14.5714C16.0387 13.953 15.7424 13.4219 15.2059 13.4219C14.6798 13.422 14.373 13.9227 14.373 14.5918Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
