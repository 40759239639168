import React from "react";
import {
  createContext,
  useContext,
  useContextSelector,
} from "use-context-selector";

import {
  Conversation,
  Message,
  MessageUpdateReason,
  Paginator,
} from "@twilio/conversations";
import { useIsTabActive, isPlainObject, useSetStateAsync } from "shared/utils";
import {
  useConversationsMetaData,
  useResetUnreadMessagesCount,
  useTwilioClient,
} from "./Twilio.context";
import { getEmailAttachmentMessages } from "..";
import { TwilioConversationContextState } from "../model";
import { useQueryClient } from "react-query";
import { invalidateMediaBin } from "shared/media-bin/shared";

const initialState: TwilioConversationContextState = {
  messages: [],
  twilioConversation: null,
  fetchMore: () => undefined,
  hasMore: false,
  updateParticipantName: () => Promise.resolve(),
  isLoading: false,
  setMessages: () => undefined,
};

const TwilioConversationContext = createContext(initialState);

const messagesPageSize = 50;

const isInitialMessage = (message: Message) =>
  (message.attributes as any).type === "initial-message";

const filterMessages = (messages: Message[]) =>
  messages
    .filter((message) => !isInitialMessage(message))
    .filter(
      ({ attributes }) => (attributes as any).MessageStateKey !== "removed"
    );

export const TwilioConversationProvider = ({
  sid,
  children,
}: {
  sid: string;
  children: React.ReactNode;
}) => {
  const twilioClient = useTwilioClient();
  const queryClient = useQueryClient();
  const resetUnreadMessagesCount = useResetUnreadMessagesCount();
  const metaData = useConversationsMetaData(sid);
  const isTabActive = useIsTabActive();
  const setStateAsync = useSetStateAsync();

  const [twilioConversation, setTwilioConversation] =
    React.useState<Conversation | null>(null);
  const [messages, setMessages] = React.useState<Message[]>([]);
  const [paginator, setPaginator] = React.useState<Paginator<Message>>();
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchMore = React.useCallback(() => {
    if (!twilioConversation || !paginator) {
      return;
    }

    paginator.prevPage().then((paginator) => {
      setStateAsync(() => setPaginator(paginator));
      setStateAsync(() =>
        setMessages((prev) => filterMessages(paginator.items).concat(prev))
      );
    });
  }, [paginator, twilioConversation]);

  const updateParticipantName = React.useCallback(
    async (id: string, name: string) => {
      if (!twilioConversation) {
        return;
      }
      const participant = await twilioConversation.getParticipantByIdentity(id);

      if (!participant) {
        return;
      }

      await participant.updateAttributes({
        ...(isPlainObject(participant.attributes)
          ? participant.attributes
          : {}),
        user_name: name,
      });
    },
    [twilioConversation]
  );

  React.useEffect(() => {
    if (!twilioClient) {
      return;
    }

    setIsLoading(true);

    const getTwilioConversation = async () => {
      const conversation = await twilioClient.getConversationBySid(sid);
      await conversation.setAllMessagesRead();
      setStateAsync(() => setTwilioConversation(conversation));
      setStateAsync(() => resetUnreadMessagesCount(sid));

      const paginator = await conversation.getMessages(messagesPageSize);
      setStateAsync(() => setPaginator(paginator));
      setStateAsync(() => setMessages(filterMessages(paginator.items)));
    };

    getTwilioConversation().finally(() => {
      setStateAsync(() => setIsLoading(false));
    });
  }, [twilioClient, sid]);

  React.useEffect(() => {
    (async () => {
      if (isTabActive && metaData && metaData.unreadMessagesCount > 0) {
        await twilioConversation?.setAllMessagesRead();
        resetUnreadMessagesCount(sid);
      }
    })();
  }, [isTabActive, sid, twilioConversation, metaData]);

  React.useEffect(() => {
    if (!twilioConversation) {
      return;
    }

    const handleMessageAdded = (message: Message) => {
      setMessages((prev) => [...prev, message]);
      invalidateMediaBin(queryClient, twilioConversation.sid);
    };

    const handleMessageRemoved = async (message: Message) => {
      setMessages((prev) => prev.filter((m) => m.sid !== message.sid));
      const attachments = getEmailAttachmentMessages(message, messages);

      await Promise.all(attachments.map((a) => a.remove()));

      invalidateMediaBin(queryClient, twilioConversation.sid);
    };

    const handleMessageUpdated = ({
      message,
      updateReasons,
    }: {
      message: Message;
      updateReasons: MessageUpdateReason[];
    }) => {
      if (!messages.find((m) => m.sid === message.sid)) {
        return;
      }
      if (
        updateReasons.includes("attributes") ||
        updateReasons.includes("body")
      ) {
        // This is bad for performance reasons see:
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/setPrototypeOf
        // However, message instance provided by Twilio as parameter to this function is referentially equal to the one in the state.
        const messageCopy = Object.assign({}, message);

        Object.setPrototypeOf(messageCopy, Message.prototype);

        setMessages((prev) =>
          prev.map((old) => (old.sid === message.sid ? messageCopy : old))
        );
      }
    };

    twilioConversation.on("messageAdded", handleMessageAdded);
    twilioConversation.on("messageRemoved", handleMessageRemoved);
    twilioConversation.on("messageUpdated", handleMessageUpdated);

    return () => {
      twilioConversation?.removeListener("messageAdded", handleMessageAdded);
      twilioConversation?.removeListener(
        "messageRemoved",
        handleMessageRemoved
      );
      twilioConversation?.removeListener(
        "messageUpdated",
        handleMessageUpdated
      );
    };
  }, [twilioConversation, messages]);

  return (
    <TwilioConversationContext.Provider
      value={{
        messages,
        twilioConversation,
        fetchMore,
        hasMore: !!paginator?.hasPrevPage,
        updateParticipantName,
        isLoading,
        setMessages,
      }}
    >
      {children}
    </TwilioConversationContext.Provider>
  );
};

export const useTwilioConversationSelector = <
  T extends keyof TwilioConversationContextState
>(
  selector: T
) =>
  useContextSelector(TwilioConversationContext, (context) => context[selector]);

export const useTwilioConversationContext = () =>
  useContext(TwilioConversationContext);
