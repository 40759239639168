import { Typography } from "@mui/material";

export const TextQuotePreview = ({ text }: { text: string }) => {
  const messageText = (text ?? "").slice(0, 100);

  return (
    <Typography variant="caption" noWrap>
      {messageText}
    </Typography>
  );
};
