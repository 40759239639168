import React from "react";
import { useSetStateAsync } from ".";

export enum DataActionType {
  ERROR = "ERROR",
  LOADING = "LOADING",
  DATA = "DATA",
  RESET = "RESET",
}

type DataState<T> = {
  isError: boolean;
  isLoading: boolean;
  data: T | null;
};

type DataAction<T> = {
  type: DataActionType;
  payload?: T | null;
};

type DataReducerType<T> = (
  state: DataState<T>,
  action: DataAction<T>
) => DataState<T>;

const initialState = {
  isLoading: false,
  isError: false,
  data: null,
};

function dataReducer<T>(
  state: DataState<T>,
  action: DataAction<T>
): DataState<T> {
  const { type, payload } = action;
  
  switch (type) {
    case DataActionType.DATA:
      return { data: payload ?? null, isError: false, isLoading: false };

    case DataActionType.ERROR:
      return { ...state, isError: true, isLoading: false };

    case DataActionType.LOADING:
      return { ...state, isError: false, isLoading: true };

    case DataActionType.RESET:
      return { data: null, isError: false, isLoading: false };

    default:
      return state;
  }
}

export function useDataReducer<T>(init?: DataState<T>) {
  const setStateAsync = useSetStateAsync();
  const [data, _dispatch] = React.useReducer<DataReducerType<T>>(
    dataReducer,
    init ? init : { ...initialState }
  );

  const dispatch = React.useCallback(
    (action: DataAction<T>) => setStateAsync(() => _dispatch(action)),
    []
  );

  React.useEffect(() => {
    return () => dispatch({ type: DataActionType.RESET });
  }, []);

  return [data, dispatch] as const;
}
