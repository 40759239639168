import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const DisconnectIcon: React.FC<SvgIconProps> = (props) => {
  const fillProp = props.fill ?? "transparent";
  return (
    <SvgIcon
      width="48"
      height="48"
      viewBox="0 0 48 48"
      {...props}
      sx={{ fill: fillProp, ...props.sx }}
      aria-label="disconnect icon"
    >
      <rect width="48" height="48" rx="24" />
      <path
        d="M13.582 18.2812H16.6113V19.8534H13.582V18.2812Z"
        fill="currentColor"
      />
      <path
        d="M18.6074 13.2559H20.1796V16.2851H18.6074V13.2559Z"
        fill="currentColor"
      />
      <path
        d="M20.7749 32.5989C19.2909 34.0829 16.8788 34.0804 15.3972 32.5989C13.9132 31.1148 13.9107 28.7076 15.3972 27.2211L19.6998 22.9185L18.086 21.3047L13.7834 25.6098C11.4031 27.9901 11.408 31.8373 13.7834 34.2127C16.1539 36.5832 20.0109 36.5881 22.3863 34.2127L26.6865 29.9125L25.0727 28.2986L20.7749 32.5989Z"
        fill="currentColor"
      />
      <path
        d="M15.8598 14.418L18.005 16.5607L16.8933 17.6725L14.748 15.5298L15.8598 14.418Z"
        fill="currentColor"
      />
      <path
        d="M32.1408 32.9245L29.998 30.7817L31.1098 29.6699L33.2526 31.8127L32.1408 32.9245Z"
        fill="currentColor"
      />
      <path
        d="M31.3867 27.4902H34.416V29.0624H31.3867V27.4902Z"
        fill="currentColor"
      />
      <path
        d="M27.8184 31.0586H29.3905V34.0878H27.8184V31.0586Z"
        fill="currentColor"
      />
      <path
        d="M34.2161 13.7797C31.8456 11.4092 27.9886 11.4043 25.6132 13.7797L21.3105 18.0799L22.9243 19.6937L27.227 15.3911C28.711 13.9071 31.1232 13.9095 32.6047 15.3911C34.0887 16.8751 34.0887 19.2823 32.6047 20.7688L28.3021 25.0714L29.9159 26.6852L34.2161 22.385C36.5939 20.0047 36.5915 16.1551 34.2161 13.7797Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
