import { useMixpanel } from "../context/Analytics.context";

export const useResetAnalyticsUser = () => {
  const mixpanel = useMixpanel();

  return () => {
    try {
      mixpanel.reset();
    } catch (error) {
      console.error("Mixpanel error:", error);
    }
  };
};
