import React from "react";
import { Message } from "@twilio/conversations";
import {
  createContext,
  useContextSelector,
  useContext,
} from "use-context-selector";

import { useTwilioConversationSelector } from "shared/twilio";
import { EditMessagePopUp } from "./EditMessagePopUp";
import { EditMessageContextState, MessageDialogType } from "./model";
import { useDeleteTwilioMessage } from "./useDeleteTwilioMessage.hook";

const initialState: EditMessageContextState = {
  toggleDialog: () => {},
  editMessage: async () => {},
  deleteMessage: async () => {},
};

const EditMessageContext = createContext(initialState);

export type EditMessageProviderProps = {
  children: React.ReactNode;
};

export const EditMessageProvider = ({ children }: EditMessageProviderProps) => {
  const setMessages = useTwilioConversationSelector("setMessages");

  const [dialogType, setDialogType] = React.useState<MessageDialogType>();

  const [showDialog, setShowDialog] = React.useState(false);

  const [message, setMessage] = React.useState<Message | null>(null);

  const [isMine, setIsMine] = React.useState(false);

  const { deleteMessage: apiDeleteMessage } = useDeleteTwilioMessage(message);

  const context: EditMessageContextState = React.useMemo(
    () => ({
      toggleDialog: (
        type: MessageDialogType,
        message: Message,
        isMine: boolean
      ) => {
        setDialogType(type);
        setShowDialog((prev) => !prev);
        setMessage((prev) => (prev ? null : message));
        setIsMine(isMine);
      },
      editMessage: async (body: string) => {
        if (!message) return;
        await message.updateBody(body);
      },
      deleteMessage: async () => {
        if (!message) return;

        if (isMine) {
          await message.remove();
          return;
        }

        await apiDeleteMessage();
      },
    }),
    [
      setShowDialog,
      setDialogType,
      setMessage,
      message,
      isMine,
      apiDeleteMessage,
    ]
  );

  const onClose = (idToDelete?: string) => {
    if (idToDelete) {
      setMessages((prev) => prev.filter((m) => m.sid !== idToDelete));
    }

    setMessage(null);
    setDialogType(undefined);
    setShowDialog(false);
  };

  return (
    <EditMessageContext.Provider value={context}>
      <EditMessagePopUp
        type={dialogType}
        open={showDialog}
        message={message}
        onClose={onClose}
      />
      {children}
    </EditMessageContext.Provider>
  );
};

export const useToggleEditMessagePopUp = () =>
  useContextSelector(EditMessageContext, ({ toggleDialog }) => toggleDialog);

export const useEditMessage = () =>
  useContextSelector(EditMessageContext, ({ editMessage }) => editMessage);

export const useDeleteMessage = () =>
  useContextSelector(EditMessageContext, ({ deleteMessage }) => deleteMessage);

export const useEditMessageContext = () => useContext(EditMessageContext);
