import React from "react";
import { LinkInfo, LinkType } from "./types";
import { findLastLink, getLinktype, getYouTubeVideoId } from "./utils";

export function useParseLink(body: string): LinkInfo | null {
  return React.useMemo(() => {
    const link = findLastLink(body);

    if (!link) {
      return null;
    }

    const type = getLinktype(link);

    if (type === LinkType.YouTube) {
      const videoId = getYouTubeVideoId(link);

      if (videoId) {
        return {
          type,
          link,
          videoId,
        };
      }
    }

    return {
      type: LinkType.Text,
      link,
    };
  }, [body]);
}
