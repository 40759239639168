import { UseBaseQueryOptions, useMutation, useQueryClient } from "react-query";
import { PublicInvitation, UpdatePublicInvitationRequest } from "./types";
import { QueryKey, useApi } from "../api";
import { config } from "../config";

export const useUpdatePublicInvitation = (
  publicInvitationId: string,
  options: UseBaseQueryOptions<PublicInvitation> = {}
) => {
  const queryClient = useQueryClient();
  const { patch } = useApi();
  const { mutate, isLoading } = useMutation(
    patch<UpdatePublicInvitationRequest, PublicInvitation>(
      config.endpoints.publicInvitations.v1.mainWithId.replace(
        /:publicInvitationId/,
        publicInvitationId
      )
    )
  );

  const updatePublicInvitation = (data: UpdatePublicInvitationRequest) => {
    mutate(data, {
      ...options,
      onSuccess: (response) => {
        queryClient.invalidateQueries([QueryKey.PublicInvitation]);
        options.onSuccess?.(response);
      },
    });
  };

  return {
    updatePublicInvitation,
    isLoading,
  };
};
