import React from "react";
import { Typography } from "@mui/material";
import { colors } from "shared/theme";

export const PageHeader = (props: { title: string }) => {
  return (
    <Typography
      variant={"h3"}
      sx={{
        fontWeight: 600,
        fontSize: 24,
        paddingLeft: { xs: 2, sm: 0 },
        lineHeight: "32px",
        color: colors.haleNavy.dark,
      }}
    >
      {props.title}
    </Typography>
  );
};
