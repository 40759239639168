import { unrefTimeout } from "./unrefTimeout";

export const throttle = <T extends (...args: any[]) => void>(
  fn: T,
  wait: number = 300
): T => {
  let inThrottle: boolean, lastFn: NodeJS.Timeout, lastTime: number;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    const context = this;

    if (!inThrottle) {
      fn.apply(context, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(() => {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));

      unrefTimeout(lastFn);
    }
  } as T;
};
