import { Box, Skeleton, Stack, Typography } from "@mui/material";
import {
  ImageContainer,
  LinkDescription,
  LinkTitle,
  LinkWrapper,
  StyledLinkContainer,
} from "./styles";
import { LinkPreviewData, useLinkPreview } from "./useLinkPreview.hook";
import { QueryDataWrapper } from "shared/api";

export type TextLinkPreviewProps = {
  link: string;
};

export const TextLinkPreview = ({ link }: TextLinkPreviewProps) => {
  const data = useLinkPreview(link);
  return (
    <QueryDataWrapper
      result={data}
      loadingView={<LinkPreviewLoader />}
      errorView={<></>}
    >
      {({ data }) => <LinkPreview data={data.data} />}
    </QueryDataWrapper>
  );
};

const LinkPreview = ({
  data,
}: {
  data: LinkPreviewData | null;
}) => {
  if (!data) {
    return null;
  }

  const { title, favicons, url, description, images } = data;

  const domain: string | undefined =
    (url.match(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/\n]+)/i) ||
      [])[1] || url;

  const favicon = favicons ? favicons[0] : null;
  const image = images ? images[images.length - 1] : null;

  return (
    <LinkWrapper>
      <Stack spacing={1}>
        <Box display="flex" alignItems="center">
          <Box mr={2} display="flex" alignItems="center">
            {favicon && <img width={25} src={favicon} alt={`${domain} icon`} />}
          </Box>
          <LinkTitle variant="h3" noWrap>
            {domain}
          </LinkTitle>
        </Box>
        <StyledLinkContainer>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        </StyledLinkContainer>
        {description && <LinkDescription>{description}</LinkDescription>}
        {image && (
          <ImageContainer>
            <img src={image} alt={`${domain}`} />
          </ImageContainer>
        )}
      </Stack>
    </LinkWrapper>
  );
};

export const LinkPreviewLoader = () => {
  return (
    <LinkWrapper>
      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />
    </LinkWrapper>
  );
};