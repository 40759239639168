export enum ErrorPagesEnum {
  NotFound = "not-found",
  Request = "request",
  Logout = "logout",
  PublicChatNotFound = "public",
}

export const ErrorPageMessages = {
  [ErrorPagesEnum.NotFound]: {
    title: "404 Not Found",
    message: "The page you are looking for does not exist.",
  },
  [ErrorPagesEnum.Logout]: {
    title: "An error occurred",
    message: "You've been logged out. Please log in again.",
  },
  [ErrorPagesEnum.PublicChatNotFound]: {
    title: "Discussion is currently unavailable.",
    message: "The discussion you are looking for doesn't exist.",
  },
  [ErrorPagesEnum.Request]: {
    title: "Something went wrong.",
    message: "We couldn't process your request. Try again later.",
  },
};
