import {
  MAX_FILE_SIZE_IN_MB,
  MAX_IMAGE_SIZE_IN_MB,
  MAX_VIDEO_SIZE_IN_MB,
} from "shared/constants";
import { isImage } from "./isImage";
import { isVideo } from "./isVideo";

export const validateFileSize = (file: File) => {
  if (isImage(file.type)) {
    return file.size <= MAX_IMAGE_SIZE_IN_MB * 1024 * 1024;
  }

  if (isVideo(file.type)) {
    return file.size <= MAX_VIDEO_SIZE_IN_MB * 1024 * 1024;
  }

  return file.size <= MAX_FILE_SIZE_IN_MB * 1024 * 1024;
};
