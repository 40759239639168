import {
  isImage,
  isPdf,
  isSheet,
  isDoc,
  isVideo,
} from "shared/utils";
import { MediaPreviewType } from "./model";

export function getPreviewType(fileType: string | null | undefined) {
  if (!fileType) {
    return MediaPreviewType.unknown;
  }

  switch (true) {
    case isImage(fileType):
      return MediaPreviewType.image;
    case isPdf(fileType):
      return MediaPreviewType.pdf;
    case isSheet(fileType):
      return MediaPreviewType.sheet;
    case isDoc(fileType):
      return MediaPreviewType.doc;
    case isVideo(fileType):
      return MediaPreviewType.video;
    default:
      return MediaPreviewType.unknown;
  }
}
