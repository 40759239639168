import {
  LinkMediaResponse,
  MediaBinViewProps,
  MediaLoader,
  MediaType,
  MediaViewType,
  useMediaList,
} from "../shared";
import { MediaBinLinkItem } from "./MediaBinLinkItem";

export const LinksBin = ({ chatSid }: MediaBinViewProps) => {
  const { data, length, fetchNextPage, hasNextPage, isLoading } =
    useMediaList<LinkMediaResponse>(chatSid, MediaType.link);

  return (
    <MediaLoader
      hasMore={hasNextPage}
      type={MediaViewType.links}
      loadMore={fetchNextPage}
      dataLength={length}
      isLoading={isLoading}
    >
      <></>
      <div data-testid="test">
        {data.map((item) => (
          <MediaBinLinkItem {...item} key={item.id} />
        ))}
      </div>
    </MediaLoader>
  );
};
