import { AddressSwitch, PublicIcon } from "shared/components";
import { AnalyticsEvent, useTrackEvent } from "shared/analytics";
import { useTogglePublicStatus } from "./useTogglePublicStatus";

type Props = {
  chatSid: string;
  publicChatUrl: string | null;
};
export const PublicStatusSwitch = ({ publicChatUrl, chatSid }: Props) => {
  const { togglePublicStatus, isLoading } = useTogglePublicStatus(chatSid);

  const trackShare = useTrackEvent(AnalyticsEvent.public_chat_copy);

  return (
    <AddressSwitch
      address={publicChatUrl}
      onChange={togglePublicStatus}
      isLoading={isLoading}
      onCopy={trackShare}
      icon={<PublicIcon sx={{ fontSize: 16, color: "secondary.main" }} />}
      title={"Set discussion as public"}
      text={
        "Public discussion allows users to join the discussion via a web browser"
      }
      copyText="Public link copied to clipboard"
    />
  );
};
