import React from "react";
import { Box } from "@mui/material";
import { Input, Popup } from "shared/components";
import { useResetPassword } from "shared/auth";

type ForgotPasswordPopupProps = {
  open: boolean;
  onClose: () => void;
};

export const ForgotPasswordPopUp: React.VFC<ForgotPasswordPopupProps> = ({
  open,
  onClose,
}) => {
  const [emailValue, setEmailValue] = React.useState("");
  const { isLoading, resetPassword } = useResetPassword();

  const handleClose = () => {
    setEmailValue("");
    onClose();
  };

  const handlePasswordReset = () => {
    resetPassword(emailValue);
    setEmailValue("");
  };

  return (
    <Popup
      open={open}
      onClose={handleClose}
      id="forgot-password"
      title="Forgot your password?"
      onClick={handlePasswordReset}
      acceptText="Reset password"
      content={
        <>
          <Box>
            If the address you type matches one of our accounts, we will send
            you an email there with instructions to reset your password. It
            might end up in your spam folder, so please check there as well.
          </Box>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handlePasswordReset();
            }}
          >
            <Box width={1} mt={7}>
              <Input
                // sx={{ maxWidth: 400 }}
                disabled={isLoading}
                type="email"
                label="Email"
                labelFixed={true}
                placeholder="Enter your e-mail"
                value={emailValue}
                onChange={(e) => setEmailValue(e.target.value)}
              />
            </Box>
          </form>
        </>
      }
      disabled={isLoading}
    />
  );
};