import { defaultTo, intersection, keys, pick } from "ramda";
import { Collaborator } from "shared/collaborators";
import { ConversationMetaData } from "shared/twilio";
import { Conversation as BaseConversation, Participant } from "./types";
type Conversation =
  | BaseConversation
  | (BaseConversation & { metadata: Partial<ConversationMetaData> });

/**
 * Get the date of the last message in the conversation
 * @description conversation.lastUpdateDate changes only on refetch, so we would prefer to use data from conversation.metadata.updateDate as it is updated in real time. However there can be a mismatch between conversations in DB and conversations in Twilio, which results in no metadata for some conversations. In this case we fallback to conversation.lastUpdateDate
 */
export function getConversationUpdateDate(c: Conversation, updateDate?: Date | null) {
  if (updateDate && updateDate.getTime() !== new Date(0).getTime()) {
    return updateDate;
  }
  return new Date(c.lastUpdateDate);
}

export function areMetaDataSame(
  prev?: ConversationMetaData,
  next?: ConversationMetaData
) {
  if (!prev && !next) {
    return true;
  }

  if (!prev || !next) {
    return false;
  }

  const defaultTo0Date = defaultTo(new Date(0));

  return (
    prev.lastMessage === next.lastMessage &&
    prev.unreadMessagesCount === next.unreadMessagesCount &&
    defaultTo0Date(prev.updateDate).getTime() ===
      defaultTo0Date(next.updateDate).getTime()
  );
}

export const participantToAvatar = (p: Collaborator | Participant) => ({
  userId: p.userId,
  userName: p.name,
  avatarColor: p._avatarColor,
});
