import { useParams } from "react-router-dom";

export const useGetMessageListPath = ({
  listPath,
  listItemPath,
}: {
  listPath: string;
  listItemPath: string;
}) => {
  const { messageId: messageIdParam } = useParams();

  return (messageId: string) =>
    messageId === messageIdParam
      ? listPath
      : listItemPath.replace(/:messageId/, messageId);
};
