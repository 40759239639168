import { Media } from "@twilio/conversations";
import React from "react";
import { useGetMediaAsObjectUrl } from "shared/twilio";
import { useSizeObserver } from "shared/utils";

export type PdfProps = {
  onLoadSuccess: ({ numPages }: { numPages: number }) => void;
  onLoadError: () => void;
  file: string;
  numPages: number;
  width: number;
};

export function usePreviewPdf(media: Media) {
  const file = useGetMediaAsObjectUrl(media);
  
  const [numPages, setNumPages] = React.useState(0);
  
  const [error, setError] = React.useState(false);
  
  const containerRef = React.useRef<HTMLElement>(null);

  const { size, isError: resizeError } = useSizeObserver(containerRef);

  React.useEffect(() => {
    return () => {
      setError(false);
      setNumPages(0);
    };
  }, []);

  return {
    containerProps: {
      ref: containerRef,
    },
    pdfProps: {
      onLoadSuccess: ({ numPages }: { numPages: number }) =>
        setNumPages(numPages),
      onLoadError: () => setError(true),
      file,
      numPages,
      width: size.width,
    },
    error: resizeError || error,
  };
}
