import React from "react";
import { Stack } from "@mui/material";
import MoreVert from "@mui/icons-material/MoreVert";
import GradingIcon from "@mui/icons-material/Grading";

import { GmailMessage } from "shared/gmail";
import { IconButton, ProButton } from "shared/components";
import { GmailMailMenu } from "./GmailMailMenu";
import {
  SummarizeEmailDialog,
  useIsAIAuthorized,
  useSummarizeEmail,
} from "shared/ai";
import { GmailMessagePreviewHeaderContent } from "./GmailMessagePreviewHeaderContent";

type InboxMessagePreviewHeaderProps = {
  message: GmailMessage;
};

export const GmailMessagePreviewHeader: React.FC<
  InboxMessagePreviewHeaderProps
> = ({ message }) => {
  const isAiPermitted = useIsAIAuthorized();
  const { isLoading, summarizeEmail, emailSummary, setEmailSummary } =
    useSummarizeEmail(message._textContent, message.id);
  const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);

  return (
    <>
      <GmailMessagePreviewHeaderContent
        from={message._from}
        subject={message._subject}
        avatarColor={message._avatarColor}
        action={
          <Stack direction={"row"} spacing={2} pl={1}>
            {isAiPermitted && (
              <ProButton
                icon={GradingIcon}
                onClick={() => summarizeEmail()}
                size={42}
                label="review the email"
              />
            )}
            <IconButton
              icon={MoreVert}
              onClick={(e) => setAnchor(e.currentTarget)}
            />
          </Stack>
        }
      />

      <GmailMailMenu
        message={message}
        anchor={anchor}
        onClose={() => setAnchor(null)}
      />
      <SummarizeEmailDialog
        isLoading={isLoading}
        onClose={() => setEmailSummary(undefined)}
        open={isLoading || !!emailSummary}
        from={message._from}
        subject={message._subject}
        avatarColor={message._avatarColor}
        summary={emailSummary}
      />
    </>
  );
};
