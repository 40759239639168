import React from "react";
import { OutgoingInvitationItem } from "shared/invitations";
import { CollaboratorItem } from "./CollaboratorItem";
import { Contact, isCollaborator, isInvitation } from "./model";

type ContactsListItemProps = {
  contact: Contact;
};

export const ContactsListItem: React.FC<ContactsListItemProps> = ({
  contact,
}) => {
  if (isInvitation(contact)) {
    return <OutgoingInvitationItem outgoingInvitation={contact} />;
  }

  if (isCollaborator(contact)) {
    return <CollaboratorItem collaborator={contact} />;
  }

  return <></>;
};
