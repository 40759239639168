import { Box, Stack } from "@mui/material";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import AutoSizer from "react-virtualized-auto-sizer";
import { LoadingIndicator } from "shared/components";
import { MediaViewType } from "../model";
import { NoMediaFallback } from "./NoMediaFallback";

type MediaLoaderProps = {
  hasMore: boolean;
  dataLength: number;
  loadMore: () => Promise<any>;
  type: MediaViewType;
  children: React.ReactNode[];
  isLoading: boolean;
};

export const MediaLoader = ({
  hasMore,
  dataLength,
  loadMore,
  type,
  isLoading,
  children,
}: MediaLoaderProps) => {
  const scrollableId = `media_loader_${type}`;
  return (
    <AutoSizer disableWidth>
      {({ height }) => (
        <Box
          id={scrollableId}
          height={height}
          width={1}
          overflow={"auto"}
          display={"flex"}
          flexDirection={"column"}
        >
          {isLoading ? (
            <LoadingIndicator />
          ) : dataLength ? (
            <InfiniteScroll
              dataLength={dataLength}
              hasMore={hasMore}
              next={loadMore}
              loader={
                <Box height={50} width={1}>
                  <LoadingIndicator />
                </Box>
              }
              scrollableTarget={scrollableId}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Stack pt={2} spacing={3}>
                {children}
              </Stack>
            </InfiniteScroll>
          ) : (
            <NoMediaFallback />
          )}
        </Box>
      )}
    </AutoSizer>
  );
};
