import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const KeyIcon: React.FC<SvgIconProps> = (props) => {
  const fillProp = props.fill ?? "transparent";

  return (
    <SvgIcon
      {...props}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="help icon"
      sx={{ fill: fillProp, ...props.sx }}
    >
      <rect width="48" height="48" rx="24" />
      <path
        d="M21.9374 22.1328V17.7383V13.3438C21.9374 13.1323 22.0147 12.9492 22.1694 12.7945C22.3241 12.6398 22.5072 12.5625 22.7187 12.5625H27.4062C27.6343 12.5625 27.8215 12.6357 27.9679 12.782C28.1142 12.9284 28.1874 13.1156 28.1874 13.3438C28.1874 13.5719 28.1142 13.7591 27.9679 13.9055C27.8215 14.0518 27.6343 14.125 27.4062 14.125H23.4999V17.25H27.4062C27.6343 17.25 27.8215 17.3232 27.9679 17.4695C28.1142 17.6159 28.1874 17.8031 28.1874 18.0312C28.1874 18.2594 28.1142 18.4466 27.9679 18.593C27.8215 18.7393 27.6343 18.8125 27.4062 18.8125H23.4999V21.9375C25.1926 21.9703 26.6168 22.5359 27.7726 23.6344C28.9283 24.7328 29.5793 26.1203 29.7257 27.7969C29.7908 29.4896 29.3147 30.9503 28.2976 32.1789C27.2804 33.4076 25.9416 34.144 24.2812 34.3883C22.5884 34.5674 21.0991 34.1891 19.8132 33.2531C18.5272 32.3172 17.7054 31.0193 17.3476 29.3594C17.071 27.699 17.3598 26.1935 18.214 24.843C19.0681 23.4924 20.3093 22.5891 21.9374 22.1328ZM23.4999 32.875C24.8348 32.8422 25.9416 32.3865 26.8202 31.5078C27.6989 30.6292 28.1546 29.5224 28.1874 28.1875C28.1546 26.8526 27.6989 25.7458 26.8202 24.8672C25.9416 23.9885 24.8348 23.5328 23.4999 23.5C22.165 23.5328 21.0582 23.9885 20.1796 24.8672C19.3009 25.7458 18.8452 26.8526 18.8124 28.1875C18.8452 29.5224 19.3009 30.6292 20.1796 31.5078C21.0582 32.3865 22.165 32.8422 23.4999 32.875Z"
        fill="currentColor"
        fillOpacity="0.96"
      />
    </SvgIcon>
  );
};
