import React from "react";
import { Stack } from "@mui/material";
import {
  IncomingInvitation,
  useRejectInvitation,
  useAcceptInvitation,
} from "shared/invitations";
import { LoadingIndicator } from "shared/components";
import { UserAvatar } from "shared/user";
import {
  AcceptButton,
  InvitationData,
  InvitationName,
  RejectButton,
  StyledListItem,
} from "./styles";

type InvitationProps = {
  invitation: IncomingInvitation;
};
export const Invitation: React.VFC<InvitationProps> = ({
  invitation: { userName, userId, _avatarColor, invitationId },
}) => {
  const { isLoading: rejectLoading, rejectInvitation } =
    useRejectInvitation(invitationId);

  const { isLoading: acceptLoading, acceptInvitation } =
    useAcceptInvitation(invitationId);

  return (
    <StyledListItem>
      <InvitationData>
        <UserAvatar
          userName={userName}
          userId={userId}
          avatarColor={_avatarColor}
          size={40}
        />
        <InvitationName noWrap>{userName}</InvitationName>
      </InvitationData>
      <Stack direction={"row"} spacing={2}>
        <AcceptButton
          disabled={acceptLoading}
          onClick={() => acceptInvitation()}
        >
          {!acceptLoading ? (
            "Accept"
          ) : (
            <LoadingIndicator size={16} color="white" />
          )}
        </AcceptButton>
        <RejectButton
          disabled={rejectLoading}
          onClick={() => rejectInvitation()}
        >
          {!rejectLoading ? (
            "Reject"
          ) : (
            <LoadingIndicator size={16} color="#FD2C5E" />
          )}
        </RejectButton>
      </Stack>
    </StyledListItem>
  );
};
