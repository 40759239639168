import {
  Box,
  Button,
  styled,
  Typography,
  alpha,
  TypographyProps,
  List,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { palette } from "shared/theme";

export const activeColor = palette.primary.light;

export const setActiveColor = ({ isActive }: { isActive: boolean }) =>
  isActive
    ? {
        backgroundColor: alpha(activeColor, 0.5),
      }
    : {};

export const StyledLink = styled(NavLink)({
  textDecoration: "none",
  color: "inherit",
  display: "block",
  "&:hover": {
    backgroundColor: alpha(activeColor, 0.5),
  },
});

export const StyledButtonLink = styled("button")(({ theme: { palette } }) => ({
  textDecoration: "underline",
  width: "100%",
  backgroundColor: "transparent",
  display: "block",
  cursor: "pointer",
  outline: "none",
  border: "none",
  color: palette.grey[600],
  "&:hover, &:active": {
    outline: "none",
    border: "none",
    backgroundColor: alpha(activeColor, 0.5),
  },
}));

export const StyledExternalLink = styled("a")(({ theme: { palette } }) => ({
  textDecoration: "underline",
  display: "block",
  color: palette.grey[600],
  "&:hover": {
    backgroundColor: alpha(activeColor, 0.5),
  },
}));

export const SettingPrimaryText = styled((props: TypographyProps) => (
  <Typography variant="h5" flexGrow={1} {...props} />
))(({ theme: { palette, breakpoints } }) => ({
  fontSize: 18,
  fontWeight: 600,
  color: palette.grey[600],
  [breakpoints.up("md")]: {
    fontSize: 20,
  },
}));

export const SettingSecondaryText = styled((props: TypographyProps) => (
  <Typography flexGrow={0} {...props} />
))(({ theme: { palette, breakpoints } }) => ({
  fontSize: 12,
  color: palette.grey[400],
  [breakpoints.up("md")]: {
    fontSize: 14,
  },
}));

export const StyledList = styled(List)(({ theme: { spacing } }) => ({
  padding: spacing(4, 0),
}));

export const IconContainer = ({ icon }: { icon: React.ReactElement }) => (
  <Box
    sx={{
      height: 48,
      width: 48,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      backgroundColor: "primary.light",
      color: "secondary.main",
    }}
  >
    {icon}
  </Box>
);
export const StyledAvatarWrapper = styled(Box)(
  ({ theme: { breakpoints } }) => ({
    borderRadius: "50%",
    borderColor: "#DCE7FF",
    borderStyle: "dashed",
    borderWidth: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    cursor: "pointer",
    width: 86,
    height: 86,
    marginBottom: 12,
    [breakpoints.up("sm")]: {
      width: 138,
      height: 138,
    },
  })
);

export const EditIconWrapper = styled(Box)({
  borderRadius: "50%",
  backgroundColor: "#FFF",
  position: "absolute",
  cursor: "pointer",
  width: 27,
  height: 27,
  bottom: 2,
  right: 2,
});

export const CancelIconWrapper = styled(Box)(({ theme: { breakpoints } }) => ({
  borderRadius: "50%",
  backgroundColor: "transparent",
  position: "absolute",
  cursor: "pointer",
  width: 25,
  height: 25,
  top: 2,
  right: 0,
  [breakpoints.up("sm")]: {
    top: 4,
    right: 2,
  },
}));

export const ProfileContainer = styled(Box)(({ theme: { breakpoints } }) => ({
  marginBottom: 9,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [breakpoints.up("sm")]: {
    alignItems: "flex-start",
  },
}));

export const ProfileName = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.grey[600],
  fontSize: 20,
  lineHeight: "30px",
  marginBottom: 5,
  fontWeight: 600,
  maxWidth: 250,
}));

export const ProfileEmail = styled(Typography)(
  ({ theme: { palette, breakpoints } }) => ({
    color: palette.grey[400],
    fontSize: 14,
    lineHeight: "15px",
    fontWeight: 500,
    maxWidth: 350,
    marginBottom: 60,
    [breakpoints.up("sm")]: {
      marginBottom: 0,
    },
    [breakpoints.up("md")]: {
      maxWidth: 200,
    },
    [breakpoints.up("lg")]: {
      maxWidth: 350,
    },
  })
);

export const EditPhotoButton = styled(Button)(({ theme: { palette } }) => ({
  color: palette.grey[600],
  backgroundColor: "transparent",
  display: "flex",
  alignItems: "center",
  fontSize: 16,
  fontWeight: 600,
  width: 160,
  outline: "none",
  border: "none",
  textDecoration: "underline",
  justifyContent: "flex-start",
  paddingLeft: 0,
  marginBottom: 64,
  "&:hover": {
    textDecoration: "underline",
    backgroundColor: "transparent",
  },
  "& div": {
    marginLeft: 6,
  },
  "& svg": {
    width: 36,
    height: 36,
  },
}));

export const ProfileInfo = styled(Box)(({ theme: { breakpoints } }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [breakpoints.up("sm")]: {
    flexDirection: "initial",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));

export const ProfileDetails = styled(Box)(({ theme: { breakpoints } }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [breakpoints.up("sm")]: {
    marginLeft: 36,
    alignItems: "flex-start",
  },
  [breakpoints.up("md")]: {
    marginLeft: 12,
  },
  [breakpoints.up("lg")]: {
    marginLeft: 64,
  },
}));

export const ProfileOption = styled(Box)(
  ({ theme: { palette, breakpoints } }) => ({
    maxWidth: 480,
    "& button": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "none",
      outline: "none",
      borderRadius: 0,
      background: "transparent",
      "&:hover": {
        background: "transparent",
      },
    },
    "& p": {
      color: palette.grey[600],
      marginLeft: 12,
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "30px",
      "&.remove": {
        color: palette.error.main,
      },
      [breakpoints.up("sm")]: {
        fontSize: 18,
      },
    },
  })
);
