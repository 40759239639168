import { Stack, StackProps, styled } from "@mui/material";
import {
  QuotePreview,
  AttachmentsProps,
  QuotedMessageProps,
} from "shared/chat";
import { AttachmentPreviewCard } from "./AttachmentPreviewCard";
import { colors } from "shared/theme";

type AttachmentsPaneProps = {
  attachmentsProps: AttachmentsProps;
  quotedMessageProps: QuotedMessageProps;
  open: boolean;
};
export const AttachmentsPane = ({
  attachmentsProps: { attachments, deleteAttachments, isLoading },
  quotedMessageProps: { quotedMessage, setQuotedMessage },
  open,
}: AttachmentsPaneProps) => {

  return (
    <Pane open={open}>
      {attachments &&
        attachments.map((a, i) => (
          <AttachmentPreviewCard
            key={a.size + a.name + i}
            isLoading={isLoading}
            file={a}
            onClose={() => deleteAttachments(a)}
          />
        ))}
      {quotedMessage && (
        <QuotePreview
          message={quotedMessage}
          onClose={() => setQuotedMessage(null)}
        />
      )}
    </Pane>
  );
};

const Pane = styled(
  (props: StackProps) => <Stack spacing={2} direction={"row"} {...props} />,
  { shouldForwardProp: (prop) => prop !== "open" }
)<{ open: boolean }>(({ theme: { spacing }, open }) => ({
  backgroundColor: colors.grey.light,
  borderTop: open ? `1px solid ${colors.diamondWhite}` : "none",
  padding: open ? spacing(2, 4) : spacing(0, 4),
  transition: "height 0.15s ease, padding 0.15s ease",
  display: "flex",
  overflowX: "auto",
  height: open ? 82 : 0,
  overflowY: "hidden",
}));
