import { BoxProps, Box, styled } from "@mui/material";
import { colors } from "shared/theme";

type TabProps = { active?: boolean; upturn?: boolean };

export const Tab = ({
  active,
  upturn,
  children,
  ...props
}: TabProps & BoxProps) => {
  return (
    <StyledTab upturn={upturn} active={active} {...props}>
      {children}
    </StyledTab>
  );
};

const StyledTab = styled(Box, {
  shouldForwardProp: (prop) => !["active", "upturn"].includes(prop as string),
})<TabProps>(({ theme: { spacing, shadows }, upturn, active }) => ({
  padding: spacing(0.5, 2),
  display: "flex",
  alignItems: "center",
  fontSize: 10,
  fontWeight: 500,
  backgroundColor: active ? colors.info.dark : colors.white,
  color: active ? colors.white : colors.haleNavy.light,
  borderRadius: upturn ? spacing(0, 0, 1.5, 1.5) : spacing(1.5, 1.5, 0, 0),
  cursor: "pointer",
  boxShadow: active
    ? shadows[4]
    : "0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12);",
  transition: "color 0.2s ease-in-out",
}));
