import React, { ReactNode } from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
} from "@mui/material";
import { useIsBreakpoint } from "shared/theme";
import { AppDialog, AppDialogTitle, LoadingButton } from "shared/components";
import {
  Conversation,
  useAttachEmailToConversation,
} from "shared/conversations";
import { useChecklist } from "shared/utils";
import { GmailMessage } from "shared/gmail";

import { GmailMessagesChecklist } from "./gmail-messages-checklist";
import { UserGmailGuard } from "../../user";
import { EmailRelayCopy } from "../../conversations/email-relay";

type Props = {
  onClose: () => void;
  dialogProps: DialogProps;
  conversation: Conversation;
};

export const AddMailsToConversationDialog: React.FC<Props> = ({
  conversation,
  onClose,
  dialogProps,
}) => {
  const isSmBreakpoint = useIsBreakpoint("sm");
  const { attachEmailToConversation, isLoading } = useAttachEmailToConversation(
    {
      onSuccess: () => {
        onClose();
      },
    }
  );

  const [selected, handleChecked, reset] = useChecklist<GmailMessage>([], "id");

  const handleSubmit = () => {
    return Promise.all(
      Object.values(selected).map((gmailMessage) =>
        attachEmailToConversation(conversation.chatSid, gmailMessage)
      )
    );
  };

  React.useEffect(() => {
    if (dialogProps.open) {
      reset();
    }
  }, [dialogProps.open]);

  const handleDialogClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  return (
    <AppDialog
      iosStyle
      onClose={onClose}
      {...dialogProps}
      keepMounted={false}
      fullScreen={isSmBreakpoint}
      onClick={handleDialogClick}
    >
      <AppDialogTitle
        title={"Add email to the discussion"}
        onClose={onClose}
        iosStyle
      />
      <UserGmailGuard enabledProvidersOnly>
        <DialogContent>
          <Box display={"flex"} justifyContent={"center"} height={1}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              flex={1}
              textAlign={"center"}
              alignItems={"center"}
            >
              <Wrapper>
                <Subtitle>
                  Add email from Inbox connected to Markus
                </Subtitle>
              </Wrapper>
              <Box flex={1} height={1} width={1} px={{ xs: 2, lg: 4 }}>
                <GmailMessagesChecklist
                  selected={selected}
                  onChecked={handleChecked}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: { xs: "12px !important", sm: 6 } }}>
          <Box width={360}>
            <LoadingButton
              variant={"contained"}
              fullWidth
              onClick={handleSubmit}
              loading={isLoading}
              size={"large"}
            >
              Add email
            </LoadingButton>
          </Box>
        </DialogActions>
      </UserGmailGuard>
      {conversation.emailRelayAddress && (
        <DialogContent sx={{ flex: "0 0 auto" }}>
          <Wrapper>
            <Subtitle>Forward email to the discussion</Subtitle>
            <Box width={1} mt={{ xs: 2, sm: 4 }}>
              <EmailRelayCopy
                emailRelayAddress={conversation.emailRelayAddress}
              />
            </Box>
          </Wrapper>
        </DialogContent>
      )}
    </AppDialog>
  );
};

const Wrapper = ({ children }: { children: ReactNode }) => (
  <Box px={{ xs: 2, lg: 4 }} width={1}>
    {children}
  </Box>
);

const Subtitle = ({ children }: { children: React.ReactNode }) => (
  <Typography
    width={1}
    textAlign="left"
    variant="h5"
    fontWeight={500}
    fontSize={{ xs: 14, sm: 16 }}
    mb={4}
    color={"grey.600"}
  >
    {children}
  </Typography>
);
