import React from "react";
import { Media } from "@twilio/conversations";
import { useTrackFile } from "shared/analytics";
import { getFileHref } from "../utils";

export function useDownloadMedia() {
  const trackFile = useTrackFile("download");

  const [isLoading, setIsLoading] = React.useState(false);

  const handleDownload = (media: Media | null) => {
    if (!media) {
      return;
    }

    setIsLoading(true);

    trackFile({
      name: media.filename ?? "attachment",
      size: media.size,
      type: media.contentType,
    });

    getFileHref(media)
      .then((href) => {
        const a = document.createElement("a");
        a.href = href;
        a.download = media.filename || "attachment";
        a.click();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return { isLoading, handleDownload };
}
