import React from "react";
import { Button, ButtonProps, lighten, styled } from "@mui/material";
import { YahooIcon } from "./icons";

export const YahooButton: React.FC<ButtonProps> = ({ children, ...props }) => (
  <StyledButton size={"large"} startIcon={<YahooIcon />} {...props}>
    {children}
  </StyledButton>
);

const StyledButton = styled(Button)(({ theme: { typography } }) => ({
  backgroundColor: lighten("#B580C9", 0.4),
  color: "#5D228F",
  borderRadius: 12,
  minHeight: 45,
  fontSize: typography.subtitle2.fontSize,
  "&:hover": {
    backgroundColor: lighten("#B580C9", 0.2),
  },
  "&:disabled": {
    opacity: 0.4,
  },
}));
