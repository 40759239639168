import { Media } from "@twilio/conversations";
import React from "react";
import { getMediaAsObjectUrl } from "../utils";

export function useGetMediaAsObjectUrl(media: Media) {
  const [file, setFile] = React.useState("");


  React.useEffect(() => {
    if (!media) {
      return;
    }
    getMediaAsObjectUrl(media).then((file) => {
      setFile(file);
    });

    return () => {
      setFile("");
    };
  }, [media]);

  React.useEffect(() => {
    return () => {
      setFile("");
    };
  }, []);

  return file;
}
