import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const AddEmailIcon: React.FC<SvgIconProps> = (props) => {
  const fillProp = props.fill ?? "transparent";
  return (
    <SvgIcon
      {...props}
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="transparent"
      aria-label="add email icon"
      sx={{ fill: fillProp, ...props.sx }}
    >
      <path
        d="M15.1058 6.2998L11.1068 9.55154C10.3513 10.1509 9.28825 10.1509 8.53271 9.55154L4.5 6.2998"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.697 12.5996V17.1086"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9974 14.8542H14.3984"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 1.5H15.3C16.9569 1.5 18.3 2.84315 18.3 4.5V9.15C18.3 9.56421 18.6358 9.9 19.05 9.9C19.4642 9.9 19.8 9.56421 19.8 9.15V4.5C19.8 2.01472 17.7853 0 15.3 0H4.5C2.01472 0 0 2.01472 0 4.5V13.5C0 15.9853 2.01472 18 4.5 18H11.8504C12.2646 18 12.6004 17.6642 12.6004 17.25C12.6004 16.8358 12.2646 16.5 11.8504 16.5H4.5C2.84315 16.5 1.5 15.1569 1.5 13.5V4.5C1.5 2.84315 2.84315 1.5 4.5 1.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
