import { UseBaseQueryOptions, useQuery } from "react-query";

import { QueryKey, useApi } from "shared/api";
import { config } from "shared/config";
import { useIsAuthenticated } from "shared/auth";

type TwilioTokenResponse = {
  identity: string;
  token: string;
};

export const useTwilioToken = (
  options: UseBaseQueryOptions<TwilioTokenResponse> = {}
) => {
  const { get } = useApi();
  const isAuthenticated = useIsAuthenticated();

  return useQuery<TwilioTokenResponse>(
    QueryKey.TwilioToken,
    get(config.endpoints.twilio.token),
    {
      ...options,
      enabled: isAuthenticated,
    }
  );
};
