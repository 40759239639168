import { Stack, styled } from "@mui/material";
import { useChatSid } from "shared/chat";
import { MediaBinHeader, MediaBinLinks } from "./bin-header";
import { EmailsBin } from "./emails-bin";
import { LinksBin } from "./links-bin";
import { ImagesBin } from "./images-bin";
import { FilesBin } from "./files-bin";
import { useMediaViewHash } from "./shared";
import {VideosBin} from "./videos-bin";

const views = {
  files: FilesBin,
  images: ImagesBin,
  emails: EmailsBin,
  links: LinksBin,
  videos: VideosBin
};

export const MediaBin = () => {
  const view = useMediaViewHash();
  const chatSid = useChatSid();

  const View = views[view] ?? ImagesBin;

  return (
    <Stack height={1}>
      <MediaBinHeader />
      <OuterWrapper>
        <MediaBinLinks current={view} />
        <InnerWrapper>
          <View chatSid={chatSid} />
        </InnerWrapper>
      </OuterWrapper>
    </Stack>
  );
};

const OuterWrapper = styled(Stack)(
  ({ theme: { spacing, breakpoints, palette } }) => ({
    flexGrow: 1,
    paddingTop: spacing(2),
    paddingBottom: spacing(4),
    [breakpoints.up("md")]: {
      borderLeft: `1px solid ${palette.grey[200]}`,
    },
  })
);
const InnerWrapper = styled(Stack)(({ theme: { spacing } }) => ({
  flexGrow: 1,
  padding: spacing(4),
}));
