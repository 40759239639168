import { Participant } from "shared/conversations";
import { Collaborator, InvitationStatus } from "shared/collaborators";
import {
  ParticipantStatus,
  OutgoingInvitation,
  IncomingInvitation,
} from "../model";

export type ParticipantWithStatus = Participant & {
  status: ParticipantStatus;
};

export function appendInvitationStatuses(
  participants: Participant[],
  collaborators: Collaborator[],
  incomingInvitations: IncomingInvitation[],
  outgoingInvitations: OutgoingInvitation[],
  userId: string
): ParticipantWithStatus[] {
  return participants.map((participant) => {
    if (participant.support) {
      return { ...participant, status: ParticipantStatus.Restricted };
    }

    if (participant.userId === userId) {
      return { ...participant, status: ParticipantStatus.Me };
    }

    if (incomingInvitations.find((i) => i.userId === participant.userId)) {
      return { ...participant, status: ParticipantStatus.InvitedBy };
    }

    if (outgoingInvitations.find((i) => i.userId === participant.userId)) {
      return { ...participant, status: ParticipantStatus.Invited };
    }

    const collaborator = collaborators.find(
      (c) =>
        c.userId === participant.userId ||
        c.authorizationCode === participant.authorizationCode
    );

    if (!collaborator && !participant.userId) {
      return { ...participant, status: ParticipantStatus.Visitor };
    }

    if (
      collaborator &&
      collaborator.invitationStatus === InvitationStatus.Accepted
    ) {
      return { ...participant, status: ParticipantStatus.Connected };
    }

    if (
      collaborator &&
      collaborator.invitationStatus === InvitationStatus.Pending
    ) {
      return { ...participant, status: ParticipantStatus.Pending };
    }

    return { ...participant, status: ParticipantStatus.None };
  });
}
