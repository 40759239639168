import React from "react";
import { CircularProgress, styled } from "@mui/material";
import {
  LoadingButton as MuiLoadingButton,
  LoadingButtonProps,
} from "@mui/lab";
import { LoadingIcon } from "./icons";

export const LoadingButton = styled((props: LoadingButtonProps) => (
  <MuiLoadingButton
    startIcon={<></>}
    loadingPosition={"start"}
    {...props}
  />
))(() => ({}));
