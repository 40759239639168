import { List } from "@mui/material";

import { QueryDataWrapper } from "shared/api";
import { useIncomingInvitations } from "shared/invitations";
import { CountSectionHeader } from "shared/components";
import { Invitation } from "./Invitation";
import { InvitationsListPlaceholder } from "./InvitationsListPlaceholder";

export const InvitationsList = () => {
  const {
    incomingInvitations: invitations,
    error,
    isFetching: isLoading,
  } = useIncomingInvitations();

  return (
    <QueryDataWrapper
      result={{
        error,
        data: invitations,
        isLoading,
      }}
    >
      {({ data: invitations }) => (
        <>
          <CountSectionHeader count={invitations.length} title="Invitations" />
          {Boolean(invitations.length) ? (
            <List>
              {invitations.map((invitation) => (
                <Invitation
                  key={invitation.invitationId}
                  invitation={invitation}
                />
              ))}
            </List>
          ) : (
            <InvitationsListPlaceholder />
          )}
        </>
      )}
    </QueryDataWrapper>
  );
};
