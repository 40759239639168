import React from "react";
import { ListItem, Stack, Box } from "@mui/material";
import HelpIcon from "@mui/icons-material/QuestionMark";
import GoogleIcon from "@mui/icons-material/Google";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import NotesIcon from "@mui/icons-material/Notes";
import { paths } from "shared/router";
import {
  ArrowRightIcon,
  EmailCircleIcon,
  PrivacyCircleIcon,
  ProfileCircleIcon,
  FeedbackCircleIcon,
} from "shared/components";

import { settings, SettingType } from "../model";
import {
  IconContainer,
  setActiveColor,
  SettingPrimaryText,
  SettingSecondaryText,
  StyledButtonLink,
  StyledExternalLink,
  StyledLink,
} from "./styles";
import { palette } from "shared/theme";

const icons: Record<SettingType, React.ReactElement> = {
  email: <EmailCircleIcon sx={{ fill: palette.primary.light, fontSize: 48 }} />,
  profile: (
    <ProfileCircleIcon sx={{ fill: palette.primary.light, fontSize: 48 }} />
  ),
  privacy: (
    <PrivacyCircleIcon sx={{ fill: palette.primary.light, fontSize: 48 }} />
  ),
  termsOfService: <IconContainer icon={<NotesIcon />} />,
  privacyPolicy: <IconContainer icon={<ShieldOutlinedIcon />} />,
  googleApiDisclosure: <IconContainer icon={<GoogleIcon />} />,
  help: <IconContainer icon={<HelpIcon />} />,
  contactUs: (
    <FeedbackCircleIcon sx={{ fill: palette.primary.light, fontSize: 48 }} />
  ),
  about: <IconContainer icon={<InfoOutlinedIcon />} />,
};

export type SettingProps = {
  setting: SettingType;
  external?: boolean;
  underScored?: boolean;
  onClick?: () => void;
};

export const SettingLink: React.VFC<SettingProps> = ({
  setting,
  external,
  underScored,
  onClick,
}) => {
  const text = settings[setting];

  if (external && text.url) {
    return (
      <StyledExternalLink
        href={text.url}
        rel="noopener noreferrer"
        target="_blank"
      >
        <ListItem
          sx={{
            padding: { xs: "8px 16px", md: "16px 24px" },
          }}
        >
          <Stack direction="row" alignItems="center" width={1} spacing={2}>
            <Box flexGrow={0} color="secondary.main">
              {icons[setting]}
            </Box>
            <Stack flex={1}>
              <SettingPrimaryText>{text.primary}</SettingPrimaryText>
            </Stack>
          </Stack>
        </ListItem>
      </StyledExternalLink>
    );
  }

  if (underScored) {
    return (
      <StyledButtonLink onClick={onClick}>
        <ListItem
          sx={{
            padding: { xs: "8px 16px", md: "16px 24px" },
          }}
        >
          <Stack direction="row" alignItems="center" width={1} spacing={2}>
            <Box flexGrow={0}>{icons[setting]}</Box>
            <Stack flex={1}>
              <SettingPrimaryText>{text.primary}</SettingPrimaryText>
            </Stack>
          </Stack>
        </ListItem>
      </StyledButtonLink>
    );
  }

  return (
    <StyledLink
      to={
        hasPath(setting)
          ? paths.app.settings[setting]
          : paths.app.settings.index
      }
      style={setActiveColor}
    >
      <ListItem
        sx={{
          padding: { xs: "8px 16px", md: "16px 24px" },
        }}
      >
        <Stack direction="row" alignItems="center" width={1} spacing={2}>
          <Box flexGrow={0}>{icons[setting]}</Box>
          <Stack flex={1}>
            <SettingPrimaryText>{text.primary}</SettingPrimaryText>
            <SettingSecondaryText>{text.secondary}</SettingSecondaryText>
          </Stack>
          <Box flexGrow={0} sx={{ display: "flex", alignItems: "centers" }}>
            <ArrowRightIcon sx={{ color: "grey.400" }} />
          </Box>
        </Stack>
      </ListItem>
    </StyledLink>
  );
};

type SettingsPaths =
  | SettingType.email
  | SettingType.profile
  | SettingType.privacy;

const hasPath = (setting: SettingType): setting is SettingsPaths => {
  return [SettingType.email, SettingType.profile, SettingType.privacy].includes(
    setting
  );
};
