import { Box } from "@mui/material";
import { AttachmentIcon } from "shared/components";

export const FileAttachmentIcon = () => {
  return (
    <Box
      sx={({ palette }) => ({
        bgcolor: palette.info.light,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 35,
        width: 35,
        borderRadius: "50%",
      })}
    >
      <AttachmentIcon sx={({ palette }) => ({ color: palette.info.dark, width: 18, height: 18 })} />
    </Box>
  );
};
