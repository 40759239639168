import { Box, Typography } from "@mui/material";
import { RemoveIcon } from "shared/components";

export const ErrorView = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box display="flex" alignItems="center" px={4}>
        <RemoveIcon
          sx={{ fontSize: 48, color: "error.main", fill: "error.main" }}
        />
        <Typography sx={{ color: "grey.500", fontSize: 20, marginLeft: 4 }}>
          Media cannot be displayed
        </Typography>
      </Box>
    </Box>
  );
};
