import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import { AppleAuthProvider } from "shared/apple-auth";
import { GoogleAuthProvider, GoogleTokenManager } from "shared/google-auth";
import { AppAuthProvider } from "./AppAuth";
import { AuthManager } from "./AuthManager";
import { PublicChatAuthProvider } from "./PublicChatAuth";

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <AppleAuthProvider>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
      >
        <AppAuthProvider>
          <PublicChatAuthProvider>
            <GoogleAuthProvider>
              <GoogleTokenManager>
                <AuthManager>{children}</AuthManager>
              </GoogleTokenManager>
            </GoogleAuthProvider>
          </PublicChatAuthProvider>
        </AppAuthProvider>
      </GoogleOAuthProvider>
    </AppleAuthProvider>
  );
};
