import React from "react";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { AppHeader, IconButton } from "shared/components";

import { EmailMessage } from "../../shared";
import { useChatContext } from "../../context";
import { SummarizeEmail } from "./SummarizeEmail";

type EmailPreviewHeaderProps = {
  message: EmailMessage;
};

export const EmailPreviewHeader: React.FC<EmailPreviewHeaderProps> = ({
  message,
}) => {
  const { toggleEmailPreview } = useChatContext();

  return (
    <AppHeader>
      <Stack
        direction={"row"}
        spacing={3}
        alignItems={"center"}
        sx={{ overflow: "hidden" }}
      >
        <Stack sx={{ overflow: "hidden" }}>
          <Tooltip title={message.subject}>
            <Typography
              variant={"body1"}
              fontWeight={600}
              color={"secondary"}
              noWrap
            >
              {message.subject}
            </Typography>
          </Tooltip>
          <Typography
            variant={"caption"}
            fontWeight={500}
            color={"secondary.light"}
            noWrap
          >
            From: {message.fromName}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction={"row"} spacing={1} alignItems={"center"} ml={3}>
        <Typography
          variant={"caption"}
          color={"secondary.light"}
          noWrap
          lineHeight={1}
        >
          {message.externalCreationDate}
        </Typography>
        <Stack pl={2} spacing={2} direction={"row"}>
          <SummarizeEmail message={message} />
          <IconButton
            color="transparent"
            onClick={() => toggleEmailPreview(undefined)}
            icon={Close}
          />
        </Stack>
      </Stack>
    </AppHeader>
  );
};
