import { Box, Skeleton as MuiSkeleton } from "@mui/material";

import { MessagesListItem } from "./MessagesListItem";
import { colors } from "shared/theme";
import { LoadingIndicator } from "./LoadingIndicator";
import { ConversationsLayout } from "shared/layouts";
import { APP_HEADER_HEIGHT } from "shared/constants";

export const ConversationsLoader = ({
  isMobile,
  inChat,
}: {
  isMobile: boolean;
  inChat: boolean;
}) => {
  if (isMobile) {
    return (
      <ConversationsLayout>
        {inChat ? <ConversationLoader /> : <ConversationListLoader />}
      </ConversationsLayout>
    );
  }
  return (
    <ConversationsLayout>
      <ConversationListLoader />
      <ConversationLoader />
    </ConversationsLayout>
  );
};

const ConversationListLoader = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      paddingTop: `${APP_HEADER_HEIGHT}px`,
      maxHeight: "100vh",
      overflow: "hidden",
    }}
  >
    <Box
      px={{ xs: 3, sm: 6 }}
      py={{ xs: 4, sm: 8 }}
      borderTop={`1px solid ${colors.diamondWhite}`}
      data-testid="conversation-list-loader"
    >
      <Skeleton width={"100%"} height={79} />
    </Box>
    <Box flex={1}>{elements}</Box>{" "}
  </Box>
);

const ConversationLoader = () => (
  <Box
    sx={{
      paddingTop: `${APP_HEADER_HEIGHT}px`,
      paddingBottom: "67px",
      flex: 1,
      display: "flex",
      width: "100%",
    }}
  >
    <Box
      flex={1}
      px={{ xs: 3, sm: 6 }}
      py={{ xs: 4, sm: 8 }}
      borderTop={`1px solid ${colors.diamondWhite}`}
      data-testid="conversation-loader"
    >
      <LoadingIndicator />
    </Box>
  </Box>
);

const Skeleton = ({
  width,
  height = 24,
}: {
  width: string | number;
  height?: number;
}) => (
  <MuiSkeleton
    variant="text"
    width={width}
    height={height}
    sx={{ bgcolor: colors.grey.main }}
  />
);

const elements = Array.from({ length: 6 }).map((_, index) => (
  <MessagesListItem
    key={index}
    title={<Skeleton width={164} />}
    subtitle={<Skeleton width={250} />}
    text={<Skeleton width={232} />}
  />
));
