import { Media } from "@twilio/conversations";
import { Box } from "@mui/material";
import { LoadingIndicator } from "shared/components";
import { useVideo } from "../hooks";
import { ErrorView } from "./ErrorView";

export const VideoPreview = ({ media }: { media: Media }) => {
  const { isError, isLoading, url, setIsError } = useVideo(media);

  if (isError) {
    return <ErrorView />;
  }

  return (
    <Box
      flex={1}
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <video
            data-testid="video"
            style={{
              width: "100%",
            }}
            controls
            onError={(e) => setIsError(true)}
          >
            <source
              src={url ?? ""}
              type="video/mp4"
              data-testid="video-source"
            />
            Sorry, your browser doesn't support embedded videos.
          </video>
        )}
      </Box>
    </Box>
  );
};
