import React from "react";
import { Box, Stack } from "@mui/material";
import { Outlet, useParams } from "react-router-dom";
import { UserConvertGuard, UserGmailGuard } from "shared/user";
import { useIsBreakpoint } from "shared/theme";
import { AppHeader, PageHeader } from "shared/components";
import { PrevButtonLink } from "shared/components";

import { GmailMessagesList } from "./GmailMessagesList";

type GmailMessagesProps = {
  qLabel: string;
  title: string;
  listId: string;
  listPath: string;
  listItemPath: string;
};

export const GmailMessagesPage: React.FC<GmailMessagesProps> = (props) => {
  const isMdBreakpoint = useIsBreakpoint("md");
  const { messageId } = useParams();

  const showPrevButton = isMdBreakpoint && Boolean(messageId);

  return (
    <UserConvertGuard>
      <UserGmailGuard>
        <AppHeader>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            {showPrevButton && <PrevButtonLink to={props.listPath} />}
            <PageHeader title={props.title} />
          </Stack>
        </AppHeader>
        {isMdBreakpoint ? (
          <MobilePage {...props} />
        ) : (
          <DesktopPage {...props} />
        )}
      </UserGmailGuard>
    </UserConvertGuard>
  );
};

const DesktopPage: React.FC<GmailMessagesProps> = (props) => {
  return (
    <Box display={"flex"} flex={1}>
      <Box flex="4 0">
        <GmailMessagesList {...props} />
      </Box>
      <Outlet />
    </Box>
  );
};

const MobilePage: React.FC<GmailMessagesProps> = (props) => {
  const { messageId } = useParams();

  return !messageId ? (
    <Box flex={1} display={"flex"}>
      <GmailMessagesList {...props} />
    </Box>
  ) : (
    <Outlet />
  );
};
