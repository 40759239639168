import React from "react";
import { useGoogleAuthContext, useGoogleLogout } from "./GoogleAuth.context";
import { Mutex } from "../api";
import { config } from "../config";
import { GoogleAuthResponse, initialAuthData } from "./types";
import { localStorageService } from "../utils";
import { useGoogleLogin } from "@react-oauth/google";
import { useGoogleAuth } from "./useGoogleAuth";
import { gmailScopes } from "../gmail";
import { useUser } from "../user";

type GoogleAuthTokenManagerState = {
  requestToken: () => Promise<string>;
};

const initialContext: GoogleAuthTokenManagerState = {
  requestToken: () => Promise.resolve(""),
};

const GoogleTokenManagerContext =
  React.createContext<GoogleAuthTokenManagerState>(initialContext);

export const GoogleTokenManager: React.FC = ({ children }) => {
  const { user } = useUser();
  const { authData, setAuthData } = useGoogleAuthContext();
  const logout = useGoogleLogout();

  const { authenticateGoogle } = useGoogleAuth();

  const loginWithGoogle = useGoogleLogin({
    scope: gmailScopes.join(" "),
    onSuccess: ({ code }) => {
      if (!code) {
        return;
      }

      authenticateGoogle({ code });
    },
    hint: user.email,
    flow: "auth-code",
  });

  const refreshTokenMutex = React.useRef(Mutex()).current;

  const requestToken = React.useCallback(async () => {

    if (refreshTokenMutex.isLocked) {
      await refreshTokenMutex.waitForUnlock();
      return readAuthData().access_token;
    }

    if (
      new Date().getTime() + 600_000 >
      new Date(readAuthData().expiry_date).getTime()
    ) {
      refreshTokenMutex.lock();

      const newAuthData = await refreshTokenFn(authData.refresh_token);

      if (!newAuthData) {
        logout();
        throw new Error();
      }

      saveAuthData(newAuthData);

      setAuthData(newAuthData);
      refreshTokenMutex.unlock();
    }

    return readAuthData().access_token;
  }, [authData]);

  const authTokenManager = React.useMemo(
    () => ({
      requestToken,
    }),
    [requestToken]
  );

  return (
    <GoogleTokenManagerContext.Provider value={authTokenManager}>
      {children}
    </GoogleTokenManagerContext.Provider>
  );
};

export const useGoogleTokenManager = () =>
  React.useContext(GoogleTokenManagerContext);

const isExpired = (date: Date, refDate: Date) => {
  return date < refDate;
};

const saveAuthData = (value: GoogleAuthResponse) =>
  localStorageService.setItem("google-auth", value);

const readAuthData = () =>
  localStorageService.getItem<GoogleAuthResponse>(
    "google-auth",
    initialAuthData
  );

const refreshTokenFn = (
  refreshToken: string
): Promise<GoogleAuthResponse | false> => {
  return fetch(`${config.apiUrl}${config.endpoints.google.refreshToken}`, {
    method: "POST",
    body: JSON.stringify({ refreshToken }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (res.status > 400) {
        return false;
      }
      return res.json();
    })
    .catch(() => {
      return false;
    });
};
