import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const DeletedIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    aria-label="deleted icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM12 5.11628C8.19822 5.11628 5.11628 8.19822 5.11628 12C5.11628 15.8018 8.19822 18.8837 12 18.8837C15.8018 18.8837 18.8837 15.8018 18.8837 12C18.8837 8.19822 15.8018 5.11628 12 5.11628Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.2565 8.2565C8.47447 8.03853 8.82786 8.03853 9.04583 8.2565L15.7435 14.9542C15.9615 15.1721 15.9615 15.5255 15.7435 15.7435C15.5255 15.9615 15.1721 15.9615 14.9542 15.7435L8.2565 9.04583C8.03853 8.82786 8.03853 8.47447 8.2565 8.2565Z"
      fill="currentColor"
    />
  </SvgIcon>
);
