import { Message } from "@twilio/conversations";
import React from "react";
import {
  useTwilioConversationSelector,
  formatParticipantName,
  getMessageByIndex,
} from "shared/twilio";
import { useSetStateAsync } from "shared/utils";

export function useQuotedMessage(index: number) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [message, setMessage] = React.useState<Message | null>(null);
  const [author, setAuthor] = React.useState("");
  const [error, setError] = React.useState(false);

  const data = author && message ? { author, message } : undefined ;

  const asyncWrapper = useSetStateAsync();

  const twilioConversation =
    useTwilioConversationSelector("twilioConversation");

  React.useEffect(() => {
    if (!twilioConversation) {
      return setError(true);
    }
    setIsLoading(true);

    getMessageByIndex(index, twilioConversation)
      .then((m) => {
        if (!m) throw new Error("Message not found");
        return m;
      })
      .then((m) => asyncWrapper(() => setMessage(m)))
      .catch(() => {
        asyncWrapper(() => setIsLoading(false));
        asyncWrapper(() => setError(true));
      });
  }, [twilioConversation, index]);

  React.useEffect(() => {
    if (!message) {
      return;
    }
    message
      .getParticipant()
      .then((p) => asyncWrapper(() => setAuthor(formatParticipantName(p))))
      .catch(() => asyncWrapper(() => setError(true)))
      .finally(() => asyncWrapper(() => setIsLoading(false)));
  }, [message]);

  React.useEffect(() => {
    setError(false);
    setAuthor("");
    setMessage(null);
    setIsLoading(false);
  }, []);

  return {
    isLoading,
    error,
    data,
  };
}
