import React from "react";
import { useSearch } from "shared/utils";

import { Conversation } from "../types";

const formatFn = (value: string | null) => {
  if (!value) {
    return "";
  }

  return value.toLowerCase().trim().replace(" ", "");
};

export function useSearchConversations(conversations: Conversation[] = []) {
  const { searchValue, handleSearch, resetSearch } = useSearch();

  const filteredConversations = React.useMemo(() => {
    return conversations.filter(({ subject, participants }) => {
      const participantsNames = participants.map(({ name }) => name).join("");

      return (
        formatFn(subject).includes(formatFn(searchValue)) ||
        formatFn(participantsNames).includes(formatFn(searchValue))
      );
    });
  }, [searchValue, conversations]);

  return {
    handleSearch,
    resetSearch,
    searchValue,
    filteredConversations,
  };
}
