import { Box, Stack, styled } from "@mui/material";

import { Media } from "@twilio/conversations";
import { DownloadButton } from "shared/components";
import { theme } from "shared/theme";
import { useDownloadMedia, useLoadMedia } from "shared/twilio";
import { getFileSizeInMB } from "shared/utils";
import { FileAttachmentIcon } from "./FileAttachmentIcon";
import { FileMediaResponse } from "../model";
import { Thumbnail } from "./Thumbnail";
import {
  IconSection,
  MediaItemContainer,
  TextPrimary,
  TextSecondary,
} from "./styles";
import { VideoAttachmentIcon } from "./VideoAttachmentIcon";

export type MediaBinFileItemProps = FileMediaResponse & {
  onClick: (m: Media | null) => void;
};

export const MediaBinFileItem = ({
  thumbnail,
  filename,
  fileSize,
  contentType,
  onClick,
  index,
  mediaSid,
  id,
}: MediaBinFileItemProps) => {
  const { isLoading, media } = useLoadMedia(Number(index), mediaSid);

  const { handleDownload, isLoading: isDownloading } = useDownloadMedia();

  const fileType = (contentType.split("/")[1] ?? "file").toUpperCase();
  const isVideo = contentType.startsWith("video/");

  const thumbnailEl = thumbnail ? (
    <Thumbnail thumbnail={thumbnail} filename={filename} />
  ) : isVideo ? (
    <VideoAttachmentIcon />
  ) : (
    <FileAttachmentIcon />
  );

  return (
    <MediaItemContainer
      data-testid={`media_bin_item_${id}`}
      onClick={() => onClick(media)}
    >
      <IconSection>{thumbnailEl}</IconSection>
      <FileDataSection>
        <TextPrimary noWrap>{filename}</TextPrimary>
        <TextSecondary>
          {fileType} {getFileSizeInMB(fileSize).toFixed(3)}MB
        </TextSecondary>
      </FileDataSection>
      <DownloadSection>
        <DownloadButton
          handleDownload={() => handleDownload(media)}
          isDownloading={isLoading || isDownloading}
          color={theme.palette.info.dark}
        />
      </DownloadSection>
    </MediaItemContainer>
  );
};

const FileDataSection = styled(Stack)(({ theme: { palette, spacing } }) => ({
  flex: 1,
  marginRight: spacing(2),
  padding: spacing(0, 3),
  borderRight: `1px solid ${palette.grey[200]}`,
  width: 0,
}));

const DownloadSection = styled(Box)(({ theme: { spacing } }) => ({
  padding: spacing(2, 0),
}));
