import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { Media, Message } from "@twilio/conversations";

import { QueryDataWrapper } from "shared/api";
import { hasEmailSent } from "shared/twilio";
import { LoadingIndicator, ErrorDottedIcon } from "shared/components";

import { useChatContext } from "../../context";
import { useEmailMessage } from "../../shared";
import { ChatMessageEmailHeader } from "./ChatMessageEmailHeader";
import { ChatMessageEmailFooter } from "./ChatMessageEmailFooter";
import { ChatMessageEmailAttachments } from "./ChatMessageEmailAttachments";

type Props = {
  externalProviderMsgId: string;
  message: Message;
  attachments?: Media[];
};

export const ChatMessageEmail: React.FC<Props> = ({
  externalProviderMsgId,
  message,
  attachments,
}) => {
  // Twilio is sending message too fast for backend to proccess it first.
  // Trying to fetch unprocessed message results in error
  // Thus we read here `messageSent` attribute on message
  // and display the message only if it is not set to false

  if (!hasEmailSent(message.attributes) || message.attributes.emailSent) {
    return (
      <ChatMessageEmailSent
        externalProviderMsgId={externalProviderMsgId}
        message={message}
        attachments={attachments}
      />
    );
  }

  return <ChatMessageEmailUnsent />;
};

const ChatMessageEmailSent = ({
  externalProviderMsgId,
  message,
  attachments,
}: Props) => {
  const emailMessageResult = useEmailMessage(externalProviderMsgId);
  const { toggleEmailPreview } = useChatContext();
  const media =
    !attachments && !message.attachedMedia
      ? null
      : (attachments ?? []).concat(message.attachedMedia ?? []);

  return (
    <QueryDataWrapper
      result={emailMessageResult}
      errorView={<ChatMessageEmailError />}
    >
      {({ data }) => (
        <Box width={1}>
          <Content onClick={() => toggleEmailPreview(externalProviderMsgId)}>
            <Box p={"16px 16px 8px"}>
              <ChatMessageEmailHeader message={data} />
              <Typography
                variant={"body2"}
                color={"secondary"}
                overflow={"hidden"}
                sx={{
                  wordWrap: "anywhere",
                }}
              >
                {data.snippet || "No text content"}
              </Typography>
            </Box>
            <ChatMessageEmailAttachments media={media} />
          </Content>
          <ChatMessageEmailFooter message={data} />
        </Box>
      )}
    </QueryDataWrapper>
  );
};

const ChatMessageEmailUnsent = () => {
  return (
    <Content>
      <LoadingIndicator minHeight={100} />
    </Content>
  );
};

const ChatMessageEmailError = () => {
  return (
    <Content>
      <Box
        sx={{
          minWidth: 280,
          display: "flex",
          alignItems: "center",
          height: "100%",
          cursor: "default",
          padding: 2,
        }}
      >
        <ErrorDottedIcon sx={{ marginRight: 2, fontSize: 35 }} />
        <Typography
          variant={"body1"}
          sx={{ color: "grey.500", fontWeight: 500 }}
        >
          Failed to load an email
        </Typography>
      </Box>
    </Content>
  );
};

const Content = styled(Box)(({ theme: { palette } }) => ({
  display: "flex",
  flexDirection: "column",
  border: `1px solid ${palette.grey[200]}`,
  borderLeft: `4px solid ${palette.info.main}`,
  minWidth: 280,
  borderRadius: 8,
  minHeight: 100,
  width: "100%",
  "&:hover": {
    backgroundColor: palette.grey[100],
    cursor: "pointer",
  },
}));
