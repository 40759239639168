import { useOutgoingInvitations } from "shared/invitations";
import { useCollaborators } from "shared/collaborators";

export const useContacts = () => {
  const {
    data: collaborators,
    isLoading: collaboratorsLoading,
    isFetching: collaboratorsFetching,
  } = useCollaborators();

  const {
    outgoingInvitations,
    isLoading: outgoingInvitationsLoading,
    isFetching: outgoingInvitationsFetching,
  } = useOutgoingInvitations();

  return {
    data: [...(collaborators?.collaborators || []), ...outgoingInvitations],
    isLoading: collaboratorsLoading || outgoingInvitationsLoading,
    isFetching: collaboratorsFetching || outgoingInvitationsFetching,
    error: null,
  };
};
