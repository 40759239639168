import React from "react";

export function useHover(
  onHoverStart: (e: React.MouseEvent<Element>) => void,
  onHoverEnd: (e: React.MouseEvent<Element>) => void
) {
  return {
    onMouseEnter: onHoverStart,
    onMouseLeave: onHoverEnd,
  };
}
