import React from "react";
import { Box, styled } from "@mui/material";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteScroll from "react-infinite-scroll-component";

import { GmailBaseMessage, GmailMessage, useGmailMessages } from "shared/gmail";
import { LoadingIndicator } from "shared/components";
import inboxBackground from "assets/images/inboxBackground.png";
import { useGetMessageListPath } from "../useGetMessageListPath";
import { GmailMailMenu, GmailMessagesListItem } from "./";

type GmailMessagesListProps = {
  listId: string;
  listPath: string;
  listItemPath: string;
  qLabel: string;
  onClick?: () => void;
};

export const GmailMessagesList: React.FC<GmailMessagesListProps> = ({
  listId,
  qLabel,
  listPath,
  listItemPath,
}) => {
  const containerRef = React.useRef<HTMLDivElement>();
  const [messages, setMessages] = React.useState<GmailBaseMessage[]>([]);
  const [currentPageToken, setCurrentPageToken] = React.useState<string>();
  const [currentMessage, setCurrentMessage] =
    React.useState<GmailMessage | null>(null);
  const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);

  const toggleMessageMenu = (
    message: GmailMessage | null,
    anchor: HTMLElement | null
  ) => {
    setCurrentMessage(message);
    setAnchor(anchor);
  };

  const { refetch } = useGmailMessages({
    qLabel,
    pageToken: currentPageToken,
    reactQueryOptions: {
      onSuccess: (response) => {
        setMessages((prev) => [...prev, ...(response.messages || [])]);
        setCurrentPageToken(response.nextPageToken);
      },
    },
  });

  React.useEffect(() => {
    refetch();
  }, []);

  const getMessageListPath = useGetMessageListPath({ listPath, listItemPath });

  const handleResetMessages = () => {
    setMessages([]);
    setCurrentPageToken(undefined);
  };

  const handleNext = () => {
    refetch();
  };

  return (
    <Box flex={1} display={"flex"} flexDirection={"column"} height={1}>
      {!messages.length ? (
        <Box
          flex={1}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <BackgroundImage src={inboxBackground} />
        </Box>
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <Box
              ref={containerRef}
              id={listId}
              height={height}
              width={width}
              overflow={"auto"}
              display={"flex"}
              flexDirection={"column"}
              py={3}
            >
              <InfiniteScroll
                dataLength={messages.length}
                hasMore={true}
                next={handleNext}
                loader={<LoadingIndicator />}
                scrollableTarget={listId}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {messages.map((message) =>
                  renderMessage(
                    message,
                    getMessageListPath(message.id),
                    toggleMessageMenu
                  )
                )}
              </InfiniteScroll>
            </Box>
          )}
        </AutoSizer>
      )}
      <GmailMailMenu
        message={currentMessage}
        anchor={anchor}
        onRefreshMessages={handleResetMessages}
        onClose={() => setAnchor(null)}
      />
    </Box>
  );
};

const renderMessage = (
  current: GmailBaseMessage,
  to: string,
  toggleMenu: (message: GmailMessage | null, anchor: HTMLElement | null) => void
) => {
  return (
    <GmailMessagesListItem
      key={current.id}
      message={current}
      to={to}
      toggleMenu={toggleMenu}
    />
  );
};

const BackgroundImage = styled("img")(({ theme: { breakpoints } }) => ({
  [breakpoints.down("md")]: {
    maxWidth: 270,
    maxHeight: 280,
  },
  [breakpoints.down("sm")]: {
    maxWidth: 213,
    maxHeight: 220,
  },
}));
