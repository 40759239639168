const IMAGE_TYPE_REGEX = /^image\/(jpeg|png|webp)/;

export async function pasteImages(data: DataTransfer) {
  const items = data.items;

  if (!items) {
    return null;
  }

  let images: File[] = [];

  for (let item of Array.from(items)) {
    if (item.kind === "file") {
      const file = item.getAsFile();

      if (file && IMAGE_TYPE_REGEX.test(file.type)) {
        images.push(file);
      }
    }
  }

  return images.length ? images : null;
}
