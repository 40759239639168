import { useParams } from "react-router-dom";
import { ErrorPageMessages, ErrorPagesEnum } from "./model";

export function useErrorPageMessage() {
  const { error } = useParams();

  switch (error) {
    case ErrorPagesEnum.Logout:
      return ErrorPageMessages[ErrorPagesEnum.Logout];

    case ErrorPagesEnum.PublicChatNotFound:
      return ErrorPageMessages[ErrorPagesEnum.PublicChatNotFound];

    case ErrorPagesEnum.Request:
      return ErrorPageMessages[ErrorPagesEnum.Request];

    default:
      return ErrorPageMessages[ErrorPagesEnum.NotFound];
  }
}
