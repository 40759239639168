import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const EmailCircleIcon: React.FC<SvgIconProps> = (props) => {
  const fillProp = props.fill ?? "transparent";
  return (
    <SvgIcon
      width="48"
      height="48"
      viewBox="0 0 48 48"
      {...props}
      aria-label="email circle icon"
      sx={{ fill: fillProp, ...props.sx }}
    >
      <rect width="48" height="48" rx="24" />
      <path
        d="M14.9334 15.5711H33.0664C34.1588 15.5711 35.05 16.4613 35.05 17.5547V30.4455C35.05 31.5352 34.1628 32.4291 33.0664 32.4291H14.9334C13.8437 32.4291 12.9498 31.5419 12.9498 30.4455V17.5547C12.9498 16.465 13.8369 15.5711 14.9334 15.5711ZM23.9591 25.5981L32.6717 16.9602H15.3211L23.9591 25.5981ZM27.5736 23.9705L33.6609 30.0578V17.9355L27.5736 23.9705ZM21.3788 24.9823L15.3211 31.04H32.6787L26.5872 24.9485L24.4459 27.0714C24.1744 27.3406 23.7362 27.3398 23.4658 27.0693L21.3788 24.9823ZM14.3389 17.9423V30.0578L20.3966 24.0001L14.3389 17.9423Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.1"
      />
    </SvgIcon>
  );
};
