import { useMutation, useQueryClient } from "react-query";

import { useApi, QueryKey } from "shared/api";
import { config } from "shared/config";
import { useChatContext } from "../context";

type DeleteEmailRequest = {
  messageSid: string;
};
type DeleteEmailResponse = {
  conversationId: string;
  messageSid: string;
  externalProviderMsgId: string;
};

export function useDeleteEmail(chatSid: string = "") {
  const queryClient = useQueryClient();
  const { toggleEmailPreview, externalProviderMsgId: previewedMessageId } =
    useChatContext();
  const { deleteRequest } = useApi();

  const { mutateAsync } = useMutation(
    deleteRequest<DeleteEmailRequest, DeleteEmailResponse>(
      config.endpoints.conversations.email.replace(/:chatSid/, chatSid)
    )
  );

  return (externalProviderMsgId: string, messageSid: string) => {
    const body = { externalProviderMsgId, messageSid };
    const requestOptions = {
      replaceParams: { emailId: externalProviderMsgId },
    };
    
    if (externalProviderMsgId === previewedMessageId) {
      toggleEmailPreview(undefined);
    }

    return mutateAsync(
      { body, requestOptions },
      {
        onSuccess: ({ conversationId }) => {
          queryClient.removeQueries([QueryKey.Conversation, conversationId]);
          queryClient.removeQueries([
            QueryKey.Conversation,
            externalProviderMsgId,
          ]);
        },
      }
    );
  };
}
