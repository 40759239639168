import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { Stack, StackProps, Typography } from "@mui/material";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { Participant } from "shared/conversations";
import { Collaborator } from "shared/collaborators";
import { useConversationOwner } from "shared/chat";
import { Checkbox } from "shared/components";
import { UserAvatar } from "shared/user";
import { StatusBadge } from "shared/invitations";

type HasOnCheckedCb = {
  onChecked: (checked: boolean, collaborator: Collaborator) => void;
};

type ParticipantsListProps = HasOnCheckedCb & {
  selectedParticipants: Record<string, Participant>;
  collaborators: Collaborator[];
};

type ListItemProps = HasOnCheckedCb &
  StackProps & {
    collaborator: Collaborator;
    isSelected: boolean;
  };

export const OwnerParticipantsList: React.FC<ParticipantsListProps> = ({
  selectedParticipants,
  collaborators,
  onChecked,
}) => {
  const sortedCollaborators = collaborators.sort((a, b) => {
    return (
      Number(!!selectedParticipants[b.userId]) -
      Number(!!selectedParticipants[a.userId])
    );
  });

  return (
    <AutoSizer disableWidth>
      {({ height }) => (
        <FixedSizeList
          width={"100%"}
          height={height}
          itemCount={collaborators.length}
          itemSize={55}
          itemData={{
            selectedParticipants,
            collaborators: sortedCollaborators,
            onChecked,
          }}
        >
          {Row}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};

const Row = (props: ListChildComponentProps<ParticipantsListProps>) => {
  const {
    data: { selectedParticipants, collaborators, onChecked },
    style,
    index,
  } = props;

  const collaborator = collaborators[index];

  const isSelected = !!selectedParticipants[collaborator?.userId];

  return (
    <ListItem
      key={index}
      style={style}
      collaborator={collaborator}
      isSelected={isSelected}
      onChecked={onChecked}
    />
  );
};

const ListItem: React.FC<ListItemProps> = React.memo(
  ({ collaborator, isSelected, onChecked, ...props }) => {
    const ownerId = useConversationOwner();

    const isOwner = collaborator.userId === ownerId;

    return (
      <Stack
        direction={"row"}
        spacing={3}
        overflow={"hidden"}
        alignItems={"center"}
        px={2}
        sx={{ cursor: "pointer" }}
        onClick={() => onChecked(!isSelected, collaborator)}
        {...props}
      >
        <UserAvatar
          userName={collaborator.name}
          userId={collaborator.userId}
          avatarColor={collaborator._avatarColor}
          size={40}
        />
        <Typography sx={{ flex: 1 }} noWrap>
          {collaborator.name}
        </Typography>
        {isOwner && <StatusBadge title="owner" />}
        <Checkbox
          checked={isSelected}
          sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
        />
      </Stack>
    );
  }
);
