import { Box, BoxProps, Stack, styled, Typography } from "@mui/material";
import { APP_HEADER_HEIGHT, MOBILE_APP_HEADER_HEIGHT } from "shared/constants";
import { GmailMessageAvatar } from "./GmailMessageAvatar";

type Props = {
  from: string;
  avatarColor: string;
  subject: string;
  action?: JSX.Element | null;
} & BoxProps;
export const GmailMessagePreviewHeaderContent = ({
  from,
  subject,
  avatarColor,
  action,
  ...props
}: Props) => {
  return (
    <Wrapper {...props}>
      <Stack
        direction={"row"}
        spacing={3}
        alignItems={"center"}
        overflow={"hidden"}
      >
        <GmailMessageAvatar from={from} color={avatarColor} size={40} />
        <Stack overflow={"hidden"}>
          <Typography
            variant={"body1"}
            fontWeight={600}
            color={"secondary"}
            textOverflow={"ellipsis"}
            noWrap
          >
            {subject}
          </Typography>
          <Typography
            variant={"caption"}
            fontWeight={500}
            color={"secondary.light"}
            textOverflow={"ellipsis"}
            noWrap
          >
            From: {from}
          </Typography>
        </Stack>
      </Stack>
      {action}
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme: { palette, spacing, breakpoints } }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: `1px solid ${palette.grey[200]}`,
  padding: spacing(6, 4),
  height: `${MOBILE_APP_HEADER_HEIGHT}px`,
  overflow: "hidden",
  [breakpoints.up("sm")]: {
    height: `${APP_HEADER_HEIGHT}px`,
  },
}));
