import { Participant, ParticipantRole } from "../types";
import { Collaborator } from "../../collaborators";

export const removeParticipant = (
  collaborator: Collaborator,
  participants: Record<string, Participant>
) => {
  return Object.entries(participants).reduce((acc, [key, value]) => {
    if (collaborator.userId !== key) {
      return { ...acc, [key]: value };
    }

    return acc;
  }, {});
};

export const appendParticipant = (
  collaborator: Collaborator,
  participants: Record<string, Participant>
) => ({
  ...participants,
  [collaborator.userId]: {
    ...collaborator,
    role: ParticipantRole.Collaborator,
  },
});
