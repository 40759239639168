import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { QueryKey, useApi } from "shared/api";
import { paths } from "shared/router";
import { config } from "../../config";
import { User, UserSetupRequest } from "../types";
import { useUser } from "../useUser";

export const useSetupUser = () => {
  const queryClient = useQueryClient();
  const { post } = useApi();
  const { user } = useUser();
  const navigate = useNavigate();

  return useQuery(
    QueryKey.UserSetup,
    () =>
      post<UserSetupRequest, User>(config.endpoints.user.v1.setup)({
        userConsent: true,
        emailContacts: [],
        skipInbox: false,
      }),
    {
      enabled: !user.isSetup && !!user.email,
      onSuccess: () => {
        navigate(paths.app.welcome);
        queryClient.invalidateQueries(QueryKey.User);
      },
    }
  );
};
