import { Collaborator } from "shared/collaborators";
import { Invitation, OutgoingInvitation } from "shared/invitations";

export type Contact = Collaborator | OutgoingInvitation;

export const isCollaborator = (contact: Contact): contact is Collaborator => {
  return Boolean((contact as Collaborator).name);
};

export const isInvitation = (contact: Contact): contact is Invitation => {
  return Boolean((contact as Invitation).userName);
};