import React from "react";
import {
  createContext,
  useContext,
  useContextSelector,
} from "use-context-selector";

import {
  useLocalStorage,
} from "shared/utils";
import {
  AuthContextState,
  AuthData,
  AuthDataType,
  initialAuthData,
} from "../types";
import {useListenToLogoutEvent} from "../hooks";

const initialState: AuthContextState = {
  isAuthenticated: false,
  authData: initialAuthData,
  setAuthData: () => {},
};

const AppAuthContext = createContext(initialState);

export const AppAuthProvider: React.FC = ({ children }) => {

  useListenToLogoutEvent();

  const [authData, setAuthData] = useLocalStorage<AuthData>(
    AuthDataType.App,
    initialAuthData
  );

  return (
    <AppAuthContext.Provider
      value={{
        authData,
        isAuthenticated: !!authData.accessToken,
        setAuthData,
      }}
    >
      {children}
    </AppAuthContext.Provider>
  );
};

export const useIsAuthenticatedForApp = () =>
  useContextSelector(AppAuthContext, ({ isAuthenticated }) => isAuthenticated);


export const useSetAppAuthData = () =>
  useContextSelector(AppAuthContext, ({ setAuthData }) => setAuthData);

export const useAppAuthContext = () => useContext(AppAuthContext);

export const useAppLogout = () => {
  const setAuthData = useSetAppAuthData();

  return () => setAuthData(initialAuthData);
};
