import {
  gmailConfig,
  GmailMessage,
  GmailMessageBody,
  getGmailApiConfig,
  formatGmailBase64String,
} from "shared/gmail";
import { useGoogleApi } from "shared/google-api";

export const useGmailMessageAttachments = () => {
  const { get } = useGoogleApi();

  return (message: GmailMessage) =>
    Promise.all(
      message._attachments
        .filter(({ body }) => Boolean(body.attachmentId))
        .map(({ body, filename, mimeType }) => {
          return get<Required<GmailMessageBody>>(
            gmailConfig.endpoints.messageAttachment
              .replace(/:userId/, "me")
              .replace(/:messageId/, message.id)
              .replace(/:attachmentId/, body.attachmentId!),
            {
              ...getGmailApiConfig(),
            }
          )()
            .then(async (response) => {
              const base64Url = `data:${mimeType};base64,${formatGmailBase64String(
                response.data
              )}`;

              return fetch(base64Url)
                .then(async (response) => await response.blob())
                .then((blob) => {
                  return new File([blob], filename, { type: mimeType });
                })
                .catch(() => {
                  return undefined;
                });
            })
            .catch(() => {
              return undefined;
            });
        })
    );
};
