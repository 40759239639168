import React from "react";
import {
  createContext,
  useContext,
  useContextSelector,
} from "use-context-selector";

import { useAuthController } from "../hooks";
import { useRequestToken } from "../hooks/useRequestToken";
import { AuthController, initialAuthData } from "../types";

type AuthManagerState = {
  requestToken: () => Promise<string>;
} & AuthController;

const initialContext: AuthManagerState = {
  requestToken: () => Promise.resolve(""),
  setAuthData: () => {},
  getAuthData: () => initialAuthData,
  authData: initialAuthData,
  isAuthenticated: false,
};

const AuthManagerContext = createContext<AuthManagerState>(initialContext);

export const AuthManager: React.FC = ({ children }) => {
  const controller = useAuthController();
  const requestToken = useRequestToken(controller);

  const authManager = React.useMemo(
    () => ({
      requestToken,
      ...controller,
    }),
    [requestToken, controller]
  );


  return (
    <AuthManagerContext.Provider value={authManager}>
      {children}
    </AuthManagerContext.Provider>
  );
};

export const useAuthManager = () => useContext(AuthManagerContext);

export const useGetAuthData = () =>
  useContextSelector(AuthManagerContext, ({ getAuthData }) => getAuthData);

export const useSetAuthData = () =>
  useContextSelector(AuthManagerContext, ({ setAuthData }) => setAuthData);

export const useRequestAccessToken = () =>
  useContextSelector(AuthManagerContext, ({ requestToken }) => requestToken);

export const useIsAuthenticated = () =>
  useContextSelector(
    AuthManagerContext,
    ({ isAuthenticated }) => isAuthenticated
  );
