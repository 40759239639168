import { Conversation } from "@twilio/conversations";
import React from "react";
import { useTrackFile } from "shared/analytics";
import { createMediaMessage } from "shared/twilio";
import { isImage, resizeImage } from "shared/utils";

export function useAttachments() {
  const trackFile = useTrackFile("send");

  const [attachments, setAttachments] = React.useState<File[] | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  return React.useMemo(
    () => ({
      isLoading,
      attachments,
      setAttachments,
      sendAttachments: async (c: Conversation) => {
        if (!attachments || isLoading) {
          return;
        }

        setIsLoading(true);

        const resizeResults = await Promise.allSettled(
          attachments.map((f) =>
            isImage(f.type) ? resizeImage(f) : Promise.resolve(f)
          )
        );

        const resizedAttachments = resizeResults.map((r, i) =>
          r.status === "fulfilled" ? r.value : attachments[i]
        );

        for (const attachment of resizedAttachments) {
          await c.sendMessage(createMediaMessage(attachment));
          trackFile(attachment);
        }

        setAttachments(null);

        setIsLoading(false);
      },
      deleteAttachments: (a?: File) => {
        if (!a || !attachments) {
          return setAttachments(null);
        }

        const newAttachments = attachments.filter((f) => f !== a);
        setAttachments(newAttachments.length ? newAttachments : null);
      },
    }),
    [setIsLoading, isLoading, attachments, setAttachments]
  );
}
