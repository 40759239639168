import React from "react";
import { Box, Skeleton, Stack, StackProps } from "@mui/material";

export const MessagesListItemSkeleton: React.VFC<StackProps> = (props) => (
  <Stack
    direction={"row"}
    height={140}
    width={1}
    spacing={5}
    padding={6}
    alignItems={"flex-start"}
    {...props}
  >
    <Skeleton variant="circular" width={40} height={40} />
    <Box flex={1} height={1}>
      <Skeleton width={200} />
      <Skeleton width={200} />
      <Skeleton height={50} />
    </Box>
    <Skeleton width={50} />
  </Stack>
);
