import { styled, Typography } from "@mui/material";
import { AppHeader } from "shared/components/AppHeader";

export const SettingsHeader = () => {
  return (
    <StyledPageHeader>
      <Typography variant="h2" noWrap>
        Settings
      </Typography>
    </StyledPageHeader>
  );
};

export const StyledPageHeader = styled(AppHeader)(
  ({ theme: { palette, breakpoints, spacing, typography } }) => ({
    flexGrow: 0,
    [breakpoints.down("sm")]: {
      borderBottom: "none",
    },
    "& .MuiTypography-root": {
      color: palette.secondary.main,
      fontSize: typography.h5.fontSize,
      fontWeight: 600,
      marginLeft: spacing(2),
      [breakpoints.up("sm")]: {
        marginLeft: spacing(4.5),
      },
      [breakpoints.up("md")]: {
        marginLeft: spacing(1),
      },
    },
  })
);
