import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getConversationPath, paths } from "shared/router";
import { useIsAuthenticatedForApp } from "shared/auth";
import { useAcceptPublicInvitation } from "shared/public-invitation";

export const useInvitationParamsGuard = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams({});
  const isAuthorized = useIsAuthenticatedForApp();
  const invitationId = params.get("invitationId");

  const { acceptPublicInvitation } = useAcceptPublicInvitation({
    onError: () => navigate(paths.home),
    onSuccess: (res) => {
      if (res.chatSid) {
        navigate(getConversationPath(res.chatSid));
      } else {
        navigate(paths.home);
      }
    },
  });

  useEffect(() => {
    if (!invitationId) {
      return navigate(paths.home);
    }

    if (isAuthorized) {
      acceptPublicInvitation();
      return;
    }

    navigate({
      pathname: paths.auth.login,
      search: `?invitationId=${invitationId}`,
    });
  }, [invitationId, acceptPublicInvitation]);
};
