import React from "react";
import { useToggleEmailPreview } from "shared/chat";
import {
  EmailMediaResponse,
  MediaBinViewProps,
  MediaLoader,
  MediaType,
  MediaViewType,
  useMediaList,
} from "../shared";
import { MediaBinEmailItem } from "./MediaBinEmailItem";

export const EmailsBin = ({ chatSid }: MediaBinViewProps) => {
  const { data, length, fetchNextPage, hasNextPage, isLoading } =
    useMediaList<EmailMediaResponse>(chatSid, MediaType.email);

  const toggleEmailPreview = useToggleEmailPreview();

  return (
    <MediaLoader
      hasMore={hasNextPage}
      type={MediaViewType.emails}
      loadMore={fetchNextPage}
      dataLength={length}
      isLoading={isLoading}
    >
      {data.map((item) => (
        <MediaBinEmailItem
          {...item.message}
          key={item.id}
          onClick={toggleEmailPreview}
        />
      ))}
    </MediaLoader>
  );
};
