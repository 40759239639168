import React from "react";
import { Media } from "@twilio/conversations";
import { Box, Skeleton, styled } from "@mui/material";

import { getMediaAsObjectUrl } from "shared/twilio";
import { useToggleMediaPreview } from "shared/chat/context";
import { getPreviewType, MediaPreviewType } from "shared/chat/media-preview";

export const ImageQuotePreview = ({ image }: { image: Media }) => {
  const mounted = React.useRef(true);

  const togglePreview = useToggleMediaPreview();
  const canPreview =
    getPreviewType(image.contentType) !== MediaPreviewType.unknown;

  const handleClick = (e: { stopPropagation: () => void }) => {
    if (!canPreview) {
      return;
    }
    e.stopPropagation();
    togglePreview(image);
  };

  const [isLoading, setIsLoading] = React.useState(false);
  const [url, setUrl] = React.useState("");

  React.useEffect(() => {
    setIsLoading(true);

    getMediaAsObjectUrl(image)
      .then((href) => {
        if (mounted.current) {
          setUrl(href);
        }
      })
      .finally(() => {
        if (mounted.current) {
          setIsLoading(false);
        }
      });
  }, [image]);

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <Box
      borderRadius={2}
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        height: 44,
        width: 44,
        position: "relative",
        color: "black",
        overflow: "hidden",
        cursor: canPreview ? "pointer" : "default",
      }}
      onClick={handleClick}
    >
      {" "}
      {isLoading ? (
        <Skeleton height={1} width={1} variant="rectangular" />
      ) : (
        <StyledImage src={url} alt={`image_preview: ${image.filename}`} />
      )}
    </Box>
  );
};

const StyledImage = styled("img")(() => ({
  height: "100%",
  width: "100%",
  objectFit: "cover",
  borderRadius: 8,
}));
