import React from "react";
import { IconButton } from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreVert";

import { OutgoingInvitation, useCancelInvitation } from "shared/invitations";
import { useIsBreakpoint } from "shared/theme";
import { QueryDataWrapper } from "shared/api";
import { LoadingIndicator } from "shared/components";
import { UserAvatar } from "shared/user";
import {
  ContactActions,
  ContactData,
  ContactItem,
  ContactName,
  StatusBadge,
  getMenuButtonId,
  CollaboratorItemMenu,
} from "shared/contacts";

type OutgoingInvitationItemProps = {
  outgoingInvitation: OutgoingInvitation;
};

export const OutgoingInvitationItem: React.FC<OutgoingInvitationItemProps> = ({
  outgoingInvitation,
}) => {
  const badgeRef = React.useRef<HTMLDivElement>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { isLoading, cancelInvitation } = useCancelInvitation(
    outgoingInvitation.invitationId
  );

  const desktop = !useIsBreakpoint("md");

  const handleClick = () => {
    if (badgeRef.current) {
      setAnchorEl(badgeRef.current);
    }
  };

  const onRemove = async () => {
    setAnchorEl(null);
    cancelInvitation();
  };

  return (
    <ContactItem
      desktop={desktop}
      id={getMenuButtonId(outgoingInvitation.invitationId)}
      onClick={handleClick}
      disabled={isLoading}
    >
      <ContactData>
        <UserAvatar
          userName={outgoingInvitation.userName}
          userId={outgoingInvitation.userId}
          avatarColor={outgoingInvitation._avatarColor}
          size={40}
        />
        <ContactName noWrap>{outgoingInvitation.userName}</ContactName>
      </ContactData>
      <ContactActions>
        <QueryDataWrapper
          result={{
            data: null,
            error: null,
            isLoading,
          }}
          loadingView={<LoadingIndicator height={40} />}
        >
          {() => (
            <>
              <StatusBadge ref={badgeRef}>Pending</StatusBadge>
              {desktop && (
                <IconButton
                  disableRipple
                  onClick={handleClick}
                  id={getMenuButtonId(outgoingInvitation.invitationId)}
                >
                  <MoreIcon />
                </IconButton>
              )}
            </>
          )}
        </QueryDataWrapper>
      </ContactActions>
      <CollaboratorItemMenu
        anchor={anchorEl}
        onClose={() => setAnchorEl(null)}
        id={outgoingInvitation.invitationId}
        onRemove={onRemove}
      />
    </ContactItem>
  );
};
