import React from "react";
import { Typography } from "@mui/material";
import {colors} from "shared/theme";

export const AuthHeader: React.FC = ({ children }) => {
  return (
    <Typography
      variant="h3"
      sx={{
        fontSize: 22,
        lineHeight: "38px",
        fontWeight: 600,
        marginBottom: 8,
        color: colors.haleNavy.main
      }}
    >
      {children}
    </Typography>
  );
};
