import React from "react";
import { Checkbox as MuiCheckbox, CheckboxProps, styled } from "@mui/material";
import { CheckCircle, CircleOutlined } from "@mui/icons-material";

export const Checkbox = styled((props: CheckboxProps) => (
  <MuiCheckbox
    icon={<CircleOutlined />}
    checkedIcon={<CheckCircle />}
    {...props}
  />
))(() => ({}));
