import React from "react";
import { UseBaseQueryOptions, useMutation, useQueryClient } from "react-query";
import { PublicInvitation } from "./types";
import { QueryKey, useApi } from "../api";
import { config } from "../config";
import { usePublicInvitationParams } from "./usePublicInvitationParams";
import { useShowNotification } from "shared/notifications";
import { InvitationType } from "../invitations";

export const useAcceptPublicInvitation = (
  options: UseBaseQueryOptions<PublicInvitation> = {}
) => {
  const { invitationId } = usePublicInvitationParams();

  const queryClient = useQueryClient();

  const { post } = useApi();

  const showNotification = useShowNotification();

  const { mutateAsync, isLoading } = useMutation(
    post<{}, PublicInvitation>(
      config.endpoints.publicInvitations.v1.accept.replace(
        /:publicInvitationId/,
        invitationId || ""
      )
    )
  );

  const acceptPublicInvitation = React.useCallback(() => {
    if (!invitationId) {
      return Promise.resolve();
    }

    return mutateAsync(
      {},
      {
        ...options,
        onSuccess: (response) => {
          const chatSid = response.chatSid;

          if (response.invitationSent) {
            const notification = chatSid
              ? `Invitation sent. You will be added to conversation when ${response.user.name} accepts it`
              : `Invitation sent for ${response.user.name}`;
            showNotification(notification);

            queryClient.invalidateQueries([QueryKey.OutgoingInvitations]);
          }

          if (chatSid) {
            queryClient.resetQueries(QueryKey.Conversations);
            showNotification("You can enter conversation now");
          }

          options.onSuccess?.(response);
        },
      }
    );
  }, [invitationId]);

  return {
    acceptPublicInvitation,
    isLoading,
  };
};
