import React from "react";
import { alpha, Avatar, AvatarProps } from "@mui/material";
import {colors} from "shared/theme";

type UnreadMessagesBadgeProps = AvatarProps & {
  count?: number;
};

export const UnreadMessagesBadge: React.FC<UnreadMessagesBadgeProps> = ({
  count,
  ...props
}) => {
  return count ? (
    <Avatar
      data-testid="unread-messages-badge"
      {...props}
      sx={{
        bgcolor: alpha(colors.redAlert, .9),
        width: 20,
        height: 20,
        fontSize: 12,
        ...props.sx,
      }}
    >
      {count}
    </Avatar>
  ) : (
    <></>
  );
};
