import React from "react";
import { useLocation } from "react-router-dom";

import { isInPublicChat } from "shared/public-chat";
import { localStorageService, sessionStorageService } from "shared/utils";
import { useAppAuthContext } from "../context/AppAuth";
import {
  usePublicChatAuthContext,
} from "../context/PublicChatAuth";
import {
  AuthController,
  AuthData,
  AuthDataType,
  initialAuthData,
} from "../types";

export function useAuthController(): AuthController {
  const { pathname, search } = useLocation();

  const { authData: appData, setAuthData: setAppData } = useAppAuthContext();
  const { authData: publicChatData, setAuthData: setPublicChatData } =
    usePublicChatAuthContext();

  return React.useMemo(() => {
    const inPublicChat = isInPublicChat();

    return {
      authData: inPublicChat ? publicChatData : appData,
      setAuthData: inPublicChat ? setPublicChatData : setAppData,
      isAuthenticated: Boolean(
        inPublicChat ? publicChatData.accessToken : appData.accessToken
      ),
      /**
       * This method solves problem of react asynchronous setState.
       * Immediately after calling setAuthData in login hook, app needs to send some request.
       * However Authorization header are taken from state, which is set asynchronous. That cause 401
       * errors. So to solve it, this method takes authData directly from local/session storage
       */
      getAuthData: () =>
        inPublicChat
          ? sessionStorageService.getItem<AuthData>(
              AuthDataType.PublicChat,
              initialAuthData
            )
          : localStorageService.getItem<AuthData>(
              AuthDataType.App,
              initialAuthData
            ),
    };
  }, [appData, publicChatData, pathname, search]);
}
