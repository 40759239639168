import { useSearchParams } from "react-router-dom";

export const usePublicChatParams = () => {
  const [params] = useSearchParams({});

  return {
    code: params.get("code"),
    chat: params.get("chat"),
  };
};
