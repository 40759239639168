import { JSONValue } from "@twilio/conversations";
import { Participant } from "shared/conversations";
import { User } from "shared/user";
import { HasCalledUsers } from "./model";

/**
 * Check if user name is called in message
 * @param
 * @returns false if user name is not called or a name that is being called without "@" sign
 */
export function isUserNameCalled(message: string) {
  const atSignIndex = message.lastIndexOf("@");
  if (atSignIndex === -1) {
    return false;
  }
  
  if (atSignIndex !== 0 && !/\s/.test(message[atSignIndex - 1])) {
    return false
  }

  const term = message.slice(atSignIndex);
  if (term.length < 4) {
    return false;
  }

  if (term.split(" ").length > 2) {
    return false;
  }

  if (/\t|\r\n|\n/.test(term)) {
    return false;
  }

  return term.replace("@", "");
}

export const findCallableParticipants = ({
  participants,
  searchTerm,
  callingUser,
}: {
  participants: Participant[];
  searchTerm: string;
  callingUser: User;
}) => {
  return participants
    .filter((p) => p.userId !== callingUser.id)
    .filter((p) => p.name.toLowerCase().startsWith(searchTerm.toLowerCase()));
};

export const hasCalledUsers = (
  attributes: JSONValue
): attributes is HasCalledUsers => {
  return (attributes as HasCalledUsers).calledUsers !== undefined;
};
