import React from "react";
import { Box, Skeleton, styled } from "@mui/material";
import { Media, Message } from "@twilio/conversations";
import { isImage } from "shared/utils";
import { useGetMediaAsObjectUrl } from "shared/twilio";
import { AttachmentMessage } from "../shared";
import { useToggleMediaPreview } from "../../context";

type MediaWithPreviewMessageProps = {
  message: Message;
  isMine: boolean;
};

export const MediaWithPreviewMessage: React.FC<
  MediaWithPreviewMessageProps
> = ({ message, isMine }) => {
  const attachments = message.attachedMedia;

  return (
    <Box>
      {attachments?.map((media) => (
        <Box key={media.sid}>
          {isImage(media.contentType) ? (
            <ImagePreview media={media} />
          ) : (
            <AttachmentMessage media={media} isMine={isMine} />
          )}
        </Box>
      ))}
    </Box>
  );
};

const ImagePreview: React.FC<
  React.HTMLAttributes<HTMLImageElement> & { media: Media }
> = ({ media, ...props }) => {
  const toggleMediaPreview = useToggleMediaPreview();
  const src = useGetMediaAsObjectUrl(media);

  return (
    <>
      {!src && (
        <Skeleton
          height={280}
          width={280}
          sx={{ borderRadius: "16px" }}
          variant="rectangular"
        />
      )}
      <StyledImage
        onClick={() => toggleMediaPreview(media)}
        width={280}
        src={src}
        alt={"image_preview"}
        sx={{
          display: !src ? "none" : "block",
          userSelect: "none",
          cursor: "pointer",
        }}
        {...props}
      />
    </>
  );
};

const StyledImage = styled("img")(({theme: {breakpoints}}) => ({
  borderRadius: 12,
  width: "100%",
  maxWidth: "100%",
  [breakpoints.up("sm")]: {
    maxWidth: 350,
  }
}));
