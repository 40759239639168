import React from "react";

import { useParseLink } from "./useParseLink";
import { LinkType } from "./types";
import { TextLinkPreview } from "./TextLinkPreview";
import { YouTubeLinkPreview } from "./YouTubeLinkPreview";

export type LinkPreviewProps = {
  body: string;
  isPublicChat: boolean;
};

export const LinkPreview: React.VFC<LinkPreviewProps> = ({
  body,
  isPublicChat,
}) => {
  const linkData = useParseLink(body);

  if (!linkData) {
    return null;
  }

  if (linkData.type === LinkType.YouTube) {
    return <YouTubeLinkPreview videoId={linkData.videoId} />;
  }

  if (isPublicChat) {
    return null;
  }

  return <TextLinkPreview link={linkData.link} />;
};
