import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { searchToPath } from "./searchToPath";

export function useSearchToPath(search: string) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    const param = searchParams.get(search);
    const transformPath = searchToPath.get(search);

    if (param && transformPath) {
      navigate(transformPath(param));
    }
  }, [searchParams, search, navigate]);
}
