import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MediaViewType } from "../model";
import { getMediaBinHash } from "../utils";

export function useMediaViewHash() {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const hashName = hash.replace("#", "");

  React.useEffect(() => {
    if (!getMediaBinHash(hash)) {
      navigate({ hash: MediaViewType.images });
    }
  }, [hash]);

  return (hashName || MediaViewType.images) as MediaViewType;
}
