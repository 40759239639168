import { useGoogleProfilePicture } from "shared/google-api";
import { useUserAvatar } from "./useUserAvatar";

type WithAvatarDataProps = {
  children: (props: {
    data?: string;
    isLoading: boolean;
    isError: boolean;
  }) => JSX.Element;
  userId: string;
};

export const WithAvatarData = ({ children, userId }: WithAvatarDataProps) => {
  const result = useUserAvatar(userId);
  const googlePictureSrc = useGoogleProfilePicture(userId);
  const data = result.data
    ? `data:image/jpeg;base64,${result.data}`
    : googlePictureSrc || "/";

  return children({
    data,
    isLoading: result.isLoading,
    isError: result.isError,
  });
};
