import { Box, styled } from "@mui/material";
import { MessagesListItem } from "shared/components";
import { GmailMessageAvatar } from "shared/gmail";
import { EmailMediaResponse } from "../shared/model";

export type MediaBinEmailItemProps = EmailMediaResponse["message"] & {
  onClick: (id?: string) => void;
};
export const MediaBinEmailItem = ({
  onClick,
  ...message
}: MediaBinEmailItemProps) => {
  return (
    <MessageItemWrapper data-testid="media_bin_email_item">
      <MessagesListItem
        AvatarEl={
          <GmailMessageAvatar
            from={message.fromEmail}
            color={message._avatarColor}
          />
        }
        title={message.subject}
        subtitle={message.fromEmail}
        timestamp={message.externalCreationDate}
        text={message.snippet}
        onClick={() => onClick(message.externalProviderMsgId)}
      />
    </MessageItemWrapper>
  );
};

const MessageItemWrapper = styled(Box)(({ theme: { spacing, palette } }) => ({
  width: "100%",
  border: `1px solid ${palette.grey[200]}`,
  borderLeft: `4px solid ${palette.info.main}`,
  borderRadius: spacing(2),
  height: 100,
  overflow: "hidden",
}));
