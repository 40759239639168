import React from "react";
import { Media } from "@twilio/conversations";
import {
  getMessageByIndex,
  useTwilioConversationSelector,
} from "shared/twilio";
import { useSetStateAsync } from "shared/utils";

type TwilioMediaState = {
  media: Media | null;
  isError: boolean;
  isLoading: boolean;
};

export function useLoadMedia(index: number, mediaSid: string) {
  const setStateAsync = useSetStateAsync();

  const [state, setState] = React.useState<TwilioMediaState>({
    media: null,
    isError: false,
    isLoading: true,
  });
  const conversation = useTwilioConversationSelector("twilioConversation");

  React.useEffect(() => {
    getMessageByIndex(index, conversation)
      .then((data) => {
        const media = data?.attachedMedia?.find((m) => m.sid === mediaSid);

        if (!media) {
          throw new Error("Failed to load media");
        }

        setStateAsync(() =>
          setState({
            media,
            isError: false,
            isLoading: false,
          })
        );
      })
      .catch(() =>
        setStateAsync(() =>
          setState((prev) => ({ ...prev, isError: true, isLoading: false }))
        )
      );
  }, [conversation, index, mediaSid]);

  return state;
}
