import React from "react";
import {
  Client,
  Conversation,
  ConversationUpdateReason,
  Message,
} from "@twilio/conversations";
import { indexBy, prop } from "ramda";
import { matchPath, useLocation } from "react-router-dom";

import { getConversationPath, paths } from "shared/router";
import { useIsTabActive } from "shared/utils";
import { useUser } from "shared/user";

import { ConversationMetaData } from "../model";
import { getTwilioUpdateDate, hasEmail } from "../utils";

export function useHandleConversationsMetadata(client?: Client) {
  const isOnConversationPage = useIsOnChatPage();
  const location = useLocation();
  const isTabActive = useIsTabActive();
  const { user } = useUser();

  const [conversationsMetaData, setMetadata] = React.useState<
    Record<string, ConversationMetaData>
  >({});

  // Load initial metadata for all conversations
  React.useEffect(() => {
    if (!client) {
      return;
    }

    client.getSubscribedConversations().then(async ({ items }) => {
      const metaData = await Promise.all(
        items.map(async (item) => ({
          sid: item.sid,
          unreadMessagesCount: (await item.getUnreadMessagesCount()) || 0,
          lastMessage: null,
          updateDate: getTwilioUpdateDate(item),
        }))
      );
      setMetadata(indexBy(prop("sid"), metaData));
    });

    return () => {
      setMetadata({});
    };
  }, [client]);

  // Update metadata on new message
  React.useEffect(() => {
    if (!client) {
      return;
    }

    const handleMessageAdded = ({
      conversation,
      body,
      media,
      attributes,
      author,
    }: Message) => {
      setMetadata((prev) => {
        const prevMetaData = prev[conversation.sid] || {
          unreadMessagesCount: 0,
          lastMessage: "",
          updateDate: new Date(),
        };

        const isActive = isOnConversationPage(conversation.sid) && isTabActive;

        const isMineMessage = author === user.id;

        const unreadMessagesCount =
          isActive || isMineMessage ? 0 : prevMetaData.unreadMessagesCount + 1;

        const lastMessage = hasEmail(attributes)
          ? "User sent an email."
          : media
          ? "User sent a file."
          : body;

        return {
          ...prev,
          [conversation.sid]: {
            ...prevMetaData,
            lastMessage,
            unreadMessagesCount,
            updateDate: new Date(),
          },
        };
      });
    };

    client.on("messageAdded", handleMessageAdded);

    return () => {
      client.removeListener("messageAdded", handleMessageAdded);
    };
  }, [client, location, isTabActive, isOnConversationPage, user]);

  // Update metadata on conversation removed
  React.useEffect(() => {
    if (!client) {
      return;
    }

    const handleConversationRemoved = (conversation: Conversation) => {
      setMetadata((prev) => {
        const { [conversation.sid]: removedSid, ...next } = prev;

        return next;
      });
    };

    client.on("conversationRemoved", handleConversationRemoved);

    return () => {
      client.removeListener("conversationRemoved", handleConversationRemoved);
    };
  }, [client]);

  // Update metadata on conversation updated
  React.useEffect(() => {
    if (!client) {
      return;
    }

    const handleConversationUpdated = async ({
      conversation,
      updateReasons,
    }: {
      conversation: Conversation;
      updateReasons: ConversationUpdateReason[];
    }) => {
      if (!updateReasons.includes("lastReadMessageIndex")) {
        return;
      }

      if (
        conversation.lastReadMessageIndex !== conversation.lastMessage?.index
      ) {
        return;
      }

      setMetadata((prev) => {
        const prevMetaData = prev[conversation.sid];

        return {
          ...prev,
          [conversation.sid]: clearUnreadMessages(prevMetaData),
        };
      });
    };

    client.on("conversationUpdated", handleConversationUpdated);

    return () => {
      client.removeListener("conversationUpdated", handleConversationUpdated);
    };
  }, [client, isOnConversationPage, isTabActive]);

  return React.useMemo(
    () => ({
      conversationsMetaData,
      resetUnreadMessagesCount: (conversationId: string) => {
        if (!client) {
          return;
        }

        const conversation = conversationsMetaData[conversationId];

        if (!conversation || conversation.unreadMessagesCount === 0) {
          return;
        }

        setMetadata((prev) => {
          const prevMetaData = prev[conversationId];

          return {
            ...prev,
            [conversationId]: clearUnreadMessages(prevMetaData),
          };
        });
      },
      setMetadata,
    }),
    [conversationsMetaData, client]
  );
}

const useIsOnChatPage = () => {
  const location = useLocation();

  return (conversationId: string) => {
    return (
      Boolean(
        matchPath(getConversationPath(conversationId), location.pathname)
      ) || Boolean(location.pathname.includes(paths.publicChat.main))
    );
  };
};

const clearUnreadMessages = (metadata: ConversationMetaData) => {
  return {
    ...metadata,
    unreadMessagesCount: 0,
  };
};
