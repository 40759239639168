import {
  ListItem,
  ListItemButton,
  styled,
  Typography,
  Box,
  Menu,
  Button,
  lighten,
  darken,
} from "@mui/material";

export const StyledListButton = styled(ListItemButton)(
  ({ theme: { spacing, breakpoints } }) => ({
    width: "100%",
    display: "flex",
    paddingLeft: 0,
    justifyContent: "space-between",
    marginBottom: spacing(2),
    [breakpoints.up("md")]: {
      paddingRight: 0,
    },
  })
);
export const StyledListItem = styled(ListItem)(
  ({ theme: { spacing, breakpoints } }) => ({
    width: "100%",
    display: "flex",
    paddingLeft: 0,
    justifyContent: "space-between",
    marginBottom: spacing(2),
    [breakpoints.up("md")]: {
      paddingRight: 0,
    },
  })
);

export const ContactData = styled(Box)({
  display: "flex",
  alignItems: "center",
});
export const ContactName = styled(Typography)(
  ({ theme: { palette, spacing } }) => ({
    marginLeft: spacing(3),
    fontWeight: 600,
    color: palette.grey[600],
    fontSize: 16,
    lineHeight: "24px",
    maxWidth: 180,
  })
);

export const StatusBadge = styled(Box)(({ theme: { palette, spacing } }) => ({
  textTransform: "capitalize",
  fontSize: 12,
  lineHeight: "24px",
  padding: spacing(0, 3),
  borderRadius: spacing(2),
  backgroundColor: palette.grey[200],
}));

export const ContactActions = styled(Box)(
  ({ theme: { palette, spacing } }) => ({
    display: "flex",
    alignItems: "center",
    "& .MuiButtonBase-root:hover": {
      backgroundColor: "transparent",
    },
  })
);

export const StyledContactMenu = styled(Menu)(
  ({ theme: { palette, spacing } }) => ({
    "& .MuiList-root": {
      padding: 0,
    },
    "& .MuiPaper-root": {
      width: 215,
      border: "0.5px solid #EDEDED",
      borderRadius: spacing(4),
      boxShadow: "none",
    },
    "& .MuiMenuItem-root": {
      color: palette.grey[600],
      fontSize: 16,
      justifyContent: "center",
    },
    "& .MuiMenuItem-root.delete": {
      color: "#FD2C5E",
    },
    "& .MuiMenuItem-root:not(:last-of-type)": {
      borderBottom: "0.5px solid #EDEDED",
    },
  })
);

export const ContactItem: React.FC<{
  id: string;
  desktop: boolean;
  onClick: () => void;
  disabled: boolean;
}> = ({ children, desktop, onClick, id, disabled }) => {
  if (desktop) {
    return <StyledListItem>{children}</StyledListItem>;
  }

  return (
    <StyledListButton onClick={onClick} id={id} disabled={disabled}>
      {children}
    </StyledListButton>
  );
};


