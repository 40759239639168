export enum InvitationType {
  Contact = 'contact',
  Conversation = 'conversation'
}

export type IncomingInvitation = {
  userId: string;
  userName: string;
  avatarId: string;
  invitationId: string;
  _avatarColor: string;
};

export type OutgoingInvitation = {
  userId: string;
  userName: string;
  avatarId: string;
  invitationId: string;
  _avatarColor: string;
};

export type Invitation = IncomingInvitation | OutgoingInvitation;

export type RejectInvitationResponse = {
  status: 200;
  message: string;
  invitation: string;
}

export type AcceptInvitationResponse = RejectInvitationResponse;


export enum ParticipantStatus {
  Connected="connected",
  Restricted="restricted",
  Pending="pending",
  Visitor="visitor",
  Invited="invited",
  InvitedBy="invitedBy",
  Me="me",
  None="none",
}

export type InviteUserRequest = {
  userId: string;
}

export type InviteUserResponse = {
  status: 201,
  message: string,
  invitationId: string,
  userId: string
}
