import { Box, styled } from "@mui/material";
import React from "react";
import {
  LoadingIndicator,
  Logo,
  MainButton,
  MainButtonProps,
} from "shared/components";
import { colors } from "shared/theme";
import { AuthLayout } from "./AuthLayout";

type InfoLayoutProps = {
  title: string;
  message?: string | JSX.Element;
  buttonLabel?: string;
  buttonOnClick?: () => void;
  children?: React.ReactNode;
  isLoading?: boolean;
  icon?: React.ReactNode;
  ButtonProps?: MainButtonProps;
};
export const InfoLayout = ({
  title,
  message,
  buttonLabel,
  buttonOnClick,
  children,
  isLoading,
  icon,
  ButtonProps,
}: InfoLayoutProps) => {
  return (
    <AuthLayout
      logo="none"
      sx={{
        background:
          "radial-gradient(67.19% 82.48% at 89.73% 26.08%, #f4ecfe 0%, rgba(255, 255, 255, 0) 100%)",
        backgroundColor: "#fff",
      }}
    >
      <Content>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <Wrapper>
            <Box>{icon || <Logo />}</Box>
            <Header>{title}</Header>
            <Message>{message}</Message>
            {children}
            <MainButton
              size="large"
              onClick={buttonOnClick}
              {...ButtonProps}
              sx={{ minWidth: 220, marginTop: { xs: "auto", sm: 12 }, ...ButtonProps?.sx }}
            >
              {buttonLabel || "Back to homepage"}
            </MainButton>
          </Wrapper>
        )}
      </Content>
    </AuthLayout>
  );
};

const Wrapper = styled("div")(({ theme: { breakpoints, spacing } }) => ({
  paddingTop: 0,
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  justifyContent: "flex-start",
  maxWidth: 480,
  [breakpoints.up("sm")]: {
    alignItems: "center",
  },
}));

const Header = styled("h3")(({ theme: { spacing } }) => ({
  fontWeight: 600,
  fontSize: 24,
  lineHeight: "38px",
  margin: "12px 0",
  padding: spacing(4, 6),
}));

const Message = styled("p")(({ theme: { spacing } }) => ({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: "28px",
  color: colors.haleNavy.main,
  letterSpacing: "0.03rem",
  margin: 0,
  padding: spacing(0, 6),
}));

const Content = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  padding: spacing(0, 4),
  width: "100%",
  display: "flex",
  flex: 1,
  justifyContent: "center",
  [breakpoints.up("sm")]: {
    paddingTop: spacing(40),
  },
}));
