import React from "react";
import { Client } from "@twilio/conversations";
import { useQueryClient } from "react-query";

import { QueryKey } from "shared/api";

import { useTwilioToken } from "./useTwilioToken";

export function useHandleTwilioClient() {
  const queryClient = useQueryClient();

  const [twilioClient, setTwilioClient] = React.useState<Client>();

  useTwilioToken({
    refetchOnWindowFocus: true,
    staleTime: 3_600_000,
    onSuccess: async ({ token }) => {
      if (!twilioClient || twilioClient?.connectionState === "disconnected") {
        return setTwilioClient(new Client(token));
      }
      await twilioClient.updateToken(token);
    },
  });

  React.useEffect(() => {
    if (!twilioClient) {
      return;
    }

    const handleTokenExpired = () => {
      setTwilioClient(undefined);
      return queryClient.invalidateQueries(QueryKey.TwilioToken);
    };

    const handleTokenAboutToExpire = () => {
      return queryClient.invalidateQueries(QueryKey.TwilioToken);
    };

    twilioClient.on("connectionError", handleTokenExpired);
    twilioClient.on("tokenExpired", handleTokenExpired);
    twilioClient.on("tokenAboutToExpire", handleTokenAboutToExpire);

    return () => {
      twilioClient.removeListener("connectionError", handleTokenExpired);
      twilioClient.removeListener("tokenExpired", handleTokenExpired);
      twilioClient.removeListener(
        "tokenAboutToExpire",
        handleTokenAboutToExpire
      );
    };
  }, [twilioClient]);

  return twilioClient;
}
