import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const UnknownFileIcon: React.FC<SvgIconProps> = (props) => {
  const fillProp = props.fill ?? "transparent";
  return (
    <SvgIcon
      width="29"
      height="29"
      viewBox="0 0 29 29"
      {...props}
      sx={{ fill: fillProp, ...props.sx }}
      aria-label="unknown file icon"
    >
      <circle cx="14.5" cy="14.5" r="14.5" />
      <path
        d="M10.875 19.4375V8.5625C10.875 8.35539 11.0429 8.1875 11.25 8.1875H16.3613C16.4508 8.1875 16.5374 8.21952 16.6053 8.27778L18.994 10.3253C19.0772 10.3965 19.125 10.5005 19.125 10.61V19.4375C19.125 19.6446 18.9571 19.8125 18.75 19.8125H11.25C11.0429 19.8125 10.875 19.6446 10.875 19.4375Z"
        stroke="currentColor"
        strokeWidth="0.525"
      />
      <path
        d="M18.975 11.1875H16.275C16.1922 11.1875 16.125 11.1203 16.125 11.0375V8.5625L19.0545 10.3934C19.0984 10.4208 19.125 10.4689 19.125 10.5206V11.0375C19.125 11.1203 19.0578 11.1875 18.975 11.1875Z"
        fill="currentColor"
      />
      <path
        d="M16.125 8.1875V8.5625M16.125 8.5625V11.0375C16.125 11.1203 16.1922 11.1875 16.275 11.1875H18.975C19.0578 11.1875 19.125 11.1203 19.125 11.0375V10.5206C19.125 10.4689 19.0984 10.4208 19.0545 10.3934L16.125 8.5625Z"
        stroke="currentColor"
        strokeWidth="0.525"
      />
    </SvgIcon>
  );
};
