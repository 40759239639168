export const MAX_FILE_SIZE_IN_MB = 20;
export const MAX_IMAGE_SIZE_IN_MB = 5;
export const MAX_VIDEO_SIZE_IN_MB = 30;

export const WEBSITE_URL = "https://www.gomarkus.com";

export const MOBILE_APP_BAR_HEIGHT = 79;
export const APP_HEADER_HEIGHT = 100;
export const MOBILE_APP_HEADER_HEIGHT = 84;
export const DESKTOP_DRAWER_WIDTH = 153;
export const TABLET_DRAWER_WIDTH = 105;

export const MARKUS_LINKS = Object.freeze({
  disclosure: {
    url: `${WEBSITE_URL}/google-api-disclosure.html`,
    label: "google api disclosure",
  },
  termsOfService: {
    url: `${WEBSITE_URL}/terms-of-service.html`,
    label: "terms & service",
  },
  privacyPolicy: {
    url: `${WEBSITE_URL}/privacy.html`,
    label: "privacy policy",
  },
  about: {
    url: `${WEBSITE_URL}/about.html`,
    label: "about",
  },
  help: {
    url: `${WEBSITE_URL}/help.html`,
    label: "help",
  },
  contactUs: {
    url: "mailto:support@gomarkus.com",
    label: "contact us",
  },
});


export const AVATAR_COLORS = Object.freeze([
  "#ff847c",
  "#feceab",
  "#99b898",
  "#72a16f",
  "#fc9d9a",
  "#c8c8a9",
  "#b0e0e6",
  "#555a57",
  "#b69caa",
  "#aac6ca",
  "#d6a6a8",
  "#b4eeb4",
  "#088da5",
  "#468499",
  "#ffa500",
  "#66CCCC",
  "#065535"
]);
