import React from "react";
import { Box, Tooltip } from "@mui/material";
import { palette, useIsBreakpoint } from "shared/theme";
import { initialUser } from "shared/user";
import { EditCircleIcon, Input } from "shared/components";
import { ProfilePicture } from "./ProfilePicture";
import { useUserProfile } from "../hooks";
import {
  EditPhotoButton,
  ProfileContainer,
  ProfileDetails,
  ProfileEmail,
  ProfileInfo,
  ProfileName,
} from "./styles";

export const Profile = () => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const { currentName, setCurrentName, user, setProfileChanged, setAvatar } =
    useUserProfile();

  const isAboveSmBreakpoint = !useIsBreakpoint("sm");

  const { email, name, id } = user || initialUser;

  const editPhoto = () => {
    if (!inputRef.current) return;

    inputRef.current.click();
  };

  const onPhotoChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files![0];

    if (file) {
      setProfileChanged(true);
      setAvatar(file);
    }
  };

  const resetPhoto = () => {
    if (!inputRef.current) return;

    inputRef.current.value = "";

    setAvatar(null);
  };

  return (
    <ProfileContainer>
      <ProfileInfo>
        <ProfilePicture
          id={id}
          name={name}
          editPhoto={editPhoto}
          resetPhoto={resetPhoto}
        />
        <ProfileDetails>
          <ProfileName noWrap>{name}</ProfileName>
          <Tooltip title={email}>
            <ProfileEmail noWrap>{email}</ProfileEmail>
          </Tooltip>
        </ProfileDetails>
      </ProfileInfo>

      {isAboveSmBreakpoint && (
        <EditPhotoButton disableRipple variant="text" onClick={editPhoto}>
          <EditCircleIcon sx={{ color: "white", fill: palette.primary.main }} />
          <Box>Edit Photo</Box>
        </EditPhotoButton>
      )}
      <Box width={1} maxWidth={470}>
        <Input
          value={currentName}
          label="Username"
          onChange={(e) => setCurrentName(e.target.value)}
          onCancel={() => setCurrentName("")}
        />
      </Box>

      <input type="file" hidden ref={inputRef} onChange={onPhotoChange} />
    </ProfileContainer>
  );
};
