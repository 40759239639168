import React from "react";
import { UseBaseQueryOptions, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useApi, isApiError } from "shared/api";
import { config } from "shared/config";
import { AuthenticateResponse } from "shared/auth";
import { useShowError } from "shared/notifications";
import { paths } from "shared/router";
import { LocalAuthError, LoginRequest } from "../types";
import { useSetAppAuthData } from "../context/AppAuth";
import { useAcceptPublicInvitation } from "shared/public-invitation";
import { AnalyticsEvent, useTrackEvent } from "shared/analytics";

export function useLoginLocal() {
  const navigate = useNavigate();
  const { post } = useApi();
  const showError = useShowError();
  const setAuthData = useSetAppAuthData();

  const { acceptPublicInvitation } = useAcceptPublicInvitation();

  const { mutate, isLoading, error } = useMutation(
    post<LoginRequest, AuthenticateResponse>(config.endpoints.auth.login)
  );

  const trackLogin = useTrackEvent(AnalyticsEvent.user_login);

  const login = React.useCallback(
    (
      data: LoginRequest,
      options: UseBaseQueryOptions<AuthenticateResponse> = {}
    ) => {
      mutate(data, {
        ...options,
        onSuccess: (response) => {
          trackLogin();
          setAuthData(response);
          acceptPublicInvitation();
          navigate({
            pathname: paths.app.conversations,
            search: window.location.search,
          });
        },
        onError: (error: unknown) => {
          if (!isApiError(error)) {
            return showError("Failed to login", "Please try again later");
          }

          switch (error.message) {
            case LocalAuthError.LoginDisabled:
              return navigate(paths.auth.registered);
            case LocalAuthError.SocialLogin:
              return showError(
                "Failed to login",
                "You have signed up to Markus with Google or Apple before. Try to sign in with the method you used to create your account."
              );
            case LocalAuthError.Unauthorized:
              return showError(
                "Failed to login",
                "Your login info is not right. Try again, or reset your password if it slipped your mind."
              );
            default:
              return showError("Failed to login", "Please try again later");
          }
        },
      });
    },
    [mutate, navigate, acceptPublicInvitation, setAuthData]
  );

  return {
    login,
    isLoading,
    error,
  };
}
