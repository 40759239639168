import React from "react";
import {
  createContext,
  useContextSelector,
  useContext,
} from "use-context-selector";

import { Snackbar } from "shared/components";
import { NotificationContextState, Notification } from "./types";

const initialState: NotificationContextState = {
  showNotification: () => {},
};

const NotificationContext = createContext(initialState);

export type NotificationProviderProps = {
  children: React.ReactNode;
};

export const NotificationsProvider = ({
  children,
}: NotificationProviderProps) => {
  const [notificationQueque, setNotificationsQueque] = React.useState<
    Notification[]
  >([]);

  const [notification, setNotification] = React.useState<Notification | null>(
    null
  );

  const [open, setOpen] = React.useState(false);

  const onExit = () => {
    setNotification(null);
  };

  const context: NotificationContextState = React.useMemo(
    () => ({
      showNotification: (message: string, opts) => {
        setNotificationsQueque((prev) => [
          ...prev,
          { message, id: Date.now(), type: "info", ...opts },
        ]);
      },
    }),
    []
  );

  React.useEffect(() => {
    if (notificationQueque.length && !notification && !open) {
      setNotification(notificationQueque[0]);
      setNotificationsQueque((prev) => prev.slice(1));
      setOpen(true);
    }
  }, [notification, notificationQueque, open]);

  return (
    <NotificationContext.Provider value={context}>
      {children}
      <Snackbar
        title={notification?.message}
        text={notification?.extraInfo}
        type={notification?.type}
        disableAutoHide={notification?.disableAutoHide}
        onClose={() => setOpen(false)}
        open={open}
        onExit={onExit}
      />
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => useContext(NotificationContext);

export const useShowNotification = () =>
  useContextSelector(
    NotificationContext,
    ({ showNotification }) => showNotification
  );
