import React from "react";
import { styled, Tooltip, Typography, TypographyProps } from "@mui/material";
import { colors } from "../theme";

type ConversationTitleProps = TypographyProps;

const fallbackSubject = "No subject";

export const ConversationTitle: React.FC<ConversationTitleProps> = ({
  children,
  ...props
}) => {
  return (
    <Tooltip title={children || fallbackSubject}>
      <Title variant={"h3"} noWrap {...props}>
        {children || fallbackSubject}
      </Title>
    </Tooltip>
  );
};

const Title = styled(Typography)(() => ({
  fontSize: 16,
  lineHeight: "38px",
  fontWeight: 600,
  color: colors.haleNavy.dark,
}));
