import {AvatarProps} from "@mui/material";
import {AsyncAvatar, SimpleAvatar} from "shared/components";
import {WithAvatarData} from "./WithAvatarData";

export type UserAvatarProps = {
  userName: string;
  avatarColor?: string;
  userId?: string;
  size?: number;
  avatarData?: string;
};

export const UserAvatar: React.FC<UserAvatarProps & AvatarProps> = (props) => {
  const { avatarData, userId, ...rest } = props;

  if (avatarData || !userId) {
    const src = avatarData ? `data:image/jpeg;base64,${avatarData}` : "/";
    return <SimpleAvatar src={src} {...rest} />;
  }

  return (
    <WithAvatarData userId={userId}>
      {({ data, isLoading, isError }) => (
        <AsyncAvatar
          isError={isError}
          isLoading={isLoading}
          data={data}
          {...rest}
        />
      )}
    </WithAvatarData>
  );
};
