import { UseBaseQueryOptions } from "react-query";

export const AIResponseAttribute = "aiResponse";

export enum MessageAssistantState {
  Closed = "closed",
  Message = "Message",
  Spelling = "Spelling",
  Chat = "Chat",
}

export type MessageAssistantContextState = {
  state: MessageAssistantState;
  AIPermitted: boolean;
  changeState: (state: MessageAssistantState) => void;
  isLoading: boolean;
  fixSpelling: (text: string, opts: UseBaseQueryOptions<TextDTO>) => void;
  transformMessage: (
    data: TransformMessageRequest,
    opts: UseBaseQueryOptions<TextDTO>
  ) => void;
  sendPrompt: (
    data: PromptRequest,
    opts?: UseBaseQueryOptions<TextDTO>
  ) => void;
  setSpellingEnabled: (enabled: boolean) => void;
  spellingEnabled: boolean;
};

export type TextDTO = {
  text: string;
};

export type TransformMessageRequest = {
  text: string;
  prompt: string;
};

export type PromptRequest = {
  prompt: string;
  history?: PromptHistoryEntry[];
};

export type HasAIResponse = {
  aiResponse: string | null;
};

export type PromptHistoryEntry = {
  content: string;
  role: "user" | "assistant";
};

export const hasAIResponse = (
  attributes: unknown
): attributes is HasAIResponse => {
  return (attributes as HasAIResponse)[AIResponseAttribute] !== undefined;
};
