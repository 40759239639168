import React from "react";
import { Badge, SvgIconProps } from "@mui/material";
import { paths } from "shared/router";
import {
  DiscussIcon,
  MessageIcon,
  PublicIcon,
  SettingsIcon,
} from "shared/components";

export type AppMenuLink = {
  id: string;
  label: string;
  url: string;
  icon: React.FC<SvgIconProps>;
};

export const useAppMenuLinks = ({
  contactsBadge,
  messagesBadge,
}: {
  contactsBadge: boolean;
  messagesBadge: boolean;
}): AppMenuLink[] => {
  return React.useMemo(
    () => [
      {
        id: "discuss",
        label: "Discuss",
        url: paths.app.conversations,
        icon: (props: SvgIconProps) => (
          <Badge
            invisible={!messagesBadge}
            color={"error"}
            badgeContent={" "}
            variant={"dot"}
            sx={{
              "& .MuiBadge-badge": {
                border: "1px solid white",
                height: 12,
                width: 12,
                borderRadius: "50%",
              },
            }}
          >
            <DiscussIcon {...props} />
          </Badge>
        ),
      },
      {
        id: "inbox",
        label: "Inbox",
        url: paths.app.inboxMessages,
        icon: MessageIcon
      },

      {
        id: "contacts",
        label: "Contacts",
        url: paths.app.contacts,
        icon: (props: SvgIconProps) => (
          <Badge
            invisible={!contactsBadge}
            color={"error"}
            badgeContent={" "}
            variant={"dot"}
            sx={{
              "& .MuiBadge-badge": {
                border: "1px solid white",
                height: 12,
                width: 12,
                borderRadius: "50%",
              },
            }}
          >
            <PublicIcon {...props} />
          </Badge>
        ),
      },
      {
        id: "settings",
        label: "Settings",
        url: paths.app.settings.index,
        icon: SettingsIcon,
      },
    ],
    [contactsBadge, messagesBadge]
  );
};
