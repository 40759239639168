import React from "react";

/**
 * Manages adding and removing values from filter list
 */
export const useHandleFilters = (initialValues: string[] = []) => {
  const [values, setValues] = React.useState<string[]>(initialValues);

  const [valuesToAdd, setValuesToAdd] = React.useState<string[]>([""]);

  const changeValueToAdd = React.useCallback(
    (index: number, newValue: string) => {
      const lastValueIndex = valuesToAdd.length - 1;
      const changingLast = index === lastValueIndex;
      const changingFirst = index === 0 && valuesToAdd.length === 1;
      const valueToRemove = newValue === "";
      const valueToAdd =
        changingLast && valuesToAdd[lastValueIndex] === "" && newValue !== "";

      let newValues = valuesToAdd.map((oldValue, i) =>
        i === index ? newValue : oldValue
      );

      // Never remove first value
      if (valueToRemove && !changingFirst) {
        const newValues = valuesToAdd.filter((_, i) => i !== index);

        return setValuesToAdd(newValues);
      }

      if (changingLast && valueToAdd) {
        newValues = [...newValues, ""];
      }

      setValuesToAdd(newValues);
    },
    [valuesToAdd, setValuesToAdd]
  );

  const removeExistingValue = React.useCallback(
    (index: number) => {
      setValues((prev) => {
        prev.splice(index, 1);

        return [...prev];
      });
    },
    [setValues, values]
  );

  React.useEffect(() => {
    setValues(initialValues);
    setValuesToAdd([""]);
  }, [initialValues]);

  return {
    values,
    valuesToAdd,
    removeExistingValue,
    changeValueToAdd,
  };
};
