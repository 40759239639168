import { JSONValue } from "@twilio/conversations";
import { isEmpty } from "ramda";
import React from "react";
import { AnalyticsEvent, useTrackEvent } from "shared/analytics";
import { useTwilioConversationSelector } from "shared/twilio";
import { CalledUser, useCalledUsers } from "../calling-users";
import { useAttachments } from "../chat-attachments";
import { useQuotedMessage, useSetQuotedMessage } from "../context";
import { HandleMessageReturnType } from "./model";
import { useMessageInput } from "./useMessageInput";
import { pasteImages } from "./utils";

export function useHandleMessage(): HandleMessageReturnType {
  /* Analytics */
  const trackMessage = useTrackEvent(AnalyticsEvent.chat_send_message);

  const {
    isLoading: attachmentsLoading,
    attachments,
    sendAttachments,
    setAttachments,
    deleteAttachments,
  } = useAttachments();

  /* Quoting */
  const quotedMessage = useQuotedMessage();
  const setQuotedMessage = useSetQuotedMessage();

  /* Calling Users */
  const {
    addCalledUser,
    onMessageChange,
    setSearchedName,
    searchedName,
    clearCalledUsers,
    beforeMessageSend,
    onMessageDelete,
    participantsToShow,
    callUsers,
    isLoading: isCallingUsersLoading,
  } = useCalledUsers();

  const {
    ref,
    setMessage: _setMessage,
    setPosition,
    position,
    message,
    positionCursor,
  } = useMessageInput({
    quotedMessage,
  });

  const isLoading = attachmentsLoading || isCallingUsersLoading;

  /* Twilio conversation */
  const twilioConversation =
    useTwilioConversationSelector("twilioConversation");

  /* Clear attachment */
  React.useEffect(() => {
    if (quotedMessage) {
      deleteAttachments();
    }
  }, [quotedMessage, deleteAttachments]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newMessage = event.currentTarget.value;
    const position = event.currentTarget.selectionEnd ?? 0;

    setPosition(position);

    // Display users to call only if not deleting
    if (message.length < newMessage.length) {
      onMessageChange(newMessage.slice(0, position));
    }
    _setMessage(newMessage);
  };

  const handleDelete = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!ref.current) return;

    const newMessage = event.target.value;
    const deletedLength = message.length - newMessage.length;
    const selEnd = ref.current.selectionEnd ?? newMessage.length;

    const deleteExtra = onMessageDelete(
      newMessage,
      message.slice(0, selEnd + deletedLength),
      deletedLength
    );
    const newPosition = selEnd - deleteExtra;

    searchedName && setSearchedName("");

    if (!deleteExtra) {
      return handleInputChange(event);
    }

    event.preventDefault();

    _setMessage(
      message.slice(0, newPosition) + message.slice(selEnd + deletedLength)
    );

    positionCursor(newPosition);
  };

  return {
    messageProps: {
      setMessage: _setMessage,
      message,
      onChange: (event) => {
        if (event.target.value.length < message.length) {
          handleDelete(event);
        } else {
          handleInputChange(event);
        }
      },
      sendMessage: async (userAttributes?: Record<string, unknown>) => {
        if (!message && !attachments) {
          return;
        }

        if (isLoading || !twilioConversation) {
          return;
        }

        let index: number | undefined;

        if (attachments) {
          await sendAttachments(twilioConversation);
        } else if (message) {
          const quoteAttributes = quotedMessage
            ? {
                quotedMessage: {
                  sid: quotedMessage.sid,
                  index: quotedMessage.index,
                },
              }
            : undefined;

          const {
            message: processedMessage,
            attributes: calledUsersAttributes,
          } = beforeMessageSend(message);

          const attributes: JSONValue = {
            ...quoteAttributes,
            ...calledUsersAttributes,
            ...userAttributes,
          };

          index = await twilioConversation?.sendMessage(
            processedMessage,
            isEmpty(attributes) ? undefined : attributes
          );

          trackMessage({ quoted: !!quotedMessage });
          _setMessage("");
          setQuotedMessage(null);

          callUsers(calledUsersAttributes?.calledUsers);
          clearCalledUsers();
        }

        twilioConversation?.setAllMessagesRead();
        return index;
      },

      onPaste: async (event) => {
        const images = await pasteImages(event.clipboardData);

        if (!images) {
          return;
        }

        setAttachments((prev) => (prev ? prev.concat(images) : images));
      },
    },
    attachmentsProps: {
      isLoading: attachmentsLoading,
      attachments,
      setAttachments,
      deleteAttachments,
    },
    quotedMessageProps: {
      quotedMessage,
      setQuotedMessage,
    },
    callingUsersProps: {
      onUserAdd: (user: CalledUser) => {
        const newMessagePart = message
          .slice(0, position)
          .replace(new RegExp(searchedName + "$"), user.name);

        _setMessage(newMessagePart + message.slice(position));
        addCalledUser(user);
        positionCursor(newMessagePart.length);
      },
      participantsToShow,
      onClose: () => {
        setSearchedName("");
      },
    },
    inputProps: {
      ref,
    },
    isLoading,
  };
}
