export const paths = {
  auth: {
    main: "/auth",
    login: "/auth/login",
    register: "/auth/register",
    registered: "/auth/registered",
    resend: "/auth/resend/:code",
    activate: "/auth/activate/:code",
    newPassword: "/auth/password/:code",
    resetPassword: "/auth/password/reset",
  },
  app: {
    main: "/app",
    conversations: "/app/conversations",
    inboxMessages: "/app/inbox",
    inboxMessagePreview: "/app/inbox/:messageId",
    settings: {
      index: "/app/settings",
      profile: "/app/settings/profile",
      email: "/app/settings/email",
      privacy: "/app/settings/privacy",
    },
    contacts: "/app/contacts",
    conversation: "/app/conversations/:conversationId",
    media: "/app/conversations/:conversationId/media",
    welcome: "/app/welcome",
  },
  publicChat: {
    main: "/public-chat",
    login: "/public-chat/login",
    conversation: "/public-chat/conversation",
  },
  invitation: "/invitation",
  home: "/",
  error: "/error/:error",
  notFound: "/not-found",
  disableSubscription: "/subscription/disable/:userId",
};
