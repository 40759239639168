import React from "react";
import {
  AuthPublicRouteWrapper,
  AuthRouteWrapper,
  NoAuthPublicRouteWrapper,
  NoAuthRouteWrapper,
} from "./route-wrappers";
import { RouteWrapperType as RouteWrapperEnum } from "./model";

type RouteWrapperProps = {
  wrapper: RouteWrapperEnum;
};

export const RouteWrapper: React.FC<RouteWrapperProps> = ({
  wrapper,
  children,
}) => {
  switch (wrapper) {
    case RouteWrapperEnum.Auth:
      return <AuthRouteWrapper>{children}</AuthRouteWrapper>;

    case RouteWrapperEnum.AuthPublic:
      return <AuthPublicRouteWrapper>{children}</AuthPublicRouteWrapper>;

    case RouteWrapperEnum.NoAuth:
      return <NoAuthRouteWrapper>{children}</NoAuthRouteWrapper>;

    case RouteWrapperEnum.NoAuthPublic:
      return <NoAuthPublicRouteWrapper>{children}</NoAuthPublicRouteWrapper>;

    default:
      return <>{children}</>;
  }
};
