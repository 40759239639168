import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const LogoIcon: React.FC<SvgIconProps> = ({
  color = "primary",
  ...props
}) => (
  <SvgIcon {...props} viewBox="0 0 80 80" aria-label="logo icon" color={color}>
    <path
      d="M62.785 0H17.215C7.70744 0 0 7.70744 0 17.215V62.785C0 72.2926 7.70744 80 17.215 80H62.785C72.2926 80 80 72.2926 80 62.785V17.215C80 7.70744 72.2926 0 62.785 0Z"
      fill="currentColor"
    />
    <path
      d="M47.073 18.02L51.2005 21.1657L60.093 27.9055C62.8955 30.0431 64.5686 33.4573 64.5686 37.0382V53.1081C64.5686 59.4271 59.4284 64.5681 53.1102 64.5681H27.138C23.7314 64.5681 20.6672 63.0735 18.5665 60.7055C16.7712 58.6817 15.6797 56.02 15.6797 53.1081V37.0382C15.6797 33.4865 17.3655 30.0801 20.1891 27.9261L33.1752 18.02C37.2667 14.8989 42.9816 14.8989 47.073 18.02ZM39.5849 27.9014C32.1399 27.9014 26.1045 33.9368 26.1045 41.3818C26.1045 48.8268 32.1399 54.8622 39.5849 54.8622C41.9632 54.8622 44.1977 54.2463 46.1375 53.1653L46.0995 53.2039L51.7359 53.7111L51.3359 47.9924C52.4371 46.0392 53.0653 43.7839 53.0653 41.3818C53.0653 33.9368 47.0299 27.9014 39.5849 27.9014Z"
      fill="white"
    />
  </SvgIcon>
);
