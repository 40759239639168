import { QueryKey, useApi } from "shared/api";
import { UseBaseQueryOptions, useQuery } from "react-query";
import { config } from "shared/config";
import { Conversation } from "../index";
import { appendAvatarColor } from "shared/utils";
import { useShowError } from "shared/notifications";

export const useConversation = (
  chatSid: string,
  options: UseBaseQueryOptions<Conversation> = {}
) => {
  const { get } = useApi();
  const showError = useShowError();

  return useQuery<Conversation>(
    [QueryKey.Conversation, chatSid],
    get(
      config.endpoints.conversations.conversation.replace(/:chatSid/, chatSid),
      {
        transformResponse: ({ participants, ...conversation }) => ({
          ...conversation,
          participants: participants.map((p) =>
            appendAvatarColor(p, (p) => p.userId || p.authorizationCode)
          ),
        }),
      }
    ),
    {
      onError: () => {
        showError("Failed to fetch conversation", "Please try again later");
      },
      enabled: !!chatSid,
      ...options,
    }
  );
};
