import React from "react";
import { Navigate } from "react-router-dom";
import { useIsAuthenticatedForApp } from "shared/auth";
import { isInPublicChat } from "shared/public-chat";
import { paths } from "../paths";

export const AuthRouteWrapper: React.FC = ({ children }) => {
  const isAuthenticated = useIsAuthenticatedForApp();
  if (isInPublicChat()) {
    return (
      <Navigate
        to={{
          pathname: paths.publicChat.login,
          search: window.location.search,
        }}
      />
    );
  }

  if (isAuthenticated) {
    return <>{children}</>;
  }

  return (
    <Navigate
      to={{ pathname: paths.auth.login, search: window.location.search }}
    />
  );
};
