import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const HelpIcon: React.FC<SvgIconProps> = (props) => {
  const fillProp = props.fill ?? "transparent";
  return (
    <SvgIcon
      {...props}
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      aria-label="help icon"
      sx={{ fill: fillProp, ...props.sx }}
    >
      <circle
        cx="10.5"
        cy="10.5"
        r="9.75"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M10.9609 12.7969H9.50781C9.51302 12.2969 9.55729 11.888 9.64062 11.5703C9.72917 11.2474 9.8724 10.9531 10.0703 10.6875C10.2682 10.4219 10.5312 10.1198 10.8594 9.78125C11.099 9.53646 11.3177 9.30729 11.5156 9.09375C11.7188 8.875 11.8828 8.64062 12.0078 8.39062C12.1328 8.13542 12.1953 7.83073 12.1953 7.47656C12.1953 7.11719 12.1302 6.80729 12 6.54688C11.875 6.28646 11.6875 6.08594 11.4375 5.94531C11.1927 5.80469 10.888 5.73438 10.5234 5.73438C10.2214 5.73438 9.9349 5.78906 9.66406 5.89844C9.39323 6.00781 9.17448 6.17708 9.00781 6.40625C8.84115 6.63021 8.75521 6.92448 8.75 7.28906H7.30469C7.3151 6.70052 7.46094 6.19531 7.74219 5.77344C8.02865 5.35156 8.41406 5.02865 8.89844 4.80469C9.38281 4.58073 9.92448 4.46875 10.5234 4.46875C11.1849 4.46875 11.7474 4.58854 12.2109 4.82812C12.6797 5.06771 13.0365 5.41146 13.2812 5.85938C13.526 6.30208 13.6484 6.82812 13.6484 7.4375C13.6484 7.90625 13.5521 8.33854 13.3594 8.73438C13.1719 9.125 12.9297 9.49219 12.6328 9.83594C12.3359 10.1797 12.0208 10.5078 11.6875 10.8203C11.401 11.0859 11.2083 11.3854 11.1094 11.7188C11.0104 12.0521 10.9609 12.4115 10.9609 12.7969ZM9.44531 15.2734C9.44531 15.0391 9.51823 14.8411 9.66406 14.6797C9.8099 14.5182 10.0208 14.4375 10.2969 14.4375C10.5781 14.4375 10.7917 14.5182 10.9375 14.6797C11.0833 14.8411 11.1562 15.0391 11.1562 15.2734C11.1562 15.4974 11.0833 15.6901 10.9375 15.8516C10.7917 16.013 10.5781 16.0938 10.2969 16.0938C10.0208 16.0938 9.8099 16.013 9.66406 15.8516C9.51823 15.6901 9.44531 15.4974 9.44531 15.2734Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
