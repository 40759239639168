import { AuthProviderType } from "shared/auth";
import { useShowError } from "shared/notifications";
import { useConvertUser } from "../useConvertUser";
import { useAppleSignIn } from "shared/apple-auth";

export const useConvertAppleUser = () => {
  const showError = useShowError();
  const { signInWithApple, isLoading: appleSignInLoading } = useAppleSignIn();
  const { convertUser, isLoading: convertUserLoading } = useConvertUser();

  const convertAppleUser = () => {
    signInWithApple()
      .then(({ user, authorization }) => {
        convertUser({
          token: authorization.id_token,
          authProvider: AuthProviderType.Apple,
          name: user?.email,
        });
      })
      .catch(() => showError("Failed to convert user", "Please try again later."));
  };

  return {
    convertAppleUser,
    isLoading: convertUserLoading || appleSignInLoading,
  };
};
