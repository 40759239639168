import { useUser } from "shared/user";
import { mergeFilters } from "../utils";
import { useAutoArchive, useHandleFilters, useUpdateFilters } from "../hooks";

export function useEmailFilters() {
  const { user, isLoading: userLoading } = useUser();
  const { isLoading: updateLoading, updateFilters: _updateFilters } =
    useUpdateFilters();

  const {
    changeValueToAdd: changeEmailToAdd,
    values: emails,
    valuesToAdd: emailsToAdd,
    removeExistingValue: removeExistingEmail,
  } = useHandleFilters(user._emailsContacts);

  const {
    changeValueToAdd: changeDomainToAdd,
    values: domains,
    valuesToAdd: domainsToAdd,
    removeExistingValue: removeExistingDomain,
  } = useHandleFilters(user._domainsContacts);

  const filters = mergeFilters({ emails, emailsToAdd, domains, domainsToAdd });

  const { autoArchive, autoarchiveEnabled, toggleAutoarchive } =
    useAutoArchive(filters);

  const updateFilters = () => {
    _updateFilters(filters, autoArchive);
  };

  const isLoading = userLoading || updateLoading;

  return {
    domains,
    emails,
    domainsToAdd,
    emailsToAdd,
    changeDomainToAdd,
    changeEmailToAdd,
    removeExistingDomain,
    removeExistingEmail,
    isLoading,
    updateFilters,
    autoArchive,
    autoarchiveEnabled,
    toggleAutoarchive,
  };
}
