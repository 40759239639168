import React from "react";
import { useInfiniteQuery } from "react-query";
import { QueryKey, useApi } from "shared/api";
import { config } from "shared/config";
import { appendAvatarColor, formatEmailMessageDate } from "shared/utils";
import {
  EmailMediaResponse,
  FileMediaResponse,
  LinkMediaResponse,
  MediaResponse,
  MediaType,
} from "../model";

export function useMediaList<
  T extends
    | EmailMediaResponse
    | FileMediaResponse
    | LinkMediaResponse = FileMediaResponse
>(chatSid: string, type: MediaType) {
  const { getInfinite } = useApi();

  const { hasNextPage, fetchNextPage, data, isLoading } =
    useInfiniteQuery<MediaResponse>({
      queryKey: [QueryKey.MediaList, chatSid, type],
      queryFn: getInfinite(
        config.endpoints.conversations.media.replace(/:chatSid/, chatSid),
        {
          query: {
            type: type,
            limit: 20,
          },
          transformResponse,
        }
      ),
      getNextPageParam,
      cacheTime: 1000 * 60 * 4.5, // 4.5 minutes
    });

  return React.useMemo(
    () => ({
      hasNextPage: Boolean(hasNextPage),
      fetchNextPage,
      data: (data?.pages || []).flatMap((p) => p.results as unknown) as T[],
      length: (data?.pages || []).reduce(
        (acc, p) => (acc += p.results.length),
        0
      ),
      isLoading,
    }),
    [hasNextPage, fetchNextPage, data, isLoading]
  );
}

const getNextPageParam = (
  lastPage: MediaResponse,
  allPages: MediaResponse[]
) => {
  const index = allPages.findIndex((p) => p === lastPage);

  if (!lastPage.next || index < 0) {
    return undefined;
  }
  return index + 2;
};

const transformResponse = (res: MediaResponse): MediaResponse => {
  if (!res.results || !res.results.length) {
    return res;
  }

  if (!(res.results[0] as EmailMediaResponse).message) {
    return res;
  }

  return {
    ...res,
    results: res.results.map((result) => {
      const emailResult = result as EmailMediaResponse;
      return {
        ...emailResult,
        message: {
          ...emailResult.message,
          ...appendAvatarColor(
            emailResult.message,
            (val) => val.externalProviderMsgId
          ),
          externalCreationDate: formatEmailMessageDate(
            emailResult.message.externalCreationDate
          ),
        },
      };
    }),
  };
};
