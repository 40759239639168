import React from "react";
import { Box, styled, Tooltip, Typography } from "@mui/material";
import { ConversationEmailMessage } from "shared/conversations";
import { QueryDataWrapper } from "shared/api";

import { useEmailMessage } from "../../shared";

type ChatMessageEmailHeaderProps = {
  message: ConversationEmailMessage;
};

export const ChatMessageEmailHeader: React.FC<ChatMessageEmailHeaderProps> = ({
  message,
}) => {
  const emailMessageResult = useEmailMessage(message.externalProviderMsgId);

  return (
    <Wrapper>
      <QueryDataWrapper result={emailMessageResult}>
        {({ data }) => (
          <>
            <Box overflow={"hidden"} maxWidth={{ xs: 150, sm: 200, lg: 300 }}>
              <Tooltip title={message.subject}>
                <Typography
                  component={Box}
                  variant={"body2"}
                  fontWeight={600}
                  color={"secondary"}
                  noWrap
                >
                  {message.subject}
                </Typography>
              </Tooltip>
              <Tooltip title={message.fromName}>
                <Typography
                  component={Box}
                  variant={"caption"}
                  fontWeight={500}
                  color={"secondary.light"}
                  noWrap
                >
                  From: {message.fromName}
                </Typography>
              </Tooltip>
            </Box>
            <Typography variant={"caption"} color={"secondary.light"} noWrap>
              {data.externalCreationDate}
            </Typography>
          </>
        )}
      </QueryDataWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: spacing(1),
}));
