import { UseBaseQueryOptions, useMutation } from "react-query";
import { useApi } from "shared/api";
import { config } from "shared/config";
import { Conversation, UpdateConversationPayload } from "shared/conversations";
import { useShowError } from "shared/notifications";

export const useUpdateChatInfo = (
  chatSid: string,
  options: UseBaseQueryOptions<Conversation> = {}
) => {
  const { patch } = useApi();
  const showError = useShowError();
  const { mutate, isLoading } = useMutation(
    patch<UpdateConversationPayload, Conversation>(
      config.endpoints.conversations.conversation.replace(/:chatSid/, chatSid)
    )
  );

  const updateChatInfo = (data: UpdateConversationPayload) => {
    mutate(data, {
      ...options,
      onSuccess: (response) => {
        options.onSuccess?.(response);
      },
      onError: () =>
        showError("Failed to update conversation", "Please try again later"),
    });
  };

  return {
    updateChatInfo,
    isLoading,
  };
};
