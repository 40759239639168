import { useApi } from "shared/api";
import { useShowError } from "shared/notifications";
import { UseBaseQueryOptions, useMutation } from "react-query";
import { config } from "shared/config";
import { useSetGoogleAuthData } from "./GoogleAuth.context";
import { GoogleAuthRequest, GoogleAuthResponse } from "./types";

export const useGoogleAuth = () => {
  const { post } = useApi();
  const showError = useShowError();
  const setGoogleAuthData = useSetGoogleAuthData();

  const { mutate, isLoading } = useMutation(
    post<GoogleAuthRequest, GoogleAuthResponse>(config.endpoints.google.auth)
  );

  const authenticateGoogle = (
    data: GoogleAuthRequest,
    options: UseBaseQueryOptions<GoogleAuthResponse> = {}
  ) => {
    mutate(data, {
      ...options,
      onSuccess: (res) => {
        setGoogleAuthData(res);
        options.onSuccess?.(res);
      },
      onError: () => showError(),
    });
  };

  return {
    authenticateGoogle,
    isLoading,
  };
};
