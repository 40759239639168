import { useLocation } from "react-router-dom";
import { getConversationPath, paths } from "shared/router";



export const useIsOnConversationPage = () => {
  const location = useLocation();

  const conversationPathRegex = new RegExp(
    getConversationPath("[\\w]+$")
  );
  
  return (
    conversationPathRegex.test(location.pathname) ||
    Boolean(location.pathname.includes(paths.publicChat.main))
  );
};

