import React from "react";
import { Stack } from "@mui/material";

import { useTwilioConversationSelector } from "shared/twilio";
import { QueryDataWrapper } from "shared/api";
import { LoadingIndicator } from "shared/components";
import { usePublicInvitationCheck } from "shared/public-invitation";
import { InvitationType } from "shared/invitations";

import { MessagesList } from "./chat-messages";
import { ChatHeader } from "./chat-header";
import { ChatBox } from "./chat-box";
import { useChatContext } from "./context";
import { ChatHeaderMenuPublic, ChatHeaderMenu } from "./chat-header";

export const Chat = () => {
  const isLoading = useTwilioConversationSelector("isLoading");
  const { conversation, isPublicChatView } = useChatContext();
  const publicInvitation = usePublicInvitationCheck(
    {
      conversationId: conversation.id,
      type: InvitationType.Conversation,
    },
    { enabled: isPublicChatView }
  );

  return (
    <>
      <ChatHeader
        isPublicChatView={isPublicChatView}
        conversation={conversation}
        invitation={publicInvitation}
        chatMenu={
          isPublicChatView ? <ChatHeaderMenuPublic /> : <ChatHeaderMenu />
        }
      />
      <Stack flex={1}>
        <QueryDataWrapper
          result={{ data: {}, isLoading, error: null }}
          loadingView={<LoadingIndicator flex={1} />}
        >
          {() => <MessagesList conversation={conversation} />}
        </QueryDataWrapper>
        <ChatBox />
      </Stack>
    </>
  );
};
