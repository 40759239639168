import { UseBaseQueryOptions, useMutation, useQueryClient } from "react-query";
import { AuthenticateRequest } from "shared/auth";
import { QueryKey, useApi } from "shared/api";
import { config } from "shared/config";
import { useShowError } from "shared/notifications";
import { User } from "./types";

export const useConvertUser = (options: UseBaseQueryOptions<User> = {}) => {
  const queryClient = useQueryClient();
  const { post } = useApi();
  const showError = useShowError();
  const { mutate, isLoading: convertLoading } = useMutation(
    post<AuthenticateRequest, User>(config.endpoints.user.v2.convert)
  );

  const convertUser = (data: AuthenticateRequest) => {
    mutate(data, {
      ...options,
      onSuccess: (response) => {
        queryClient.invalidateQueries(QueryKey.TwilioToken);
        queryClient.invalidateQueries(QueryKey.User);
        options.onSuccess?.(response);
      },
      onError: () => {
        showError("Failed to convert user", "Please try again later.");
      },
    });
  };

  return {
    convertUser,
    isLoading: convertLoading,
  };
};
