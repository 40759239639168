import React from "react";
import { LoadingIndicator } from "shared/components";
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  StackProps,
  styled,
  Typography,
} from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { copyToClipboard } from "shared/utils";
import { useShowNotification } from "shared/notifications";

type Props = {
  address: string | null;
  title: string;
  text: string;
  icon: React.ReactElement;
  isLoading?: boolean;
  copyText?: string;
  fullWidth?: boolean;
  onCopy?: () => void;
  disabledText?: string;
};

export const AddressCopy: React.FC<Props> = ({
  title,
  text,
  address,
  icon,
  onCopy,
  children,
  disabledText,
  isLoading = false,
  copyText = `${title} address copied to clipboard`,
  fullWidth = false,
}) => {
  const showNotification = useShowNotification();

  const isEnabled = !!address;

  const handleCopy = async (value: string) => {
    await copyToClipboard(value);
    onCopy && onCopy();
    showNotification(copyText);
  };
  return (
    <Wrapper
      extraPadding={!isEnabled && !disabledText}
      width={fullWidth ? 1 : undefined}
      maxWidth={fullWidth ? undefined : 360}
    >
      <Stack spacing={2} direction={"row"} alignItems={"start"}>
        <Avatar
          sx={{ height: 36, width: 36, backgroundColor: "primary.light" }}
        >
          {icon}
        </Avatar>
        <Content>
          <Typography
            component={"span"}
            fontSize={14}
            fontWeight={600}
            lineHeight={1.7}
          >
            {title}
          </Typography>
          <Typography variant={"caption"} maxWidth={290}>
            {text}
          </Typography>
        </Content>
        {children}
      </Stack>
      {(isEnabled || disabledText || isLoading) && (
        <CopySection>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <>
              <AddressText variant={"caption"} noWrap textOverflow={"ellipsis"}>
                {address || disabledText}
              </AddressText>
              <IconButton
                aria-label="copy text"
                disabled={!isEnabled}
                color={"inherit"}
                onClick={() => handleCopy(address as string)}
              >
                <ContentCopy />
              </IconButton>
            </>
          )}
        </CopySection>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(
  (props: StackProps & { extraPadding: boolean }) => (
    <Stack spacing={3} direction={"column"} {...props} />
  ),
  { shouldForwardProp: (prop) => prop !== "extraPadding" }
)(({ theme: { palette, spacing }, extraPadding }) => ({
  border: `1px solid ${palette.grey[200]}`,
  borderRadius: 14,
  minHeight: 30,
  padding: extraPadding ? spacing(4) : spacing(4, 4, 1),
  color: palette.grey[500],
  overflow: "hidden",
}));

const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
}));

const CopySection = styled(Box)(({ theme: { spacing, palette } }) => ({
  borderTop: `1px solid ${palette.grey[200]}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: spacing(2),
  paddingTop: spacing(1),
  overflow: "hidden",
}));

const AddressText = styled(Typography)(({ theme: { palette } }) => ({
  borderRadius: 14,
  color: palette.grey[300],
}));
