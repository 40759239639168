import React from "react";
import { Navigate } from "react-router-dom";
import { useIsAuthenticatedForPublicChat } from "shared/auth";
import { paths } from "../paths";

export const AuthPublicRouteWrapper: React.FC = ({ children }) => {
  const isAuthenticated = useIsAuthenticatedForPublicChat();

  if (isAuthenticated) {
    return <>{children}</>;
  }
  return (
    <Navigate
      to={{ pathname: paths.publicChat.login, search: window.location.search }}
    />
  );
};
