import { Box, styled } from "@mui/material";
import { BoxProps } from "@mui/system";
import { Link } from "react-router-dom";
import { MediaViewType } from "../shared";

export const MediaBinLinks = ({ current }: { current: MediaViewType }) => {
  return (
    <Wrapper>
      <Container>
        {["images", "videos", "files", "emails", "links"].map((hash) => {
          const active = current === hash;
          return (
            <StyledLink key={hash} to={{ hash }}>
              <LinkItem
                active={active}
                sx={{
                  width: { xs: 65, lg: 100 },
                }}
              >
                {hash}
              </LinkItem>
            </StyledLink>
          );
        })}
      </Container>
    </Wrapper>
  );
};

const Container = styled(Box)(({ theme: { palette } }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "relative",
  "&:after": {
    position: "absolute",
    bottom: 0,
    zIndex: -1,
    content: "''",
    display: "block",
    width: "100%",
    height: 1,
    backgroundColor: palette.grey[200],
  },
}));

const Wrapper = styled(Box)(({ theme: { palette, spacing } }) => ({
  width: "100%",
  padding: `0 ${spacing(4)}`,
}));

const LinkItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active",
})<BoxProps & { active: boolean }>(({ theme: { palette }, active }) => ({
  textTransform: "capitalize",
  height: 50,
  padding: "0 4px",
  color: active ? palette.info.dark : "grey.500",
  borderBottom: active ? `2px solid ${palette.info.dark}` : "none",
  fontWeight: active ? 500 : 400,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledLink = styled(Link)({
  textDecoration: "none",
  color: "inherit",
});
