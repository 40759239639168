import {
  formHookFactory,
  defaultInputState,
  InputState,
} from "shared/forms";

type ResetPasswordFormState = {
  newPassword: InputState;
  confirmNewPassword: InputState;
};

const validatePassword = (val: string) =>
  val.length >= 6 &&
  val.length < 50 &&
  /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(val);

const validateConfirmPassword = (val: string, state: ResetPasswordFormState) => 
  val === state["newPassword"].value;

export const useResetPasswordForm = formHookFactory<ResetPasswordFormState>({
  newPassword: { ...defaultInputState("newPassword", validatePassword) },
  confirmNewPassword: { ...defaultInputState("confirmNewPassword", validateConfirmPassword) },
});
