import { AuthProviderType, useAuthenticate } from "shared/auth";
import { useAppleSignIn } from "shared/apple-auth";
import { AnalyticsEvent, useTrackEvent } from "shared/analytics";

export const useLoginWithApple = () => {
  const { authenticate, isLoading: authLoading } = useAuthenticate();
  const { signInWithApple, isLoading: appleSignInLoading } = useAppleSignIn();
  
  const trackAppleLogin = useTrackEvent(AnalyticsEvent.apple_account_connected);

  const loginWithApple = () => {
    signInWithApple().then(({ user, authorization }) => {
      trackAppleLogin();
      authenticate(
        {
          name: user?.email,
          token: authorization.id_token,
          authProvider: AuthProviderType.Apple,
        }
      );
    });
  };

  return {
    loginWithApple,
    isLoading: appleSignInLoading || authLoading,
  };
};
