import { Box, Typography } from "@mui/material";
import { EmailMessage } from "../../shared";

export const ChatMessageEmailFooter = ({
  message: { user, fromEmail },
}: {
  message: EmailMessage;
}) => {
  const authorInfo = user
    ? `added by ${user.name}`
    : `sent via Email Relay by ${fromEmail}`;

  return (
    <Box width={1} pt={2} display={"flex"} justifyContent={"right"}>
      <Typography
        maxWidth={300}
        noWrap={true}
        fontSize={10}
        component="p"
        variant="caption"
        color="#8397AA"
        textAlign="right"
      >
        {authorInfo}
      </Typography>
    </Box>
  );
};
