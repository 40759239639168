import React from "react";
import { Box } from "@mui/material";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  GmailBaseMessage,
  gmailLabels,
  GmailMessage,
  useGmailMessages,
} from "shared/gmail";
import { LoadingIndicator } from "shared/components";
import { HasOnCheckedCb } from "shared/types";

import { ChecklistItem } from "./GmailMessagesChecklistItem";

type Props = HasOnCheckedCb<GmailMessage> & {
  selected: Record<string, GmailMessage>;
};

const listId = "gmail-messages-checklist";

export const GmailMessagesChecklist: React.FC<Props> = ({
  selected,
  onChecked,
}) => {
  const containerRef = React.useRef<HTMLDivElement>();
  const [messages, setMessages] = React.useState<GmailBaseMessage[]>([]);
  const [currentPageToken, setCurrentPageToken] = React.useState<string>();

  const { refetch } = useGmailMessages({
    qLabel: `label:(-${gmailLabels.archived})`,
    pageToken: currentPageToken,
    reactQueryOptions: {
      onSuccess: (response) => {
        setMessages((prev) => [...prev, ...(response.messages || [])]);
        setCurrentPageToken(response.nextPageToken);
      },
    },
  });

  React.useEffect(() => {
    refetch();
  }, []);

  const handleNext = () => {
    refetch();
  };

  return (
    <Box flex={1} display={"flex"} flexDirection={"column"} height={1}>
      <Box flex={1} display={"flex"} flexDirection={"column"} height={1} mt={2}>
        <AutoSizer>
          {({ height, width }) => (
            <Box
              ref={containerRef}
              id={listId}
              height={height}
              width={width}
              overflow={"auto"}
              display={"flex"}
              flexDirection={"column"}
              py={3}
            >
              <InfiniteScroll
                dataLength={messages.length}
                hasMore={messages.length ? true : false}
                next={handleNext}
                loader={<LoadingIndicator />}
                scrollableTarget={listId}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {messages.map((message) =>
                  renderMessage({ message, selected, onChecked })
                )}
              </InfiniteScroll>
            </Box>
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
};

const renderMessage = ({
  message,
  selected,
  onChecked,
}: Props & { message: GmailBaseMessage }) => {
  return (
    <ChecklistItem
      key={message.id}
      message={message}
      isSelected={!!selected[message.id]}
      onChecked={onChecked}
    />
  );
};
