import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ExitCircleIcon: React.FC<SvgIconProps> = (props) => {
  const fillProp = props.fill ?? "transparent";
  return (
    <SvgIcon
      viewBox="0 0 48 48"
      {...props}
      aria-label="exit circle icon"
      sx={{ fill: fillProp, ...props.sx }}
    >
      <rect width="48" height="48" rx="24" />
      <path
        d="M28.3689 14.8846C28.1799 15.2217 28.3024 15.6468 28.6428 15.8341C31.5346 17.424 33.5102 20.4561 33.5714 23.9593C33.6626 29.1844 29.4439 33.5104 24.1672 33.6025C18.8905 33.6946 14.5234 29.5185 14.4322 24.2934C14.371 20.7878 16.2415 17.6901 19.0741 16.0011C19.4078 15.8022 19.5154 15.3729 19.3147 15.0427C19.114 14.7123 18.6806 14.6058 18.347 14.8047C15.0871 16.7484 12.9522 20.3095 13.0221 24.318C13.127 30.3272 18.1226 35.1047 24.1916 34.9988C30.2601 34.8929 35.0864 29.9444 34.9815 23.9347C34.9115 19.9245 32.6521 16.441 29.3278 14.613C28.9874 14.4259 28.5581 14.5475 28.3689 14.8846Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        d="M22.999 14V20C22.999 20.5523 23.4467 21 23.999 21C24.5513 21 24.999 20.5523 24.999 20V14C24.999 13.4477 24.5513 13 23.999 13C23.4467 13 22.999 13.4477 22.999 14Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
