import React from "react";
import { UseBaseQueryOptions, useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useApi, isApiError } from "shared/api";
import { config } from "shared/config";
import { getErrorPage } from "shared/notifications";
import { paths } from "shared/router";
import { ChangePasswordRequest, ChangePasswordResponse } from "../types";

export function useChangePassword() {
  const navigate = useNavigate();
  const { code } = useParams();
  const { post } = useApi();

  const { mutate, isLoading, error } = useMutation(
    post<ChangePasswordRequest, ChangePasswordResponse>(
      config.endpoints.auth.changePassword
    )
  );

  const changePassword = React.useCallback(
    (
      password: string,
      options: UseBaseQueryOptions<ChangePasswordResponse> = {}
    ) =>
      mutate(
        { password, code: code! },
        {
          ...options,
          onSuccess: () => navigate(paths.auth.login),
          onError: (error: unknown) => {
            if (isApiError(error) && error.status === 410) {
              navigate(paths.auth.resend.replace(":code", code || ""));
            } else {
              navigate(getErrorPage("Request"));
            }
          },
        }
      ),
    [mutate, navigate, code]
  );

  React.useEffect(() => {
    if (!code) navigate(paths.notFound);
  }, [code, navigate]);

  return {
    changePassword,
    isLoading,
    error,
  };
}
