import { Message } from "@twilio/conversations";
import { Menu } from "shared/components";
import { useIsBreakpoint } from "shared/theme";
import { MessageType } from "shared/twilio";
import { useSetQuotedMessage } from "../../context";
import { useToggleEditMessagePopUp } from "./EditMessage.context";
import { MessageDialogType, MessagePermissions } from "./model";

type Props = {
  permissions: MessagePermissions;
  message: Message;
  anchor: HTMLElement | null;
  open: boolean;
  type: MessageType;
  isMine: boolean;
  onClose: () => void;
};

export const MessageMenuPopup = ({
  permissions,
  message,
  anchor,
  open,
  onClose,
  type,
  isMine,
}: Props) => {
  const toggleDialog = useToggleEditMessagePopUp();

  const setQuotedMessage = useSetQuotedMessage();

  const isMobile = useIsBreakpoint("sm");

  const openDialog = (type: MessageDialogType) => {
    onClose();
    toggleDialog(type, message, isMine);
  };

  const quoteMessage = () => {
    onClose();
    setQuotedMessage(message);
  };

  return (
    <Menu.Menu
      disableAutoFocusItem={isMobile ? true : false}
      id={`message_${message.sid}_menu`}
      anchorEl={anchor}
      {...calculateMenuPosition(type, isMine)}
      open={open}
      onClose={onClose}
      MenuListProps={{
        "aria-labelledby": `message_${message.sid}_menu_button`,
      }}
    >
      {permissions.quote && <Menu.Item onClick={quoteMessage}>Reply</Menu.Item>}
      {permissions.edit && (
        <Menu.Item onClick={() => openDialog(MessageDialogType.Edit)}>
          Edit
        </Menu.Item>
      )}
      {permissions.delete && (
        <Menu.Item onClick={() => openDialog(MessageDialogType.Delete)} danger>
          Delete
        </Menu.Item>
      )}
    </Menu.Menu>
  );
};

const calculateMenuPosition = (type: MessageType, isMine: boolean) => {
  if (type === MessageType.Email) {
    return {
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      transformOrigin: {
        horizontal: "right",
        vertical: "bottom",
      },
    } as const;
  }
  return {
    anchorOrigin: {
      vertical: "top",
      horizontal: isMine ? "left" : "right",
    },
    transformOrigin: {
      horizontal: isMine ? "right" : "left",
      vertical: "center",
    },
  } as const;
};
