import { UseBaseQueryOptions, useMutation, useQueryClient } from "react-query";
import { gmailConfig } from "./model";
import { getGmailApiConfig } from "./utils";
import { QueryKey } from "../api";
import { useGoogleApi } from "../google-auth";

export const useDeleteGmailFilter = (filterId: string | undefined) => {
  const queryClient = useQueryClient();
  const { deleteRequest } = useGoogleApi();

  const { mutate, isLoading } = useMutation(
    deleteRequest(
      gmailConfig.endpoints.filter
        .replace(/:userId/, "me")
        .replace(/:filterId/, filterId || ""),
      {
        ...getGmailApiConfig(),
      }
    )
  );

  const deleteGmailFilter = (options: UseBaseQueryOptions = {}) => {
    if (!filterId) {
      return;
    }

    mutate(undefined, {
      ...options,
      onSuccess: (response) => {
        queryClient.invalidateQueries(QueryKey.GmailFilters);
        options.onSuccess?.(response);
      },
    });
  };

  return {
    deleteGmailFilter,
    isLoading,
  };
};
