export type GmailBaseMessage = {
  id: string;
  threadId: string;
};

export enum LabelId {
  Unread = "UNREAD",
  Inbox = "INBOX",
  New = "NEW",
  Important = "IMPORTANT",
}

export type GmailMessageHeader = {
  name: string;
  value: string;
};

export type GmailMessageBody = {
  data?: string;
  size: number;
  attachmentId?: string;
};

export type GmailMessageMetadata = {
  content: GmailMessagePart;
  attachments: GmailMessagePart[];
};

export type GmailMessagePart = {
  filename: string;
  mimeType: string;
  partId: string;
  body: GmailMessageBody;
  headers: GmailMessageHeader[];
  parts?: GmailMessagePart[];
};

export type GmailMessage = GmailBaseMessage & {
  historyId: string;
  internalDate: string;
  sizeEstimate: string;
  snippet: string;
  labelIds: LabelId[];
  payload: GmailMessagePart;
  _avatarColor: string;
  _parts: GmailMessagePart[];
  _htmlContent: string;
  _textContent: string;
  _attachments: GmailMessagePart[];
  _from: string;
  _subject: string;
};

export type GmailMessagesResponse = {
  nextPageToken: string;
  resultSizeEstimate: 201;
  messages?: GmailBaseMessage[];
};

export enum GmailSizeComparison {
  Unspecified = "unspecified",
  Smaller = "smaller",
  Larger = "larger",
}

export type GmailFilterCriteria = {
  from?: string;
  to?: string;
  subject?: string;
  query?: string;
  negatedQuery?: string;
  hasAttachment?: boolean;
  excludeChats?: boolean;
  size?: number;
  sizeComparison?: GmailSizeComparison;
};

export type GmailMessageModify = {
  addLabelIds?: string[];
  removeLabelIds?: string[];
};

export type GmailMessageModifyResponse = Pick<
  GmailMessage,
  "id" | "threadId" | "labelIds"
>;

export type GmailFilterAction = GmailMessageModify & {
  forward?: string;
};

export type GmailFilter = {
  id: string;
  criteria: GmailFilterCriteria;
  action: GmailFilterAction;
};

export type GmailFiltersResponse = {
  filter: GmailFilter[];
  _filterById: Record<string, GmailFilter>;
};

export enum GmailMessageListVisibility {
  Show = "show",
  Hide = "hide",
}

export enum GmailLabelListVisibility {
  LabelShow = "labelShow",
  LabelShowIfUnread = "labelShowIfUnread",
  LabelHide = "labelHide",
}

export enum GmailLabelType {
  System = "system",
  User = "user",
}

export type GmailLabelColor = {
  textColor: string;
  backgroundColor: string;
};

export type GmailLabel = {
  id: string;
  name: string;
  messageListVisibility: GmailMessageListVisibility;
  labelListVisibility: GmailLabelListVisibility;
  type: GmailLabelType;
  messagesTotal: number;
  messagesUnread: number;
  threadsTotal: number;
  threadsUnread: number;
  color: GmailLabelColor;
};

export type GmailLabelsResponse = {
  labels: GmailLabel[];
  _labelsByName: Record<string, GmailLabel>;
};
