import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const OutlookIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} width="37" height="35" viewBox="0 0 37 35" fill="none" aria-label="outlook icon">
      <g clipPath="url(#clip0_7602_5048)">
        <path
          d="M37.0002 18.3759C37.0022 18.0817 36.8527 17.8079 36.6061 17.654H36.6018L36.5863 17.6453L23.7654 9.9278C23.71 9.88977 23.6526 9.855 23.5933 9.82369C23.0983 9.56398 22.5102 9.56398 22.0152 9.82369C21.9559 9.85502 21.8984 9.88977 21.8431 9.9278L9.02219 17.6453L9.00671 17.654C8.61487 17.9018 8.49474 18.4257 8.73841 18.8242C8.81021 18.9416 8.9091 19.0394 9.0265 19.1092L21.8474 26.8267C21.903 26.8644 21.9604 26.8991 22.0195 26.9308C22.5145 27.1905 23.1026 27.1905 23.5976 26.9308C23.6567 26.8991 23.7141 26.8644 23.7697 26.8267L36.5906 19.1092C36.8468 18.9573 37.0031 18.6774 37.0002 18.3759Z"
          fill="#0A2767"
        />
        <path
          d="M10.5166 13.2221H18.9303V21.0647H10.5166V13.2221ZM35.2791 5.24999V1.66249C35.2993 0.765533 34.6011 0.0214931 33.7191 0H11.8857C11.0036 0.0214931 10.3054 0.765533 10.3256 1.66249V5.24999L23.2326 8.74998L35.2791 5.24999Z"
          fill="#0364B8"
        />
        <path d="M10.3257 5.25H18.9303V13.125H10.3257V5.25Z" fill="#0078D4" />
        <path
          d="M27.5348 5.25H18.9302V13.125L27.5348 21H35.279V13.125L27.5348 5.25Z"
          fill="#28A8EA"
        />
        <path d="M18.9302 13.125H27.5348V21H18.9302V13.125Z" fill="#0078D4" />
        <path d="M18.9302 21H27.5348V28.875H18.9302V21Z" fill="#0364B8" />
        <path
          d="M10.5166 21.0664H18.9302V28.1959H10.5166V21.0664Z"
          fill="#14447D"
        />
        <path d="M27.5347 21H35.2788V28.875H27.5347V21Z" fill="#0078D4" />
        <path
          d="M36.6057 19.0601L36.5894 19.0689L23.7685 26.4014C23.7125 26.4364 23.6557 26.4696 23.5964 26.4994C23.3786 26.6048 23.1421 26.6643 22.9011 26.6744L22.2007 26.2579C22.1415 26.2277 22.084 26.1941 22.0286 26.1572L9.03558 18.6165H9.02956L8.60449 18.375V33.2185C8.61112 34.2088 9.40574 35.0063 10.3796 35H35.2522C35.2668 35 35.2797 34.993 35.2952 34.993C35.5009 34.9796 35.7037 34.9366 35.8975 34.8652C35.9813 34.8292 36.0621 34.7864 36.1393 34.7375C36.197 34.7043 36.2959 34.6316 36.2959 34.6316C36.7367 34.3 36.9976 33.7761 36.9998 33.2185V18.375C36.9995 18.6589 36.8489 18.9207 36.6057 19.0601Z"
          fill="url(#paint0_linear_7602_5048)"
        />
        <path
          opacity="0.5"
          d="M36.3116 18.3181V19.2281L22.9055 28.6143L9.02623 18.6244C9.02623 18.6196 9.02237 18.6156 9.01762 18.6156L7.74414 17.8369V17.1806L8.26903 17.1719L9.37902 17.8194L9.40482 17.8281L9.49947 17.8894C9.49947 17.8894 22.5441 25.4581 22.5785 25.4756L23.0776 25.7731C23.1206 25.7556 23.1636 25.7381 23.2153 25.7206C23.2411 25.7031 36.1652 18.3094 36.1652 18.3094L36.3116 18.3181Z"
          fill="#0A2767"
        />
        <path
          d="M36.6057 19.0601L36.5894 19.0697L23.7684 26.4022C23.7125 26.4372 23.6557 26.4705 23.5963 26.5002C23.0984 26.7476 22.5161 26.7476 22.0182 26.5002C21.9593 26.4705 21.9018 26.4378 21.8461 26.4022L9.02525 19.0697L9.00978 19.0601C8.76224 18.9237 8.60706 18.6613 8.60449 18.375V33.2185C8.61066 34.2087 9.405 35.0062 10.3787 35C10.3787 35 10.3787 35 10.3788 35H35.2255C36.1992 35.0063 36.9936 34.2087 36.9998 33.2186C36.9998 33.2186 36.9998 33.2185 36.9998 33.2185V18.375C36.9995 18.6589 36.8489 18.9207 36.6057 19.0601Z"
          fill="#1490DF"
        />
        <path
          opacity="0.1"
          d="M23.9553 26.293L23.7635 26.4023C23.7078 26.4383 23.6504 26.4714 23.5914 26.5012C23.38 26.6067 23.1501 26.6686 22.915 26.6832L27.793 32.5492L36.3022 34.6343C36.5353 34.4552 36.7208 34.2197 36.8417 33.9492L23.9553 26.293Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M24.8244 25.7969L23.7635 26.4032C23.7078 26.4392 23.6504 26.4723 23.5914 26.5021C23.38 26.6076 23.1501 26.6695 22.915 26.6841L25.2004 33.0917L36.3047 34.6326C36.7422 34.2986 36.9997 33.7749 37 33.2186V33.027L24.8244 25.7969Z"
          fill="black"
        />
        <path
          d="M10.4029 35H35.2229C35.6049 35.002 35.9774 34.8793 36.2856 34.65L22.1998 26.2596C22.1406 26.2294 22.0832 26.1958 22.0277 26.159L9.03471 18.6182H9.02869L8.60449 18.375V33.1677C8.60352 34.1787 9.40868 34.999 10.4029 35C10.4028 35 10.4028 35 10.4029 35Z"
          fill="#28A8EA"
        />
        <path
          opacity="0.1"
          d="M20.651 8.60387V27.2676C20.6495 27.922 20.2582 28.5102 19.6615 28.7551C19.4766 28.8359 19.2775 28.8776 19.0763 28.8776H8.60449V7.87499H10.3254V7H19.0764C19.9456 7.00335 20.6491 7.7199 20.651 8.60387Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M19.7905 9.47894V28.1427C19.7927 28.354 19.7486 28.5633 19.6615 28.7552C19.4225 29.3541 18.8515 29.7471 18.2159 29.75H8.60449V7.87507H18.2159C18.4656 7.87253 18.7116 7.93585 18.9301 8.05883C19.4575 8.32906 19.7903 8.87835 19.7905 9.47894Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M19.7905 9.47894V26.3927C19.7863 27.2762 19.0848 27.9924 18.2159 28H8.60449V7.87507H18.2159C18.4656 7.87253 18.7116 7.93585 18.9301 8.05883C19.4575 8.32906 19.7903 8.87835 19.7905 9.47894Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M18.9301 9.47887V26.3926C18.9291 27.2775 18.2256 27.9957 17.3554 28H8.60449V7.875H17.3554C18.2255 7.87547 18.9305 8.59317 18.93 9.47801C18.9301 9.47829 18.9301 9.47858 18.9301 9.47887Z"
          fill="black"
        />
        <path
          d="M1.57723 7.875H17.353C18.2241 7.875 18.9302 8.59309 18.9302 9.47887V25.5211C18.9302 26.4069 18.2241 27.125 17.353 27.125H1.57723C0.70614 27.125 0 26.4069 0 25.5211V9.47887C0 8.59309 0.70616 7.875 1.57723 7.875Z"
          fill="url(#paint1_linear_7602_5048)"
        />
        <path
          d="M4.92971 14.6036C5.31844 13.7614 5.9459 13.0565 6.73153 12.5797C7.60156 12.0732 8.59217 11.8207 9.5943 11.8499C10.5231 11.8295 11.4396 12.0689 12.2437 12.5421C12.9997 13.0005 13.6086 13.6723 13.9964 14.4758C14.4188 15.3612 14.6293 16.3356 14.6108 17.3196C14.6313 18.3478 14.4147 19.3667 13.9784 20.2945C13.5813 21.1268 12.9531 21.8225 12.1714 22.2957C11.3363 22.7833 10.3858 23.0291 9.42307 23.0062C8.47443 23.0295 7.53773 22.7873 6.71518 22.3062C5.95263 21.8471 5.3361 21.1746 4.93918 20.3689C4.51428 19.4963 4.30126 18.5328 4.31793 17.5593C4.30023 16.5398 4.50937 15.5294 4.92971 14.6036ZM6.85026 19.3548C7.05752 19.8872 7.409 20.349 7.86389 20.6866C8.32722 21.0159 8.88204 21.1857 9.44713 21.1713C10.0489 21.1955 10.6422 21.0198 11.1371 20.6708C11.5862 20.3343 11.9285 19.8713 12.1215 19.339C12.3372 18.7446 12.4437 18.115 12.4355 17.4814C12.4422 16.8418 12.3421 16.2057 12.1395 15.6002C11.9606 15.0535 11.6294 14.5714 11.1861 14.2124C10.7036 13.8469 10.112 13.6615 9.51081 13.6874C8.93346 13.6722 8.36622 13.8435 7.89056 14.1766C7.42797 14.5155 7.06983 14.9814 6.858 15.5197C6.3881 16.7536 6.38565 18.1209 6.85111 19.3566L6.85026 19.3548Z"
          fill="white"
        />
        <path d="M27.5347 5.25H35.2788V13.125H27.5347V5.25Z" fill="#50D9FF" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_7602_5048"
          x1="22.8022"
          y1="18.375"
          x2="22.8022"
          y2="35"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#35B8F1" />
          <stop offset="1" stopColor="#28A8EA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7602_5048"
          x1="3.28856"
          y1="6.62177"
          x2="15.9546"
          y2="28.1945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1784D9" />
          <stop offset="0.5" stopColor="#107AD5" />
          <stop offset="1" stopColor="#0A63C9" />
        </linearGradient>
        <clipPath id="clip0_7602_5048">
          <rect width="37" height="35" fill="white" />
        </clipPath>
      </defs>
  </SvgIcon>
);
