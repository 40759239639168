import { Box, Divider, Stack, Typography } from "@mui/material";
import { AppleButton, GoogleButton, LoadingIndicator } from "shared/components";
import { QueryDataWrapper } from "shared/api";
import { useLoginWithGoogle, useLoginWithApple } from "shared/auth";
import { useTrackEvents, AnalyticsEvent } from "shared/analytics";
import {colors} from "shared/theme";

export const SocialLogin = ({ parentLoading }: { parentLoading?: boolean }) => {
  const trackEvent = useTrackEvents();

  const { loginWithGoogle, isLoading: googleAuthLoading } =
    useLoginWithGoogle();
  const { loginWithApple, isLoading: appleAuthLoading } = useLoginWithApple();

  const clickGoogleLogin = () => {
    trackEvent(AnalyticsEvent.welcome_googleSignIn_tap);
    loginWithGoogle();
  };

  const clickAppleLogin = () => {
    trackEvent(AnalyticsEvent.welcome_appleSignIn_tap);
    loginWithApple();
  };

  const isLoading = parentLoading || googleAuthLoading || appleAuthLoading;

  return (
    <>
      <QueryDataWrapper
        result={{
          isLoading: isLoading,
          error: undefined,
          data: null,
        }}
        loadingView={<LoadingView />}
      >
        {() => (
          <Box>
            <Stack spacing={3} my={3}>
              <GoogleButton onClick={clickGoogleLogin}>
                Sign in with Google
              </GoogleButton>
              <AppleButton fullWidth onClick={clickAppleLogin}>
                Sign in with Apple
              </AppleButton>
            </Stack>
          </Box>
        )}
      </QueryDataWrapper>
      <Box pb={5}>
        <Divider>
          <Typography variant="body1" sx={{ fontSize: 14, fontWeight: 500, color: colors.haleNavy.main }}>
            OR
          </Typography>
        </Divider>
      </Box>
    </>
  );
};

const LoadingView = () => (
  <Box width={1} height={126}>
    <LoadingIndicator />
  </Box>
);
