import React from "react";
import {
  alpha,
  Box,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { Visibility, VisibilityOff, Close } from "@mui/icons-material";
import { useToggle } from "shared/utils";
import { colors } from "shared/theme";

export type InputProps = {
  label: string;
  showLabel?: boolean;
  onCancel?(): void;
  errorMessage?: string;
  infoMessage?: string;
  labelFixed?: boolean;
} & TextFieldProps;

export const Input: React.VFC<InputProps> = ({
  onCancel,
  label,
  id = label,
  infoMessage,
  errorMessage,
  type,
  showLabel = true,
  labelFixed = false,
  ...props
}) => {
  const { InputProps = {}, InputLabelProps = {}, ...muiProps } = props;

  const [showPassword, toggleShowPassword] = useToggle(false);

  const hasMessage = Boolean(infoMessage || errorMessage);
  const showInfo = Boolean(!props.error && infoMessage);
  const showError = Boolean(props.error && errorMessage);
  const isPassword = type === "password";

  const passwordAdornment = isPassword ? (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={toggleShowPassword}
        edge="end"
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  ) : undefined;

  const cancelAdornment =
    onCancel && props.value ? (
      <IconButton aria-label="reset input text" onClick={onCancel} edge="end">
        <InputAdornment position="end">
          <Close />
        </InputAdornment>
      </IconButton>
    ) : undefined;

  return (
    <Box position="relative" width={1} pb={hasMessage ? 6 : 3}>
      <StyledTextField
        variant="outlined"
        aria-describedby={infoMessage ? id + "_info" : undefined}
        aria-invalid={props.error ? "true" : "false"}
        aria-errormessage={props.error ? id + "_error" : undefined}
        aria-label={showLabel ? undefined : label}
        {...muiProps}
        label={showLabel ? label : ""}
        type={isPassword ? (!showPassword ? "password" : "text") : type}
        InputLabelProps={{
          shrink: labelFixed || undefined,
          ...InputLabelProps,
        }}
        InputProps={{
          endAdornment: passwordAdornment || cancelAdornment,
          ...InputProps,
        }}
      />
      <Box sx={visuallyHidden} id={id + "_info"}>
        {label}
      </Box>
      <ErrorMessage show={showError} id={id + "_error"}>
        {errorMessage}
      </ErrorMessage>
      <Message show={showInfo}>{infoMessage}</Message>
    </Box>
  );
};

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => !["center", "maxWidth"].includes(String(prop)),
})<InputProps>(
  ({ theme: { spacing }, disabled, error, InputProps, multiline }) => ({
    "& .MuiInputLabel-root": {
      color: getInputColor({ disabled, error }),
      transform: "translate(20px, 16px) scale(1)",
      "&[data-shrink=true]": {
        fontWeight: 500,
        transform: "translate(14px, -10px) scale(0.75)",
      },
      "&.Mui-focused": {
        color: getInputColor({ disabled, error, focused: true }),
      },
    },
    "&& .MuiOutlinedInput-root": {
      backgroundColor: "transparent",
      width: "100%",
      color: getInputColor({ disabled, error, text: true }),
      borderRadius: spacing(2),
      ...(multiline
        ? {
            padding: spacing(
              0,
              InputProps?.endAdornment ? 3.5 : 0,
              0,
              InputProps?.startAdornment ? 3.5 : 0
            ),
          }
        : undefined),
      "& .MuiOutlinedInput-input": {
        lineHeight: "24px",
        fontWeight: 400,
        fontSize: 16,
        padding: spacing(
          4,
          InputProps?.endAdornment ? 0 : 5,
          4,
          InputProps?.startAdornment ? 0 : 5
        ),
      },
      "& .MuiOutlinedInput-input::placeholder": {
        color: colors.haleNavy.main,
        opacity: 0.8,
      },
      "& fieldset": {
        borderRadius: spacing(2),
        borderColor: getInputColor({ disabled, error }),
      },
      "&:hover fieldset": {
        borderColor: getInputColor({ hover: true, error, disabled, }),
      },
      "&.Mui-focused fieldset": {
        borderColor: getInputColor({ focused: true }),
        borderWidth: 1,
      },
      "&:hover.Mui-focused fieldset": {
        borderColor: getInputColor({ focused: true }),
      },

      "& .MuiInputAdornment-positionStart": {
        color: getInputColor({ disabled, error }),
      },
      "& .MuiInputAdornment-positionEnd": {
        color: getInputColor({ disabled, error }),
      },
      "&.Mui-focused .MuiInputAdornment-positionStart": {
        color: getInputColor({ disabled, error, focused: true }),
      },
      "&.Mui-focused .MuiInputAdornment-positionEnd": {
        color: getInputColor({ disabled, error, focused: true }),
      },
      "& .MuiButtonBase-root": {
        color: getInputColor({ disabled, error }),
      },
      "&.Mui-focused .MuiButtonBase-root": {
        color: getInputColor({ disabled, error, focused: true }),
      },
    },
  })
);

export const ErrorMessage = styled(
  (props: TypographyProps & { show: boolean }) => (
    <Typography {...props} variant="caption" />
  ),
  { shouldForwardProp: (prop) => prop !== "show" }
)(({ theme: { palette, spacing }, show }) => ({
  position: "absolute",
  top: 58,
  left: 0,
  padding: spacing(1, 3),
  width: "100%",
  color: palette.error.main,
  fontSize: 11,
  lineHeight: "11px",
  visibility: show ? "initial" : "hidden",
}));

export const Message = styled(
  (props: TypographyProps & { show: boolean }) => (
    <Typography {...props} variant="caption" />
  ),
  { shouldForwardProp: (prop) => prop !== "show" }
)(({ theme: { spacing }, show }) => ({
  position: "absolute",
  top: 58,
  left: 0,
  padding: spacing(1, 3),
  width: "100%",
  color: colors.textDark,
  fontSize: 11,
  lineHeight: "11px",
  visibility: show ? "initial" : "hidden",
}));

const getInputColor = (props: {
  disabled?: boolean;
  error?: boolean;
  focused?: boolean;
  text?: boolean;
  hover?: boolean;
}) => {
  if (props.text) {
    return props.disabled
      ? alpha(colors.haleNavy.main, 0.5)
      : colors.haleNavy.main;
  }

  if (props.disabled) {
    return alpha(colors.haleNavy.main, 0.5);
  }

  if (props.focused) {
    return colors.blueAccent;
  }

  if (props.error) {
    return colors.redAlert;
  }
  
  if (props.hover) {
    return colors.haleNavy.light;
  }

  return colors.haleNavy.main;
};
