import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const LogoutIcon: React.FC<SvgIconProps> = (props) => {
  const fillProp = props.fill ?? "transparent";
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      aria-label="logout icon"
      sx={{ fill: fillProp, ...props.sx }}
    >
    
        <path
          d="M15.516 7.38948V6.45648C15.516 4.42148 13.866 2.77148 11.831 2.77148H6.95597C4.92197 2.77148 3.27197 4.42148 3.27197 6.45648V17.5865C3.27197 19.6215 4.92197 21.2715 6.95597 21.2715H11.841C13.87 21.2715 15.516 19.6265 15.516 17.5975V16.6545"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.3094 12.0214H10.2684"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.3812 9.10632L22.3092 12.0213L19.3812 14.9373"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
    </SvgIcon>
  );
};
