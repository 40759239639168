import React from "react";
import { Button, ButtonProps, lighten, styled } from "@mui/material";

type AppStoreButtonProps = ButtonProps & { target?: string };

export const AppStoreButton = styled((props: AppStoreButtonProps) => (
  <Button {...props} />
))(({ theme: { palette } }) => ({
  backgroundColor: palette.common.black,
  color: palette.common.white,
  borderRadius: 6,
  lineHeight: 1.1,
  textAlign: "left",
  "&:hover": {
    backgroundColor: lighten(palette.common.black, 0.2),
  },
}));
