import { useQuery } from "react-query";
import { QueryKey, useApi } from "shared/api";
import { config } from "shared/config";

// returns base64 string
export const useUserAvatar = (userId: string) => {
  const { get } = useApi();
  return useQuery(
    [QueryKey.UserAvatar, userId],
    get<string>(config.endpoints.user.v1.avatar.replace(/:userId/, userId)),
    { enabled: !!userId, staleTime: Infinity, cacheTime: Infinity }
  );
};
