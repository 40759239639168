import React from "react";

import { MoreVert } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Menu,
  MenuButtonProps,
  Popup,
} from "shared/components";
import { indexBy, useToggle } from "shared/utils";
import {
  ConversationEditDialog,
  useConversationsContext,
  useLeaveConversation,
  useUpdateChatInfo,
  useDeleteConversation,
} from "shared/conversations";
import { useToggleMediaBin } from "shared/media-bin";
import { paths } from "shared/router";
import { useUser } from "shared/user";
import { useChatContext } from "../context";
import { Box } from "@mui/system";

export const ChatHeaderMenu: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { conversation } = useChatContext();
  const { updateConversation, removeConversation } = useConversationsContext();

  const toggleMediaBin = useToggleMediaBin();

  const [openDialog, toggleDialog] = useToggle(false);
  const [openInformation, toggleInformation] = useToggle(false);
  const { leaveConversation, isLoading: isLeaveLoading } = useLeaveConversation(
    conversation.chatSid
  );
  const { deleteConversation, isLoading: isDeleteLoading } =
    useDeleteConversation(conversation.chatSid);

  const isMineConversation = conversation.ownerId === user.id;

  const { updateChatInfo, isLoading: isUpdateChatInfoLoading } =
    useUpdateChatInfo(conversation.chatSid, {
      onSuccess: (response) => {
        updateConversation(response);
        toggleInformation();
      },
    });

  const handleDelete = () => {
    toggleDialog();
    navigate(paths.app.conversations);
    removeConversation(conversation.chatSid);
    isMineConversation ? deleteConversation() : leaveConversation();
  };

  return (
    <Box data-testid="ChatHeaderMenu">
      <Menu MenuButton={Anchor} id="chat_header_menu">
        <Menu.Item onClick={toggleInformation}>
          {isMineConversation ? "Settings" : "Information"}
        </Menu.Item>
        <Menu.Item color="secondary" onClick={toggleMediaBin}>
          Media
        </Menu.Item>

        <Menu.Item danger onClick={toggleDialog}>
          {isMineConversation ? "Delete discussion" : "Leave discussion"}
        </Menu.Item>
      </Menu>
      <ConversationEditDialog
        iosStyle
        title={isMineConversation ? "Settings" : "Information"}
        onClose={toggleInformation}
        onSubmit={updateChatInfo}
        isLoading={isUpdateChatInfoLoading}
        dialogProps={{ open: openInformation }}
        isMineConversation={isMineConversation}
        initialData={{
          isPublic: !!conversation.publicChatUrl,
          subject: conversation.subject,
          participantsByUserId: indexBy(conversation.participants, "userId"),
        }}
        conversation={conversation}
      />
      <Popup
        id="delete_conversation"
        danger
        open={openDialog}
        onClose={toggleDialog}
        onClick={handleDelete}
        disabled={isMineConversation ? isDeleteLoading : isLeaveLoading}
        title={
          isMineConversation
            ? "Are you sure you want to delete this discussion?"
            : "Are you sure you want to leave this discussion?"
        }
        content={
          isMineConversation
            ? "If you choose to delete this discussion, please note that it will become inaccessible for all participants. Are you sure you want to proceed with the deletion?"
            : "If you leave this discussion, you won't be able to access it in the future."
        }
      />
    </Box>
  );
};

const Anchor = (props: MenuButtonProps) => (
  <IconButton label="More" icon={MoreVert} {...props} />
);
