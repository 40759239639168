import React from "react";
import { Link } from "react-router-dom";
import { visuallyHidden } from "@mui/utils";
import {
  Box,
  ButtonProps,
  Stack,
  Typography,
  Button,
  TypographyProps,
  styled,
} from "@mui/material";
import { paths } from "shared/router";
import { useIsBreakpoint } from "shared/theme";
import { ArrowLeftIcon } from "shared/components";
import { settings, useCurrentSetting } from "shared/settings";

type SettingLayoutProps = {
  onSave?: () => void;
  saveButtonProps?: ButtonProps;
};

export const SettingLayout: React.FC<SettingLayoutProps> = ({
  children,
  saveButtonProps,
  onSave,
}) => {
  const isBelowMediumBreakpoint = useIsBreakpoint("md");

  const currentSetting = useCurrentSetting();

  const title = currentSetting ? settings[currentSetting].primary : "";

  return (
    <Box width={{ xs: 1, lg: 3 / 4 }} flex={1}>
      <Stack
        width={1}
        direction="row"
        alignItems="center"
        px={{ xs: 0, md: 6 }}
        py={{ xs: 2, md: 5 }}
      >
        {isBelowMediumBreakpoint && (
          <Link to={paths.app.settings.index}>
            <Box
              flex={0}
              padding={{ xs: 4, sm: 6.5 }}
              display="flex"
              alignItems="center"
            >
              <ArrowLeftIcon sx={{ color: "secondary.main" }} />
            </Box>
            <Typography sx={visuallyHidden}>Go to previous page</Typography>
          </Link>
        )}
        <SettingTitle>{title}</SettingTitle>
        {onSave && (
          <SaveButton
            variant="text"
            size="small"
            onClick={onSave}
            {...saveButtonProps}
          >
            Save
          </SaveButton>
        )}
      </Stack>
      <Box height={1} px={{ xs: 4, sm: 6, overflow: "auto" }}>
        {children}
      </Box>
    </Box>
  );
};

const SettingTitle = styled((props: TypographyProps) => (
  <Typography variant="h3" noWrap {...props} />
))(({ theme: { palette, breakpoints } }) => ({
  fontSize: 20,
  lineHeight: "36px",
  color: palette.grey[600],
  fontWeight: 600,
  flex: 1,
}));

const SaveButton = styled(Button)(
  ({ theme: { palette, spacing, breakpoints } }) => ({
    color: palette.primary.main,
    fontSize: 14,
    padding: spacing(0, 2),
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "unset",
    },
    [breakpoints.up("sm")]: {
      fontSize: 18,
    },
  })
);
