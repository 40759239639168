import {
  Box,
  Button,
  ButtonProps,
  Divider,
  Grow,
  Paper,
  Stack,
  styled,
} from "@mui/material";
import React from "react";
import { UserAvatar } from "shared/user";
import { palette } from "shared/theme";
import { CallingUsersProps } from "./model";

type CallingUsersMenuProps = {
  callingUsersProps: CallingUsersProps;
};

export const CallingUsersMenu = ({
  callingUsersProps: { onUserAdd, onClose, participantsToShow },
}: CallingUsersMenuProps) => {
  const { height, paperRef } = useMenu(participantsToShow.length, onClose);
  return (
    <Grow in={!!participantsToShow.length}>
      <Paper
        elevation={0}
        sx={{
          position: "absolute",
          top: -height - 10,
          left: 5,
          width: 200,
          borderRadius: 4,
          minWidth: 180,
          color: "secondary.main",
          border: `1px solid ${palette.grey[200]}`,
          padding: 0,
        }}
        ref={paperRef}
      >
        <Stack maxHeight={200} width={200} divider={<Divider flexItem />}>
          {participantsToShow.map((p) => (
            <StyledButton
              startIcon={
                <UserAvatar
                  userName={p.name}
                  userId={p.userId}
                  avatarColor={p._avatarColor}
                  size={24}
                  sx={{
                    height: 24,
                    width: 24,
                    "&.MuiAvatar-root": { fontSize: 16 },
                  }}
                />
              }
              onClick={() => onUserAdd({ name: p.name, id: p.userId })}
              key={p.userId}
              variant="text"
            >
              <Box
                sx={{
                  paddingLeft: 1,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {p.name}
              </Box>
            </StyledButton>
          ))}
        </Stack>
      </Paper>
    </Grow>
  );
};

const useMenu = (participantsLenght: number, onClose: () => void) => {
  const [height, setHeight] = React.useState(0);

  const paperRef = React.useRef<HTMLDivElement>(null);

  const open = participantsLenght > 0;

  React.useEffect(() => {
    if (!participantsLenght || !paperRef.current) {
      return;
    }
    setHeight(paperRef.current.clientHeight);
  }, [participantsLenght]);

  React.useEffect(() => {
    if (!open || !paperRef.current) {
      return;
    }

    const handleClose = (e: KeyboardEvent) => {
      if (e.code === "Escape") onClose();
    };

    const handleClick = (e: MouseEvent) => {
      if (paperRef.current && e.target instanceof Node) {
        paperRef.current.contains(e.target) || onClose();
      }
    };
    document.addEventListener("keydown", handleClose);
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("keydown", handleClose);
      document.removeEventListener("click", handleClick);
      setHeight(0);
    };
  }, [open]);

  return { paperRef, height };
};

const StyledButton = styled((props: ButtonProps) => (
  <Button {...props} variant="text" />
))(({ theme: { spacing } }) => ({
  flexShrink: 0,
  padding: spacing(2.5, 4),
  justifyContent: "left",
  overflow: "hidden",
  width: "100%",
  minWidth: 0,
}));
