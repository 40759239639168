import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { Stack, StackProps, Typography } from "@mui/material";

import { Participant } from "shared/conversations";
import { UserAvatar } from "shared/user";

type ListProps = {
  participants: Participant[];
};

type ListItemProps = StackProps & {
  participant: Participant;
};

const Row = (props: ListChildComponentProps<ListProps>) => {
  const {
    data: { participants },
    style,
    index,
  } = props;

  return (
    <ListItem key={index} style={style} participant={participants[index]} />
  );
};

export const PublicChatParticipantsList: React.FC<ListProps> = ({
  participants,
}) => {
  return (
    <AutoSizer disableWidth>
      {({ height }) => (
        <FixedSizeList
          width={"100%"}
          height={height}
          itemCount={participants.length}
          itemSize={55}
          itemData={{
            participants,
          }}
        >
          {Row}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};

const ListItem: React.FC<ListItemProps> = React.memo(
  ({ participant, ...props }) => {
    return (
      <Stack
        direction={"row"}
        spacing={3}
        overflow={"hidden"}
        alignItems={"center"}
        {...props}
      >
        <UserAvatar
          userId={participant.userId}
          userName={participant.name}
          size={40}
          avatarColor={participant._avatarColor}
        />
        <Typography sx={{ flex: 1 }} noWrap>
          {participant.name}
        </Typography>
      </Stack>
    );
  },
  (prevListItem, nextListItem) => {
    return (
      prevListItem.participant.authorizationCode ===
      nextListItem.participant.authorizationCode
    );
  }
);
