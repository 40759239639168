import { Link } from "react-router-dom";
import logo from "assets/images/logo.png";
import { paths } from "../router";

type LogoProps = {
  width?: number;
  height?: number;
  publicChatView?: boolean;
};

export const Logo = ({
  width = 86,
  height = 86,
  publicChatView = false,
}: LogoProps) =>
  publicChatView ? (
    <a target="_blank" rel="noopener noreferrer" href="https://gomarkus.com">
      <img src={logo} alt="markus-logo" width={width} height={height} />
    </a>
  ) : (
    <Link to={paths.app.conversations}>
      <img src={logo} alt="markus-logo" width={width} height={height} />
    </Link>
  );
