import React from "react";
import { compose, prop, sortBy, toLower } from "ramda";

import { useSearch } from "shared/utils";
import { Contact, isCollaborator } from "./model";

const formatFn = (value: string) => {
  return value.toLowerCase().trim().replace(" ", "");
};

export function useSearchContacts(contacts: Contact[] = []) {
  const { searchValue, handleSearch, resetSearch } = useSearch();

  const filteredContacts = React.useMemo(() => {
    return sortBy(
      compose(toLower, prop("name")),
      contacts
        .map((contact) => ({
          ...contact,
          name: isCollaborator(contact) ? contact.name : contact.userName,
        }))
        .filter((contact) =>
          formatFn(contact.name).includes(formatFn(searchValue))
        )
    );
  }, [searchValue, contacts]);

  return {
    handleSearch,
    resetSearch,
    searchValue,
    filteredContacts,
  };
}
