import { Box, Stack } from "@mui/material";
import { colors } from "shared/theme";

/**
 * Layouts takes exactly 2 children components in Desktop and only one in Mobile.
 */
export const ConversationsLayout = ({
  children,
}: {
  children: React.ReactNode | [React.ReactNode, React.ReactNode];
}) => {
  const isDesktop = Array.isArray(children);
  return isDesktop ? (
    <Box display={"flex"} flex={1} height={1}>
      <Stack
        flex="1 0 40%"
        height={1}
        sx={{
          borderRight: `1px solid ${colors.diamondWhite}`,
          minWidth: 0,
          width: "100%",
        }}
      >
        {children[0]}
      </Stack>
      <Stack
        display={"flex"}
        flex="1 0 60%"
        height={1}
        sx={{ minWidth: 0, width: "100%" }}
      >
        {children[1]}
      </Stack>
    </Box>
  ) : (
    <Stack flex={1} height={1}>
      {children}
    </Stack>
  );
};
