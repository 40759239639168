export const resizeImage = async (image: File): Promise<File> => {
  const reader = new FileReader();
  const img = document.createElement("img");

  return new Promise((resolve, reject) => {
    reader.addEventListener("load", (e) => {
      if (!e.target || !e.target.result) {
        return reject("Failed to read image");
      }

      img.addEventListener("load", () => {
        const canvas = document.createElement("canvas");
        const { width: destWidth } = calculateImageSize(img);

        if (img.width >= destWidth) {
          resolve(image);
        }

        resizeInSteps({ canvas, img, destWidth });

        canvas.toBlob((result) => {
          if (!result) {
            return reject("Failed to transform image");
          }

          resolve(blobToFile(result, image.name));
        }, image.type);
      });
      img.src = e.target.result as string;
    });

    reader.readAsDataURL(image);
  });
};

const calculateImageSize = (image: HTMLImageElement, maxSize = 700) => {
  let height = image.height;
  let width = image.width;

  const isVertical = image.width < image.height;
  const isToLarge = Math.max(height, width) > maxSize;

  if (!isToLarge) {
    return { width, height };
  }

  if (isVertical) {
    width *= maxSize / height;
    height = maxSize;
  } else {
    height *= maxSize / width;
    width = maxSize;
  }

  return { width, height };
};

const blobToFile = (blob: Blob, name: string): File => {
  (blob as any).name = name;
  (blob as any).lastModified = new Date();

  return blob as File;
};

const resizeInSteps = ({
  canvas,
  img,
  destWidth,
  steps = 2,
}: {
  canvas: HTMLCanvasElement;
  img: HTMLImageElement;
  destWidth: number;
  steps?: number;
}) => {
  const ctx = canvas.getContext("2d")!;

  // setup smoothing
  if ("imageSmoothingQuality" in ctx) {
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = "high";
  }

  // no need to resize
  if (destWidth >= img.height) {
    return;
  }

  // get height form ratio
  const destHeight = destWidth * (img.height / img.width);
  const wStep = Math.floor((img.width - destWidth) / steps);
  const hStep = Math.floor((img.height - destHeight) / steps);

  let curr = {
    width: img.width - wStep,
    height: img.height - hStep,
  };

  for (let i = 0; i < steps; i++) {
    canvas.width = curr.width;
    canvas.height = curr.height;

    ctx.drawImage(img, 0, 0, curr.width, curr.height);
    curr = { width: curr.width - wStep, height: curr.height - hStep };
  }
};
