import { UseBaseQueryOptions, useQuery } from "react-query";
import { QueryKey, useApi } from "shared/api";
import { config } from "shared/config";
import { PublicInvitation } from "./types";
import { InvitationType } from "../invitations";

type GetPublicInvitationQuery = {
  type: InvitationType;
  conversationId?: string;
};

export const usePublicInvitationCheck = (
  query: GetPublicInvitationQuery,
  options: UseBaseQueryOptions<PublicInvitation> = {}
) => {
  const { get } = useApi();

  return useQuery<PublicInvitation>(
    [QueryKey.PublicInvitation, query.type, query.conversationId],
    get(config.endpoints.publicInvitations.v1.check, { query }),
    {
      ...options,
    }
  );
};
