import React from "react";
import { Client } from "@twilio/conversations";
import { useQueryClient } from "react-query";

import { QueryKey } from "shared/api";

export function useInvalidateConversations(client?: Client) {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (!client) {
      return;
    }

    const invalidateConversationsQuery = () => {
      queryClient.invalidateQueries(QueryKey.Conversations);
    };

    client.on("conversationAdded", invalidateConversationsQuery);
    client.on("conversationRemoved", invalidateConversationsQuery);

    return () => {
      client.removeListener("conversationAdded", invalidateConversationsQuery);
      client.removeListener(
        "conversationRemoved",
        invalidateConversationsQuery
      );
    };
  }, [client]);
}
