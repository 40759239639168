import { Avatar as MuiAvatar, AvatarProps, Skeleton } from "@mui/material";
import { QueryDataWrapper } from "shared/api";

type SimpleAvatarProps = AvatarProps & {
  userName: string;
  avatarColor?: string;
  size?: number;
};

type AsyncAvatarProps = AvatarProps &
  SimpleAvatarProps & { isLoading: boolean; isError: boolean; data?: string };

export const AsyncAvatar = (props: AsyncAvatarProps) => {
  const { isLoading, isError, data, ...rest } = props;

  return (
    <QueryDataWrapper
      result={{
        data,
        isLoading: isLoading,
        error: isError,
      }}
      loadingView={
        <Skeleton
          variant="circular"
          width={props.size ?? 32}
          height={props.size ?? 32}
          data-testid="avatar-loading"
        />
      }
      errorView={<SimpleAvatar {...rest} src={"/"} />}
    >
      {({ data }) => <SimpleAvatar {...rest} src={data} />}
    </QueryDataWrapper>
  );
};

export const SimpleAvatar = ({
  userName,
  size = 32,
  avatarColor = "#ccc",
  src,
  ...props
}: SimpleAvatarProps) => {
  return (
    <MuiAvatar
      aria-label={`${userName}'s avatar`}
      src={src ?? "/"}
      alt={userName}
      {...props}
      sx={{
        ...props.sx,
        fontSize: size / 2,
        width: size,
        height: size,
        backgroundColor: avatarColor,
      }}
    />
  );
};
