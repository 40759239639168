import { useShowNotification } from "../Notifications.context";

export function useShowError() {
  const showNotification = useShowNotification();
  return (error?: string, extraInfo?: string) => {
    if (!error) {
      return showNotification("Something went wrong", {
        extraInfo: "Please try again",
      });
    }

    showNotification(error, { extraInfo, type: "error" });
  };
}
