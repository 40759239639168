import { AVATAR_COLORS } from "shared/constants";

const cache: { [key: string]: string } = {};

export const appendAvatarColor = <T>(
  item: T,
  identityFn: (val: T) => string
): T & { _avatarColor: string } => {
  const key = identityFn(item);

  return {
    ...item,
    _avatarColor: getAvatarColor(key),
  };
};

export const getAvatarColor = (key: string) => {
  if (!cache[key]) {
    cache[key] = drawAvatarColor();
  }

  return cache[key];
}

const drawAvatarColor = () => {
  return AVATAR_COLORS[Math.floor(Math.random() * AVATAR_COLORS.length)];
};
