import { Box, Button, Dialog, lighten, styled } from "@mui/material";

export const StyledDialog = styled(Dialog)(
  ({ theme: { breakpoints, palette } }) => ({
    "& .MuiDialog-container": {
      [breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
      },
    },
    "& .MuiDialog-paper": {
      width: "100%",
      margin: 0,
      borderRadius: "16px 16px 0 0",
      padding: 25,
      paddingTop: 55,
      "&.MuiDialog-paper": {
        maxWidth: "unset",
      },
      [breakpoints.up("sm")]: {
        borderRadius: 14,
        margin: 16,
        "&.MuiDialog-paper": {
          maxWidth: 375,
        },
      },
    },
    "& .MuiDialogTitle-root": {
      fontSize: 20,
      padding: 8,
      fontWeight: 600,
      textAlign: "center",
      color: palette.secondary.main,
      marginBottom: 20,
    },

    "& .MuiDialogContentText-root": {
      fontSize: 16,
      lineHeight: "28px",
      textAlign: "center",
      color: palette.grey[600],
    },
    "& .MuiDialogActions-root": {
      marginTop: 40,
      display: "flex",
      justifyContent: "center",
    },
  })
);

export const StyledIconContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

export const CancelButton = styled(Button)(({ theme: { palette } }) => ({
  backgroundColor: palette.primary.main,
  color: palette.common.white,
  borderRadius: 12,
  textTransform: "none",
  width: 137,
  height: 56,
  fontSize: 16,
  "&:hover": {
    backgroundColor: palette.primary.dark,
  },
  "&.Mui-disabled": {
    backgroundColor: lighten(palette.primary.main, 0.6),
    color: palette.common.white,
  },
}));

export const AcceptButton = styled(Button)(({ theme: { palette } }) => ({
  backgroundColor: palette.common.white,
  color: palette.grey[300],
  borderColor: palette.grey[300],
  borderWidth: 1,
  borderStyle: "solid",
  borderRadius: 12,
  textTransform: "none",
  width: 137,
  height: 56,
  fontSize: 16,
  "&:hover": {
    backgroundColor: palette.grey[100],
  },
}));

export const StyledIconWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: 20,
});
