import { Box, Stack } from "@mui/material";
import { UseQueryResult } from "react-query";

import { useIsBreakpoint } from "shared/theme";
import { paths } from "shared/router";
import {
  Logo,
  PrevButtonLink,
  AppHeader,
  AvatarsGroup,
  ConversationTitle,
} from "shared/components";
import { PublicInvitation } from "shared/public-invitation";
import { useToggleMediaBin } from "shared/media-bin";
import { Conversation, participantToAvatar } from "shared/conversations";
import { ChatHeaderControlPanel } from "./ChatHeaderControlPanel";

export type ChatHeaderProps = {
  conversation: Conversation;
  isPublicChatView: boolean;
  invitation: UseQueryResult<PublicInvitation, unknown>;
  chatMenu: JSX.Element;
};
export const ChatHeader = ({
  conversation,
  isPublicChatView,
  invitation,
  chatMenu,
}: ChatHeaderProps) => {
  const isLgBreakpoint = useIsBreakpoint("lg");
  const toggleMediaBin = useToggleMediaBin();

  return (
    <AppHeader>
      <Stack
        direction={"row"}
        spacing={{ xs: 0, sm: 2, md: 3 }}
        alignItems={"center"}
        sx={{ overflow: "hidden" }}
      >
        {isPublicChatView && (
          <Box
            mt={4}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Logo width={74} height={74} publicChatView={isPublicChatView} />
          </Box>
        )}
        {isLgBreakpoint && !isPublicChatView && (
          <PrevButtonLink
            to={paths.app.conversations}
            buttonProps={{ sx: { marginLeft: { xs: -2, sm: -3 } } }}
          />
        )}
        <AvatarsGroup
          avatars={conversation.participants.map(participantToAvatar)}
        />
        <Stack
          pl={2}
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 0, md: 2 }}
          sx={{ overflow: "hidden" }}
        >
          <ConversationTitle
            fontSize={{
              xs: 20,
              sm: 24,
            }}
            sx={{ width: "100%" }}
          >
            {conversation.subject}
          </ConversationTitle>
        </Stack>
      </Stack>
      <ChatHeaderControlPanel
        emailRelayAddress={conversation.emailRelayAddress ?? ""}
        publicChatUrl={conversation.publicChatUrl}
        isPublicChatView={isPublicChatView}
        onMediaBinClick={toggleMediaBin}
        publicInvitationData={invitation}
        menu={chatMenu}
      />
    </AppHeader>
  );
};
