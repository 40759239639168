import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ReturnIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    width="14"
    height="12"
    viewBox="0 0 14 12"
    aria-label="return icon"
  >
    <path d="M12.7913 0C12.3727 0 12.0335 0.339283 12.0335 0.757809V7.35443H5.17375V4.69514C5.17375 4.52339 4.97153 4.43152 4.84218 4.54451L0.91174 7.97784C0.82049 8.05755 0.820509 8.19945 0.911781 8.27913L4.84222 11.7106C4.97158 11.8235 5.17375 11.7316 5.17375 11.5599V8.90263H12.7913C13.2101 8.90263 13.5491 8.55537 13.5491 8.12851V0.757809C13.5491 0.339283 13.2098 0 12.7913 0Z" />
  </SvgIcon>
);
