import { UseBaseQueryOptions, useQuery } from "react-query";
import { QueryKey, useApi } from "shared/api";
import { config } from "shared/config";
import { useShowError } from "shared/notifications";
import { appendAvatarColor } from "shared/utils";
import { useUser } from "shared/user";
import { Collaborator } from "../model";

type CollaboratorsResponse = {
  collaborators: Collaborator[];
};

export const useCollaborators = (
  options: UseBaseQueryOptions<CollaboratorsResponse> = {}
) => {
  const {
    user: { isSetup },
  } = useUser();
  const { get } = useApi();
  const showError = useShowError();

  return useQuery<CollaboratorsResponse>(
    [QueryKey.CollaboratorList],
    get(config.endpoints.collaborator.v2.list, {
      transformResponse: ({ collaborators }) => ({
        collaborators: collaborators.map((col) =>
          appendAvatarColor(col, (c) => c.userId || c.authorizationCode)
        ),
      }),
    }),
    {
      onError: () => showError("Failed to fetch collaborators"),
      enabled: !!isSetup,
      ...options,
    }
  );
};
