import React from "react";

export const useIsTabActive = () => {
  const [active, setActive] = React.useState(
    document.visibilityState === "visible"
  );

  React.useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        return setActive(true);
      }

      if (document.visibilityState === "hidden") {
        setActive(false);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      return document.removeEventListener(
        "visibilitychange",
        handleVisibilityChange
      );
    };
  }, []);

  return active;
};
