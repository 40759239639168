import { Box, Stack } from "@mui/material";

import { ChatEmailPreview } from "shared/chat";
import { useEmailToPreview, useMediaToPreview } from "../../context";
import { getPreviewType } from "../utils";
import { MediaPreviewType } from "../model";
import { MediaPreviewHeader } from "./MediaPreviewHeader";
import { ImagePreview } from "./ImagePreview";
import { OfficePreview } from "./OfficePreview";
import { PdfPreview } from "./PdfPreview";
import { ErrorView } from "./ErrorView";
import { VideoPreview } from "./VideoPreview";

export const MediaPreview = () => {
  const media = useMediaToPreview();
  const emailPreview = useEmailToPreview();
  const mediaPreviewType = getPreviewType(media?.contentType);

  if (emailPreview) {
    return <ChatEmailPreview />;
  }

  if (media) {
    let previewComponent: JSX.Element;

    switch (mediaPreviewType) {
      case MediaPreviewType.image:
        previewComponent = <ImagePreview media={media} />;
        break;
      case MediaPreviewType.pdf:
        previewComponent = <PdfPreview media={media} />;
        break;
      case MediaPreviewType.video:
        previewComponent = <VideoPreview media={media} />;
        break;
      case MediaPreviewType.sheet:
      case MediaPreviewType.doc:
        previewComponent = <OfficePreview media={media} />;
        break;
      default:
        previewComponent = <ErrorView />;
        break;
    }

    return (
      <Stack flex={1} height={1} overflow="hidden">
        <MediaPreviewHeader />
        <Box flex={1} px={{ xs: 2, sm: 4 }} py={6}>
          {previewComponent}
        </Box>
      </Stack>
    );
  }

  return <></>;
};
